import { EuiSpacer, EuiText } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect } from "react";

function MMLogout() {
  useEffect(() => {
    AuthenticationHelper.logout();
  }, []);

  return (
    <MMPage title={txt.get("login.logout")}>
      <EuiSpacer />
      <EuiText>{txt.get("login.logging_out")}</EuiText>
    </MMPage>
  );
}

export default MMLogout;
