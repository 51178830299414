import AuthenticationHelper from "helpers/authentication-helper";
import env from "helpers/env-helper";

const ENV_PREPEND: string = "REACT_APP_FEATURE_";

export enum Feature {
  OrdersCloseConfirm = "ORDERS_CLOSE_CONFIRM",
  OrdersReferrerDetails = "ORDERS_REFERRER_DETAILS",
  OrdersFittingQuality = "ORDERS_FITTING_QUALITY",
  TextToSentence = "TEXT_TO_SENTENCE",
  OrdersTips = "ORDERS_TIPS",
  OrdersCleanRationale = "ORDERS_CLEAN_RATIONALE",
  OrdersVlotMatching = "ORDERS_VLOT_MATCHING",
  OrdersNewIntakeScreens = "ORDERS_NEW_INTAKE_SCREENS",
  OrdersDuplicateReferral = "ORDERS_DUPLICATE_REFERRAL",
}

export const feat = (feature: Feature): boolean => {
  const envKey = `${ENV_PREPEND}${feature}`;
  const envValue = env(envKey);
  if (envValue === "1") {
    return true;
  }
  let allowedIds: string[] = envValue
    .split(",")
    .map((item: string) => item.trim());
  let personalDetailsIdString: string =
    "u" + AuthenticationHelper.getPersonalDetailsId();
  let organisationIdString: string =
    "o" + AuthenticationHelper.getOrganisationId();
  return (
    allowedIds.findIndex(
      (id: string) =>
        id === personalDetailsIdString || id === organisationIdString
    ) >= 0
  );
};
