import MMPage from "components/layouts/page/page";

function MMTOC() {
  return (
    <MMPage title="Terms & Conditions">
      <p>Explanation</p>
    </MMPage>
  );
}

export default MMTOC;
