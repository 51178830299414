import {
  EuiBasicTable,
  EuiComboBox,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiLink,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import txt from "helpers/text-helper";
import { columnString } from "hoc/helper-hooks";
import { useNavigate } from "react-router-dom";
import { Addressee, formattedAddress } from "store/data/adress/address";
import { Location, viewName } from "store/data/location/location";
import { AssignedDevice } from "store/data/scan/scanner";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMLocationsList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "location_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("location_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "location_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "location_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "location_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [error, setError] = useState("");
  const [isActiveValue, setIsActiveValue] = useLocalStorage(
    "location_is_actie",
    "_"
  );

  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisations, setSelectedOrganisations] = useLocalStorage(
    "location_organisations",
    []
  );

  useEffect(() => {
    const loadOrganisations = async () => {
      const organisations = await api.getOrganisations();
      setOrganisations(organisations);
    };

    loadOrganisations();
  }, []);

  const onOrganisationChange = (selectedOptions: any[]) => {
    setSelectedOrganisations(selectedOptions);
  };

  const toSelectOptions = (list: any[]) => {
    return list
      .map((item) => ({
        label: item.name,
        "data-id": item.id,
      }))
      .concat([
        {
          label: txt.uf(
            "generic.without_x",
            txt.get("admin.organisations.name")
          ),
          "data-id": "_",
        },
      ]);
  };

  useEffect(() => {
    const loadLocations = async () => {
      setIsLoading(true);

      let filters: any = {};
      if (selectedOrganisations && selectedOrganisations.length > 0) {
        filters.organisation_ids = selectedOrganisations.map(
          (organisation: any) => organisation["data-id"]
        );
      }
      if (isActiveValue && isActiveValue !== "_") {
        filters.is_active = isActiveValue === "yes";
      }

      if (search) {
        filters.search = search;
      }

      const result = await api.getAdminLocations(
        filters,
        limit,
        offset,
        locationFieldToSortKey(sortBy),
        sortOrder
      );
      if (result.status === "OK") {
        setLocations(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setLocations([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadLocations();
  }, [
    sortOrder,
    sortBy,
    search,
    limit,
    offset,
    selectedOrganisations,
    isActiveValue,
  ]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.locations.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.locations.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const locationFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const onLocationsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("admin.locations.id"),
      field: "id",
      sortable: api.locationIsSortableBy(locationFieldToSortKey("id")),
      style: { minWidth: "60px" },
    },
    {
      name: txt.get("admin.locations.name"),
      field: "name",
      sortable: api.locationIsSortableBy(locationFieldToSortKey("name")),
      render: (name: string, location: Location) => (
        <EuiLink href={`/admin/locations/${location.id}`}>
          <MMColumnFormatted
            value={name || "-"}
            highlight={search}
            style={{ display: "inline-block", minWidth: "150px" }}
          />
        </EuiLink>
      ),
    },
    {
      name: txt.get("admin.locations.city"),
      field: "city",
      sortable: api.locationIsSortableBy(locationFieldToSortKey("city")),
      render: (city: string, location: Location) => (
        <MMColumnFormatted
          value={city || "-"}
          noWrap={true}
          highlight={search}
        />
      ),
    },
    {
      name: txt.get("admin.locations.details"),
      field: "details",
      sortable: api.locationIsSortableBy(locationFieldToSortKey("details")),
      render: (details: string, location: Location) => (
        <MMColumnFormatted
          value={details || "-"}
          noWrap={true}
          highlight={search}
        />
      ),
    },
    {
      name: txt.get("admin.locations.view_name"),
      field: "name",
      render: (name: string, location: Location) => (
        <MMColumnFormatted value={viewName(location)} highlight={search} />
      ),
    },
    {
      name: txt.get("admin.organisations.name"),
      field: "organisation_id",
      sortable: api.locationIsSortableBy(
        locationFieldToSortKey("organisation_id")
      ),
      render: (organisation_id: number, location: Location) => (
        <MMColumnFormatted
          value={location.organisation?.name || ""}
          noWrap={false}
          highlight={search}
        />
      ),
    },
    {
      name: txt.get("generic.is_active"),
      sortable: api.locationIsSortableBy(locationFieldToSortKey("is_active")),

      type: "boolean",
      field: "is_active",
      render: (is_active: boolean) => (
        <EuiHealth color={is_active ? "success" : "danger"}>
          <MMColumnFormatted
            value={is_active ? txt.get("generic.yes") : txt.get("generic.no")}
            noWrap={true}
            highlight={search}
          />
        </EuiHealth>
      ),
    },
    {
      name: txt.get("admin.scanners.name_number_unsure"),
      sortable: api.locationIsSortableBy(
        locationFieldToSortKey("assigned_devices")
      ),
      field: "assigned_devices",
      render: (assigned_devices: AssignedDevice[]) =>
        assigned_devices
          ? assigned_devices
              .map((device: AssignedDevice) => device.device_serial)
              .join(", ")
          : "",
    },
    {
      name: txt.get("address.page_title"),
      field: "addressees",
      render: (addressees: Addressee[], location: Location) => {
        const mainAddress: string | null = formattedAddress(location, "main");
        const shippingAddress: string | null = formattedAddress(
          location,
          "shipping"
        );
        const invoiceAddress: string | null = formattedAddress(
          location,
          "invoice"
        );

        return (
          <EuiText size="xs">
            {mainAddress ? (
              <Fragment>
                <MMColumnFormatted
                  value={mainAddress}
                  noWrap={true}
                  highlight={search}
                />
                <span style={{ color: "#909090" }}> ({"main"})</span>
                <br />
              </Fragment>
            ) : (
              ""
            )}
            {shippingAddress ? (
              <Fragment>
                <MMColumnFormatted
                  value={shippingAddress}
                  noWrap={true}
                  highlight={search}
                />
                <span style={{ color: "#909090" }}> ({"shipping"})</span>
                <br />
              </Fragment>
            ) : (
              ""
            )}
            {invoiceAddress ? (
              <Fragment>
                <MMColumnFormatted
                  value={invoiceAddress}
                  noWrap={true}
                  highlight={search}
                />
                <span style={{ color: "#909090" }}> ({"invoice"})</span>
                <br />
              </Fragment>
            ) : (
              ""
            )}
          </EuiText>
        );
      },
    },
    {
      name: `${txt.get("admin.locations.vat_number")} / ${txt.get(
        "admin.locations.coc_number"
      )}`,
      field: "vat_number",
      render: (vat_number: string, location: Location) => (
        <MMCell
          size="xs"
          text={`${txt.lo("admin.locations.vat")}: ${vat_number || "-"}`}
          subText={
            location.coc_number
              ? `${txt.lo("admin.locations.coc")}: ${location.coc_number}`
              : ""
          }
        />
      ),
    },
    {
      name: "Legacy naming",
      field: "legacy_naming",
      render: (legacy_naming: any) => (
        <EuiText size="xs">
          {columnString(legacy_naming.master_sheet)}{" "}
          <span style={{ color: "#909090" }}> ({"Master sheet"})</span>
          <br />
          {columnString(legacy_naming.vlot)}
          <span style={{ color: "#909090" }}> ({"Vlot"})</span>
        </EuiText>
      ),
    },
  ];

  const getRowProps = (location: any) => {
    const { id } = location;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          navigate(`/admin/locations/${id}`);
        }
      },
    };
  };

  const isActiveOptions = [
    {
      value: "_",
      dropDownDisplay: (
        <EuiHealth color="success" style={{ lineHeight: "inherit" }}>
          {txt.get("generic.active")}
        </EuiHealth>
      ),
      inputDisplay: (
        <EuiText color="subdued" size="xs">
          {txt.uf("generic.select_x", txt.get("generic.active_state"))}
        </EuiText>
      ),
    },
    {
      value: "yes",

      inputDisplay: (
        <EuiHealth
          textSize="xs"
          color="success"
          style={{ lineHeight: "inherit" }}
        >
          {txt.get("generic.active")}
        </EuiHealth>
      ),
    },
    {
      value: "no",
      inputDisplay: (
        <EuiHealth
          textSize="xs"
          color="danger"
          style={{ lineHeight: "inherit" }}
        >
          {txt.get("generic.inactive")}
        </EuiHealth>
      ),
    },
  ];
  const onIsActiveChange = (value: string) => {
    setIsActiveValue(value);
  };
  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiComboBox
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.uf(
              "generic.select_x",
              txt.get("admin.organisations.name")
            )}
            placeholder={txt.uf(
              "generic.select_x",
              txt.get("admin.organisations.name")
            )}
            // singleSelection={{ asPlainText: true }}
            options={toSelectOptions(organisations)}
            selectedOptions={selectedOrganisations}
            onChange={onOrganisationChange}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            compressed={true}
            style={{ width: "225px" }}
            options={isActiveOptions}
            valueOfSelected={isActiveValue}
            onChange={(value) => onIsActiveChange(value)}
          ></EuiSuperSelect>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={locations}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf(
                "generic.found_no_x",
                txt.get("admin.locations.page_title")
              )
        }
        onChange={onLocationsChange}
      />
    </Fragment>
  );
}

export default MMLocationsList;
