import {
  AccessorFn,
  Axis,
  BarSeries,
  Chart,
  PartialTheme,
  Position,
  ScaleType,
  Settings,
} from "@elastic/charts";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  euiPaletteForStatus,
  EuiSelect,
  EuiText,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function MMFinance() {
  const api = new ConnectAPIHelper();
  const navigate = useNavigate();

  const availableDataFilters = [
    { value: "a", text: txt.get("finance.order_value_chart.all_order_value") },
    {
      value: "i",
      text: txt.get("finance.order_value_chart.filter_only_internal"),
    },
    {
      value: "e",
      text: txt.get("finance.order_value_chart.filter_only_external"),
    },
    { value: "b", text: txt.get("finance.order_value_chart.filter_only_b2b") },
  ];
  const [selectedDataFilter, setSelectedDataFilter] = useState<string>(
    UrlHelper.queryParam("f") || "a"
  );

  const availableGroupings = [
    {
      value: "t",
      text: txt.get("finance.order_value_chart.group_by_organisation_type"),
    },
    {
      value: "o",
      text: txt.get("finance.order_value_chart.group_by_organisation"),
    },
    {
      value: "p",
      text: txt.get("finance.order_value_chart.group_by_practitioner"),
    },
    {
      value: "l",
      text: txt.get("finance.order_value_chart.group_by_location"),
    },
  ];
  const [selectedGrouping, setSelectedGrouping] = useState<string>(
    UrlHelper.queryParam("g") || "t"
  );

  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [orderAmounts, setOrderAmounts] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    const params = UrlHelper.toQueryString({
      f: selectedDataFilter,
      g: selectedGrouping,
    });

    const uri = "/finance?".concat(params);

    const title = txt.get("finance.order_stats");
    window.history.replaceState(null, title, uri);
  }, [selectedDataFilter, selectedGrouping]);

  useEffect(() => {
    const loadOrderAmounts = async () => {
      setIsLoading(true);

      const result: ApiResponse = await api.getOrderAmounts({
        is_simple: true,
      });

      if (result.status === ApiResponseStatus.OK) {
        setOrderAmounts(result.result);
        setError("");
      } else {
        setError(result.message);
        setOrderAmounts([]);
      }

      setIsLoading(false);
    };

    loadOrderAmounts();
  }, []);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton
            aria-label={txt.get("finance.page_title")}
            size="s"
            fill={true}
            style={{ width: "40px", minInlineSize: "40px" }}
            iconType="stats"
            onClick={() => navigate("/finance")}
          />
        );
      }

      if (await AuthenticationHelper.hasPermission(["referrals#edit_all"])) {
        buttons.push(
          <EuiButton
            size="s"
            color="accent"
            onClick={() => navigate("/finance/referrals")}
          >
            {txt.get("referrals.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/order-values")}>
            {txt.get("finance.order_values.page_title")}{" "}
            {/* <EuiNotificationBadge color="accent">2</EuiNotificationBadge> */}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all"])) {
        buttons.push(
          <EuiButton
            size="s"
            onClick={() => navigate("/finance/authorizations")}
          >
            {txt.get("finance.authorizations.page_title")}
          </EuiButton>
        );
      }
      if (await AuthenticationHelper.hasPermission(["finance#read_all"])) {
        // if (buttons.length > 0) {
        //   buttons.push(<EuiIcon type="arrowRight" key="chevron3" />);
        // }
        buttons.push(
          <EuiButton size="s" onClick={() => navigate("/finance/declarations")}>
            {txt.get("finance.declarations.page_title")}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const barSeriesTheme: PartialTheme = {
    barSeriesStyle: {
      displayValue: {
        fontSize: 10,
        fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
        fontStyle: "normal",
        padding: 0,
        fill: "#000",
        offsetX: 0,
        offsetY: -2,
      },
    },
  };

  const totalSeriesTheme: PartialTheme = {
    colors: {
      vizColors: euiPaletteForStatus(2),
    },
    barSeriesStyle: {
      displayValue: {
        fontSize: 13,
        fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
        fontStyle: "normal",
        padding: 0,
        fill: "#fff",
        offsetX: 0,
        offsetY: -3,
      },
    },
  };

  const filterOrderAmountItem = (i: any): boolean => {
    // Only allow "INTERNAL" organisation type.
    if (selectedDataFilter === "i" && i.ordering !== "INTERNAL") {
      return false;
    }

    // Only allow "EXTERNAL" organisation type.
    if (selectedDataFilter === "e" && i.ordering !== "EXTERNAL") {
      return false;
    }

    // Only allow "B2B" organisation type.
    if (selectedDataFilter === "b" && i.ordering !== "B2B") {
      return false;
    }

    return true;
  };

  // A helper to list and sort all unique periods available in the data.
  // For example: '2024-01', '2024-02', '2024-03', ...
  const getAllUniquePeriodsInTheRawData = (rawData: any[]) => {
    return rawData
      .map((i: any) => i.period)
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort();
  };

  const initializeChartSeriesWithAllUniquePeriods = (
    chart: any[],
    chartSeriesName: string,
    allUniquePeriods: string[]
  ) => {
    // Initialize this whole chart series at the same time to make sure
    // that every chart series include all unique period values.
    for (const uniquePeriod of allUniquePeriods) {
      // Initialize this data point by pushing an empty data-point
      const k = { g: chartSeriesName, x: uniquePeriod, count: 0, y: 0 };
      chart.push(k);
    }
  };

  const toBarSeriesChartData = (amounts: any[]) => {
    const periods = getAllUniquePeriodsInTheRawData(amounts);

    return amounts
      .filter((i: any) => filterOrderAmountItem(i))
      .sort((a, b) => {
        switch (selectedGrouping) {
          case "o":
            // Sort the organisation name forwards.
            return b.organisation_name < a.organisation_name ? 1 : -1;
          case "l":
            // Sort the location name forwards.
            return b.location_name < a.location_name ? 1 : -1;
          case "p":
            // Sort the practitioner name forwards.
            return b.practitioner_name < a.practitioner_name ? 1 : -1;
          default:
            return 0;
        }
      })
      .reduce((chart, i) => {
        // Unlike the total-series chart, we disallow 'DRAFTED' here.
        if (["CANCELLED", "DRAFTED", "REJECTED"].includes(i.order_status)) {
          return chart;
        }

        // Determine the name of this chart series.
        let name: string = "?";
        if (selectedGrouping === "o") {
          name = i.organisation_name;
        } else if (selectedGrouping === "l") {
          name = i.location_name;
        } else if (selectedGrouping === "p") {
          name = i.practitioner_name;
        }

        if (selectedDataFilter === "a" || selectedGrouping === "t") {
          if (i.ordering === "INTERNAL") {
            name = "Manometric";
          } else if (i.ordering === "EXTERNAL") {
            name = "DW";
          } else if (i.ordering === "B2B") {
            name = "B2B";
          }
        }

        // Find the corresponding data point.
        let point = chart.find((j: any) => j.x === i.period && j.g === name);

        // Initialize this x-axis (i.period) by pushing an empty data-point
        if (!point) {
          initializeChartSeriesWithAllUniquePeriods(chart, name, periods);

          // Find the corresponding data point again.
          point = chart.find((j: any) => j.x === i.period && j.g === name);
        }

        // Accumulate the y-axis (i.amount)
        point.count += parseInt(i.order_count);
        point.y += parseFloat(i.amount);

        return chart;
      }, []);
  };

  const toTotalSeriesChartData = (amounts: any[]) => {
    const periods = getAllUniquePeriodsInTheRawData(amounts);

    return amounts
      .filter((i: any) => filterOrderAmountItem(i))
      .sort((a, b) => {
        // Sort the order_status backwards.
        return a.order_status < b.order_status ? 1 : -1;
      })
      .reduce((chart, i) => {
        // Unlike the bar-series chart, we allow 'DRAFTED' status here.
        if (["CANCELLED", "REJECTED"].includes(i.order_status)) {
          return chart;
        }

        // Determine the name of the chart series
        const name: string =
          i.order_status === "DRAFTED" ? "Drafted" : "Valued";

        // Find the corresponding data point.
        let point = chart.find((j: any) => j.x === i.period && j.g === name);

        // Initialize this x-axis (i.period) by pushing an empty data-point
        if (!point) {
          initializeChartSeriesWithAllUniquePeriods(chart, name, periods);

          // Find the corresponding data point again.
          point = chart.find((j: any) => j.x === i.period && j.g === name);
        }

        // Accumulate the y-axis (i.amount)
        point.count += parseInt(i.order_count);
        point.y += parseFloat(i.amount);

        return chart;
      }, []);
  };

  const displayValueSettings = {
    showValueLabel: true,
    isAlternatingValueLabel: false,
  };

  const yAccessorFn: AccessorFn = (d: any) => d.y || 0;

  const splitAccessorFn: AccessorFn = (d: any) => `${d.g}`;

  const renderOrderValuesChart = (amounts: any[]) => (
    <>
      <Chart title={""} size={{ height: 350, width: "100%" }}>
        <Settings
          theme={totalSeriesTheme}
          showLegend={false}
          legendPosition={Position.Bottom}
        />
        <BarSeries
          id="finance-order-value-total-series"
          displayValueSettings={displayValueSettings}
          xScaleType={ScaleType.Ordinal}
          yScaleType={ScaleType.Linear}
          xAccessor="x"
          yAccessors={[yAccessorFn]}
          stackAccessors={["x"]}
          splitSeriesAccessors={[splitAccessorFn]}
          data={toTotalSeriesChartData(amounts)}
        />
        <Axis id="bottom-axis" position="bottom" />
        <Axis
          id="left-axis"
          position="left"
          tickFormat={(d: any) => `${StringHelper.decimal(d / 1000, 1)}k`}
        />
      </Chart>
      <Chart title={""} size={{ height: 365, width: "100%" }}>
        <Settings
          theme={barSeriesTheme}
          showLegend={true}
          legendSize={50}
          legendPosition={Position.Bottom}
        />
        <BarSeries
          id="finance-order-value-chart-bar-series"
          displayValueSettings={displayValueSettings}
          xScaleType={ScaleType.Ordinal}
          yScaleType={ScaleType.Linear}
          xAccessor="x"
          yAccessors={[yAccessorFn]}
          stackAccessors={["x"]}
          splitSeriesAccessors={[splitAccessorFn]}
          data={toBarSeriesChartData(amounts)}
        />
        <Axis id="bottom-axis" position="bottom" />
        <Axis
          id="left-axis"
          position="left"
          tickFormat={(d: any) => `${StringHelper.decimal(d / 1000, 1)}k`}
        />
      </Chart>
    </>
  );

  const handleCostingsExportDownload = async (type: "csv" | "xls" = "xls") => {
    setIsDownloading(true);
    const result: any = await api.getOrderLinesValidateExport(type);
    const url =
      type === "csv"
        ? `data:application/csv;charset=utf-8,${result}`
        : `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result}`;

    const time = DateHelper.format(DateHelper.parseDate(), "yyyyMMdd_HHmm");
    const ext = type === "csv" ? "csv" : "xlsx";
    const fileName = `order_value_export_${time}.${ext}`;
    FileUploadHelper.triggerDownloadFile(url, fileName);

    setIsDownloading(false);
  };

  return (
    <MMPage
      title={txt.get("finance.order_stats")}
      topActions={buttonsToShow}
      sideActions={[
        <EuiButton
          isLoading={isDownloading}
          size="s"
          iconType="download"
          iconSide="right"
          onClick={() => {
            handleCostingsExportDownload();
          }}
        >
          {txt.get("finance.download")}
        </EuiButton>,
        <EuiButton
          isLoading={isDownloading}
          size="s"
          iconType="download"
          iconSide="right"
          onClick={() => {
            handleCostingsExportDownload("csv");
          }}
        >
          {txt.get("finance.download_csv")}
        </EuiButton>,
      ]}
    >
      {/*
      <EuiFlexItem>
        <EuiText size="s">
          <p>{txt.get("finance.intro")}</p>
        </EuiText>
      </EuiFlexItem>
      */}
      <EuiFlexGroup>
        <EuiFlexItem grow={2}>
          <EuiSelect
            options={availableDataFilters}
            value={selectedDataFilter}
            onChange={(e: any) => {
              const v = e.target.value;
              setSelectedDataFilter(v);

              const g = selectedGrouping;
              if (v === "a" && g !== "t") {
                // When you are displaying all data ("a"),
                // group it by organisation-type ("t").
                setSelectedGrouping("t");
              } else if (v === "i" && ["t", "o"].includes(g)) {
                // Grouping by organisation-type ("t") or organisation ("o")
                // doesn't make sense for Internal/Manometric ("i") data.
                //
                // When the switch is made like that, switch
                // to practitioner ("p") grouping instead.
                setSelectedGrouping("p");
              } else if ((v === "e" || v === "b") && g === "t") {
                // Grouping by organisation-type ("t") does not make
                // sense for External/DW ("e") or B2B ("b") data.
                //
                // When the switch is made like that, use
                // the location ("l") grouping instead.
                setSelectedGrouping("l");
              }
            }}
          ></EuiSelect>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiSelect
            options={availableGroupings}
            value={selectedGrouping}
            onChange={(e: any) => {
              setSelectedGrouping(e.target.value);
            }}
            disabled={selectedDataFilter === "a"}
          ></EuiSelect>
        </EuiFlexItem>
        <EuiFlexItem grow={8}></EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          {isLoading ? (
            <EuiLoadingSpinner />
          ) : error ? (
            <EuiText>{error}</EuiText>
          ) : (
            renderOrderValuesChart(orderAmounts)
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMFinance;
