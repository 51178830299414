import { Page, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { styles } from "../styles";
import MMPdfFooter from "./pdf-footer";
import MMPdfHeader from "./pdf-header";

export interface MMPdfPageProps {
  title: string;
  children: any;
  withLogo?: boolean;
}

function MMPdfPage(props: MMPdfPageProps) {
  const [title, setTitle] = useState<string>(props.title);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);
  return (
    <Page size="A4" style={[styles.page, styles.font]}>
      <MMPdfHeader title={title} />
      <View style={styles.body}>{props.children}</View>
      <MMPdfFooter />
    </Page>
  );
}

export default MMPdfPage;
