import {
  EuiButton,
  EuiComboBox,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiTextArea,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import {
  onStringValue,
  updateField,
  useStateWithCallback,
} from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Insurer, UzoviCode } from "store/data/insurer/insurer";

const INSURER_IS_ACTIVE_DEFAULT = true;

const toUzoviOptions = (uzoviCodes?: UzoviCode[] | null) => {
  if (!uzoviCodes) return [];
  return uzoviCodes.map((uzoviCode: UzoviCode) => ({
    label: `${uzoviCode.name} (${uzoviCode.code})`,
    "data-code": uzoviCode.code,
  }));
};
export interface MMInsurerProps {
  fields: {
    insurer: Insurer | null;
    specialties: string[];
  };
  handleSubmit?: Function;
}

function MMInsurer(props: MMInsurerProps) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();

  const [editAllowed, setEditAllowed] = useState(false);

  const [insurer, setInsurer] = useStateWithCallback(props.fields.insurer);
  const [uzoviCodes, setUzoviCodes] = useState<any[]>([]);
  const [uzoviCodeOptions, setUzoviCodeOptions] = useState<any[]>([]);
  const [uzoviCodeSelectedOptions, setUzoviCodeSelectedOptions] = useState<
    any[]
  >([]);

  useEffect(() => {
    const loadUzoviCodes = async () => {
      const uzoviCodes = await api.getAdminUzoviCodes(
        { only_available_for: insurer?.id || "_" },
        undefined,
        undefined,
        "name",
        "asc"
      );
      setUzoviCodes(uzoviCodes);
    };

    loadUzoviCodes();

    const setPermissions = async () => {
      setEditAllowed(
        await AuthenticationHelper.hasPermission("insurers#edit_all")
      );
    };
    setPermissions();
  }, [insurer]);

  useEffect(() => {
    setUzoviCodeOptions(toUzoviOptions(uzoviCodes));
    setUzoviCodeSelectedOptions(
      toUzoviOptions(props.fields.insurer?.uzovi_codes)
    );
  }, [props.fields.insurer, uzoviCodes]);

  const onActiveChange = (e: any) => {
    setInsurer(updateField(insurer, "is_active", e.target.checked));
  };

  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      let selectedCodes: string[] = uzoviCodeSelectedOptions.map(
        (selected: any) => selected["data-code"]
      );
      let selectedUzoviCodes: UzoviCode[] = uzoviCodes.filter(
        (uzoviCode: UzoviCode) => selectedCodes.includes(uzoviCode.code)
      );
      const updatedInsurer: Insurer = updateField(
        insurer,
        "uzovi_codes",
        selectedUzoviCodes
      );

      console.log(
        "updating insurer",
        selectedCodes,
        selectedUzoviCodes,
        updatedInsurer
      );
      props.handleSubmit(updatedInsurer);
    }
  };

  return (
    <EuiForm>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label={txt.get("insurers.name")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_name"
              value={insurer?.name || ""}
              onChange={(e) =>
                setInsurer(updateField(insurer, "name", onStringValue(e)))
              }
            />
          </EuiFormRow>
          <EuiFormRow label={txt.get("generic.acronym")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_acronym"
              value={insurer?.acronym || ""}
              onChange={(e) =>
                setInsurer(updateField(insurer, "acronym", onStringValue(e)))
              }
            />
          </EuiFormRow>
          <EuiFormRow label={txt.get("admin.insurers.uzovi_codes")}>
            <EuiComboBox
              compressed={true}
              placeholder={txt.uf(
                "generic.select_x",
                txt.get("admin.insurers.uzovi_codes")
              )}
              options={uzoviCodeOptions}
              selectedOptions={uzoviCodeSelectedOptions}
              onChange={(selected: any) => {
                setUzoviCodeSelectedOptions(selected);
              }}
            />
          </EuiFormRow>
          <EuiFormRow label={txt.get("admin.insurers.email")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_email"
              value={insurer?.email || ""}
              onChange={(e) =>
                setInsurer(updateField(insurer, "email", onStringValue(e)))
              }
            />
          </EuiFormRow>
          <EuiFormRow label={txt.get("admin.insurers.website")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_website"
              value={insurer?.website || ""}
              onChange={(e) =>
                setInsurer(updateField(insurer, "website", onStringValue(e)))
              }
            />
          </EuiFormRow>{" "}
          <EuiFormRow
            label={
              <span id="insurer-is-active">
                {txt.get("admin.insurers.is_active")}
              </span>
            }
          >
            <EuiSwitch
              disabled={!editAllowed}
              onChange={onActiveChange}
              label={
                (
                  insurer &&
                  insurer.is_active !== undefined &&
                  insurer.is_active !== null
                    ? insurer.is_active
                    : INSURER_IS_ACTIVE_DEFAULT
                )
                  ? txt.get("generic.yes")
                  : txt.get("generic.no")
              }
              checked={
                insurer &&
                insurer.is_active !== undefined &&
                insurer.is_active !== null
                  ? insurer.is_active
                  : INSURER_IS_ACTIVE_DEFAULT
              }
              aria-describedby="insurer-is-active"
            />
          </EuiFormRow>{" "}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={txt.get("admin.insurers.quotation_contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_quotation_contact"
              value={insurer?.quotation_contact || ""}
              onChange={(e) =>
                setInsurer(
                  updateField(insurer, "quotation_contact", onStringValue(e))
                )
              }
            />
          </EuiFormRow>

          <EuiFormRow label={txt.get("admin.insurers.quotation_type")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_quotation_type"
              value={insurer?.quotation_type || ""}
              onChange={(e) =>
                setInsurer(
                  updateField(insurer, "quotation_type", onStringValue(e))
                )
              }
            />
          </EuiFormRow>

          <EuiFormRow label={txt.get("admin.insurers.quotation_answer")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="insurer_quotation_answer"
              value={insurer?.quotation_answer || ""}
              onChange={(e) =>
                setInsurer(
                  updateField(insurer, "quotation_answer", onStringValue(e))
                )
              }
            />
          </EuiFormRow>

          <EuiFormRow label={txt.get("admin.insurers.payment_term_days")}>
            <EuiFieldNumber
              disabled={!editAllowed}
              name="insurer_payment_term_days"
              value={insurer?.payment_term_days || ""}
              onChange={(e) =>
                setInsurer(
                  updateField(insurer, "payment_term_days", onStringValue(e))
                )
              }
            />
          </EuiFormRow>

          <EuiFormRow label={txt.get("admin.insurers.declaration_term_days")}>
            <EuiFieldNumber
              disabled={!editAllowed}
              name="insurer_declaration_term_days"
              value={insurer?.declaration_term_days || ""}
              onChange={(e) =>
                setInsurer(
                  updateField(
                    insurer,
                    "declaration_term_days",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={txt.get("insurers.contract_notes")}>
            <EuiTextArea
              disabled={!editAllowed}
              rows={15}
              name="insurer_contract_notes"
              value={insurer?.contract_notes || ""}
              onChange={(e) =>
                setInsurer(
                  updateField(insurer, "contract_notes", onStringValue(e))
                )
              }
            />
          </EuiFormRow>

          <EuiSpacer />
        </EuiFlexItem>
      </EuiFlexGroup>

      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMInsurer;
