import { Text, View } from "@react-pdf/renderer";
import txt from "helpers/text-helper";
import {
  getStatusDateString,
  Order,
  OrderLine,
  OrderStatus,
} from "store/data/order/order";
import { styles } from "../styles";
import MMPdfProductDetails from "./pdf-product-details";

export interface MMPdfOrderProductsProps {
  order: Order;
  orderLines: OrderLine[]; //a subselection of order lines to print
}

function MMPdfOrderProducts(props: MMPdfOrderProductsProps) {
  return (
    <View>
      <View style={styles.spacer} />
      <View style={styles.columns}>
        <View style={[styles.section_6, styles.tableHeader]}>
          <Text style={styles.title}>
            {txt.get("pdfs.client_dossier.production")}
          </Text>
        </View>
        <View
          style={[
            styles.section_6,
            styles.tableHeader,
            styles.right,
            { paddingTop: "4pt" },
          ]}
        >
          <Text style={(styles.subtitle, { paddingTop: "4pt" })}>
            {getStatusDateString(
              OrderStatus.Produced,
              props.order.order_status_logs
            ) || "-"}
          </Text>
        </View>
      </View>
      <View style={styles.table}>
        <View style={[styles.tableHeader, styles.columns]}>
          <View style={styles.section_4}>
            <Text style={styles.soft}>{txt.get("pdfs.quotation.product")}</Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_1")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_2")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_3")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_4")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_l1")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_l2")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_s")}
            </Text>
          </View>
          <View style={styles.section_1}>
            <Text style={styles.soft}>
              {txt.get("orders.order.measurement_j")}
            </Text>
          </View>
        </View>
        {props.orderLines.map((orderLine: OrderLine, i: number) => (
          <View key={`order-line-${i}`}>
            <View style={[styles.columns, styles.small]}>
              <View style={styles.section_4}>
                <Text>{orderLine.code}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.o1 || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.o2 || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.o3 || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.o4 || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.l1 || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.l2 || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.s || "-"}</Text>
              </View>
              <View style={styles.section_1}>
                <Text>{orderLine.measurements?.j || "-"}</Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.columns, styles.small]}>
              <View style={styles.section_4}>
                <MMPdfProductDetails orderLine={orderLine} />
              </View>
              <View style={styles.section_8}>
                <Text>
                  {orderLine.order_type}
                  {orderLine.remake_reason
                    ? `, ${txt.lo("pdfs.products.reason")}: ${orderLine.remake_details?.reason || orderLine.remake_reason}`
                    : ""}
                  {orderLine.replacement_for
                    ? `, ${txt.lo("pdfs.products.replaces")}: ${orderLine.replacement?.code || orderLine.replacement_for}`
                    : ""}
                </Text>
                <Text>{orderLine.measurements?.notes || ""}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}
export default MMPdfOrderProducts;
