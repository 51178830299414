export interface ClientInsurance {
  id: number;
  client_id: number;
  reference_date: Date;
  registration_number: string;
  insurer_uzovi_code: string;
  insurance_code: string;
  insurer_name: string;
  insurance_name: string;
  insurer_number: string;
}
//todo add Insurance object
export const toInsuranceDetails = (insurance: ClientInsurance) => {
  let result: string = "";
  if (insurance) {
    result += `${insurance.insurer_name} (${insurance.insurer_uzovi_code})`;

    result += `, ${insurance.insurance_name}`;
  }
  return result;
};
