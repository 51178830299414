import { EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { adminNav } from "./admin";
import MMOrderMatchesList from "./order-matches-list";
function MMAdminDataOrderMatches() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);

  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/order-matches"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo(
        "admin.data.order_matches.page_title"
      )}`}
      subPages={subPages}
      backTo="/admin/"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMOrderMatchesList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminDataOrderMatches;
