import {
  EuiBasicTableColumn,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPanel,
} from "@elastic/eui";
import MMCell from "components/layouts/table/cell";
import MMMemoryTable from "components/layouts/table/memory-table";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";

export interface MMEvalutionInfoProps {
  client: Client | null;
  evaluations: any[];
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMEvaluationInfo(props: MMEvalutionInfoProps) {
  const [client, setClient] = useState<Client>();
  const [evaluations, setEvaluations] = useState<any[]>([]);

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    if (props.evaluations) {
      setEvaluations(props.evaluations);
    } else {
      setEvaluations([]);
    }
  }, [props.evaluations, props.client, props.isHeaderShown]);

  const getColumns = (): Array<EuiBasicTableColumn<any>> => [
    {
      name: "",
      field: "production_code",
      render: (productionCode: string) => (
        <MMCell size="xs" text={productionCode} />
      ),
    },
    {
      name: "",
      field: "rating",
      render: (rating: number | null) => (
        <MMCell size="xs" text={rating !== null ? `${rating}/10` : "-"} />
      ),
    },
    {
      name: "",
      field: "timestamp",
      render: (timestamp: Date) => (
        <MMCell size="xs" text={DateHelper.toDate(timestamp)} />
      ),
    },
  ];

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <MMTitle text={txt.get("clients.care_info.evaluations.name")} />
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <MMMemoryTable items={evaluations} columns={getColumns()} />
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMEvaluationInfo;
