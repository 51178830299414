import { EuiThemeModifications } from "@elastic/eui";
const MMThemeSettings = JSON.parse(`
  {
    "colors": {
      "LIGHT": {
        "primary": "#3B7879",
        "accent": "#1d5d5e",
        "success": "#57bba4",
        "warning": "#f6d679",
        "danger": "#ae3629",
        "warningText": "#bcb311"
      },
      "DARK": {
        "primary": "#3B7879",
        "accent": "#1d5d5e",
        "success": "#57bba4",
        "warning": "#f6d679",
        "danger": "#ae3629",
        "warningText": "#bcb311"
      }
    },
   
    "border": {
      "color": "#e4eae6"
    },
    "base" : 16
  }

`) as EuiThemeModifications;

// const MMThemeSettings = {
//     colors: {
//         LIGHT: {
//             primary: '#36786A',
//             brand: '#36786A',
//             title: '#36786A',
//             text: '#333333',
//             accent: '#0000FF',
//         },
//         DARK: {
//             primary: '#36786A',
//             brand: '#36786A',
//             title: '#aaaaaa',
//             text: '#36786A',
//             accent: '#0000FF',
//         }
//       },
//     font: {
//       family: 'proxima-nova',
//       familySerif: 'tasman',
//     },

//     // base: 18,
// } as EuiThemeModifications

export const disableEmotionWarnings = () => {
  const log = console.error.bind(console);
  console.error = (...args) => {
    if (
      typeof args[0] === "string" &&
      args[0].includes("The pseudo class") &&
      args[0].includes(
        "is potentially unsafe when doing server-side rendering. Try changing it to"
      )
    ) {
      return;
    }
    log(...args);
  };
};

disableEmotionWarnings();
export default MMThemeSettings;
