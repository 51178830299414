import { MMOrderLineProductInput } from "components/orders/order-entry/order-line-entry";
import txt from "./text-helper";

const productOption = (
  product: any,
  lang: string,
  orderLine?: MMOrderLineProductInput,
  orderLineIndex?: number,
  selectBy: "family" | "name" = "family"
) => ({
  data_order_line: orderLine || null,
  data_order_line_index: orderLineIndex,
  data_product_photos:
    product && product.product_photos ? product.product_photos : [],

  label: product
    ? selectBy === "name" && product[`variant_name_${lang}`]
      ? product[`variant_name_${lang}`]
      : product[`translation_family_${lang}`]
        ? product[`translation_family_${lang}`]
        : product.family
    : orderLine?.name,

  data_name: product
    ? product && product.translations && product.translations[lang]
      ? product.translations[lang].name
      : product.name
    : orderLine?.name,
  data_family: product
    ? product[`translation_family_${lang}`]
      ? product[`translation_family_${lang}`]
      : product.family
    : orderLine?.name,
  value: product
    ? selectBy === "family"
      ? product.family
        ? product.family
        : ""
      : product.name
        ? product.name
        : ""
    : orderLine?.name,
});
const selectableProductOptions = (
  productVariations: any[],
  lang: string,
  selectBy: "name" | "family" = "family"
) => {
  let selectableOptions: any = {};

  const sortOrder: any = {
    "Finger (DIP-PIP-IP)": 1,
    "Finger (MCP 2-5)": 2,
    "Thumb (CMC1-MCP1)": 3,
    Wrist: 4,
    Special: 5,
    Adjustment: 6,
    Confection: 7,
    "Ankle foot": 8,
    Neck: 9,
    Legacy: 10,
    "R&D Development": 11,
  };

  let filteredProducts: any = {};
  for (let i = 0; i < productVariations.length; i++) {
    const variation = productVariations[i];
    const key =
      (selectBy === "family" ? variation.family : variation.name) +
      variation.category;
    if (!filteredProducts[key]) {
      filteredProducts[key] = variation;
    }
  }
  filteredProducts = Object.values(filteredProducts);

  //list only the first of every family / category combination
  filteredProducts = productVariations.filter(
    (prod: any, index: number, array: any[]) =>
      array.findIndex(
        (t) =>
          (selectBy === "family"
            ? t.family === prod.family
            : t.name === prod.name) && t.category === prod.category
      ) === index
  );

  for (let i = 0; i < filteredProducts.length; i++) {
    const product = filteredProducts[i];
    if (!selectableOptions[product.category]) {
      selectableOptions[product.category] = {
        data_category: product.category,
        label: product.translations[lang]
          ? product.translations[lang].category
          : product.category,
        options: [],
      };
    }
    selectableOptions[product.category].options.push(
      productOption(product, lang, undefined, undefined, selectBy)
    );
  }
  selectableOptions = Object.values(selectableOptions);
  selectableOptions.sort(
    (a: any, b: any) => sortOrder[a.data_category] - sortOrder[b.data_category]
  );

  // console.log(
  //   "selectableProductOptions",
  //   productVariations,
  //   lang,
  //   selectBy,
  //   selectableOptions
  // );
  /* if the result is only in one category 
     (which is probably for names withing one family) 
     just directly show the options, not categorised. 
     this is hacky
     */
  return selectableOptions.length === 1 && selectBy === "name"
    ? selectableOptions[0].options
    : selectableOptions;
};

const selectableCategoryOptions = (
  productVariations: any[],
  lang: string,
  withSubLocations: boolean = false
) => {
  const locationCat: string = txt.get("products.categories.body_part");
  const colorCat: string = txt.get("products.categories.color");
  const materialCat: string = txt.get("products.categories.material");
  let categories: any = {
    [locationCat]: {},
    [colorCat]: {},
    [materialCat]: {},
  };

  const OTHER_CATEGORY = txt.get("generic.other");

  for (let i = 0; i < productVariations.length; i++) {
    const variation: any = productVariations[i];

    //location
    const generalLocation: string =
      variation[`general_location_${lang}`] ||
      variation[`general_location_en`] ||
      OTHER_CATEGORY;
    const location: string =
      variation[`location_${lang}`] || variation[`location`] || OTHER_CATEGORY;

    const locationKey: string = variation[`location`] || "";

    if (withSubLocations) {
      if (!categories[locationCat][generalLocation]) {
        categories[locationCat][generalLocation] = {};
      }
      if (
        generalLocation !== location &&
        !categories[locationCat][generalLocation][location]
      ) {
        categories[locationCat][generalLocation][location] = locationKey;
      }
    } else {
      if (!categories[locationCat][generalLocation]) {
        categories[locationCat][generalLocation] = generalLocation;
      }
    }

    //color
    const color: string = variation[`color_${lang}`] || OTHER_CATEGORY;

    const colorKey: string = variation[`color_code`] || OTHER_CATEGORY;

    if (!categories[colorCat][color]) {
      categories[colorCat][color] = colorKey;
    }

    //material
    const material: string =
      variation[`material_for_statement_${lang}`] || OTHER_CATEGORY;

    if (!categories[materialCat][material]) {
      categories[materialCat][material] = material;
    }
  }

  for (const cat in categories) {
    const ordered = Object.keys(categories[cat])
      .sort()
      .reduce((obj: any, key) => {
        obj[key] = categories[cat][key];
        return obj;
      }, {});

    categories[cat] = ordered;
  }

  //no material and color selection for now.....
  delete categories[colorCat];
  delete categories[materialCat];
  return categories;
};

export { productOption, selectableProductOptions, selectableCategoryOptions };
