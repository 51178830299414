import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { MMLink } from "components/layouts/navigation/link";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import txt from "helpers/text-helper";
import { columnDateShort } from "hoc/helper-hooks";
import { useNavigate } from "react-router-dom";
import { Addressee, formattedAddress } from "store/data/adress/address";
import { UzoviCode } from "store/data/insurer/insurer";
import { useLocalStorage } from "store/local-storage";
import { ADDRESS_NAME_FINANCIAL } from "./agency";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMAgencyList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [agencies, setAgencies] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "agencies_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("agencies_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "agencies_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "agencies_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [error, setError] = useState("");

  useEffect(() => {
    const loadAgencies = async () => {
      setIsLoading(true);
      const result = await api.getAdminUzoviCodes(
        {},
        limit,
        offset,
        agenciesFieldToSortKey(sortBy),
        sortOrder,
        false
      );

      if (result.status === ApiResponseStatus.OK) {
        setAgencies(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setAgencies([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadAgencies();
  }, [sortOrder, sortBy, limit, offset]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf(
          "generic.found_no_x",
          txt.get("admin.insurers.agencies.page_title")
        )
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.insurers.agencies.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    // enableAllColumns: false,
    // readOnly: false,
  };

  const agenciesFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onAgenciesChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("generic.id"),
      field: "id",
      sortable: api.uzoviCodesSortableBy(agenciesFieldToSortKey("id")),
      render: (id: number) => <MMCell text={id + ""} wrap={false} />,
    },
    {
      name: txt.get("admin.insurers.name"),
      field: "insurer_id",
      sortable: api.uzoviCodesSortableBy(agenciesFieldToSortKey("insurer_id")),
      render: (insurer_id: number, uzoviCode: UzoviCode) =>
        insurer_id ? (
          <MMLink href={`/admin/insurers/${insurer_id}`}>
            {uzoviCode.insurer?.acronym}
          </MMLink>
        ) : (
          "-"
        ),
    },
    {
      name: txt.get("admin.insurers.agencies.name"),
      field: "name",
      sortable: api.uzoviCodesSortableBy(agenciesFieldToSortKey("name")),
      render: (name: string, uzoviCode: UzoviCode) =>
        name ? (
          <MMLink href={`/admin/insurers/agencies/${uzoviCode.id}`}>
            {name}
          </MMLink>
        ) : (
          "-"
        ),
    },
    {
      name: txt.get("admin.insurers.uzovi_code"),
      field: "code",
      sortable: api.uzoviCodesSortableBy(agenciesFieldToSortKey("uzovi_codes")),
      render: (code: string) => <MMCell text={code} />,
    },
    {
      name: txt.get("address.page_title"),
      field: "addressees",
      render: (addressees: Addressee[], uzoviCode: UzoviCode) => {
        const financeAddress: string | null = formattedAddress(
          uzoviCode,
          ADDRESS_NAME_FINANCIAL
        );

        return (
          <EuiText size="xs">
            {financeAddress ? (
              <Fragment>
                <MMColumnFormatted
                  value={financeAddress}
                  noWrap={true}
                  // highlight={search}
                />
                <span style={{ color: "#909090" }}>
                  {" "}
                  ({ADDRESS_NAME_FINANCIAL})
                </span>
                <br />
              </Fragment>
            ) : (
              ""
            )}
          </EuiText>
        );
      },
    },
    {
      name: txt.get("generic.updated_at"),
      sortable: api.uzoviCodesSortableBy(agenciesFieldToSortKey("updated_at")),
      type: "date",
      field: "updated_at",
      render: columnDateShort,
    },
  ];

  const getRowProps = (agency: UzoviCode) => {
    const { id } = agency;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          navigate(`/admin/insurers/agencies/${id}`);
        }
      },
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={agencies}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf(
                "generic.found_no_x",
                txt.get("admin.insurers.agencies.page_title")
              )
        }
        onChange={onAgenciesChange}
      />
    </Fragment>
  );
}

export default MMAgencyList;
