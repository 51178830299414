import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiProgress,
  EuiSelectable,
  EuiSpacer,
  EuiSwitch,
  EuiText,
} from "@elastic/eui";
import AuthAdminAPIHelper from "api/auth-admin-api-helper";
import txt from "helpers/text-helper";
import { useStateWithCallback } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertConfirmActionState,
  AlertInputValueType,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { AuthGroup } from "store/data/auth/group";
import { AuthRole } from "store/data/auth/role";
import { AuthSocialLogin, AuthUser } from "store/data/auth/user";
import { PersonalDetails } from "store/data/personal-details/personal-details";
import { v4 as uuid } from "uuid";
export interface MMUserCredentialsProps {
  fields: {
    user: PersonalDetails | null;
  };
  handleSubmit?: Function;
}

function MMUserCredentials(props: MMUserCredentialsProps) {
  const dispatch = useDispatch();
  const api = new AuthAdminAPIHelper();
  const [isDemo, setIsDemo] = useState(false);

  const [resetRef] = useState("reset_password" + uuid());
  const [createRef] = useState("create_password" + uuid());
  const [user] = useStateWithCallback(props.fields.user);
  const [credentials, setCredentials] = useState<AuthUser>();
  const [roles, setRoles] = useState<AuthRole[]>([]);
  const [rolesSelection, setRoleSelection] = useState<any[]>([]);
  const [groups, setGroups] = useState<AuthGroup[]>([]);
  const [groupsSelection, setGroupSelection] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadCredentials = async () => {
    if (user && user.username) {
      const result = await api.getUser(user.username);
      console.log("loadCredentials", result);
      if (result) {
        setCredentials(result);
      }
    } else {
      console.log("loadCredentials no user to load credentials for");
    }
    setIsLoading(false);
  };

  const loadRoles = async () => {
    const result = await api.getRoles();
    console.log("loadRoles", result, credentials?.roles);

    setRoles(result);
  };

  const loadGroups = async () => {
    const result = await api.getGroups();
    console.log("loadGroups", result, credentials?.groups);

    setGroups(result);
  };

  const createCredentials = async () => {
    setIsLoading(true);
    if (user) {
      let newCredentials: AuthUser = {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        username: user.email,
        emailVerified: true,
        enabled: true,
      };
      if (user.organisation_id) {
        newCredentials.attributes = [
          { organisation_id: ["" + user.organisation_id] },
        ];
      }
      const result = await api.createUser(newCredentials);
      console.log("createUser", result);
      await loadCredentials();
    } else {
      console.error("no personalDetails to create userCredentials for");
    }
  };

  const saveCredentials = async () => {
    setIsLoading(true);
    if (user) {
      let updatedCredentials: AuthUser | undefined = { ...credentials };
      cleanUpCredentials(updatedCredentials);
      //update isDemo
      if (!updatedCredentials.attributes) {
        updatedCredentials.attributes = {};
      }

      if (isDemo) {
        updatedCredentials.attributes.is_demo = ["1"];
      } else if (updatedCredentials.attributes.is_demo) {
        delete updatedCredentials.attributes.is_demo;
      }

      let addRoles: AuthRole[] = [];
      let removeRoles: AuthRole[] = [];

      const originalRoles: AuthRole[] =
        credentials && credentials.roles ? credentials.roles : [];

      const selectedRoles: any[] = rolesSelection.filter(
        (role: any) => role.checked === "on"
      );

      if (credentials && credentials.roles) {
        addRoles = roles.filter(
          (role: AuthRole) =>
            originalRoles.findIndex((ori: any) => ori.id === role.id) < 0 &&
            selectedRoles.findIndex((sel: any) => sel.id === role.id) >= 0
        );

        removeRoles = roles.filter(
          (role: AuthRole) =>
            originalRoles.findIndex((ori: any) => ori.id === role.id) >= 0 &&
            selectedRoles.findIndex((sel: any) => sel.id === role.id) < 0
        );
      }

      let addGroups: AuthGroup[] = [];
      let removeGroups: AuthGroup[] = [];

      const originalGroups: AuthGroup[] =
        credentials && credentials.groups ? credentials.groups : [];

      const selectedGroups: any[] = groupsSelection.filter(
        (group: any) => group.checked === "on"
      );

      if (credentials && credentials.groups) {
        addGroups = groups.filter(
          (group: AuthGroup) =>
            originalGroups.findIndex((ori: any) => ori.id === group.id) < 0 &&
            selectedGroups.findIndex((sel: any) => sel.id === group.id) >= 0
        );

        removeGroups = groups.filter(
          (group: AuthGroup) =>
            originalGroups.findIndex((ori: any) => ori.id === group.id) >= 0 &&
            selectedGroups.findIndex((sel: any) => sel.id === group.id) < 0
        );
      }

      const result: any = await api.saveUser(
        updatedCredentials,
        addRoles,
        removeRoles,
        addGroups,
        removeGroups
      );

      console.log(
        "saveUser",
        addRoles,
        removeRoles,
        addGroups,
        removeGroups,
        result
      );
      dispatch(
        toastAdd(
          txt.uf("generic.are_saved", txt.get("admin.users.user_credentials")),
          null,
          "success"
        )
      );
      await loadCredentials();
    } else {
      console.error("no userCredentials found to save");
    }
  };

  const handleCreatePassword = () => {
    dispatch(
      confirmAsk(
        `${txt.get("account.password")}.`,
        `${txt.get("admin.users.create_password_help")}.`,
        createRef,
        {},
        {
          valueType: AlertInputValueType.Password,
          description: txt.get("account.password"),
          isMandatory: true,
          isVisible: true,
        }
      )
    );
  };

  const cleanUpCredentials = (credentials: any) => {
    if (credentials.socialLogins) {
      delete credentials.socialLogins;
    }
    if (credentials.groups) {
      delete credentials.groups;
    }
    if (credentials.roles) {
      delete credentials.roles;
    }
    credentials.credentials = [];
  };

  const createPassword = async (password?: string) => {
    if (password) {
      let updatedCredentials: AuthUser = { ...credentials };
      updatedCredentials.credentials = [];
      cleanUpCredentials(updatedCredentials);
      updatedCredentials.credentials.push({
        type: "password",
        value: password,
        temporary: true,
      });

      const result = await api.saveUser(updatedCredentials);
      if (result.content.success) {
        await loadCredentials();
      }
    } else {
      console.error("no password passed", password);
    }
  };
  const handleResetPassword = () => {
    dispatch(
      confirmAsk(
        `${txt.get("account.password_reset")}.`,
        txt.get("admin.users.password_reset_confirm"),
        resetRef
      )
    );
  };

  const resetPassword = async () => {
    if (
      credentials &&
      credentials.id &&
      credentials.credentials &&
      credentials.credentials.length > 0
    ) {
      const result = await api.resetPassword(credentials.id);
      if (result.content.success) {
        dispatch(
          toastAdd(txt.get("admin.users.password_reset_done"), null, "success")
        );
      }
    } else {
      console.error("No credentials to reset password for");
    }
  };

  useEffect(() => {
    if (roles && credentials && credentials.roles) {
      const userRoles: any[] = credentials.roles;
      const roleSelection = roles.map((role: any) => ({
        id: role.id,
        label: role.name,
        checked:
          userRoles.findIndex(
            (userRole: AuthRole) => userRole.id === role.id
          ) >= 0
            ? "on"
            : undefined,
      }));
      roleSelection.sort((a: any, b: any) => {
        if (a.label === "user" && b.label !== "user") {
          return -1;
        } else if (b.label === "user" && a.label !== "user") {
          return 1;
        } else if (a.label === "admin" && b.label !== "admn") {
          return 1;
        } else if (b.label === "admin" && a.label !== "admin") {
          return -1;
        } else {
          return a < b ? -1 : a > b ? 1 : 0;
        }
      });
      console.log("roleSelection", roles, userRoles, roleSelection);
      setRoleSelection(roleSelection);
    }
  }, [credentials, roles]);

  useEffect(() => {
    if (groups && credentials && credentials.groups) {
      const userGroups: any[] = credentials.groups;
      const groupSelection = groups.map((group: any) => ({
        id: group.id,
        label: group.name,
        checked:
          userGroups.findIndex(
            (userGroup: AuthGroup) => userGroup.id === group.id
          ) >= 0
            ? "on"
            : undefined,
      }));

      // groupSelection.sort((a: any, b: any) => {
      //   if (a.label === "user" && b.label !== "user") {
      //     return -1;
      //   } else if (b.label === "user" && a.label !== "user") {
      //     return 1;
      //   } else if (a.label === "admin" && b.label !== "admn") {
      //     return 1;
      //   } else if (b.label === "admin" && a.label !== "admin") {
      //     return -1;
      //   } else {
      //     return a < b ? -1 : a > b ? 1 : 0;
      //   }
      // });
      console.log("groupSelection", groups, userGroups, groupSelection);
      setGroupSelection(groupSelection);
    }
  }, [credentials, groups]);

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === resetRef
    ) {
      resetPassword();
    }
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === createRef
    ) {
      createPassword(alertConfirm.input?.value);
    }
  }, [alertConfirm]);

  useEffect(() => {
    setIsLoading(true);

    loadRoles();
    loadGroups();
    loadCredentials();
  }, []);

  useEffect(() => {
    if (
      credentials &&
      credentials.attributes &&
      credentials.attributes.is_demo
    ) {
      setIsDemo(credentials.attributes.is_demo.includes("1"));
    }
  }, [credentials]);

  const onCredentialsCreate = (e: any) => {
    createCredentials();
  };

  const onDemoChange = (e: any) => {
    setIsDemo(e.target.checked);
  };

  const renderSocialLoginOptions = (options: AuthSocialLogin[]) => {
    return options.length > 0 ? (
      <EuiFormRow label={txt.get("account.social")}>
        <EuiFlexGrid key={"social"}>
          {options.map((option) => (
            <EuiFlexItem key={option.userId}>
              <EuiText>
                {`${option.userName} (${option.identityProvider})`}
              </EuiText>
            </EuiFlexItem>
          ))}
        </EuiFlexGrid>
      </EuiFormRow>
    ) : (
      <></>
    );
  };

  return (
    <EuiPanel>
      <EuiForm>
        {isLoading ? <EuiProgress size="s" color="accent" /> : <></>}
        <EuiFormRow
          helpText={
            credentials ? null : txt.get("admin.users.credentials_help")
          }
          label={txt.get("admin.users.user_credentials")}
        >
          {credentials && credentials.id ? (
            <EuiFieldText
              name="username"
              value={credentials.username || ""}
              onChange={() => {}}
              disabled={true}
            ></EuiFieldText>
          ) : (
            <EuiButtonEmpty
              onClick={onCredentialsCreate}
              iconType="lock"
              iconSide="right"
              size="s"
            >
              {txt.get("admin.users.create_credentials")}
            </EuiButtonEmpty>
          )}
        </EuiFormRow>
        {credentials && credentials.id ? (
          <Fragment>
            <EuiFormRow
              label={
                <span id="user-is-demo">
                  {txt.get("admin.organisations.demo")}
                </span>
              }
            >
              <EuiSwitch
                onChange={onDemoChange}
                label={isDemo ? txt.get("generic.on") : txt.get("generic.off")}
                checked={isDemo}
                aria-describedby="user-is-demo"
              />
            </EuiFormRow>
            <EuiFormRow label={txt.get("account.roles")}>
              <EuiSelectable
                options={rolesSelection}
                aria-label={txt.uf(
                  "generix.select_x",
                  txt.get("account.roles")
                )}
                listProps={{ bordered: true }}
                onChange={(newOptions) => {
                  setRoleSelection(newOptions);
                }}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
            <EuiFormRow label={txt.get("account.groups")}>
              <EuiSelectable
                options={groupsSelection}
                aria-label={txt.uf(
                  "generix.select_x",
                  txt.get("account.groups")
                )}
                listProps={{ bordered: true }}
                onChange={(newOptions) => {
                  setGroupSelection(newOptions);
                }}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
            <EuiFormRow label={txt.get("account.password")}>
              {credentials &&
              credentials.credentials &&
              credentials.credentials.length > 0 ? (
                <Fragment>
                  <EuiButtonEmpty
                    iconType="lock"
                    style={{ display: "inline" }}
                    iconSide="right"
                    size="xs"
                    onClick={() => {
                      handleCreatePassword();
                    }}
                  >
                    {txt.get("admin.users.new_password")}
                  </EuiButtonEmpty>
                </Fragment>
              ) : (
                <EuiButtonEmpty
                  iconType="lock"
                  iconSide="right"
                  size="s"
                  onClick={() => {
                    handleCreatePassword();
                  }}
                >
                  {txt.get("admin.users.create_password")}
                </EuiButtonEmpty>
              )}
            </EuiFormRow>
            {renderSocialLoginOptions(credentials.socialLogins || [])}
            <EuiSpacer />
            <EuiButton
              size="s"
              iconSide="right"
              isLoading={isLoading}
              style={{ marginRight: "3px" }}
              onClick={() => {
                saveCredentials();
              }}
            >
              {txt.uf(
                "generic.save_x",
                txt.get("admin.users.user_credentials")
              )}
            </EuiButton>
            <EuiButton
              size="s"
              color="text"
              iconSide="right"
              isLoading={isLoading}
              onClick={() => {
                handleResetPassword();
              }}
            >
              {txt.get("account.password_reset")}
            </EuiButton>{" "}
          </Fragment>
        ) : (
          <></>
        )}
      </EuiForm>
    </EuiPanel>
  );
}
export default MMUserCredentials;
