import env from "helpers/env-helper";

const SILENCE_LOG: boolean = env("REACT_APP_SILENCE_LOG") === "1";

export const setupLogLevels = () => {
  if (SILENCE_LOG) {
    // Redefine the old console with a proxied version
    // that captures every method call and return an empty function
    // except for errors
    window.console = new Proxy<Console>(window.console, {
      get(target: any, prop: string, receiver) {
        if (prop === "error") {
          return target[prop];
        }
        return (...args: any[]) => {};
      },
    });
  }
};
