import { WorkflowStep } from "./workflow-step";

export const WORKFLOW_EMPTY: Workflow = {
  id: null,
  name: "",
  acronym: "",
  is_active: true,
  workflow_steps: [],
};

export interface Workflow {
  id: number | null;
  name: string;
  acronym: string;
  is_active: boolean;
  workflow_steps: WorkflowStep[];
}
