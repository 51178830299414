import env from "helpers/env-helper";
function MMVersionInfo(props: any) {
  const versionInfo = () => {
    const build_version = env("REACT_APP_BUILD_VERSION");
    return `${build_version || "v.local"}`;
  };

  return <span>{versionInfo()}</span>;
}

export default MMVersionInfo;
