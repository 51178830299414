import { EuiIcon, EuiToolTip } from "@elastic/eui";
import { feat, Feature } from "feats";
import { ReactNode } from "react";

export interface MMTipProps {
  text: string | ReactNode;
  children?: any;
}

function MMTip(props: MMTipProps) {
  return feat(Feature.OrdersTips) ? (
    <EuiToolTip content={<div className="mm-tooltip">{props.text}</div>}>
      {props.children ? (
        <div>{props.children}</div>
      ) : (
        <EuiIcon
          style={{ position: "relative", top: "-5px" }}
          type={"iInCircle"}
        />
      )}
    </EuiToolTip>
  ) : (
    <div>{props.children}</div>
  );
}

export default MMTip;
