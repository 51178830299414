import MMPage from "components/layouts/page/page";

function MMPrivacyPolicy() {
  return (
    <MMPage title="Privacy Policy">
      <p>Explanation</p>
    </MMPage>
  );
}

export default MMPrivacyPolicy;
