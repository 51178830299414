import {
  EuiButtonIcon,
  EuiDatePicker,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSuperSelect,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";
import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import { Input } from "helpers/input-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { Moment } from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { indicationDescription } from "store/data/indication/indication";
import { OrderStatus, ORDER_EMPTY } from "store/data/order/order";
import { formalName } from "store/data/personal-details/personal-details";
import { Referral, REFERRAL_EMPTY } from "store/data/referral/referral";
import { v4 as uuid } from "uuid";
import { MMOrderEntryInputProps } from "../order-entry";
import { getOrderInput } from "../order-inputs";

function MMOrderIntake(props: MMOrderEntryInputProps) {
  const dispatch = useDispatch();

  const [editReferralRef] = useState("edit_referral" + uuid());
  const [referral, setReferral] = useState(props.order?.referral || undefined);
  const [clientReferrals, setClientReferrals] = useState<Referral[]>(
    props.clientReferrals || []
  );
  const [clientReferralOptions, setClientReferralOptions] = useState<any[]>([]);
  const [selectedClientReferralOption, setSelectedClientReferralOption] =
    useState<any>(
      props.order?.referral_id ? props.order.referral_id + "" : "_"
    );

  const [isDisabledReferral, setIsDisabledReferral] = useState(false);

  useEffect(() => {
    if (feat(Feature.OrdersDuplicateReferral)) {
      setIsDisabledReferral(!props.isFirstOrderOfReferral);
    }
  }, [props.isFirstOrderOfReferral]);

  useEffect(() => {
    setReferral(props.order?.referral || undefined);

    // const setInterfaceForPermissions = async () => {
    // };

    // setInterfaceForPermissions();
  }, [props.order]);

  const handleEditReferral = () => {
    dispatch(
      confirmAsk(
        txt.get("orders.order.intake.edit_referral"),
        txt.get(
          "orders.order.intake.edit_referral_description",
          `<a style='color:#999999;text-decoration:underline;' href='?order=${props.order?.referral?.first_order_id}' target="_blank">#${props.order?.referral?.first_order_id}</a>`
        ),
        editReferralRef
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === editReferralRef
    ) {
      duplicateReferral();
    }
  }, [alertConfirm]);

  const duplicateReferral = () => {
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(props.order || ORDER_EMPTY, "referral", {
            ...(props.order?.referral || REFERRAL_EMPTY),
            id: null,
            first_order_id: null,
          }),
          "referral_id",
          null
        )
      );
    } else {
      setReferral(REFERRAL_EMPTY);
    }
    setSelectedClientReferralOption("_");
  };

  const clearReferral = () => {
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(props.order || ORDER_EMPTY, "referral", REFERRAL_EMPTY),
          "referral_id",
          null
        )
      );
    } else {
      setReferral(REFERRAL_EMPTY);
    }
    setSelectedClientReferralOption("_");
  };

  const handleIntakeDate = (referral: Referral): Referral => {
    if (
      props.order &&
      props.order.status !== OrderStatus.Drafted &&
      !referral.intake_at &&
      props.order.ordered_at
    ) {
      console.log("handleIntakeDaet");
      return updateField(referral, "intake_at", props.order.ordered_at);
    }
    return referral;
  };

  useEffect(() => {
    let result: any[] = [];
    const noSelection = [
      {
        value: "_",
        inputDisplay: txt.get("orders.order.choose_referral"),
      },
    ];

    result = noSelection.concat(
      !clientReferrals || !clientReferrals.map
        ? []
        : clientReferrals.map((referral: Referral) => {
            return {
              value: referral.id + "",
              inputDisplay: txt.get(
                "orders.order.referral_from",
                referral.first_order_id
                  ? `${txt.lo("orders.order.name")} ${referral.first_order_id}`
                  : referral.referred_at
                    ? DateHelper.toDate(referral.referred_at)
                    : txt.get("generic.unknown")
              ),
              dropdownDisplay: (
                <Fragment>
                  <EuiText size="s" style={{ fontWeight: "bold" }}>
                    <div>
                      {referral.first_order_id
                        ? `${txt.get("orders.order.name")} ${referral.first_order_id}`
                        : txt.uf(
                            "generic.unknown_x",
                            txt.lo("orders.order.name")
                          )}
                      {referral.client_code ? (
                        <EuiText
                          size="xs"
                          color="subdued"
                          style={{
                            float: "right",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          PN
                          {referral.client_code}
                        </EuiText>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {referral.referred_at
                        ? DateHelper.toDate(referral.referred_at)
                        : txt.uf(
                            "generic.unknown_x",
                            txt.lo("orders.order.intake.referral_date")
                          )}
                    </div>
                  </EuiText>
                  <EuiText size="s" color="subdued">
                    <p style={{ whiteSpace: "nowrap" }}>
                      {referral.referrer?.person
                        ? formalName(referral.referrer?.person)
                        : txt.uf(
                            "generic.unknown_x",
                            txt.lo("admin.referrers.name")
                          )}
                      <br />
                      {referral.referrer?.organisation
                        ? referral.referrer.organisation.name
                        : referral.referrer?.alternative_organisation}
                      <br />
                      {indicationDescription(referral.indications)}
                    </p>
                  </EuiText>
                </Fragment>
              ),
            };
          })
    );

    setClientReferralOptions(result);
  }, [clientReferrals]);

  const areOtherReferralsAvailable = () => {
    if (clientReferrals.length === 0) return false;
    if (
      clientReferrals.length === 1 &&
      !!referral?.id &&
      clientReferrals[0].id === referral.id
    )
      return false;
    return true;
  };
  useEffect(() => {
    setClientReferrals(props.clientReferrals || []);
  }, [props.clientReferrals]);

  const onIntakeAtChanged = (value: Moment | null) => {
    if (value) {
      // This is done because the related column in the database is a timestamp.
      // The function expects a date (yyyy-mm-dd) value from a datepicker.
      // Here, it sets the "time" part to combat timezone confusion.
      value.second(0);
      value.minute(0);
      value.hour(12);
    }

    const intakeAt = MomentHelper.toDate(value);

    const updatedReferral: Referral = handleIntakeDate(
      updateField(referral || REFERRAL_EMPTY, "intake_at", intakeAt)
    );

    if (props.onChange) {
      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
    } else {
      setReferral(updatedReferral);
    }
  };

  const handleClientReferralOptionChange = (currentOption: string) => {
    let referral: Referral | undefined =
      currentOption === "_"
        ? undefined
        : clientReferrals.find(
            (referral: Referral) => referral.id === parseInt(currentOption)
          );

    console.log("referral", { currentOption, referral });

    if (referral) {
      delete referral.orders;
    }
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(
            {
              ...ORDER_EMPTY,
              ...props.order,
            },
            "referral_id",
            referral ? referral.id : null
          ),
          "referral",
          referral
        )
      );
    } else {
      setReferral(referral);
    }

    setSelectedClientReferralOption(currentOption);
  };

  const renderIntake = (referralInput?: Input | null) => {
    return (
      <EuiFlexGrid>
        {referralInput ? (
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            {AuthenticationHelper.isInternalUser() ? (
              <EuiFlexGroup alignItems="flexEnd" gutterSize="xs">
                <EuiFlexItem grow={false}>
                  <EuiFormRow
                    display="rowCompressed"
                    label={txt.get("orders.order.intake.name")}
                    isDisabled={isDisabledReferral}
                  >
                    <EuiDatePicker
                      disabled={isDisabledReferral}
                      compressed={true}
                      showTimeSelect={false}
                      dateFormat="DD-MM-YYYY"
                      selected={
                        referral?.intake_at
                          ? MomentHelper.toMoment(referral.intake_at)
                          : null
                      }
                      placeholder={txt.get("orders.order.intake_at")}
                      onChange={(value) => {
                        onIntakeAtChanged(value ? value : null);
                      }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                {isDisabledReferral && props.isEditable ? (
                  <EuiFlexItem grow={false}>
                    <EuiToolTip
                      content={txt.get("orders.order.intake.edit_referral")}
                    >
                      <EuiButtonIcon
                        style={{
                          opacity: 0.5,
                          position: "relative",
                          top: "-4px",
                        }}
                        data-testid="button-edit-referral"
                        color="text"
                        iconType="pencil"
                        iconSize="s"
                        size="xs"
                        onClick={() => {
                          handleEditReferral();
                        }}
                      />
                    </EuiToolTip>
                  </EuiFlexItem>
                ) : (
                  <></>
                )}
              </EuiFlexGroup>
            ) : (
              <></>
            )}
            <EuiFlexItem grow={true}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup alignItems="flexEnd" gutterSize="xs">
                {AuthenticationHelper.isInternalUser() &&
                areOtherReferralsAvailable() ? (
                  <EuiFlexItem>
                    <EuiFormRow
                      className="product-input"
                      display="rowCompressed"
                      label={<br />}
                      isDisabled={!props.isEditable}
                    >
                      <EuiSuperSelect
                        disabled={!props.isEditable}
                        compressed={true}
                        color="accent"
                        fullWidth
                        hasDividers={true}
                        name={txt.get("orders.order.choose_referral")}
                        placeholder={txt.get("orders.order.choose_referral")}
                        options={clientReferralOptions}
                        valueOfSelected={selectedClientReferralOption}
                        onChange={(value) =>
                          handleClientReferralOptionChange(value)
                        }
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                ) : (
                  <></>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <></>
        )}
      </EuiFlexGrid>
    );
  };

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        {renderIntake(getOrderInput("referral", props.inputs))}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderIntake;
