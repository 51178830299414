import {
  EuiButton,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiNotificationBadge,
  EuiPanel,
  EuiSpacer,
  EuiSwitch,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
  EuiToolTip,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMAppointmentsShortlist from "components/appointments/appointments-shortlist";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderStatus, OrderStatusAction } from "store/data/order/order";
import { totalOrderCount } from "store/data/order/order-count";
import { OrganisationOrdering } from "store/data/organisation/organisation";
import { ReferralStatus } from "store/data/referral/referral";
import { useLocalStorage } from "store/local-storage";
import MMOrdersShortlist, { MMOrderAction } from "./order-shortlist";

const MIN_WIDTH = "40%";
const DELIVERY_FROM = "2024-01-01";

function MMOrders() {
  localStorage.setItem("last_visited_orders_view", "orders");
  const isSmallScreen = useIsWithinBreakpoints(["s", "m", "l"]);
  const api = new ConnectAPIHelper();
  const [selectedTasksTab, setSelectedTasksTab] = useState(
    UrlHelper.queryParam("tasks_tab")
  );
  const [selectedOrdersTab, setSelectedOrdersTab] = useState(
    UrlHelper.queryParam("orders_tab")
  );
  const [taskListsToShow, setTaskListsToShow] = useState<string[]>([]);
  const [orderListsToShow, setOrderListsToShow] = useState<string[]>([]);
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [filtersToShow, setFiltersToShow] = useState<any[]>([]);
  const [orderCounts, setOrderCounts] = useState<any[]>([]);
  const [orderCounts2024, setOrderCounts2024] = useState<any[]>([]);

  let searchParams = useSearchParams();

  const [locations, setLocations] = useState([]);

  const [selectedLocations, setSelectedLocations] = useLocalStorage(
    "order_locations",
    []
  );
  const [showMyOrders, setShowMyOrders] = useLocalStorage(
    "order_show_my_orders",
    true
  );

  const [showDemo] = useLocalStorage("order_show_demo", false);

  useEffect(() => {
    const loadLocations = async () => {
      const orderValues: any = await api.getOrderValues();

      const locations = orderValues.locations;
      setLocations(locations);
      if (locations?.length === 1) {
        setSelectedLocations([]);
        setShowMyOrders(true);
      }
    };

    loadLocations();
  }, []);

  const onLocationChange = (selectedOptions: any[]) => {
    setSelectedLocations(selectedOptions);
    if (selectedOptions.length === 0) {
      setShowMyOrders(true);
    }
  };

  const toSelectOptions = (list: any[]) => {
    return list.map((item) => ({
      label: item.name,
      "data-id": item.id,
    }));
  };

  const toIds = (list: any[]) => {
    return list.map((item) => item["data-id"]);
  };

  const handleOrderNavigate = (orderId: string | number) => {
    let params = UrlHelper.queryParams();
    params.order = orderId;
    searchParams[1](params, { replace: true });
  };

  const loadOrderCounts = async () => {
    let by: string[] = [];
    if (selectedLocations.length > 0) {
      by.push("location");
    }
    const result = await api.getOrderCounts(by, showDemo);
    setOrderCounts(result);
    if (AuthenticationHelper.isInternalUser()) {
      const result = await api.getOrderCounts(by, showDemo, DELIVERY_FROM);
      setOrderCounts2024(result);
    }
  };

  useEffect(() => {
    let params: any = {
      tasks_tab: selectedTasksTab,
      orders_tab: selectedOrdersTab,
    };
    const orderParam = UrlHelper.queryParam("order");
    if (orderParam) {
      params.order = orderParam;
    }

    window.history.replaceState(
      null,
      txt.get("my_dashboard.page_title"),
      `/my-dashboard?${UrlHelper.toQueryString(params)}`
    );
    loadOrderCounts();
  }, [selectedTasksTab, selectedOrdersTab]);

  useEffect(() => {
    const setFiltersForPermissions = async () => {
      const filtersToShow: any[] = [];

      /* thinking again. why not just delete demo orders */
      // if (await AuthenticationHelper.hasPermission("orders#read_demo")) {
      //   filtersToShow.push(
      //     <EuiSwitch
      //       compressed={true}
      //       checked={showDemo}
      //       onChange={(e: any) => {
      //         setShowDemo(!showDemo);
      //       }}
      //       showLabel={true}
      //       label={txt.get("orders.show_demo")}
      //     />
      //   );
      // }

      if (locations && locations.length > 1) {
        filtersToShow.push(
          <EuiFlexGroup
            direction="column"
            gutterSize="s"
            justifyContent="flexEnd"
          >
            <EuiComboBox
              compressed={true}
              style={{ width: "225px" }}
              aria-label={txt.uf(
                "generic.select_x",
                txt.get("admin.locations.name")
              )}
              placeholder={txt.uf(
                "generic.select_x",
                txt.get("admin.locations.name")
              )}
              // singleSelection={{ asPlainText: true }}
              options={toSelectOptions(locations)}
              selectedOptions={selectedLocations}
              onChange={onLocationChange}
            />
            {selectedLocations.length > 0 &&
            AuthenticationHelper.isInternalUser() ? (
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiText size="xs" grow={false}>
                  {txt.get("orders.show_all_location_orders")}
                </EuiText>
                <EuiSwitch
                  compressed={true}
                  checked={!showMyOrders}
                  onChange={(e: any) => {
                    setShowMyOrders(!showMyOrders);
                  }}
                  label={txt.get("orders.show_all_location_orders")}
                  showLabel={false}
                />
              </EuiFlexGroup>
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        );
      }

      setFiltersToShow(filtersToShow);
    };
    setFiltersForPermissions();
  }, [locations, showDemo, showMyOrders, selectedLocations]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      const taskListsToShow: any[] = [];
      const orderListsToShow: any[] = [];

      if (
        await AuthenticationHelper.hasPermission([
          "orders#read",
          "orders#submit",
        ])
      ) {
        let statusses: any[] = [
          OrderStatus.Submitted,
          OrderStatus.Accepted,
          OrderStatus.DeliveredPartial,
          OrderStatus.Produced,
          OrderStatus.ProducedPartial,
        ];

        orderListsToShow.push({
          list: "ordered",
          statusses,
        });

        orderListsToShow.push("drafts");
      }

      if (
        await AuthenticationHelper.hasPermission([
          "orders#deliver_all",
          "orders#deliver_org",
          "orders#deliver",
        ])
      ) {
        orderListsToShow.push("delivered");
      }

      if (
        false &&
        (await AuthenticationHelper.hasPermission(["appointments#read"]))
      ) {
        const personalDetails = AuthenticationHelper.getPersonalDetails();
        if (
          personalDetails.is_practitioner &&
          personalDetails.legacy_info?.vlot_practitioner_id
        ) {
          taskListsToShow.push("appointments");
        }
      }

      if (
        await AuthenticationHelper.hasPermission([
          "orders#accept_all",
          "orders#accept_internal",
          "orders#accept_external",
        ])
      ) {
        let ordering: OrganisationOrdering[] | undefined;

        if (!(await AuthenticationHelper.hasPermission("orders#accept_all"))) {
          ordering = [];
          if (
            await AuthenticationHelper.hasPermission("orders#accept_internal")
          ) {
            ordering.push(OrganisationOrdering.Internal);
          }
          if (
            await AuthenticationHelper.hasPermission("orders#accept_external")
          ) {
            ordering.push(OrganisationOrdering.External);
          }
          if (await AuthenticationHelper.hasPermission("orders#accept_b2b")) {
            ordering.push(OrganisationOrdering.B2B);
          }
        }
        taskListsToShow.push({ list: "to_review", ordering });
      }

      if (
        await AuthenticationHelper.hasPermission([
          // "referrals#accept_all",
          "referrals#accept_external",
        ])
      ) {
        let ordering: OrganisationOrdering[] | undefined = [];

        ordering.push(OrganisationOrdering.External);

        // if (
        //   !(await AuthenticationHelper.hasPermission("referrals#accept_all"))
        // ) {
        //   ordering = [];
        //   if (
        //     await AuthenticationHelper.hasPermission(
        //       "referrals#accept_external"
        //     )
        //   ) {
        //     ordering.push(OrganisationOrdering.External);
        //   }
        // }
        taskListsToShow.push({ list: "to_review_referral", ordering });
      }
      if (
        (await AuthenticationHelper.hasPermission(["referrals#edit"])) &&
        AuthenticationHelper.isInternalUser()
      ) {
        taskListsToShow.push("to_fix_my_referral");
      }
      if (
        (await AuthenticationHelper.hasPermission(["referrals#edit"])) &&
        AuthenticationHelper.isInternalUser()
      ) {
        taskListsToShow.push("waiting_for_referrals");
      }
      if (
        (await AuthenticationHelper.hasPermission([
          "orders#finalize_external",
        ])) &&
        AuthenticationHelper.isInternalUser()
      ) {
        taskListsToShow.push("no-client-code");
      }

      if (
        await AuthenticationHelper.hasPermission([
          "orders#deliver_all",
          "orders#deliver_org",
          "orders#deliver",
        ])
      ) {
        taskListsToShow.push("to_deliver");
      }

      if (
        (await AuthenticationHelper.hasPermission([
          "orders#finalize_external",
        ])) &&
        AuthenticationHelper.isInternalUser()
      ) {
        taskListsToShow.push("to_finalize");
      }

      // if (
      //   await AuthenticationHelper.hasPermission(["order_status_logs#edit_all"])
      // ) {
      //   taskListsToShow.push("to_verify");
      // }

      if (await AuthenticationHelper.hasPermission(["orders#evaluate_all"])) {
        taskListsToShow.push("to_evaluate_external");
      }

      // if (await AuthenticationHelper.hasPermission(["files#edit_all"])) {
      //   taskListsToShow.push("no_referral");
      // }

      setTaskListsToShow(taskListsToShow);
      setOrderListsToShow(orderListsToShow);

      let buttons: any = [];

      if (
        await AuthenticationHelper.hasPermission([
          "orders#submit",
          "orders#submit_org",
          "orders#submit_all",
        ])
      ) {
        buttons.push(
          <EuiButton
            key="add_order"
            size="s"
            fill
            iconType="plus"
            onClick={() => handleOrderNavigate("new")}
          >
            {txt.get("generic.add_new_x", txt.lo("orders.order.name"))}
          </EuiButton>
        );
      }

      // if (
      //   await AuthenticationHelper.hasPermission([
      //     "orders#read",
      //     "orders#read_org",
      //     "orders#read_all",
      //   ])
      // ) {
      //   buttons.push(
      //     <EuiButton
      //       key="search_orders"
      //       color="accent"
      //       size="s"
      //       iconType="search"
      //       onClick={() => handleNavigate("/orders/search")}
      //     >
      //       {txt.get("orders.search.page_title")}
      //     </EuiButton>
      //   );
      // }

      setButtonsToShow(buttons);

      const currentOrdersTab = UrlHelper.queryParam("orders_tab");
      const ordersTabIsAvailable =
        orderListsToShow.findIndex((list: any) =>
          typeof list === "string"
            ? list === currentOrdersTab
            : (list.list === list) === currentOrdersTab
        ) >= 0;

      if (currentOrdersTab !== "" && ordersTabIsAvailable) {
        setSelectedTasksTab(currentOrdersTab);
      } else {
        setSelectedOrdersTab(
          typeof orderListsToShow[0] === "string"
            ? orderListsToShow[0]
            : orderListsToShow[0].list
        );
      }

      const currentTasksTab = UrlHelper.queryParam("tasks_tab");
      const tasksTabIsAvailable =
        taskListsToShow.findIndex((list: any) =>
          typeof list === "string"
            ? list === currentTasksTab
            : (list.list === list) === currentTasksTab
        ) >= 0;

      if (currentTasksTab !== "" && tasksTabIsAvailable) {
        setSelectedTasksTab(currentTasksTab);
      } else if (taskListsToShow.length > 0) {
        setSelectedTasksTab(
          typeof taskListsToShow[0] === "string"
            ? taskListsToShow[0]
            : taskListsToShow[0].list
        );
      }
    };

    setInterfaceForPermissions();
    loadOrderCounts();
  }, [selectedLocations, showDemo, showMyOrders]);

  const groups = AuthenticationHelper.getGroups();

  const onShortListChange = () => {
    loadOrderCounts();
  };

  const filteredCountFilters = (countFilters: any[]) => {
    if (selectedLocations.length > 0) {
      return countFilters.map((countFilter: any) => ({
        ...countFilter,
        location: toIds(selectedLocations),
      }));
    }
    return countFilters;
  };
  const orderListsForUser = (): any => {
    let shortlists = [];

    const showExtendedExternaClientInfo =
      !AuthenticationHelper.isInternalUser() &&
      !AuthenticationHelper.getGroups().includes("/the-hand-clinic") &&
      !AuthenticationHelper.hasRole("b2b");

    if (
      orderListsToShow.includes("ordered") ||
      orderListsToShow.findIndex(
        (list: any) => list && list.list && list.list === "ordered"
      ) > -1
    ) {
      let listInfo: any = orderListsToShow.find(
        (list: any) => list && list.list && list.list === "ordered"
      );

      let countFilters: any[] = [
        {
          by: ["me"],
          for: ["me"],
          status: listInfo.statusses,
        },
        {
          by: ["others"],
          for: ["me"],
          status: listInfo.statusses,
        },
      ];

      let columns: any[] = [];
      columns.push("order_id");
      if (groups.includes("/manometric")) {
        columns.push("client_code");
      } else {
        if (showExtendedExternaClientInfo) {
          columns.push("external_client_details");
        }
        columns.push("external_client_reference");
      }
      columns.push("ordered_at");
      columns.push("status");

      shortlists.push({
        id: "ordered",
        name: txt.get("orders.my_orders.ordered"),
        count: filteredCountFilters(countFilters),
        content: (
          <EuiFlexItem key="ordered">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: listInfo.statusses,
                forMeOrdersSelected: true,
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              clickable={true}
              name="ordered"
              //orderTab="measurements"
            />
          </EuiFlexItem>
        ),
      });
    }

    const initUserListColumns = (
      isInternalUser: boolean,
      showExtendedExternalClientInfo: boolean
    ) => {
      let columns: any[] = [];
      columns.push("order_id");
      if (isInternalUser) {
        columns.push("client_code");
      } else {
        if (showExtendedExternalClientInfo) {
          columns.push("external_client_details");
        }
        columns.push("external_client_reference");
      }
      columns.push("last_update");
      columns.push("products");

      return columns;
    };

    if (orderListsToShow.includes("drafts")) {
      let columns: any[] = initUserListColumns(
        AuthenticationHelper.isInternalUser(),
        showExtendedExternaClientInfo
      );

      shortlists.push({
        id: "drafts",
        name: txt.get("orders.my_orders.drafts"),
        count: filteredCountFilters(
          showMyOrders
            ? [
                { by: ["me"], for: ["me"], status: [OrderStatus.Drafted] },
                { by: ["others"], for: ["me"], status: [OrderStatus.Drafted] },
              ]
            : [{ status: [OrderStatus.Drafted] }]
        ),
        content: (
          <EuiFlexItem key="drafts">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                status: OrderStatus.Drafted,
                includeDemo: showDemo,
                forMeOrdersSelected: showMyOrders === true ? true : undefined,
                sortBy: "updated_at",
                sortOrder: "desc",
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              clickable={true}
              name="drafts"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (orderListsToShow.includes("delivered")) {
      let columns: any[] = initUserListColumns(
        AuthenticationHelper.isInternalUser(),
        showExtendedExternaClientInfo
      );

      shortlists.push({
        id: "delivered",
        name: txt.get("orders.my_orders.delivered"),
        count: filteredCountFilters(
          showMyOrders
            ? [
                {
                  by: ["me"],
                  for: ["me"],
                  status: [OrderStatus.Delivered, OrderStatus.Finalized],
                },
                {
                  by: ["me"],
                  for: ["others"],
                  status: [OrderStatus.Delivered, OrderStatus.Finalized],
                },
              ]
            : [{ status: [OrderStatus.Delivered, OrderStatus.Finalized] }]
        ),
        content: (
          <EuiFlexItem key="delivered">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                status: [OrderStatus.Delivered, OrderStatus.Finalized],
                byMeOrdersSelected: showMyOrders === true ? true : undefined,
                includeDemo: showDemo,
                sortBy: "updated_at",
                sortOrder: "desc",
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              clickable={true}
              orderTab="fitting"
              name="delivered"
            />
          </EuiFlexItem>
        ),
      });
    }
    return shortlists;
  };

  const taskListsForUser = (): any => {
    let shortlists = [];

    const showExtendedExternaClientInfo =
      !AuthenticationHelper.isInternalUser() &&
      !AuthenticationHelper.getGroups().includes("/the-hand-clinic") &&
      !AuthenticationHelper.hasRole("b2b");

    if (taskListsToShow.includes("appointments")) {
      shortlists.push({
        id: "appointments",
        name: txt.get("appointments.page_title"),
        count: [],
        highlightCount: true,
        content: (
          <EuiFlexItem key="appointments">
            <MMAppointmentsShortlist />
          </EuiFlexItem>
        ),
      });
    }

    if (
      taskListsToShow.includes("to_review") ||
      taskListsToShow.findIndex(
        (list: any) => list && list.list && list.list === "to_review"
      ) > -1
    ) {
      let toReviewFilters: any = {
        status: OrderStatus.Submitted,
        locations: selectedLocations ? toIds(selectedLocations) : undefined,
        includeDemo: showDemo,
      };
      let listInfo: any = taskListsToShow.find(
        (list: any) => list && list.list && list.list === "to_review"
      );

      if (listInfo.ordering) {
        toReviewFilters.ordering = listInfo.ordering;
      }

      shortlists.push({
        id: "to_review",
        name: txt.get("orders.my_orders.to_review"),
        count: filteredCountFilters([
          {
            status: [OrderStatus.Submitted],
            has_client_code: [true, false],
            ...(listInfo.ordering && listInfo.ordering.length > 0
              ? { organisation_ordering: listInfo.ordering }
              : {}),
          },
        ]),
        highlightCount: true,
        content: (
          <EuiFlexItem key="to_review">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={toReviewFilters}
              columns={[
                "order_id",
                "practitioner",
                "corrigable_client_code",
                "ordered_at",
                "products",
              ]}
              actions={[MMOrderAction.ChangeStatus]}
              statusActions={[OrderStatusAction.Accept]}
              clickable={true}
              orderTab="measurements"
              name="to_review"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (
      taskListsToShow.includes("to_review_referral") ||
      taskListsToShow.findIndex(
        (list: any) => list && list.list && list.list === "to_review_referral"
      ) > -1
    ) {
      let listInfo: any = taskListsToShow.find(
        (list: any) => list && list.list && list.list === "to_review_referral"
      );
      let toReviewReferralFilters: any = {
        // status: OrderStatus.Submitted,
        referralStatus: [ReferralStatus.Submitted],
        locations: selectedLocations ? toIds(selectedLocations) : undefined,
        includeDemo: showDemo,
      };

      if (listInfo.ordering) {
        toReviewReferralFilters.ordering = listInfo.ordering;
      }

      let toReviewReferralCount: any[] = [
        {
          // status: OrderStatus.Submitted,
          referral_status: toReviewReferralFilters.referralStatus,
          ...(listInfo.ordering && listInfo.ordering.length > 0
            ? { organisation_ordering: listInfo.ordering }
            : {}),
        },
      ];

      shortlists.push({
        id: "to_review_referral",
        name: txt.get("orders.my_orders.to_review_referral"),
        count: filteredCountFilters(toReviewReferralCount),
        highlightCount: true,
        content: (
          <EuiFlexItem key="to_review_referral">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={toReviewReferralFilters}
              columns={[
                "order_id",
                "practitioner",
                "referrer",
                "referral",
                "ordered_at",
                // "products",
              ]}
              orderTab="referral"
              actions={[MMOrderAction.Edit]}
              clickable={true}
              name="to_review_referral"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (taskListsToShow.includes("waiting_for_referrals")) {
      const allSubmitted: OrderStatus[] = [
        OrderStatus.Submitted,
        OrderStatus.Accepted,
        OrderStatus.ProducedPartial,
        OrderStatus.Produced,
        OrderStatus.PackagedPartial,
        OrderStatus.Packaged,
        OrderStatus.RoutedPartial,
        OrderStatus.Routed,
        OrderStatus.ShippedPartial,
        OrderStatus.Shipped,
        OrderStatus.DeliveredPartial,
        OrderStatus.Delivered,
        OrderStatus.ReturnedPartial,
        OrderStatus.Returned,
        OrderStatus.Finalized,
        OrderStatus.Rejected,
      ];
      const allExceptCancelled: OrderStatus[] = allSubmitted.concat([
        OrderStatus.Drafted,
      ]);
      shortlists.push({
        id: "waiting_for_referrals",
        name: txt.get("referrals.wait_for_referral"),
        count: filteredCountFilters(
          showMyOrders
            ? [
                // {
                //   by: ["me"],
                //   for: ["me"],
                //   has_referral: [false],
                //   status: allSubmitted,
                // },
                // {
                //   by: ["others"],
                //   for: ["me"],
                //   has_referral: [false],
                //   status: allSubmitted,
                // },
                // {
                //   by: ["me"],
                //   for: ["me"],
                //   referral_status: [ReferralStatus.Pending],
                //   status: allExceptCancelled,
                // },
                {
                  for: ["me"],
                  referral_status: [ReferralStatus.Pending],
                  status: allExceptCancelled,
                },
              ]
            : [
                {
                  referral_status: [ReferralStatus.Pending],
                  status: allExceptCancelled,
                },
              ]
        ),
        content: (
          <EuiFlexItem key="waiting_for_referrals">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                referralStatus: [ReferralStatus.Pending],
                includeDemo: showDemo,
                status: allExceptCancelled,
                forMeOrdersSelected: showMyOrders === true ? true : undefined,
                sortBy: "referral.updated_at",
                sortOrder: "asc",
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={[
                "order_id",
                groups.includes("/manometric")
                  ? "client_code"
                  : "external_client_details",
                "referrer",
                "referral",
                "referral_last_update",
              ]}
              clickable={true}
              orderTab="referral"
              name="waiting_for_referrals"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (taskListsToShow.includes("to_fix_my_referral")) {
      let toReviewReferralFilters: any = {
        // status: OrderStatus.Submitted,
        includeDemo: showDemo,
        referralStatus: [ReferralStatus.Rejected],
        locations: selectedLocations ? toIds(selectedLocations) : undefined,
      };

      let toReviewReferralCount: any[] = showMyOrders
        ? [
            {
              by: ["me"],
              for: ["me"],
              referral_status: toReviewReferralFilters.referralStatus,
            },
            {
              by: ["others"],
              for: ["me"],
              referral_status: toReviewReferralFilters.referralStatus,
            },
          ]
        : [{ referral_status: toReviewReferralFilters.referralStatus }];
      // toReviewReferralFilters.byMeOrdersSelected = true;
      // toReviewReferralFilters.forMeOrdersSelected = true;
      toReviewReferralFilters.forMeOrdersSelected =
        showMyOrders === true ? true : undefined;

      let columns: string[] = [];
      columns = columns.concat(["order_id", "products"]);

      if (groups.includes("/manometric")) {
        columns.push("client_code");
      } else {
        if (showExtendedExternaClientInfo) {
          columns.push("external_client_details");
        }
        columns.push("external_client_reference");
      }

      columns = columns.concat(["referrer", "referral", "ordered_at"]);

      shortlists.push({
        id: "to_fix_my_referral",
        name: txt.get("referrals.fix"),
        count: filteredCountFilters(toReviewReferralCount),
        highlightCount: true,
        content: (
          <EuiFlexItem key="to_fix_my_referral">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={toReviewReferralFilters}
              columns={columns}
              actions={[MMOrderAction.Edit]}
              clickable={true}
              orderTab="referral"
              name="to_fix_my_referral"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (taskListsToShow.includes("no-client-code")) {
      let columns: string[] = ["order_id", "practitioner"];

      if (groups.includes("/manometric")) {
        columns.push("corrigable_client_code");
      }
      columns = columns.concat(["ordered_at", "status"]);

      shortlists.push({
        id: "no_client_code",
        name: txt.get("orders.my_orders.no_client_code"),
        explanation: txt.get("orders.my_orders.no_client_code_explanation"),
        highlightCount: true,
        count: [
          {
            organisation_ordering: [OrganisationOrdering.External],
            has_client_code: [false],
            status: [
              OrderStatus.Accepted,
              OrderStatus.Rejected,
              OrderStatus.Produced,
              OrderStatus.ProducedPartial,
              OrderStatus.Packaged,
              OrderStatus.PackagedPartial,
              OrderStatus.Returned,
              OrderStatus.Delivered,
              OrderStatus.Finalized,
            ],
          },
        ],
        content: (
          <EuiFlexItem key="no-client-code">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: [
                  OrderStatus.Accepted,
                  OrderStatus.Rejected,
                  OrderStatus.Produced,
                  OrderStatus.ProducedPartial,
                  OrderStatus.Packaged,
                  OrderStatus.PackagedPartial,
                  OrderStatus.Returned,
                  OrderStatus.Delivered,
                  OrderStatus.Finalized,
                ],
                hasClientCode: false,
                ordering: [OrganisationOrdering.External],
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              actions={[MMOrderAction.Edit]}
              clickable={true}
              name="no_client_code"
            />
          </EuiFlexItem>
        ),
      });
    }
    if (taskListsToShow.includes("to_deliver")) {
      let columns = ["order_id"];
      if (groups.includes("/manometric")) {
        columns.push("practitioner");
        columns.push("client_code");
      } else {
        if (showExtendedExternaClientInfo) {
          columns.push("external_client_details");
        }
        columns.push("external_client_reference");
      }

      columns.push("ordered_at");
      if (AuthenticationHelper.isInternalUser()) {
        columns.push("delivery");
      }
      columns.push("products");

      shortlists.push({
        id: "to_deliver",
        name: txt.get("orders.my_orders.to_deliver"),
        count: filteredCountFilters(
          showMyOrders
            ? [
                {
                  by: ["me"],
                  for: ["me"],
                  status: [
                    OrderStatus.Produced,
                    OrderStatus.ProducedPartial,
                    OrderStatus.DeliveredPartial,
                  ],
                },
                {
                  by: ["others"],
                  for: ["me"],
                  status: [
                    OrderStatus.Produced,
                    OrderStatus.ProducedPartial,
                    OrderStatus.DeliveredPartial,
                  ],
                },
              ]
            : [
                {
                  status: [
                    OrderStatus.Produced,
                    OrderStatus.ProducedPartial,
                    OrderStatus.DeliveredPartial,
                  ],
                },
              ]
        ),
        highlightCount: true,

        content: (
          <EuiFlexItem key="to_deliver">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: [
                  OrderStatus.DeliveredPartial,
                  OrderStatus.Produced,
                  OrderStatus.ProducedPartial,
                ],
                since: DELIVERY_FROM,
                forMeOrdersSelected: showMyOrders === true ? true : undefined,
                // byMeOrdersSelected: true,
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              actions={[MMOrderAction.Deliver]}
              clickable={true}
              orderTab="fitting"
              name="to_deliver"
            />
          </EuiFlexItem>
        ),
      });

      if (AuthenticationHelper.isInternalUser()) {
        shortlists.push({
          id: "to_deliver_2024",
          name: AuthenticationHelper.isInternalUser()
            ? txt.get("orders.my_orders.to_deliver_2024")
            : txt.get("orders.my_orders.to_deliver"),
          explanation: AuthenticationHelper.isInternalUser()
            ? txt.get("orders.my_orders.to_deliver_2024_explanation")
            : undefined,
          countList: AuthenticationHelper.isInternalUser()
            ? orderCounts2024
            : orderCounts,
          count: filteredCountFilters(
            showMyOrders
              ? [
                  {
                    by: ["me"],
                    for: ["me"],
                    status: [
                      OrderStatus.Produced,
                      OrderStatus.ProducedPartial,
                      OrderStatus.DeliveredPartial,
                    ],
                  },
                  {
                    by: ["others"],
                    for: ["me"],
                    status: [
                      OrderStatus.Produced,
                      OrderStatus.ProducedPartial,
                      OrderStatus.DeliveredPartial,
                    ],
                  },
                ]
              : [
                  {
                    status: [
                      OrderStatus.Produced,
                      OrderStatus.ProducedPartial,
                      OrderStatus.DeliveredPartial,
                    ],
                  },
                ]
          ),
          highlightCount: true,

          content: (
            <EuiFlexItem key="to_deliver_2024">
              <MMOrdersShortlist
                onChange={onShortListChange}
                filters={{
                  includeDemo: showDemo,
                  status: [
                    OrderStatus.DeliveredPartial,
                    OrderStatus.Produced,
                    OrderStatus.ProducedPartial,
                  ],
                  since: DELIVERY_FROM,
                  forMeOrdersSelected: showMyOrders === true ? true : undefined,
                  // byMeOrdersSelected: true,
                  locations: selectedLocations
                    ? toIds(selectedLocations)
                    : undefined,
                }}
                columns={columns}
                actions={[MMOrderAction.Deliver]}
                clickable={true}
                orderTab="fitting"
                name="to_deliver_2024"
              />
            </EuiFlexItem>
          ),
        });
      }
    }

    if (taskListsToShow.includes("rejected")) {
      shortlists.push({
        id: "rejected",
        name: txt.get("orders.my_orders.rejected"),
        content: (
          <EuiFlexItem key="rejected">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: OrderStatus.Rejected,
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={["products", "client_code", "ordered_at", "status"]}
              actions={[MMOrderAction.Edit]}
              clickable={true}
              name="rejected"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (taskListsToShow.includes("to_finalize")) {
      let columns: string[] = ["order_id", "practitioner"];

      if (groups.includes("/manometric")) {
        columns.push("corrigable_client_code");
      }
      columns = columns.concat(["ordered_at", "status"]);

      shortlists.push({
        id: "to_finalize",
        name: txt.get("orders.my_orders.to_finalize"),
        explanation: txt.get("orders.my_orders.to_finalize_explanation"),
        highlightCount: true,
        count: filteredCountFilters([
          {
            status: [OrderStatus.Delivered],
            organisation_ordering: [OrganisationOrdering.External],
            has_client_code: [true],
          },
        ]),
        content: (
          <EuiFlexItem key="to_finalize">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: [OrderStatus.Delivered],
                ordering: [OrganisationOrdering.External],
                hasClientCode: true,
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              actions={[MMOrderAction.ChangeStatus]}
              statusActions={[OrderStatusAction.Finalize]}
              clickable={true}
              orderTab="measurements"
              name="to_finallize"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (taskListsToShow.includes("no_referral")) {
      shortlists.push({
        id: "no_referral",
        name: txt.get("orders.my_orders.no_referral"),

        content: (
          <EuiFlexItem key="no_referral">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: OrderStatus.Submitted,
                withReferral: "without",
                forMeOrdersSelected: showMyOrders === true ? true : undefined,
                byMeOrdersSelected: showMyOrders === true ? true : undefined,
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={["order_id", "client_code", "ordered_at", "products"]}
              actions={[MMOrderAction.AttachReferral]}
              clickable={true}
              name="no_referral"
            />
          </EuiFlexItem>
        ),
      });
    }

    if (taskListsToShow.includes("to_verify")) {
      let columns = ["order_id"];
      if (groups.includes("/manometric")) {
        columns.push("practitioner");
        columns.push("external_client_details");
      } else {
        if (showExtendedExternaClientInfo) {
          columns.push("external_client_details");
        }
        columns.push("external_client_reference");
      }

      columns.push("ordered_at");
      columns.push("status");

      shortlists.push({
        id: "to_verify",
        name: txt.get("orders.my_orders.to_verify"),
        count: filteredCountFilters([
          {
            organisation_ordering: [OrganisationOrdering.External],
            status: [
              OrderStatus.Produced,
              OrderStatus.ProducedPartial,
              OrderStatus.DeliveredPartial,
            ],
          },
        ]),
        highlightCount: true,
        content: (
          <EuiFlexItem key="to_verify">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: [
                  OrderStatus.DeliveredPartial,
                  OrderStatus.Produced,
                  OrderStatus.ProducedPartial,
                ],
                ordering: [OrganisationOrdering.External],
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              actions={[MMOrderAction.ChangeStatus]}
              statusActions={[OrderStatusAction.Deliver]}
              columns={columns}
              clickable={true}
              orderTab="measurements"
              name="to_verify"
            />
          </EuiFlexItem>
        ),
      });

      shortlists.push({
        id: "to_evaluate_external",
        name: txt.get("orders.my_orders.to_evaluate"),
        count: [
          {
            organisation_ordering: [OrganisationOrdering.External],
            status: [OrderStatus.Delivered, OrderStatus.Finalized],
            is_evaluated: [false],
          },
        ],
        highlightCount: true,
        content: (
          <EuiFlexItem key="to_evaluate_external">
            <MMOrdersShortlist
              onChange={onShortListChange}
              filters={{
                includeDemo: showDemo,
                status: [OrderStatus.Delivered, OrderStatus.Finalized],
                ordering: [OrganisationOrdering.External],
                isEvaluated: false,
                locations: selectedLocations
                  ? toIds(selectedLocations)
                  : undefined,
              }}
              columns={columns}
              clickable={true}
              actions={[MMOrderAction.Evaluate]}
              name="to_evaluate_external"
            />
          </EuiFlexItem>
        ),
      });
    }

    return shortlists;
  };

  const shortlistTabs = (shortlists: any[], selected: string, setter: any) => {
    return shortlists.map((shortlist: any, index: number) => {
      const currentTotalCount: number = totalOrderCount(
        shortlist.countList ? shortlist.countList : orderCounts,
        shortlist.count
      );
      return (
        <EuiTab
          key={index}
          // href={shortlist.href}
          onClick={() => setter(shortlist.id)}
          isSelected={shortlist.id === selected}
          append={
            shortlist.count ? (
              <EuiNotificationBadge
                // className={
                //   selectedLocations.length > 0 && currentTotalCount > 0
                //     ? "stale-info"
                //     : ""
                // }
                color={
                  shortlist.highlightCount && currentTotalCount > 0
                    ? "accent"
                    : "subdued"
                }
              >
                <EuiText size="xs" style={{ fontWeight: 500 }}>
                  {currentTotalCount}
                </EuiText>
              </EuiNotificationBadge>
            ) : (
              ""
            )
          }
          // append={"A"}
        >
          {shortlist.explanation ? (
            <EuiToolTip position="top" content={shortlist.explanation}>
              <span style={{ whiteSpace: "normal" }}>{shortlist.name}</span>
            </EuiToolTip>
          ) : (
            <span style={{ whiteSpace: "normal" }}>{shortlist.name}</span>
          )}
        </EuiTab>
      );
    });
  };
  const buttons = () => {
    return buttonsToShow;
  };

  const filters = () => {
    return filtersToShow;
  };

  const selectedTasksShortlist = () => {
    const result = taskListsForUser().find(
      (shortlist: any) => shortlist.id === selectedTasksTab
    );
    // console.log("selectedShortlist", result);
    return result?.content;
  };

  const selectedOrdersShortlist = () => {
    const result = orderListsForUser().find(
      (shortlist: any) => shortlist.id === selectedOrdersTab
    );
    // console.log("selectedShortlist", result);
    return result?.content;
  };

  return (
    <MMPage
      title={txt.get("orders.my_orders.name")}
      hideTitle={true}
      topActions={buttons()}
      sideActions={filters()}
    >
      <EuiSpacer size="xl" />
      <EuiFlexGroup
        gutterSize="xl"
        justifyContent="spaceBetween"
        direction={isSmallScreen ? "column" : "row"}
      >
        {taskListsToShow.length > 0 ? (
          <EuiFlexItem
            style={{ flexBasis: MIN_WIDTH, flexGrow: 1, flexShrink: 0 }}
          >
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <EuiIcon type="checkInCircleFilled" size="l" />
              <EuiTitle>
                <EuiText>
                  {showMyOrders
                    ? txt.get("orders.my_orders.tasks")
                    : txt.get("orders.my_orders.all_tasks")}
                  {selectedLocations.length > 0 ? (
                    <EuiText color="accent">
                      {selectedLocations
                        .map((loc: any) => loc.label)
                        .join(" & ")}
                    </EuiText>
                  ) : (
                    ""
                  )}
                </EuiText>
              </EuiTitle>
            </div>
            <EuiSpacer size="m" />
            <EuiTabs>
              {shortlistTabs(
                taskListsForUser(),
                selectedTasksTab,
                setSelectedTasksTab
              )}
            </EuiTabs>
            <EuiSpacer />
            <EuiPanel>{selectedTasksShortlist()}</EuiPanel>
          </EuiFlexItem>
        ) : (
          <></>
        )}
        {orderListsToShow.length > 0 ? (
          <EuiFlexItem
            style={{ flexBasis: MIN_WIDTH, flexGrow: 0, flexShrink: 0 }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <EuiIcon type="documentEdit" size="l" />
              <EuiTitle>
                <EuiText>
                  {showMyOrders
                    ? txt.get("orders.my_orders.name")
                    : txt.get("orders.my_orders.all_orders")}
                  {selectedLocations.length > 0 ? (
                    <EuiText color="accent">
                      {selectedLocations
                        .map((loc: any) => loc.label)
                        .join(" & ")}
                    </EuiText>
                  ) : (
                    ""
                  )}
                </EuiText>
              </EuiTitle>
            </div>

            <EuiSpacer size="m" />

            <EuiTabs>
              {shortlistTabs(
                orderListsForUser(),
                selectedOrdersTab,
                setSelectedOrdersTab
              )}
            </EuiTabs>
            <EuiSpacer />
            <EuiPanel>{selectedOrdersShortlist()}</EuiPanel>
          </EuiFlexItem>
        ) : (
          <></>
        )}
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMOrders;
