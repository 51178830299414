import { EuiButton, EuiFlexItem } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { useNavigate } from "react-router-dom";
import { ORDER_EMPTY } from "store/data/order/order";
import MMOrderEntry from "./order-entry/order-entry";

function MMOrderNew() {
  const navigate = useNavigate();

  const navigateBack = () => {
    const lastVisited = localStorage.getItem("last_visited_orders_view");
    if (lastVisited === "orders") {
      navigate("/my-dashboard");
    } else if (lastVisited === "search") {
      navigate("/orders");
    } else {
      navigate("/my-dashboard");
    }
  };

  return (
    <MMPage
      title={""}
      hideTitle={true}
      topActions={[
        <EuiButton
          color="accent"
          aria-label={txt.uf("generic.back")}
          iconType="arrowLeft"
          size="s"
          onClick={() => navigateBack()}
        >
          {txt.uf("generic.back")}
        </EuiButton>,
      ]}
    >
      <EuiFlexItem>
        <MMOrderEntry isOrderChanged={false} order={ORDER_EMPTY} />
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMOrderNew;
