import {
  /* createAsyncThunk, */ createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface User {
  email: string;
  name: string;
  google_id: string;
  thumbnail: string;
  google_credential: string;
}

export interface UserState {
  user: User;
}

export const USER_EMPTY: User = {
  email: "",
  name: "",
  google_id: "",
  thumbnail: "",
  google_credential: "",
};

const initialState: UserState = {
  user: USER_EMPTY,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await APIHelper.getCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userSave: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    userClear: (state) => {
      state.user = USER_EMPTY;
    },
  },

  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = 'failed';
  //     });
  // },
});

export const { userSave, userClear } = userSlice.actions;

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const userGet = (state: RootState) => state.user.user;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default userSlice.reducer;
