import {
  EuiBasicTable,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import txt from "helpers/text-helper";
import { columnDateShort } from "hoc/helper-hooks";
import { useNavigate } from "react-router-dom";
import { Insurer, UzoviCode } from "store/data/insurer/insurer";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMInsurersList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [insurers, setInsurers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "insurers_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("insurers_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "insurers_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "insurers_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [error, setError] = useState("");

  useEffect(() => {
    const loadInsurers = async () => {
      setIsLoading(true);
      const result = await api.getAdminInsurers(
        limit,
        offset,
        insurersFieldToSortKey(sortBy),
        sortOrder
      );
      if (result.status === ApiResponseStatus.OK) {
        setInsurers(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setInsurers([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadInsurers();
  }, [sortOrder, sortBy, limit, offset]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.insurers.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.insurers.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    // enableAllColumns: false,
    // readOnly: false,
  };

  const insurersFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onInsurersChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  /* copied highlightPart and columnFormatted from production-list, should be changed into a component, or intergrated into MMCell */
  const highlightPart = (val: string, highlight: string) => {
    const lVal = val.toString().toLowerCase() as string;
    const lHighlight = highlight.toString().toLowerCase() as string;

    return lVal.indexOf(lHighlight) >= 0 ? (
      <span>
        {val.substring(0, lVal.indexOf(lHighlight))}
        <span style={{ backgroundColor: "#36786A44" }}>
          {val.substring(
            lVal.indexOf(lHighlight),
            lVal.indexOf(lHighlight) + lHighlight.length
          )}
        </span>
        <span>
          {val.substring(lVal.indexOf(lHighlight) + lHighlight.length)}
        </span>
      </span>
    ) : (
      val
    );
  };

  const columnFormatted = (
    value: string,
    noWrap: boolean = false,
    highlight?: string
  ) => (
    <span style={noWrap ? { whiteSpace: "nowrap" } : {}}>
      {!!highlight
        ? highlightPart(value ? value.toString() : "", highlight)
        : value}
    </span>
  );

  const columns = [
    {
      name: txt.get("admin.insurers.id"),
      field: "id",
      sortable: api.insurersIsSortableBy(insurersFieldToSortKey("id")),
      render: (id: number) => <MMCell text={id + ""} wrap={false} />,
    },
    {
      name: txt.get("admin.insurers.name"),
      field: "name",
      sortable: api.insurersIsSortableBy(insurersFieldToSortKey("name")),
      render: (name: string, insurer: Insurer) => (
        <MMCell
          wrap={false}
          text={name}
          subText={insurer.acronym}
          subSoft={true}
        />
      ),
    },
    {
      name: txt.get("admin.insurers.uzovi_codes"),
      field: "uzovi_codes",
      sortable: api.insurersIsSortableBy(insurersFieldToSortKey("uzovi_codes")),
      render: (uzoviCodes: UzoviCode[]) =>
        uzoviCodes ? (
          <EuiFlexGrid gutterSize="none">
            {uzoviCodes.map((uzoviCode: UzoviCode, i: number) => (
              <MMCell
                key={`code-${i}`}
                text={uzoviCode.name}
                subText={uzoviCode.code}
                subSoft={true}
                wrap={false}
              />
            ))}
          </EuiFlexGrid>
        ) : (
          <></>
        ),
    },

    {
      name: txt.get("admin.insurers.payment_term_days"),
      field: "payment_term_days",
      type: "number",
      sortable: api.insurersIsSortableBy(
        insurersFieldToSortKey("payment_term_days")
      ),
    },
    {
      name: txt.get("admin.insurers.declaration_term_days"),
      field: "declaration_term_days",
      type: "number",
      sortable: api.insurersIsSortableBy(
        insurersFieldToSortKey("declaration_term_days")
      ),
    },
    // {
    //   name: txt.get("admin.insurers.email"),
    //   field: "email",
    //   type: "string",
    //   sortable: api.insurersIsSortableBy(insurersFieldToSortKey("email")),
    // },

    // {
    //   name: txt.get("admin.insurers.website"),
    //   field: "website",
    //   type: "string",
    //   sortable: api.insurersIsSortableBy(insurersFieldToSortKey("website")),
    // },

    // {
    //   name: txt.get("admin.insurers.quotation_contact"),
    //   field: "quotation_contact",
    //   type: "string",
    //   sortable: api.insurersIsSortableBy(
    //     insurersFieldToSortKey("quotation_contact")
    //   ),
    // },

    {
      name: txt.get("admin.insurers.quotation_type"),
      field: "quotation_type",
      type: "string",
      sortable: api.insurersIsSortableBy(
        insurersFieldToSortKey("quotation_type")
      ),
    },

    {
      name: txt.get("admin.insurers.quotation_answer"),
      field: "quotation_answer",
      type: "string",
      sortable: api.insurersIsSortableBy(
        insurersFieldToSortKey("quotation_answer")
      ),
    },

    // {
    //   name: txt.get("admin.insurers.declaration_description"),
    //   field: "declaration_description",
    //   type: "string",
    //   sortable: api.insurersIsSortableBy(insurersFieldToSortKey("declaration_description")),
    // },

    {
      name: txt.get("admin.insurers.is_active"),
      type: "string",
      field: "is_active",
      sortable: api.insurersIsSortableBy(insurersFieldToSortKey("is_active")),
      render: (is_active: boolean) => (
        <EuiHealth color={is_active ? "success" : "danger"}>
          {columnFormatted(
            is_active ? txt.get("generic.yes") : txt.get("generic.no"),
            true
          )}
        </EuiHealth>
      ),
    },
    // {
    //   name: txt.get("generic.created_at"),
    //   sortable: api.insurersIsSortableBy(insurersFieldToSortKey("created_at")),
    //   type: "date",
    //   field: "created_at",
    //   render: columnDateShort,
    // },
    {
      name: txt.get("generic.updated_at"),
      sortable: api.insurersIsSortableBy(insurersFieldToSortKey("updated_at")),
      type: "date",
      field: "updated_at",
      render: columnDateShort,
    },
  ];

  const getRowProps = (organisation: any) => {
    const { id } = organisation;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          navigate(`/admin/insurers/${id}`);
        }
      },
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={insurers}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf("generic.found_no_x", txt.get("admin.insurers.page_title"))
        }
        onChange={onInsurersChange}
      />
    </Fragment>
  );
}

export default MMInsurersList;
