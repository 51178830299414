//todo: deprecate
export interface Evaluation {
  id: number;
  score: number;
  notes?: string;
  evaluated_at: Date;
  evaluated_type: string;
  evaluated_id: number;
}

export interface NpsEvaluation {
  rating: number;
  client_code: string;
  production_code: string;
  source_id: string;
  submitted_at: Date;
  // order_line_id:number;
  // production_id:number;
}

export interface JotformProductEvaluationQA {
  id: string;
  question: string;
  answer: string; //json string
}
export interface JotformProductEvaluation {
  form_title: string;
  form_id: string;
  submission_id: string;
  questions_and_answers: any;
  submitted_at: Date;
  // order_line_id:number;
  // production_id:number;
}

export const qaFilteredForDossier = (qas: JotformProductEvaluationQA[]) => {
  const excludeIds: string[] = ["96", "69", "64", "70"];
  return qas.filter(
    (qa: JotformProductEvaluationQA) => !excludeIds.includes(qa.id)
  );
};
export const qaToAnswerString = (qa: JotformProductEvaluationQA) => {
  let result: string = "";
  let object: any;

  try {
    object = JSON.parse(qa.answer);
  } catch (e) {
    object = qa.answer;
  }
  if (Array.isArray(object)) {
    result = object.filter((o: string) => o.trim() !== "").join(", ");
  } else if (typeof object === "string") {
    result += object;
  } else {
    result += object.toString();
  }
  return result || "-";
};
