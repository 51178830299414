import {
  EuiBadge,
  EuiButton,
  EuiButtonIcon,
  EuiCopy,
  EuiFlexItem,
  EuiSelectable,
  EuiSelectableOption,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import MMPage from "components/layouts/page/page";

import AuthAdminAPIHelper from "api/auth-admin-api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt, { DEFAULT_USER_LANGUAGE } from "helpers/text-helper";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthGroup } from "store/data/auth/group";
import { AuthRole } from "store/data/auth/role";
import { useLocalStorage } from "store/local-storage";

export const toLanguageOptions = (languages: any, selected?: string) => {
  let result: EuiSelectableOption[] = [];
  for (const key in languages) {
    if (Object.prototype.hasOwnProperty.call(languages, key)) {
      const language = languages[key];
      let option: EuiSelectableOption = {
        label: language,
        "data-test-subj": key,
      };
      if (selected && key === selected) {
        option.checked = "on";
      }
      result.push(option);
    }
  }
  return result;
};

function MMMyAccount() {
  const api = new ConnectAPIHelper();
  const authApi = new AuthAdminAPIHelper();

  const [userLanguage, setUserLanguage] = useLocalStorage(
    "user_language",
    DEFAULT_USER_LANGUAGE
  );
  const [languageOptions, setLanguageOptions] = useState<EuiSelectableOption[]>(
    []
  );
  const [availableGroups, setAvailableGroups] = useState<string[]>([]);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  useEffect(() => {
    const loadAvailableInfo = async () => {
      if (AuthenticationHelper.hasRole("super_admin")) {
        const roles: AuthRole[] = await authApi.getRoles();
        setAvailableRoles(roles.map((role: AuthRole) => role.name));

        const groups: AuthGroup[] = await authApi.getGroups();
        setAvailableGroups(groups.map((group: AuthGroup) => group.path));
      } else {
        setAvailableGroups(AuthenticationHelper.getGroups());
        setAvailableRoles(AuthenticationHelper.getRoles());
      }

      setUserGroups(AuthenticationHelper.getGroups());
      setUserRoles(AuthenticationHelper.getRoles());
    };

    loadAvailableInfo();
  }, []);

  useEffect(() => {
    //set the language for the texthelper
    txt.lang(userLanguage);

    const loadLanguages = async () => {
      const result = await api.getLanguages(userLanguage);

      setLanguageOptions(toLanguageOptions(result, userLanguage));
    };

    loadLanguages();
  }, [userLanguage]);

  useEffect(() => {
    let selectedLanguages = languageOptions.filter(
      (option: EuiSelectableOption) => option.checked === "on"
    );
    if (selectedLanguages.length > 0) {
      setUserLanguage(selectedLanguages[0]["data-test-subj"]);
    }
  }, [languageOptions]);

  const renderUserGroups = () => {
    const loadedUserGroups: string[] = userGroups || [];
    return availableGroups.map((group) => (
      <EuiBadge
        key={group}
        isDisabled={!loadedUserGroups.includes(group)}
        color={loadedUserGroups.includes(group) ? "success" : "hollow"}
      >
        {group.substring(1).replace("/", " ")}
      </EuiBadge>
    ));
  };

  const renderUserRoles = () => {
    const loadedUserRoles = userRoles || [];

    return availableRoles.map((role) => (
      <EuiBadge
        key={role}
        isDisabled={!loadedUserRoles.includes(role)}
        color={loadedUserRoles.includes(role) ? "success" : "hollow"}
      >
        {role}
      </EuiBadge>
    ));
  };
  return (
    <MMPage title={txt.get("account.my-account.page_title")}>
      <EuiFlexItem>
        <EuiSpacer />
        <table>
          <tbody>
            <tr>
              <td style={{ width: "250px", padding: 10, verticalAlign: "top" }}>
                <EuiText>{txt.get("account.name")}:</EuiText>
              </td>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{AuthenticationHelper.getFullName()}</EuiText>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{txt.get("account.email")}:</EuiText>
              </td>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{AuthenticationHelper.getEmail()}</EuiText>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{txt.get("account.roles")}:</EuiText>
              </td>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{renderUserRoles()}</EuiText>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{txt.get("account.groups")}:</EuiText>
              </td>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{renderUserGroups()}</EuiText>
              </td>
            </tr>
            <tr>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText>{txt.get("account.language_preference")}:</EuiText>
              </td>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiSelectable
                  style={{ display: "flex", width: "200px" }}
                  aria-label="Select language"
                  listProps={{ bordered: true }}
                  options={languageOptions}
                  singleSelection="always"
                  onChange={(newLanguageOptions) =>
                    setLanguageOptions(newLanguageOptions)
                  }
                >
                  {(list) => list}
                </EuiSelectable>
              </td>
            </tr>
            {AuthenticationHelper.hasRole("admin") ||
            AuthenticationHelper.hasRole("3d") ? (
              <tr>
                <td style={{ padding: 10, verticalAlign: "top" }}>
                  <EuiText>{txt.get("account.token")}:</EuiText>
                </td>
                <td style={{ padding: 10, verticalAlign: "top" }}>
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <textarea
                      readOnly={true}
                      rows={7}
                      style={{ width: 450 }}
                      value={AuthenticationHelper.getToken()}
                    ></textarea>
                    <div style={{ position: "absolute", top: 0, right: -40 }}>
                      <EuiCopy textToCopy={AuthenticationHelper.getToken()}>
                        {(copy) => (
                          <EuiButtonIcon
                            onClick={copy}
                            iconType="copy"
                            aria-label={txt.get(
                              "generic.copy",
                              txt.get("account.token")
                            )}
                          />
                        )}
                      </EuiCopy>
                    </div>
                  </div>
                </td>
              </tr>
            ) : null}
            <tr>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <EuiText></EuiText>
              </td>
              <td style={{ padding: 10, verticalAlign: "top" }}>
                <NavLink to="/logout">
                  <EuiButton>{txt.get("login.logout")}</EuiButton>
                </NavLink>
              </td>
            </tr>
          </tbody>
        </table>
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMMyAccount;
