import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiDatePicker,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexItem,
  EuiFormControlLayout,
  EuiFormRow,
  EuiHorizontalRule,
  EuiMarkdownFormat,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  AlertConfirm,
  AlertConfirmActionState,
  AlertInput,
  AlertInputValueType,
  confirmAlternative,
  confirmClose,
  confirmGet,
  confirmPerform,
} from "store/components/alert/confirm";

function MMAlertConfirm() {
  const dispatch = useDispatch();
  const alertConfirm: AlertConfirm = useSelector(confirmGet);

  const [inputValue, setInputValue] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [title, setTitle] = useState<any | undefined>();
  const [fields, setFields] = useState<any | undefined>();
  const [editableDate, setEditableDate] = useState<any | null>();

  useEffect(() => {
    let isValid = true;
    if (alertConfirm.input && alertConfirm.input.isMandatory) {
      isValid =
        alertConfirm.input.valueType === AlertInputValueType.Check
          ? !!inputValue
          : inputValue !== undefined && inputValue !== "";
    }
    setIsValid(isValid);
  }, [inputValue, alertConfirm]);

  useEffect(() => {
    if (alertConfirm.actionData && alertConfirm.actionData.fields) {
      setFields((data: any) => [...alertConfirm.actionData.fields]);
    } else {
      setFields((data: any) => null);
    }
    if (alertConfirm.actionData && alertConfirm.actionData.title) {
      setTitle(alertConfirm.actionData.title);
    } else {
      setTitle("");
    }
    if (alertConfirm.actionData && alertConfirm.actionData.editableDate) {
      setEditableDate(alertConfirm.actionData.editableDate);
    } else {
      setEditableDate(null);
    }
  }, [alertConfirm]);

  const onFieldChange = (e: any, index: number) => {
    if (fields && fields[index]) {
      let newFields: any[] = [];
      fields.forEach((field: any, i: number) => {
        if (i === index) {
          newFields.push({
            ...field,
            ...{
              value: field.type !== "check" ? e.target.value : field.value,
              checked:
                field.type === "check" ? e.target.checked : field.checked,
            },
          });
        } else {
          newFields.push({ ...field });
        }
      });
      setFields(newFields);
    }
  };

  const onInputValueChange = (e: any) => {
    setInputValue(
      alertConfirm.input?.valueType === AlertInputValueType.Check
        ? e.target.checked
        : e.target.value
    );
  };
  const closeAlertConfirm = () => {
    dispatch(confirmClose());
  };

  const onCancel = () => {
    closeAlertConfirm();
  };

  const alertAlternative = () => {
    dispatch(confirmAlternative({ ...alertConfirm }));
  };

  const onAlternative = () => {
    alertAlternative();
  };

  const onConfirm = () => {
    let resultingConfirm: AlertConfirm = { ...alertConfirm };
    if (resultingConfirm.input) {
      resultingConfirm.input = {
        isVisible: resultingConfirm.input.isVisible,
        isMandatory: resultingConfirm.input.isMandatory,
        valueType: resultingConfirm.input.valueType,
        value: inputValue,
      };
    }
    if (resultingConfirm.actionData && resultingConfirm.actionData.fields) {
      resultingConfirm.actionData = {
        ...resultingConfirm.actionData,
        fields: [...fields],
      };
    }
    if (
      resultingConfirm.actionData &&
      resultingConfirm.actionData.editableDate
    ) {
      resultingConfirm.actionData = {
        ...resultingConfirm.actionData,
        editableDate: editableDate,
      };
    }
    dispatch(confirmPerform(resultingConfirm));
  };

  const renderFields = (fields: any) => {
    return fields.map((field: any, i: number) =>
      field.type === "check" ? (
        renderCheckField(field, i)
      ) : field.type === "select" ? (
        renderSelectField(field, i)
      ) : (
        <></>
      )
    );
  };

  const renderCheckField = (field: any, index: number) => {
    return (
      <EuiFormRow key={`${field.key}.${field.value}`}>
        <EuiCheckbox
          compressed={true}
          readOnly={field.read_only}
          disabled={field.read_only}
          id={`${field.key}.${field.value}.${index}`}
          name={field.key}
          label={<EuiText>{field.label}</EuiText>}
          onChange={(e: any) => {
            onFieldChange(e, index);
          }}
          checked={fields[index].checked}
        />
      </EuiFormRow>
    );
  };

  const renderSelectField = (field: any, index: number) => {
    return (
      <EuiFormControlLayout
        key={`${field.id}.${field.key}.${field.value}`}
        fullWidth={true}
        className="confirm-select-list-control"
        compressed={true}
        isDisabled={field.read_only}
        readOnly={field.read_only}
        append={
          <EuiSelect
            compressed={true}
            disabled={field.read_only}
            options={field.options}
            value={field.value}
            onChange={(e: any) => {
              onFieldChange(e, index);
            }}
          />
        }
      >
        <EuiText style={{ backgroundColor: "white" }}>{field.label}</EuiText>
      </EuiFormControlLayout>
    );
  };

  const renderEditableDateField = () => {
    return editableDate ? (
      <Fragment>
        <EuiSpacer />
        <EuiFormRow
          label={txt.get("orders.order.happened_at")}
          key="editable_date"
        >
          <EuiDatePicker
            dateFormat={"DD-MM-YYYY"}
            compressed={true}
            id="editable_date"
            name="editable_date"
            showTimeSelect={true}
            selected={MomentHelper.toMoment(DateHelper.parseDate(editableDate))}
            onChange={(moment: any) => {
              setEditableDate(
                DateHelper.toDateTimeSortable(MomentHelper.toDate(moment))
              );
            }}
          />
        </EuiFormRow>
      </Fragment>
    ) : (
      <></>
    );
  };
  const renderTitle = () => {
    return title ? (
      <Fragment>
        <EuiText size="s">{txt.str_to_html(title)}</EuiText>
        <EuiSpacer size="m" />
      </Fragment>
    ) : (
      <></>
    );
  };

  const renderExtraFields = (input?: AlertInput) => {
    return fields ? (
      <Fragment>
        {renderFields(fields)}
        <EuiHorizontalRule />
      </Fragment>
    ) : (
      <></>
    );
  };

  const renderInput = (input?: AlertInput) => {
    return input && input.isVisible ? (
      <Fragment>
        <EuiSpacer size="m"></EuiSpacer>
        <EuiFormRow
          label={
            input.valueType === AlertInputValueType.Check
              ? ""
              : input.description || txt.get("generic.add_description")
          }
          isInvalid={!isValid}
        >
          {input.valueType === AlertInputValueType.Check ? (
            <EuiCheckbox
              id="input"
              name="input"
              onChange={onInputValueChange}
              checked={!!inputValue}
              value="checked"
              label={
                <EuiText>
                  {input.valueType === AlertInputValueType.Check
                    ? input.description || txt.get("generic.add_description")
                    : ""}
                </EuiText>
              }
            />
          ) : input.valueType === AlertInputValueType.Password ? (
            <EuiFieldPassword
              autoComplete="off"
              type="dual"
              name="input"
              isInvalid={!isValid}
              value={inputValue}
              onChange={onInputValueChange}
            />
          ) : (
            <EuiFieldText
              name="input"
              isInvalid={!isValid}
              value={inputValue}
              onChange={onInputValueChange}
            />
          )}
        </EuiFormRow>
        <EuiSpacer size="s"></EuiSpacer>
      </Fragment>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (alertConfirm.actionState === AlertConfirmActionState.Perform) {
      dispatch(confirmClose());
    }
  }, [alertConfirm]);

  return alertConfirm.actionState === AlertConfirmActionState.Ask ? (
    <EuiModal className="modal-confirm" onClose={onCancel}>
      <EuiModalHeader>
        <EuiTitle>
          <EuiText>{alertConfirm.title}</EuiText>
        </EuiTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {renderTitle()}
        {renderExtraFields(alertConfirm.input)}
        {alertConfirm?.contentType === "markdown" ? (
          <EuiMarkdownFormat>{alertConfirm.content}</EuiMarkdownFormat>
        ) : (
          <EuiText>{txt.str_to_html(alertConfirm.content)}</EuiText>
        )}

        {renderInput(alertConfirm.input)}
        {renderEditableDateField()}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexItem grow={true}>
          {alertConfirm.altText ? (
            <EuiButtonEmpty style={{ color: "#707070" }} onClick={onCancel}>
              {alertConfirm.cancelText || txt.get("generic.no")}
            </EuiButtonEmpty>
          ) : (
            <></>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          {alertConfirm.altText ? (
            <EuiButtonEmpty
              style={{ border: "solid 1px #707070", color: "#707070" }}
              onClick={onAlternative}
            >
              {alertConfirm.altText}
            </EuiButtonEmpty>
          ) : (
            <EuiButtonEmpty onClick={onCancel}>
              {alertConfirm.cancelText || txt.get("generic.no")}
            </EuiButtonEmpty>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton
            fill={true}
            onClick={onConfirm}
            disabled={!isValid}
            color={alertConfirm.type === "danger" ? "danger" : "accent"}
          >
            {alertConfirm.confirmText || txt.get("generic.confirm")}
          </EuiButton>
        </EuiFlexItem>
      </EuiModalFooter>
    </EuiModal>
  ) : (
    // defaultFocusedButton={alertConfirm.input ? undefined : "confirm"}

    <></>
  );
}

export default MMAlertConfirm;
