export const HIDDEN_ROLES: string[] = [
  "uma_authorization",
  "default-roles-manometric",
  "rabbitmq-management",
  "rabbitmq.tag:administrator",
  "rabbitmq.tag:management",
  "processing-sys",
  "production-sys",
  "scanner-sys",
  "rabbitmq.configure:*/*",
  "rabbitmq.read:*/*",
  "rabbitmq.write:*/*",
  "rabbitmq.read:*/*/*",
  "rabbitmq.write:*/*/*",
  "offline_access",
  "super_admin",
];
