import createActivityDetector from "activity-detector";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";

const AUTHENTICATION_CHECK_TIMEOUT = 15 * 60 * 1000; // 15 minutes

function MMAuthenticationProvider(props: any) {
  const [isInitializing, setIsInitializing] = useState(true);
  const [isActive, setIsActive] = useState(true);

  const activityDetector = createActivityDetector({
    timeToIdle: AUTHENTICATION_CHECK_TIMEOUT,
  });

  const checkAuthenticationOnActive = async () => {
    //became active but not on init, so later
    //do an authentication check
    if (isInitializing) {
      console.log(
        "still initializing, no auth check necessary",
        DateHelper.toDateTime()
      );
    } else if (isActive) {
      const authCheckResult =
        await AuthenticationHelper.refreshAuthentication();
      console.log(
        "became active after a while, auth check:",
        DateHelper.toDateTime(),
        authCheckResult
      );
    } else {
      console.log("became inactive after a while", DateHelper.toDateTime());
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await txt.init();

      AuthenticationHelper.init(
        async (user: any) => {
          setIsInitializing(false);
        },
        () => {
          setIsInitializing(false);
        }
      );
    };

    initialize();
  }, []);

  useEffect(() => {
    activityDetector.on("idle", () => setIsActive(false));
    activityDetector.on("active", () => setIsActive(true));

    return () => activityDetector.stop();
  }, []);

  useEffect(() => {
    checkAuthenticationOnActive();
  }, [isActive]);

  return isInitializing ? null : props.children;
}

export default MMAuthenticationProvider;
