import { EuiBottomBar, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import env from "helpers/env-helper";

function MMBetaBar() {
  const betaBar: string = env("REACT_APP_SHOW_BETA_BAR");
  const hasBetaBar: boolean = !!betaBar && parseInt(betaBar) !== 0;

  return hasBetaBar ? (
    <EuiBottomBar className="demo-bar" paddingSize="none">
      <EuiFlexGroup gutterSize="s" justifyContent="center" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiText size="s" color="#ffffffaa">
            {betaBar === "1" ? "BETA" : betaBar}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiBottomBar>
  ) : (
    <></>
  );
}

export default MMBetaBar;
