import AuthenticationHelper from "helpers/authentication-helper";
import { OrderStatus } from "store/data/order/order";
import { OrganisationOrdering } from "store/data/organisation/organisation";

export const deletePermitted = async (status: OrderStatus, isDemo: boolean) => {
  // console.log("deletePermitted", status, isDemo);
  return (
    ((isDemo || status === OrderStatus.Drafted) &&
      (await AuthenticationHelper.hasPermission([
        "orders#delete_drafted",
        "orders#delete_drafted_org",
        "orders#delete_drafted_all",
        "orders#delete_all",
      ]))) ||
    ((isDemo || status !== OrderStatus.Drafted) &&
      (await AuthenticationHelper.hasPermission("orders#delete_all")))
  );
};

export const downloadPermitted = async (status: OrderStatus) => {
  const result =
    status !== OrderStatus.Drafted && AuthenticationHelper.isInternalUser();

  return result;
};

export const savePermitted = async (status: OrderStatus) => {
  const result =
    (status === OrderStatus.Drafted &&
      (await AuthenticationHelper.hasPermission([
        "orders#submit",
        "orders#submit_org",
        "orders#submit_all",
      ]))) ||
    (status !== OrderStatus.Drafted &&
      (await AuthenticationHelper.hasPermission([
        "orders#edit_submitted",
        "orders#edit_submitted_org",
        "orders#edit_submitted_all",
      ])));
  // console.log("savePermitted", result);
  return result;
};

export const submitPermitted = async (status: OrderStatus) => {
  return (
    status === OrderStatus.Drafted &&
    (await AuthenticationHelper.hasPermission([
      "orders#submit",
      "orders#submit_org",
      "orders#submit_org",
    ]))
  );
};

export const isAccepter = async (
  status: OrderStatus,
  ordering: OrganisationOrdering[]
) => {
  // console.log("acceptPermitted", status, ordering);
  if (
    (!ordering || ordering.includes(OrganisationOrdering.Internal)) &&
    (await AuthenticationHelper.hasPermission([
      "orders#accept_internal",
      "orders#accept_all",
    ]))
  )
    return true;

  if (
    (!ordering || ordering.includes(OrganisationOrdering.External)) &&
    (await AuthenticationHelper.hasPermission([
      "orders#accept_external",
      "orders#accept_all",
    ]))
  )
    return true;

  if (
    (!ordering || ordering.includes(OrganisationOrdering.B2B)) &&
    (await AuthenticationHelper.hasPermission([
      "orders#accept_b2b",
      "orders#accept_all",
    ]))
  )
    return true;

  return false;
};

export const acceptPermitted = async (
  status: OrderStatus,
  ordering: OrganisationOrdering[]
) => {
  // console.log("acceptPermitted", status, ordering);
  if (
    ![
      OrderStatus.Submitted,
      OrderStatus.Rejected,
      OrderStatus.Cancelled,
    ].includes(status)
  )
    return false;

  return isAccepter(status, ordering);
};

export const cancelPermitted = async (status: OrderStatus) => {
  return (
    status !== OrderStatus.Drafted &&
    status !== OrderStatus.Cancelled &&
    (await AuthenticationHelper.hasPermission(["orders#cancel_all"]))
  );
};

export const deliverPermitted = async (status: OrderStatus) => {
  return (
    [
      OrderStatus.Accepted,
      OrderStatus.Packaged,
      OrderStatus.PackagedPartial,
      OrderStatus.Routed,
      OrderStatus.RoutedPartial,
      OrderStatus.Produced,
      OrderStatus.ProducedPartial,
      OrderStatus.Shipped,
      OrderStatus.ShippedPartial,
      OrderStatus.DeliveredPartial,
    ].includes(status) &&
    (await AuthenticationHelper.hasPermission([
      "orders#deliver",
      "orders#deliver_org",
      "orders#deliver_all",
    ]))
  );
};

export const finalizePermitted = async (status: OrderStatus) => {
  return (
    [OrderStatus.Delivered].includes(status) &&
    (await AuthenticationHelper.hasPermission(["orders#finalize_external"])) &&
    AuthenticationHelper.isInternalUser()
  );
};

export const rejectPermitted = async (status: OrderStatus) => {
  return false;
};

export const configuringPermitted = async (status: OrderStatus) => {
  return status === OrderStatus.Drafted;
};

export const correctionsPermitted = async (status: OrderStatus) => {
  let result =
    status !== OrderStatus.Drafted &&
    (await AuthenticationHelper.hasPermission(["orders#correct_submitted"]));
  // console.log("correctionsPermitted", result);
  return result;
};
