class LocalStorageHelper {
  static set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  static get(key: string): string | null {
    const value: string | null = localStorage.getItem(key);
    return value;
  }

  static clear(key: string): void {
    localStorage.removeItem(key);
  }

  static clearAll(): void {
    localStorage.clear();
  }
}

export default LocalStorageHelper;
