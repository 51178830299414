export enum ContactMethodType {
  Email = "EMAIL",
  MobilePhone = "MOBILE_PHONE",
  Phone = "PHONE",
}

export interface ContactMethod {
  id: number | null;
  type: ContactMethodType | null;
  name: string | null;
  value: string | null;
  salutation: string | null;
  validation_code: string | null;
  validation_requested_at: Date | null;
  validation_confirmed_at: Date | null;
}

export const contact = (
  contactable: any,
  name?: string,
  type?: ContactMethodType,
  i: number = 0
): ContactMethod | null => {
  if (contactable && contactable.contact_methods) {
    let filtered: ContactMethod[] = contactable.contact_methods;

    if (name) {
      filtered = filtered.filter(
        (contactMethod: ContactMethod) => contactMethod.name === name
      );
    }
    if (type) {
      filtered = filtered.filter(
        (contactMethod: ContactMethod) => contactMethod.type === type
      );
    }

    if (filtered.length > 0 && i < filtered.length) {
      return filtered[i];
    }
  }
  return { name, type } as ContactMethod;
};
