import { EuiLoadingSpinner, EuiText } from "@elastic/eui";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import env from "helpers/env-helper";
import { useEffect, useState } from "react";
import MMMapView from "./map-view";

function MMMap({ lat, lng }: { lat?: number; lng?: number }) {
  const [location, setLocation] = useState<google.maps.LatLngLiteral | null>(
    null
  );

  useEffect(() => {
    if (lat && lng) {
      console.log("setting new map location");
      setLocation({ lat, lng } as google.maps.LatLngLiteral);
    }
  }, [lat, lng]);

  const API_KEY: string = env("REACT_APP_GOOGLE_MAPS");

  const render: any = (status: any) => {
    console.log(status, lat, lng);
    switch (status) {
      case Status.LOADING:
        return <EuiLoadingSpinner />;
      case Status.FAILURE:
        return <EuiText>could not load google api</EuiText>;
      case Status.SUCCESS:
        return <MMMapView location={location || undefined} />;
    }
  };

  return <Wrapper apiKey={API_KEY} render={render} />;
}

export default MMMap;
