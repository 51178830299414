import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MMClientsList from "./clients-list";
function MMClients() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [sideButtonsToShow, setSideButtonsToShow] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      buttons.push(
        <EuiButton
          aria-label={txt.get("clients.page_title")}
          size="s"
          fill={true}
          style={{ width: "40px", minInlineSize: "40px" }}
          iconType="faceHappy"
          onClick={() => navigate("/clients")}
        />
      );
      let sideButtons: any[] = [];
      if (
        await AuthenticationHelper.hasPermission(["client_details#edit_all"])
      ) {
        sideButtons.push(
          <EuiButton
            key="add_client"
            color="accent"
            size="s"
            fill
            disabled={true}
            iconType="plus"
            onClick={() => navigate("/clients/new")}
          >
            {txt.get("generic.add_new_x", txt.lo("clients.name"))}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
      setSideButtonsToShow(sideButtons);
    };
    setInterfaceForPermissions();
  }, []);

  return (
    <MMPage
      title={txt.get("clients.page_title")}
      topActions={buttonsToShow}
      sideActions={sideButtonsToShow}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMClientsList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMClients;
