import { Client } from "store/data/client/client";
import { Order, OrderLine } from "store/data/order/order";

export interface MMCostingDetailsProps {
  order?: Order | null;
  hasValidation?: boolean;
  onChange?: any;
  client?: Client | null;
}

export const toVlotInvoiceDetailsByIndex = (
  order?: Order | null,
  orderLineIndex?: number
) => {
  if (
    order &&
    order.order_lines &&
    orderLineIndex &&
    order.order_lines.length <= orderLineIndex
  ) {
    const orderLine: OrderLine | undefined = order.order_lines[orderLineIndex];
    if (orderLine && orderLine.vlot_invoice_detail) {
      return orderLine.vlot_invoice_detail;
    }
  }
  return null;
};

export const toCostingByIndex = (
  order?: Order | null,
  orderLineIndex?: number
) => {
  if (
    order &&
    order.order_lines &&
    orderLineIndex &&
    order.order_lines.length <= orderLineIndex
  ) {
    const orderLine: OrderLine | undefined = order.order_lines[orderLineIndex];
    if (orderLine && orderLine.costing) {
      return orderLine.costing;
    }
  }
  return null;
};

export const toVlotInvoiceDetails = (
  order?: Order | null,
  orderLineId?: number
) => {
  if (order && order.order_lines && orderLineId) {
    const orderLine: OrderLine | undefined = order.order_lines.find(
      (orderLine: OrderLine) => orderLine.id === orderLineId
    );
    if (orderLine && orderLine.vlot_invoice_detail) {
      return orderLine.vlot_invoice_detail;
    }
  }
  return null;
};

export const toCosting = (order?: Order | null, orderLineId?: number) => {
  if (order && order.order_lines && orderLineId) {
    const orderLine: OrderLine | undefined = order.order_lines.find(
      (orderLine: OrderLine) => orderLine.id === orderLineId
    );
    if (orderLine && orderLine.costing) {
      return orderLine.costing;
    }
  }
  return null;
};

export const toCostingIndex = (order?: Order | null, orderLineId?: number) => {
  if (order && order.order_lines && orderLineId) {
    const orderLineIndex: number = order.order_lines.findIndex(
      (orderLine: OrderLine) => orderLine.id === orderLineId
    );
    if (orderLineIndex >= 0) {
      return orderLineIndex;
    }
  }
  return null;
};

export const isCostingFirstWithMeasuringFee = (
  order?: Order | null,
  orderLineIndex?: number
) => {
  if (!order) return true;
  if (orderLineIndex === undefined) return true;
  if (!order.order_lines || order.order_lines.length <= 0) return true;

  const orderLine = order.order_lines[orderLineIndex];
  if (!orderLine) return true;
  //first that has a consecutive measuring fee. because
  const firstOrderLineIndex = order.order_lines.findIndex(
    (current: OrderLine) =>
      !!current?.costing?.contract?.measuring_fee_first &&
      !!current?.costing?.contract?.measuring_fee_consecutive
  );

  if (firstOrderLineIndex >= 0 && firstOrderLineIndex === orderLineIndex)
    return true;

  //the only case when there are more than one and this one is not the first one.. all other cases it's true
  if (orderLineIndex > firstOrderLineIndex) {
    console.log("found orderlineindex higher than current", {
      orderLineIndex,
      firstOrderLineIndex,
    });
    return false;
  }

  return true;
};
