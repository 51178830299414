export const SCANNER_SORTABLE_FIELDS: string[] = [
  "scanner_id",
  "revision",
  "health",
  "software_version",
];

export const SCANNER_FILTERABLE_FIELDS: string[] = [
  "name",
  "health",
  "online",
  "upload",
  "date_range",
];

export const SCAN_SORTABLE_FIELDS: string[] = [
  "scan_id",
  "name",
  "captured_at",
  "client",
  "hand",
  "status",
  "processing_status",
  "quality",
  "scanner",
];

export const SCAN_FILTERABLE_FIELDS: string[] = [
  "name",
  "client_number",
  "status",
  "scanner",
  "date_range",
];

export const SCANS_DEFAULT_SINCE = "2022-10-01";
