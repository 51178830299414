import {
  EuiCheckbox,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTreeView,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { snakeCase } from "lodash";
import { useEffect, useState } from "react";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";

export type OnCategoryChange = (categories: any[]) => void;

const SEARCH_DEBOUNCE_DELAY: number = 700;

export interface MMProductFiltersProps {
  showSearch: boolean;
  showLocations: boolean;
  categories: any[];
  onChange: OnCategoryChange;
  treeHeight?: string;
}

function MMProductFilters(props: MMProductFiltersProps) {
  const [categories] = useState<any[]>(props.categories);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "product_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);

  const [treeItems, setTreeItems] = useState<any[]>([]);

  const indexOfCat = (id: string) =>
    selectedCategories.findIndex((cat: string) => {
      return cat === id;
    });

  const isCategorySelected = (id: string) => indexOfCat(id) >= 0;

  const toggleCategory = (id: string, select?: boolean) => {
    const catIndex: number = indexOfCat(id);
    if (catIndex >= 0 && (select === undefined || !select)) {
      //change to 'radio button' idea - one selection at a time
      setSelectedCategories([]);
      // setSelectedCategories((cats: string[]) =>
      //   cats.filter((cat, i) => i !== catIndex)
      // );
    } else if (catIndex < 0 && (select === undefined || select)) {
      //change to 'radio button' idea - one selection at a time
      setSelectedCategories([id]);
      // setSelectedCategories((cats: string[]) => cats.concat([id]));
    }
  };
  const categoriesToTreeOptions = (categories: any, prefix: string = "") => {
    let result: any[] = [];
    if (typeof categories === "string") {
      result.push({ label: categories, id: categories });
    } else {
      for (const key in categories) {
        let id: string = prefix
          ? `${prefix}-${snakeCase(key)}`
          : snakeCase(key);
        let category: any = {
          isExpanded: !prefix && result.length === 0 ? true : undefined,
          callback:
            typeof categories[key] === "string"
              ? () => {
                  toggleCategory(id);
                }
              : () => {},
          label: (
            <EuiFlexItem>
              {typeof categories[key] === "string" ? (
                <EuiCheckbox
                  checked={isCategorySelected(id)}
                  onChange={() => {}}
                  id={id}
                  label={key}
                />
              ) : (
                <EuiText style={{ padding: "10px" }} size="s">
                  {key}
                </EuiText>
              )}
            </EuiFlexItem>
          ),
          id: id,
        };

        if (typeof categories[key] !== "string") {
          category.children = categoriesToTreeOptions(
            categories[key],
            prefix ? `${prefix}-${snakeCase(key)}` : snakeCase(key)
          );
        }
        result.push(category);
      }
    }
    return result;
  };

  useEffect(() => {
    if (props.onChange) {
      let cats: any[] = selectedCategories.map((cat: string) => {
        const cats: string[] = cat.split("-");
        return {
          category: cats.length > 0 ? cats[0] : null,
          select: cats.length > 1 ? cats[1] : null,
          sub_select: cats.length > 2 ? cats[2] : null,
        };
      });
      cats.push({ category: "search", select: search || null });
      props.onChange(cats);
    }
  }, [selectedCategories, search]);

  useEffect(() => {
    setTreeItems(categoriesToTreeOptions(categories));
  }, [categories, selectedCategories]);

  return (
    <EuiFlexGroup direction="column" justifyContent="flexStart" gutterSize="xl">
      <EuiFlexItem grow={false} style={{ position: "relative", top: "-3px" }}>
        <EuiFieldSearch
          compressed={true}
          placeholder={txt.get("orders.order.search_product")}
          value={searchFieldValue}
          isClearable={true}
          //contentEditable={!isLoading}
          onChange={(e: any) => setSearchFieldValue(e.target.value)}
          aria-label={txt.get("generic.search")}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {treeItems && treeItems.length > 0 ? (
          <EuiTreeView
            style={
              props.treeHeight
                ? {
                    height: props.treeHeight,
                    overflowY: "auto",
                  }
                : {}
            }
            showExpansionArrows={true}
            items={treeItems}
            aria-label={txt.get("products.categories.title")}
          />
        ) : (
          <></>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
export default MMProductFilters;
