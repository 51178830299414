import MMMain from "components/main/main";
import MMAuthenticationProvider from "hoc/authentication-provider/authentication-provider";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "store/store";
//import reportWebVitals from 'helpers/web-report-helper';
import "@elastic/eui/dist/eui_theme_light.css";
import "styles/style.scss";
import "styles/env.scss";

import { EuiProvider } from "@elastic/eui";
import { trackInit } from "helpers/analytics-helper";
import { sentryInit } from "helpers/sentry-helper";
import MMThemeSettings from "styles/theme";
// import MMSocketProvider from "hoc/socket-provider/socket-provider";
import LocalStorageMigrator from "helpers/local-storage-migrator";
import { setupLogLevels } from "helpers/log-helper";
import MMMqttProvider from "hoc/mqtt-provider/mqtt-provider";

LocalStorageMigrator.migrate();
setupLogLevels();
sentryInit();
trackInit();

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <MMAuthenticationProvider>
    <Provider store={store}>
      <MMMqttProvider />
      <EuiProvider colorMode="light" modify={MMThemeSettings}>
        <MMMain />
      </EuiProvider>
    </Provider>
  </MMAuthenticationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

declare global {
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): number;
  }
}
