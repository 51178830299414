import MMFooter from "components/layouts/footer/footer";
import MMNavigation from "components/layouts/navigation/navigation";

import { EuiContext, EuiPageTemplate } from "@elastic/eui";
import MMAlertConfirm from "components/layouts/alert/confirm";
import MMBetaBar from "components/layouts/demo-banner/beta-bar";
import MMDemoBanner from "components/layouts/demo-banner/demo-banner";
import MMHeader from "components/layouts/header/header";
import MMToasts from "components/layouts/toasts/toasts";
import MMTours from "components/layouts/tours/tours";
import MMOrderPopover from "components/orders/order-popover";
import txt from "helpers/text-helper";
import { BrowserRouter } from "react-router-dom";

// first example of using languages, now language change in user settings for this only works if
// todo: add keys via translation files
// todo: make the text helper an HOC? to make sure we have a rerender if languages change?

const euiTexts: any = {
  en: undefined,
  nl: {
    "euiComboBoxOptionsList.loadingOptions": "Opties laden",
    "euiComboBoxOptionsList.noMatchingOptions":
      "Niks gevonden voor '{searchValue}'",
    "euiSelectable.noMatchingOptions": "Niks gevonden voor '{searchValue}'",
  },
};

function MMMain() {
  return (
    <EuiContext i18n={{ mapping: euiTexts[txt.lang()] }}>
      <BrowserRouter>
        <EuiPageTemplate>
          <MMBetaBar />
          <MMHeader />
          <MMNavigation />
          <MMFooter />
          <MMOrderPopover />
          <MMToasts />
          <MMTours />
          <MMAlertConfirm />
          <MMDemoBanner />
        </EuiPageTemplate>
      </BrowserRouter>
    </EuiContext>
  );
}

export default MMMain;
