/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import blobStream from "blob-stream";
import { format } from "date-fns";
import fs from "fs";
import PDFDocument from "pdfkit";

// Asset loading
import HelveticaBold from "pdfkit/js/data/Helvetica-Bold.afm";
import Helvetica from "pdfkit/js/data/Helvetica.afm";
import Europa from "./assets/fonts/Europa-Bold.ttf";
import Logo from "./assets/logo.png";

import { formatLocation } from "store/data/location/location";

async function load(fsLocation, assetUrl) {
  // console.log(`loading ${fsLocation} from ${assetUrl}`);
  const response = await fetch(assetUrl);
  if (!response.ok) {
    console.error(`failed to load`, assetUrl);
    return;
  }
  const arrayBuffer = await response.arrayBuffer();
  // console.log(`loaded '${fsLocation}', size: `, arrayBuffer.byteLength);
  fs.writeFileSync(fsLocation, Buffer.from(arrayBuffer));
}

load("data/Helvetica.afm", Helvetica);
load("data/Helvetica-Bold.afm", HelveticaBold);
load("data/Europa-Bold.ttf", Europa);
load("assets/logo.png", Logo);

const Page = {
  marginTop: 40,
  marginLeft: 60,
  contentWidth: 480,
  footerHeight: 750,
};

function addHeader(doc, position, header, text) {
  const size = [Page.contentWidth, 25];
  const padding = 8.0;
  const split = 0.3;

  doc // draw borders
    .rect(position[0], position[1], size[0], size[1])
    .strokeColor("black")
    .opacity(1.0)
    .lineWidth(0.5)
    .stroke();
  doc // draw cell separator
    .moveTo(position[0] + size[0] * split, position[1])
    .lineTo(position[0] + size[0] * split, position[1] + size[1])
    .stroke();
  doc // draw label text
    .fillColor("black")
    .font("Helvetica-Bold")
    .fontSize(12)
    .text(header, position[0] + padding, position[1] + padding, {
      width: size[0] - 2 * padding,
      align: "left",
    });
  doc // draw text
    .font("Helvetica")
    .text(
      text,
      position[0] + size[0] * split + padding,
      position[1] + padding,
      {
        width: size[0] - 2 * padding,
        align: "left",
      }
    );
}

const formatProduct = (p) =>
  p
    ? `${p.name || ""} ${p.size || ""} ${p.color || ""}`
        .replace(" n.v.t.", "")
        .replace(" n/a", "")
    : "no product";

const formatMeasurement = (c, m) => {
  console.log("formatMeasurement", c, m, c.measurements[m]);
  return c.measurements[m]
    ? isNaN(parseFloat(c.measurements[m]))
      ? c.measurements[m]
      : parseFloat(c.measurements[m]).toFixed(2)
    : "";
};

const formatLoc = (c) =>
  c.digits.length > 0 ? `${c.hand}-[${c.digits}]` : c.hand;

const columns = [
  { name: "Code", width: 0.12, fmt: (c) => c.code },
  { name: "Product", width: 0.28, fmt: (c) => formatProduct(c.product) },
  { name: "Loc", a: "l", width: 0.08, fmt: (c) => formatLoc(c) },
  { name: "O1", a: "r", width: 0.06, fmt: (c) => formatMeasurement(c, "o1") },
  { name: "O2", a: "r", width: 0.06, fmt: (c) => formatMeasurement(c, "o2") },
  { name: "O3", a: "r", width: 0.06, fmt: (c) => formatMeasurement(c, "o3") },
  { name: "O4", a: "r", width: 0.06, fmt: (c) => formatMeasurement(c, "o4") },
  { name: "L1", a: "r", width: 0.06, fmt: (c) => formatMeasurement(c, "l1") },
  { name: "L2", a: "r", width: 0.06, fmt: (c) => formatMeasurement(c, "l2") },
  { name: "S1", a: "r", width: 0.08, fmt: (c) => c.measurements["s1"] || "" },
  { name: "J1", a: "r", width: 0.08, fmt: (c) => c.measurements["j1"] || "" },
];

function addProductHeader(doc, position) {
  const size = [Page.contentWidth, 25];
  const padding = [4.0, 8.0];

  doc // draw borders
    .rect(position[0], position[1], size[0], size[1])
    .strokeColor("black")
    .opacity(1.0)
    .lineWidth(0.5)
    .stroke();
  let offset = position[0];
  columns.forEach((col) => {
    doc // draw text
      .fillColor("black")
      .font("Helvetica-Bold")
      .fontSize(9)
      .text(col.name, offset + padding[0], position[1] + padding[1], {
        width: col.width * size[0] - padding[1],
        align: col.a === "r" ? "right" : "left",
      });
    offset += col.width * size[0];
  });
  return size[1];
}

function addProductRow(doc, position, orderLine, product) {
  const size = [Page.contentWidth, 0];
  const padding = [4.0, 8.0];

  const line = { ...orderLine, ...{ product } };
  console.log("adding product row", line, product);
  let offset = position[0];
  doc // text defaults
    .fillColor("black")
    .font("Helvetica")
    .fontSize(8);
  columns.forEach((col) => {
    const text = col.fmt(line);
    const options = {
      width: col.width * size[0] - padding[1],
      align: col.a === "r" ? "right" : "left",
    };
    size[1] = Math.max(
      size[1],
      doc.heightOfString(text, options) + 2 * padding[1]
    );
    doc.text(text, offset + padding[0], position[1] + padding[1], options);
    offset += col.width * size[0];
  });

  // Add notes box, which also includes order type, remake reason and replacement for (if set)
  const prefix =
    `Order type: ${orderLine.order_type}, ` +
    `Reason: ${orderLine.remake_reason || "-"}, ` +
    `Replacement for: ${orderLine.replacement_for || "-"}\n\n`;
  const notes = prefix + (orderLine.measurements["notes"] || "");

  if (notes) {
    const boxStart = position[0] + columns[0].width * size[0];
    const boxWidth = size[0] - columns[0].width * size[0] - padding[0];
    const options = { width: boxWidth };
    const innerHeight = doc.heightOfString(notes, options) + 2 * padding[1];
    const outerHeight = innerHeight + padding[1];
    doc // draw note box
      .rect(boxStart, position[1] + size[1], boxWidth, innerHeight)
      .fill("#eee")
      .fillOpacity(1.0)
      .fill()
      .fillColor("black")
      .text(
        notes,
        boxStart + padding[0],
        position[1] + size[1] + padding[1],
        options
      );
    size[1] += outerHeight;
  }

  doc // draw borders
    .rect(position[0], position[1], size[0], size[1])
    .strokeColor("black")
    .opacity(1.0)
    .lineWidth(0.5)
    .stroke();
  return size[1];
}

function addFooter(doc, order, pageNumber) {
  const d = format(new Date(), "yyyyMMdd-HHmm");
  const text = `${d} | ON${order.id} | ${order.ordered_by} | ${
    order.client_code
  } | ${formatLocation(order.location)}`;
  doc // draw line
    .moveTo(Page.marginLeft, Page.footerHeight)
    .lineTo(Page.marginLeft + Page.contentWidth, Page.footerHeight)
    .lineWidth(0.5)
    .strokeColor("black")
    .opacity(1.0)
    .stroke();
  doc // draw text
    .fillColor("grey")
    .font("Helvetica")
    .fontSize(10)
    .text(`${pageNumber}`, Page.marginLeft, Page.footerHeight + 12, {
      align: "right",
      width: Page.contentWidth,
    })
    .text(text, Page.marginLeft, Page.footerHeight + 12, {
      align: "left",
      width: Page.contentWidth,
    });
}

async function generateOrderPDF(order, products, callback) {
  console.log("Generating PDF for order", order);
  const doc = new PDFDocument({ size: "A4", margin: 30 });

  // Metadata
  doc.info["Title"] = "Manometric Order Form";
  doc.info["Author"] = "Online Order Form";
  doc.info["Subject"] = `Order for client ${order.client_code}`;

  // Page 1: order header
  addFooter(doc, order, 1);
  doc
    .image("assets/logo.png", Page.marginLeft, Page.marginTop, {
      fit: [200, 80],
    })
    .font("data/Europa-Bold.ttf")
    .fontSize(20)
    .fillColor("black")
    .text("Manometric Order Form", Page.marginLeft, 130);

  let orderHeader = {
    rows: [
      ["Order number", `ON${order.id}`],
      ["Order date", format(new Date(order.ordered_at), "dd-MMM-yyyy")],
      ["Ordered by", order.ordered_by],
      ["Location", formatLocation(order.location)],
      ["Delivery", order.delivery_method],
      ["Client code", order.client_code],
    ],
  };
  if (order.external_client_reference) {
    orderHeader.rows.push(["External Number", order.external_client_reference]);
  }
  orderHeader.rows.forEach((row, index) => {
    addHeader(doc, [Page.marginLeft, 180 + 25 * index], row[0], row[1]);
  });

  // Page 2: order lines
  doc.addPage();
  addFooter(doc, order, 2);

  doc // draw label
    .fillColor("black")
    .font("Helvetica-Bold")
    .fontSize(12)
    .text("Products", Page.marginLeft + 8.0, 105);

  addProductHeader(doc, [Page.marginLeft, 125]);
  let offset = 150;
  order.order_lines.forEach((line) => {
    if (line.code) {
      offset += addProductRow(
        doc,
        [Page.marginLeft, offset],
        line,
        products.find((p) => p.code === line.code)
      );
    }
  });

  // Render PDF
  const stream = doc.pipe(blobStream());
  stream.on("finish", () => {
    const blob = stream.toBlob("application/pdf");
    const url = stream.toBlobURL("application/pdf");
    callback({ blob, url });
  });
  doc.end();
}

export default generateOrderPDF;
