import { Image, Text, View } from "@react-pdf/renderer";
import txt from "helpers/text-helper";
import { styles } from "../styles";
import { A4_P_S } from "../styles/params";

export interface MMPdfFooterProps {}

function MMPdfFooter(props: MMPdfFooterProps) {
  return (
    <View fixed={true} style={[styles.footer, styles.small, styles.soft]}>
      <View
        style={{
          ...styles.columns,
          borderTopWidth: "0.25pt",
          borderTopColor: "#909090",

          alignItems: "flex-end",
        }}
      >
        <View style={styles.section_3}>
          {/* <Text style={styles.small}>{txt.get('brand.Manometric B.V.')}</Text> */}
          <Text>{txt.get("brand.address_line_1")}</Text>
          <Text>{txt.get("brand.address_line_2")}</Text>
        </View>
        <View style={styles.section_3}>
          <Text>{txt.get("brand.phone")}</Text>
          <Text>{txt.get("brand.email")}</Text>
        </View>
        <View style={styles.section_3}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "24pt" }}>
              <Text>{txt.get("pdfs.footer.agb_code")}:</Text>
            </View>
            <View>
              <Text>{txt.get("brand.agb_code")}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "24pt" }}>
              <Text>{txt.get("pdfs.footer.coc_number")}:</Text>
            </View>
            <View>
              <Text>{txt.get("brand.coc_number")}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section_3}>
          <View
            style={{
              flexGrow: 1,
              alignItems: "flex-end",
              justifyContent: "center",
              height: "26pt",
            }}
          >
            <Image
              style={styles.logoFooter}
              src="/pdf-assets/logo-manometric-grey.png"
            />
          </View>
        </View>
      </View>
      <View style={{ alignItems: "flex-end", paddingRight: A4_P_S }}>
        <Text
          style={styles.small}
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        />
      </View>
    </View>
  );
}

export default MMPdfFooter;
