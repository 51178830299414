import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { useEffect, useState } from "react";

export interface MMPdfPreviewProps {
  file: any;
}

function MMPdfPreview(props: MMPdfPreviewProps) {
  const [file, setFile] = useState<any>(props.file);

  useEffect(() => {
    console.log("MMPdfPreview", props.file);
    setFile(props.file);
  }, [props.file]);

  return file ? (
    <EuiFlexGroup>
      <EuiFlexItem>
        <iframe height="100%" title="PDF Preview" src={file}></iframe>
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : (
    <></>
  );
}

export default MMPdfPreview;
