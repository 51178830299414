import { Document, Font, PDFViewer, Text, View } from "@react-pdf/renderer";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { formattedAddressParts } from "store/data/adress/address";
import { Client } from "store/data/client/client";
import { OrderCostingType } from "store/data/costing/costing";
import {
  IndicationGrouped,
  indicationsGrouped,
  IndicationSide,
} from "store/data/indication/indication";
import { UzoviCode } from "store/data/insurer/insurer";
import { Order, OrderLine } from "store/data/order/order";
import { formalName } from "store/data/personal-details/personal-details";
import { handDescription } from "store/data/scan/scan";
import fontFuturaPTBold from "./assets/fonts/futura-pt-bold.ttf";
import fontFuturaPT from "./assets/fonts/futura-pt.ttf";
import MMPdfClientAddress from "./sections/pdf-client-address";
import MMPdfInsurerAddress from "./sections/pdf-insurer-address";
import MMPdfMarkdown from "./sections/pdf-markdown";
import MMPdfOrderLines from "./sections/pdf-order-lines";
import MMPdfPage from "./sections/pdf-page";
import MMPdfProductDetails from "./sections/pdf-product-details";
import { styles } from "./styles";
import { A4_P_S } from "./styles/params";

// 21 - aanschaf hulpmiddel in eigendom
// 23 - reparatie hulpmiddel in eigendom
// 24 - aanpassingen hulpmiddel in eigendom

export const pdfnltobr = (value: string) => {
  return value.replaceAll("<br>", "\n").replaceAll("<br/>", "\n");
};
export interface MMPdfQuotationProps {
  order: Order;
  selectedOrderLines?: number[];
  client: Client;
  uzoviCode?: UzoviCode;
  insuranceDetails?: any;
}

function MMPdfQuotation(props: MMPdfQuotationProps) {
  const [order, setOrder] = useState<Order>(props.order);
  const [selectedOrderLines, setSelectedOrderLines] = useState<number[]>(
    props.selectedOrderLines || []
  );
  const [client, setClient] = useState<Client>(props.client);
  const [uzoviCode, setUzoviCode] = useState<UzoviCode | undefined>(
    props.uzoviCode
  );

  const [insuranceDetails, setInsuranceDetails] = useState<any>(
    props.insuranceDetails
  );

  useEffect(() => {
    setOrder(props.order);
    setSelectedOrderLines(props.selectedOrderLines || []);
    setClient(props.client);
    setInsuranceDetails(props.insuranceDetails);
    setUzoviCode(props.uzoviCode);
  }, [props.order, props.client, props.insuranceDetails, props.uzoviCode]);

  const toQuotationNumber = (order: Order) =>
    order.costing?.quotation?.code
      ? `${order.costing?.quotation?.code}${toQuotationNumberSuffix(order.order_lines, selectedOrderLines)}`
      : "PRO FORMA";

  const toQuotationNumberSuffix = (
    orderLines: OrderLine[],
    selection?: number[]
  ) => {
    let result: string = "";

    let selectedOrderLines: OrderLine[] = toSelectedOrderLines(
      orderLines,
      selection
    );
    if (selectedOrderLines.length === orderLines.length) return result;

    result += "-";

    result += selectedOrderLines
      .map((orderLine: OrderLine) =>
        orderLine.hand ? handDescription(orderLine.hand, undefined, true) : ""
      )
      .join("");
    return result;
  };

  const toSelectedOrderLines = (
    orderLines: OrderLine[],
    selection?: number[]
  ) => {
    if (!selection || selection.length === 0) return orderLines;

    return orderLines.filter(
      (orderLine: OrderLine) =>
        selection.findIndex((id: number) => id === orderLine.id) >= 0
    );
  };

  Font.register({
    family: "futura-pt",

    fonts: [{ src: fontFuturaPT }, { src: fontFuturaPTBold, fontWeight: 600 }],
  });

  const productDetails = (orderLines: OrderLine[]) => (
    <View style={{ paddingTop: "10pt" }}>
      <Text style={styles.subtitle}>{txt.get("orders.order.products")}</Text>
      {orderLines.map((orderLine: OrderLine, i: number) => (
        <View key={`order-line-${i}`}>
          <MMPdfProductDetails orderLine={orderLine} />
        </View>
      ))}
    </View>
  );

  return (
    <PDFViewer height={"100%"}>
      <Document
        title={`${txt.get("pdfs.quotation.name")} - ${toQuotationNumber(order)}`}
      >
        <MMPdfPage title={txt.up("pdfs.quotation.name")}>
          <View>
            <View style={styles.addressPaneHolder}>
              {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                <MMPdfClientAddress client={client} />
              ) : uzoviCode ? (
                <MMPdfInsurerAddress uzoviCode={uzoviCode} />
              ) : (
                "-"
              )}
            </View>
            <View style={{ ...styles.columns, padding: `${A4_P_S}` }}>
              <View style={{ ...styles.section_2, padding: "0pt" }}>
                <Text>{txt.get("pdfs.quotation.name")}:</Text>
                <Text>{txt.get("pdfs.quotation.date")}:</Text>
                {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                  <></>
                ) : (
                  <Text>{txt.get("pdfs.quotation.our_reference")}:</Text>
                )}
              </View>
              <View style={{ ...styles.section_4, padding: "0pt" }}>
                <Text>{toQuotationNumber(order)}</Text>
                <Text>
                  {DateHelper.toDateFullYear(
                    order.referral?.intake_at || undefined
                  )}
                </Text>
                {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                  <></>
                ) : (
                  <Text>
                    {txt.get("pdfs.quotation.agb_code")}{" "}
                    {txt.get("brand.agb_code")}{" "}
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.columns}>
              <View style={styles.section_6}>
                <View style={styles.columns}>
                  <View
                    style={{
                      ...styles.section_2,
                      paddingLeft: "0pt",
                      paddingTop: "0pt",
                    }}
                  >
                    <Text>{txt.get("pdfs.quotation.regarding")}:</Text>
                  </View>
                  {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                    <View
                      style={{
                        ...styles.section_4,
                        paddingLeft: "0pt",
                        paddingTop: "0pt",
                      }}
                    >
                      <Text>
                        {order.costing?.notes ||
                          txt.get("pdfs.quotation.private")}
                      </Text>
                    </View>
                  ) : (
                    <View
                      style={{
                        ...styles.section_4,
                        paddingLeft: "0pt",
                        paddingTop: "0pt",
                      }}
                    >
                      <Text>{formalName(client)}</Text>
                      <Text>
                        {formattedAddressParts(client, [
                          "street_name",
                          "street_number",
                          "street_number_suffix",
                        ])}
                      </Text>
                      <Text>
                        {formattedAddressParts(client, ["postal_area", "city"])}
                      </Text>
                    </View>
                  )}
                </View>
              </View>

              <View style={styles.section_6}>
                <View style={styles.columns}>
                  <View
                    style={{
                      ...styles.section_3,
                      paddingLeft: "0pt",
                      paddingTop: "0pt",
                    }}
                  >
                    <Text>{txt.get("pdfs.quotation.client_code")}:</Text>
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>{txt.get("pdfs.quotation.birth_date")}:</Text>
                    )}

                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {txt.get("pdfs.quotation.citizen_service_number")}:
                      </Text>
                    )}
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {txt.get(
                          "pdfs.quotation.insurance_registration_number"
                        )}
                        :
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      ...styles.section_3,
                      paddingLeft: "0pt",
                      paddingTop: "0pt",
                    }}
                  >
                    <Text>{order.client_code}</Text>
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {client.birth_date
                          ? DateHelper.toDateFullYear(client.birth_date)
                          : "-"}
                      </Text>
                    )}
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>{client.bsn || "-"}</Text>
                    )}
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {insuranceDetails?.registration_number || "-"}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.spacer} />
            <MMPdfOrderLines
              order={order}
              orderLines={toSelectedOrderLines(
                order.order_lines,
                selectedOrderLines
              )}
            />
            <View style={styles.spacer} />
            <View break style={styles.columns}>
              <View style={styles.section_9}>
                <Text style={[styles.title]}>
                  {txt.get("pdfs.quotation.rationale")}
                </Text>
                <MMPdfMarkdown>{order.costing?.rationale || ""}</MMPdfMarkdown>
              </View>
              <View
                style={[
                  styles.section_3,
                  { borderLeft: "0.5pt", borderLeftColor: "#777777" },
                ]}
              >
                <Text style={[styles.subtitle, { paddingTop: "5pt" }]}>
                  {txt.get("pdfs.quotation.indication")}
                </Text>
                <Text>
                  {order.referral?.indications
                    ? indicationsGrouped(order.referral.indications).map(
                        (indication: IndicationGrouped, i: number) => (
                          <Text key={`indication-${i}`}>
                            {indication.indication}{" "}
                            {indication.sides
                              .map((side: IndicationSide) =>
                                handDescription(side)
                              )
                              .join(" & ")
                              .toLowerCase()}
                          </Text>
                        )
                      )
                    : "-"}
                </Text>
                <Text style={[styles.subtitle, { paddingTop: "10pt" }]}>
                  {txt.get("pdfs.quotation.referrer")}
                </Text>
                <Text>
                  {order.referral?.referrer?.person
                    ? formalName(order.referral.referrer.person)
                    : ""}
                </Text>
                {order.referral?.referrer?.agb_code ? (
                  <Text style={styles.soft}>
                    {txt.get("pdfs.quotation.agb_code")}{" "}
                    {order.referral?.referrer?.agb_code}
                  </Text>
                ) : (
                  <></>
                )}
                <Text>
                  {order.referral?.referrer?.organisation
                    ? order.referral.referrer.organisation.name
                    : order.referral?.referrer?.alternative_organisation
                      ? order.referral?.referrer?.alternative_organisation
                      : ""}
                </Text>
                {order.referral?.referrer?.organisation_agb_code ? (
                  <Text style={styles.soft}>
                    {txt.get("pdfs.quotation.agb_code")}{" "}
                    {order.referral?.referrer?.organisation_agb_code}
                  </Text>
                ) : (
                  <></>
                )}

                {productDetails(
                  toSelectedOrderLines(order.order_lines, selectedOrderLines)
                )}
              </View>
            </View>
          </View>
        </MMPdfPage>
      </Document>
    </PDFViewer>
  );
}

export default MMPdfQuotation;
