import { Location } from "../location/location";
import { PersonalDetails } from "../personal-details/personal-details";

export const SCANNER_EMPTY: Scanner = {
  id: null,
  name: "",
  serial: "",
  location: "",
  description: "",
};
export interface Scanner {
  id: number | null;
  name: string; //old?
  serial?: string;
  location: string;
  description: string;
  created_at?: Date;
  updated_at?: Date;
  commissioned_at?: Date;
}

export interface AssignedDevice {
  id: number | null;
  assigned_type: "location" | "personal_details";
  assigned_id: number;
  device_id: number;
  device_serial?: string;
  device_description?: string;
}

export interface AssignedDeviceDetails {
  device: AssignedDevice;
  location?: Location;
  user?: PersonalDetails;
}
