class MetaInfoHelper {
  static setTitle(title?: string) {
    if (!title) title = "";

    document.title = title;

    const titleElement = document.querySelector("title");
    if (titleElement == null) {
      //add a title element
    } else {
      titleElement.innerText = title;
    }
  }
}

export default MetaInfoHelper;
