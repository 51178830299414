import { EuiLink } from "@elastic/eui";
import { useNavigate } from "react-router-dom";

export interface MMLinkProps {
  href: string;
  external?: boolean;
  children: any;
}

/* link using navigate of react router dom, instead of reloading page, wrapping around euilink */
export function MMLink(props: MMLinkProps) {
  const navigate = useNavigate();

  return (
    <EuiLink
      external={props.external}
      onClick={(e: any) => {
        e.preventDefault();
        if (e && e.target && e.target.href) {
          navigate(e.target.href.replace(window.location.origin, ""));
        }
      }}
      href={props.href}
    >
      {props.children}
    </EuiLink>
  );
}
