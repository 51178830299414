import { EuiText } from "@elastic/eui";
import { useEffect, useState } from "react";

function MMGeocoderView({
  onLocation,
  address,
}: {
  address: string;
  onLocation: Function;
}) {
  const getLocationForAddress = (address: string) => {
    return new Promise((resolve, reject) => {
      const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results: any, status: any) => {
        if (status === "OK") {
          resolve({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          } as google.maps.LatLngLiteral);
        } else {
          console.log("error with geocoding", status);
          reject(null);
        }
      });
    });
  };

  const [currentPosition, setCurrentPosition] =
    useState<google.maps.LatLngLiteral | null>(null);

  useEffect(() => {
    const loadPosition = async () => {
      if (address) {
        try {
          const geoCodeResult: any = await getLocationForAddress(address);
          if (geoCodeResult) {
            setCurrentPosition(geoCodeResult);
          }
        } catch (e: any) {
          setCurrentPosition(null);
        }
      }
    };
    loadPosition();
  }, [address]);

  useEffect(() => {
    if (currentPosition) {
      console.log("geocoded address to position", address, currentPosition);
    }
    onLocation(currentPosition);
  }, [currentPosition]);

  return (
    <EuiText size="xs" style={{ color: "#909090", textAlign: "right" }}>
      {address}
    </EuiText>
  );
}

export default MMGeocoderView;
