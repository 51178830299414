import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { Workflow, WORKFLOW_EMPTY } from "store/data/production/workflow";
import { v4 as uuid } from "uuid";
import { adminNav } from "./admin";
import MMWorkflow from "./workflow";
function MMAdminWorkflow() {
  const api = new ConnectAPIHelper();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteRef] = useState("delete_workflow_" + uuid());
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const { workflowId } = useParams();

  const [workflow, setWorkflow] = useState<Workflow | null>(null);

  const isNew: boolean = workflowId === "new";
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/workflows"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const loadWorkflow = async () => {
      if (isNew) {
        setWorkflow(WORKFLOW_EMPTY);
      } else {
        const workflowIdParam: number = Number(workflowId);
        if (isNaN(workflowIdParam)) {
          navigate("/404");
        } else {
          const result = await api.getWorkflow(workflowIdParam);
          if (result.status === "OK" && result.result && result.result.id) {
            console.log("setting workflow", result.result);
            setWorkflow(result.result);
          } else {
            navigate("/404");
          }
        }
      }
    };
    loadWorkflow();
  }, [workflowId]);

  const saveWorkflow = async (workflow: Workflow) => {
    let result;
    if (workflow.id) {
      result = await api.updateWorkflow(workflow.id, workflow);
    } else {
      result = await api.createWorkflow(workflow);
    }
    if (result.status === "OK") {
      if (!workflow.id) {
        navigate(`/admin/workflows/${result.result.workflow_id}`);
      }

      dispatch(
        toastAdd(
          txt.get("generic.is_saved", txt.get("admin.workflows.name")),
          null,
          "success"
        )
      );
    } else {
      dispatch(
        toastAdd(
          result.message
            ? result.message
            : txt.get("admin.workflows.error_saving"),
          null,
          "danger"
        )
      );
    }
  };

  const handleDelete = async () => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("admin.workflows.name"))}.`,
        txt.get("admin.workflows.delete_confirm"),
        deleteRef,
        null,
        null,
        `${txt.get("generic.yes")}, ${txt.lo(
          "generic.delete_x",
          txt.get("admin.workflows.name")
        )}`
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === deleteRef
    ) {
      deleteWorkflow();
    }
  }, [alertConfirm]);

  const deleteWorkflow = async () => {
    if (workflowId && !isNaN(Number(workflowId))) {
      await api.deleteWorkflow(Number(workflowId));
      dispatch(
        toastAdd(
          txt.get("generic.is_deleted", txt.get("admin.workflows.name")),
          null,
          "success"
        )
      );
    }
    navigate("/admin/workflows");
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (
        !isNew &&
        (await AuthenticationHelper.hasPermission("workflows#delete_all"))
      ) {
        buttons.push(
          <EuiButton
            size="s"
            onClick={handleDelete}
            color="danger"
            iconType={"cross"}
          >
            {txt.uf("generic.delete_x", txt.get("admin.workflows.name"))}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.workflows.name")}`}
      subTitle={`#${workflowId || txt.get("generic.new")}`}
      subPages={subPages}
      backTo="/admin/workflows"
      backToText={txt.uf(
        "generic.back_to",
        txt.get("admin.workflows.page_title")
      )}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        {workflow ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <MMWorkflow handleSubmit={saveWorkflow} fields={{ workflow }} />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiProgress size="s" color="accent" />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminWorkflow;
