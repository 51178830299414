import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useIsWithinMaxBreakpoint,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMFiles from "components/layouts/files/files";
import MMFileUpload from "components/layouts/input/file-upload";
import { FileType } from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { AttachedToType, File } from "store/data/file/file";
import { MMOrderEntryInputProps } from "../order-entry";

function MMOrderAttachments(props: MMOrderEntryInputProps) {
  const dispatch = useDispatch();
  const api = new ConnectAPIHelper();
  const isMobileScreen: boolean = useIsWithinMaxBreakpoint("xs");

  const [files, setFiles] = useState<any[]>([]);
  const [fileToAdd, setFileToAdd] = useState<File>();

  useEffect(() => {
    const referrals =
      props.order && props.order.files
        ? props.order.files.filter(
            (file: any) => file.attachment.type !== "referral_letter_reference"
          )
        : [];
    setFiles(referrals);
  }, [props.order]);

  const handleAdded = (file: File) => {
    setFileToAdd((old) => file);
  };

  useEffect(() => {
    if (fileToAdd && props.onChange && props.order && props.order.files) {
      let files: File[] =
        props.order && props.order.files
          ? props.order.files.concat([fileToAdd])
          : [fileToAdd];
      props.onChange({ ...props.order, files });
      setFileToAdd(undefined);
    }
  }, [fileToAdd]);

  const handleDelete = (fileId: number) => {
    const deleteFile = async (fileId: number) => {
      const result = await api.removeOrderFile(fileId, props.order?.id);
      if (result && props.onChange && props.order && props.order.files) {
        const files = props.order.files.filter(
          (file: any) => file.id !== fileId
        );
        props.onChange({ ...props.order, files });
      } else {
        dispatch(
          toastAdd(txt.get("orders.order.delete_file_failed"), null, "danger")
        );
      }
    };
    deleteFile(fileId);
  };

  return (
    <EuiFlexGrid gutterSize="none">
      <EuiFlexGroup gutterSize="xs" direction="column">
        <EuiTitle size="xs">
          <EuiText color="grey">
            {txt.get("orders.order.attachments.name")}
          </EuiText>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiText size="s">
          {txt.get("orders.order.attachments.explanation")}
        </EuiText>
        <EuiSpacer size="s" />
        <EuiFormRow
          label={""}
          style={
            !isMobileScreen && props.maxHeight
              ? { overflowX: "auto", height: props.maxHeight }
              : {}
          }
        >
          <EuiFlexGroup direction="column" style={{ width: "220px" }}>
            {files.length > 0 ? (
              <EuiFlexItem>
                <MMFiles files={files} onDelete={handleDelete} />
              </EuiFlexItem>
            ) : (
              <></>
            )}
            <EuiFlexItem>
              <MMFileUpload
                multiple={true}
                fileTypes={[
                  FileType.Docx,
                  FileType.Image,
                  FileType.Pdf,
                  FileType.Text,
                ]}
                attachmentOptions={{
                  attachedToId: props.order?.id,
                  attachedToType: AttachedToType.Order,
                }}
                onAdded={handleAdded}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiFlexGroup>
    </EuiFlexGrid>
  );
}

export default MMOrderAttachments;
