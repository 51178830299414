import {
  EuiButton,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { onStringValue, toSelectOptions, updateField } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import {
  Address,
  address,
  Addressee,
  addressee,
  ADDRESSEE_EMPTY,
} from "store/data/adress/address";
import {
  contact,
  ContactMethod,
  ContactMethodType,
} from "store/data/contactmethod/contactmethod";
import { Insurer, UzoviCode } from "store/data/insurer/insurer";

const ACTIVE_COUNTRY_CODES: string[] = [
  "NL",
  // "AT",
  // "DE",
  // "IT",
  // "BE",
  // "ES",
  // "CH",
  // "UK",
  // "US",
];
export const ADDRESS_NAME_FINANCIAL: string = "financial";
export interface MMAgencyProps {
  fields: {
    agency: UzoviCode | null;
  };
  handleSubmit?: Function;
}

function MMAgency(props: MMAgencyProps) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();

  const [editAllowed, setEditAllowed] = useState(false);

  const [agency, setAgency] = useState(props.fields.agency);

  const [insurers, setInsurers] = useState<Insurer[]>([]);
  const [selectedInsurers, setSelectedInsurers] = useState<any[]>([]);

  const [countries] = useState(
    ACTIVE_COUNTRY_CODES.map((code) => ({ name: code, id: code }))
  );
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);

  useEffect(() => {
    const loadInsurers = async () => {
      const insurerResult: ApiResponse = await api.getAdminInsurers(
        100,
        0,
        "name",
        "asc"
      );
      if (insurerResult && insurerResult.status === ApiResponseStatus.OK) {
        setInsurers(insurerResult.result);
      } else {
        setInsurers([]);
        console.error(
          "something went wrong loading insurers",
          insurerResult.message
        );
      }
    };

    loadInsurers();

    const setPermissions = async () => {
      setEditAllowed(
        await AuthenticationHelper.hasPermission("insurers#edit_all")
      );
    };
    setPermissions();
  }, []);

  useEffect(() => {
    if (insurers.length > 0 && agency) {
      setSelectedInsurers(
        toSelectOptions(
          insurers.filter((insurer: any) => insurer.id === agency.insurer_id)
        )
      );
      if (agency.addressees && agency.addressees.length > 0) {
        let selectedCountry = agency.addressees[0].address.country;

        setSelectedCountries(
          toSelectOptions(
            countries.filter((country: any) => selectedCountry === country.id)
          )
        );
      }
    }
  }, [insurers, agency]);

  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      props.handleSubmit(agency);
    }
  };

  const onInsurerChange = (selectedOptions: any[]) => {
    setAgency(
      updateField(
        agency,
        "insurer_id",
        selectedOptions.length > 0 ? selectedOptions[0]["data-id"] : null
      )
    );
  };

  const onAddress = (address: Address, name: string) => {
    let addressees: Addressee[] = agency?.addressees || [];

    let result: Addressee | null = addressee(agency, name) || {
      ...ADDRESSEE_EMPTY,
      addressable_type: "uzovi_code",
      addressable_id: agency?.id || null,
      name,
    };
    result.address = address;

    let found: boolean = false;
    for (let i = 0; i < addressees.length; i++) {
      const addressee: Addressee = addressees[i];
      if (addressee.name === name) {
        addressees[i] = result;
        found = true;
      }
    }
    if (!found) {
      addressees.push(result);
    }
    console.log("onAddress", address, name, addressees);
    setAgency((old: any) => updateField(agency, "addressees", addressees));
  };

  const onContactMethod = (contactMethod: ContactMethod) => {
    let updatedContactMethods: ContactMethod[] = agency?.contact_methods || [];
    let found: boolean = false;
    for (let i = 0; i < updatedContactMethods.length; i++) {
      const current: ContactMethod = updatedContactMethods[i];
      if (
        current.name === contactMethod.name &&
        current.type === contactMethod.type
      ) {
        updatedContactMethods[i] = { ...contactMethod };
        found = true;
      }
    }
    if (!found) {
      updatedContactMethods.push(contactMethod);
    }

    setAgency(updateField(agency, "contact_methods", updatedContactMethods));
  };

  const onCountryChange = (selectedOptions: any[], name: string) => {
    onAddress(
      updateField(
        address(agency, ADDRESS_NAME_FINANCIAL),
        "country",
        selectedOptions.length > 0 ? selectedOptions[0]["data-id"] : null
      ),
      name
    );
  };
  return (
    <EuiForm>
      <EuiFormRow label={txt.get("admin.insurers.name")}>
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select insurer"
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("admin.insurers.name")
          )}
          singleSelection={{ asPlainText: true }}
          options={toSelectOptions(insurers)}
          selectedOptions={selectedInsurers}
          onChange={onInsurerChange}
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("admin.insurers.agencies.name")}>
        <EuiFieldText
          disabled={!editAllowed}
          name="agency_name"
          value={agency?.name || ""}
          onChange={(e) =>
            setAgency(updateField(agency, "name", onStringValue(e)))
          }
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("admin.insurers.uzovi_code")}>
        <EuiFieldText
          disabled={!editAllowed}
          name="agency_uzovi_code"
          value={agency?.code || ""}
          onChange={(e) =>
            setAgency(updateField(agency, "code", onStringValue(e)))
          }
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFlexGroup justifyContent="flexStart">
        <EuiFlexItem grow={1} style={{ maxWidth: "400px" }}>
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={3}>
              <EuiFormRow label={txt.get("address.street_name")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_street_name"
                  value={
                    address(agency, ADDRESS_NAME_FINANCIAL)?.street_name || ""
                  }
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(agency, ADDRESS_NAME_FINANCIAL),
                        "street_name",
                        onStringValue(e)
                      ),
                      ADDRESS_NAME_FINANCIAL
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label={txt.get("address.street_number_short")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_street_number"
                  value={
                    address(agency, ADDRESS_NAME_FINANCIAL)?.street_number || ""
                  }
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(agency, ADDRESS_NAME_FINANCIAL),
                        "street_number",
                        onStringValue(e)
                      ),
                      ADDRESS_NAME_FINANCIAL
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label={txt.get("address.street_number_suffix_short")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_street_number_suffix"
                  value={
                    address(agency, ADDRESS_NAME_FINANCIAL)
                      ?.street_number_suffix || ""
                  }
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(agency, ADDRESS_NAME_FINANCIAL),
                        "street_number_suffix",
                        onStringValue(e)
                      ),
                      ADDRESS_NAME_FINANCIAL
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={3}>
              <EuiFormRow label={txt.get("address.postal_area")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_postal_area"
                  value={
                    address(agency, ADDRESS_NAME_FINANCIAL)?.postal_area || ""
                  }
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(agency, ADDRESS_NAME_FINANCIAL),
                        "postal_area",
                        onStringValue(e)
                      ),
                      ADDRESS_NAME_FINANCIAL
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={6}>
              <EuiFormRow label={txt.get("address.city")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_city"
                  value={address(agency, ADDRESS_NAME_FINANCIAL)?.city || ""}
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(agency, ADDRESS_NAME_FINANCIAL),
                        "city",
                        onStringValue(e)
                      ),
                      ADDRESS_NAME_FINANCIAL
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label={"Country"}>
                <EuiComboBox
                  inputRef={(input) => {
                    if (input) input.name = "country_code";
                  }}
                  style={{ width: "100px" }}
                  isDisabled={!editAllowed}
                  aria-label="Country code"
                  placeholder={"..."}
                  singleSelection={{ asPlainText: true }}
                  options={toSelectOptions(countries)}
                  selectedOptions={selectedCountries}
                  onChange={(e: any) =>
                    onCountryChange(e, ADDRESS_NAME_FINANCIAL)
                  }
                  aria-autocomplete="none"
                  isClearable={false}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("contact.type.phone")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="agency_phone"
              value={
                contact(agency, "phone", ContactMethodType.Phone)?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(agency, "phone", ContactMethodType.Phone),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="agency_phone"
              value={
                contact(agency, "phone", ContactMethodType.Phone)?.salutation ||
                ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(agency, "phone", ContactMethodType.Phone),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>{" "}
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("contact.type.fax")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="agency_fax"
              value={
                contact(agency, "fax", ContactMethodType.Phone)?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(agency, "fax", ContactMethodType.Phone),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="agency_phone"
              value={
                contact(agency, "fax", ContactMethodType.Phone)?.salutation ||
                ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(agency, "fax", ContactMethodType.Phone),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>{" "}
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("contact.type.email")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="agency_email"
              value={
                contact(agency, "email", ContactMethodType.Phone)?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(agency, "email", ContactMethodType.Phone),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="agency_email"
              value={
                contact(agency, "email", ContactMethodType.Email)?.salutation ||
                ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(agency, "email", ContactMethodType.Email),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMAgency;
