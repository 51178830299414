import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiProgress,
  EuiSelectable,
  EuiSelectableOption,
  EuiSwitch,
} from "@elastic/eui";
import AuthAdminAPIHelper from "api/auth-admin-api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { toLanguageOptions } from "components/my-account/my-account";
import StringHelper from "helpers/string-helper";
import txt, { DEFAULT_USER_LANGUAGE } from "helpers/text-helper";
import { useStateWithCallback } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { AuthGroup } from "store/data/auth/group";
import { Organisation } from "store/data/organisation/organisation";
import { useLocalStorage } from "store/local-storage";

export interface MMOrganisationGroupProps {
  fields: {
    organisation: Organisation | null;
  };
  handleSubmit?: Function;
}

function MMOrganisationGroup(props: MMOrganisationGroupProps) {
  const dispatch = useDispatch();
  const api = new AuthAdminAPIHelper();
  const connectApi = new ConnectAPIHelper();
  const [groups, setGroups] = useState<AuthGroup[]>([]);
  const [group, setGroup] = useState<AuthGroup>();
  const [orderSettings, setOrderSettings] = useState<any>(null);
  const [isDemo, setIsDemo] = useState(false);

  const [userLanguage] = useLocalStorage(
    "user_language",
    DEFAULT_USER_LANGUAGE
  );
  const [languageOptions, setLanguageOptions] = useState<EuiSelectableOption[]>(
    []
  );
  const [organisation] = useStateWithCallback(props.fields.organisation);
  const [deliveryMethods, setDeliveryMethods] = useState<any>([]);
  const [orderTypes, setOrderTypes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadGroups = async () => {
    const result = await api.getGroups();
    console.log("loadGroups", result);
    setGroups(result);
    return result;
  };

  const createGroup = async () => {
    setIsLoading(true);
    if (organisation) {
      const slugifiedName: string = StringHelper.slugify(organisation.name);
      const newGroup: any = {
        name: slugifiedName,
        path: slugifiedName,
        attributes: {
          organisation_id: ["" + organisation.id],
        },
      };
      const result = await api.createOrganisationGroup(newGroup);
      const groups = await loadGroups();
      const group = findOrganisationGroup(groups, organisation);
      const permissionResult = await api.createPermissionGroupPolicies(
        group.name,
        group.id
      );
      console.log("createGroup", result, group, permissionResult);
    } else {
      console.error("no organisation to create group for");
    }
  };

  const saveGroup = async () => {
    setIsLoading(true);
    if (group) {
      let updatedGroup: AuthGroup = { ...group };
      //update isDemo
      if (!updatedGroup.attributes) {
        updatedGroup.attributes = {};
      }
      if (isDemo) {
        updatedGroup.attributes.is_demo = ["1"];
      } else if (updatedGroup.attributes.is_demo) {
        delete updatedGroup.attributes.is_demo;
      }
      //update language_codes
      let selectedLanguages: any[] = toAuthGroupSelectedPropertyList(
        languageOptions,
        "data-test-subj"
      );

      if (selectedLanguages.length > 0) {
        updatedGroup.attributes.language_code = selectedLanguages;
      } else if (updatedGroup.attributes.language_code) {
        delete updatedGroup.attributes.language_code;
      }

      //update delivery methods
      const selectedDeliveryMethods: any[] =
        toAuthGroupSelectedPropertyList(deliveryMethods);

      if (selectedDeliveryMethods.length > 0) {
        updatedGroup.attributes.delivery_methods = selectedDeliveryMethods;
      } else if (updatedGroup.attributes.delivery_methods) {
        delete updatedGroup.attributes.delivery_methods;
      }

      //update order type
      const selectedOrderTypes: any[] =
        toAuthGroupSelectedPropertyList(orderTypes);
      if (selectedOrderTypes.length > 0) {
        updatedGroup.attributes.order_types = selectedOrderTypes;
      } else if (updatedGroup.attributes.order_types) {
        delete updatedGroup.attributes.order_types;
      }

      const result: any = await api.saveOrganisationGroup(updatedGroup);
      console.log("saveGroup", result);
      dispatch(
        toastAdd(
          txt.get(
            "generic.are_saved",
            txt.get("admin.organisations.group_settings")
          ),
          null,
          "success"
        )
      );
      await loadGroups();
    } else {
      console.error("no group found to save");
    }
  };

  const toAuthGroupSelectedPropertyList = (
    list: any[],
    key: string = "label"
  ) => {
    console.log(list);
    return list
      .filter((option: any) => option.checked === "on")
      .map((option) => option[key]);
  };
  useEffect(() => {
    setIsLoading(true);
    const loadOrderSettings = async () => {
      const result = await connectApi.getOrderValues();
      setOrderSettings(result);
    };
    const loadLanguages = async () => {
      const result = await connectApi.getLanguages(userLanguage);

      setLanguageOptions(toLanguageOptions(result, ""));
    };

    loadLanguages();
    loadGroups();
    loadOrderSettings();
  }, []);

  useEffect(() => {
    if (groups && organisation && orderSettings) {
      const group = findOrganisationGroup(groups, organisation);
      setGroup(group);
    } else {
      setGroup(undefined);
    }
    setIsLoading(false);
  }, [organisation, groups, orderSettings]);

  useEffect(() => {
    if (group && group.attributes && group.attributes.language_code) {
      const updatedLanguageOptions: any = languageOptions.map((option) => ({
        ...option,
        checked: group.attributes.language_code.includes(
          option["data-test-subj"]
        )
          ? "on"
          : undefined,
      }));
      setLanguageOptions(updatedLanguageOptions);
    }

    if (group) {
      let methods: any = toOrderSettingOptions(
        orderSettings.methods,
        group && group.attributes && group.attributes.delivery_methods
          ? group.attributes.delivery_methods
          : []
      );
      console.log(methods);
      setDeliveryMethods(methods);

      let types: any = toOrderSettingOptions(
        orderSettings.types,
        group && group.attributes && group.attributes.order_types
          ? group.attributes.order_types
          : []
      );
      setOrderTypes(types);
    }

    if (group && group.attributes && group.attributes.is_demo) {
      setIsDemo(group.attributes.is_demo.includes("1"));
    }
  }, [group]);

  const findOrganisationGroup: any = (
    groups: AuthGroup[],
    organisation: Organisation
  ) => {
    const index: number = groups.findIndex(
      (group) =>
        group.attributes &&
        group.attributes.organisation_id &&
        group.attributes.organisation_id.includes("" + organisation.id)
    );
    if (index >= 0) {
      return groups[index];
    }
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      let result: AuthGroup | null =
        group.subGroups && group.subGroups.length > 0
          ? findOrganisationGroup(group.subGroups, organisation)
          : null;
      if (result) {
        return result;
      }
    }
    return null;
  };

  const organisationGroupDescription = (group?: AuthGroup) =>
    group ? group.name : null;

  // const toTreeItems: any = (groups: AuthGroup[]) =>
  //   groups.map((group: AuthGroup) => ({
  //     label: group.name,
  //     id: group.id,
  //     className:
  //       organisation &&
  //       group.attributes &&
  //       group.attributes.organisation_id &&
  //       group.attributes.organisation_id.includes("" + organisation.id)
  //         ? "active"
  //         : null,
  //     icon:
  //       organisation &&
  //       group.attributes &&
  //       group.attributes.organisation_id &&
  //       group.attributes.organisation_id.includes("" + organisation.id) ? (
  //         <EuiIcon type="check" />
  //       ) : (
  //         <EuiIcon type="empty" />
  //       ),
  //     children:
  //       group.subGroups && group.subGroups.length > 0
  //         ? toTreeItems(group.subGroups)
  //         : null,
  //   }));

  // const orderSettingsIdByName = (orderSettingsList: any[], name: string) => {
  //   const match: any = orderSettingsList.find(
  //     (setting) => setting.name === name
  //   );
  //   return match && match.code ? match.code : "";
  // };

  const toOrderSettingOptions = (
    orderSettingsList: any[],
    selectedNames: string[] = []
  ) => {
    return orderSettingsList.map((setting) => ({
      id: setting.code,
      label: setting.name,
      checked: selectedNames.includes(setting.name) ? "on" : undefined,
    }));
  };

  const onGroupCreate = (e: any) => {
    createGroup();
  };

  const onDemoChange = (e: any) => {
    setIsDemo(e.target.checked);
  };

  return organisation && organisation.id !== null ? (
    <EuiPanel>
      <EuiForm>
        {isLoading ? <EuiProgress size="s" color="accent" /> : <></>}
        <EuiFormRow
          helpText={group ? null : txt.get("admin.organisations.group_help")}
          label={txt.get("admin.organisations.group")}
        >
          {group ? (
            <EuiFieldText
              name="group"
              value={organisationGroupDescription(group) || ""}
              onChange={() => {}}
              disabled={true}
            ></EuiFieldText>
          ) : (
            <EuiButtonEmpty
              onClick={onGroupCreate}
              iconType="lock"
              iconSide="right"
              size="s"
            >
              {txt.get("admin.organisations.link_group")}
            </EuiButtonEmpty>
          )}
        </EuiFormRow>
        {group && orderSettings ? (
          <Fragment>
            <EuiFormRow
              label={
                <span id="organisation-is-demo">
                  {txt.get("admin.organisations.demo")}
                </span>
              }
            >
              <EuiSwitch
                onChange={onDemoChange}
                label={isDemo ? txt.get("generic.on") : txt.get("generic.off")}
                checked={isDemo}
                aria-describedby="organisation-is-demo"
              />
            </EuiFormRow>
            <EuiFormRow label={txt.get("admin.organisations.language")}>
              <EuiSelectable
                options={languageOptions}
                aria-label="Select language"
                listProps={{ bordered: true }}
                singleSelection="always"
                onChange={(newLanguageOptions) =>
                  setLanguageOptions(newLanguageOptions)
                }
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
            <EuiFormRow label={txt.get("admin.organisations.delivery_methods")}>
              <EuiSelectable
                options={deliveryMethods}
                aria-label="Select delivery method"
                listProps={{ bordered: true }}
                onChange={(newOptions) => {
                  setDeliveryMethods(newOptions);
                }}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
            <EuiFormRow label={txt.get("admin.organisations.order_types")}>
              <EuiSelectable
                options={orderTypes}
                aria-label="Select order type"
                listProps={{ bordered: true }}
                onChange={(newOptions) => setOrderTypes(newOptions)}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
            <EuiButton
              iconSide="right"
              isLoading={isLoading}
              onClick={() => {
                saveGroup();
              }}
            >
              {txt.get(
                "generic.save_x",
                txt.get("admin.organisations.group_settings")
              )}
            </EuiButton>
          </Fragment>
        ) : (
          <></>
        )}
      </EuiForm>
    </EuiPanel>
  ) : (
    <></>
  );
}
export default MMOrganisationGroup;
