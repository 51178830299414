import { EuiBadge } from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";

function MMDemoBanner(props: any) {
  return AuthenticationHelper.isDemo() ? (
    <EuiBadge className="demo-banner" color="accent">
      {txt.up("generic.demo")}
    </EuiBadge>
  ) : (
    <></>
  );
}

export default MMDemoBanner;
