import { EuiText, EuiTitle } from "@elastic/eui";

export interface MMTitleProps {
  text: string;
  prepend?: any;
  append?: any;
}

function MMTitle(props: MMTitleProps) {
  return (
    <EuiTitle className="mm-title-text-holder subdued" size="xs">
      <EuiText color="grey" style={{ whiteSpace: "nowrap" }}>
        {props.prepend ? props.prepend : <></>}
        {<span className="mm-title-text">{props.text}</span>}
        {props.append ? props.append : <></>}
      </EuiText>
    </EuiTitle>
  );
}

export default MMTitle;
