import { Text, View } from "@react-pdf/renderer";

export interface MMPdfMarkdownProps {
  children: string;
}
function MMPdfMarkdown(props: MMPdfMarkdownProps) {
  let lines: string[] = props.children.split("\n");

  return (
    <View>
      {lines.map((line: string, i: number) => {
        let style: any = {};
        if (line.search(/\*\*/) >= 0) {
          style.fontWeight = "bold";
        }
        if (line.search(/####/) >= 0) {
          style.fontWeight = "bold";
        }
        if (line.search(/_/) >= 0) {
          style.fontWeight = "bold";
          style.color = "#555555";
        }

        return (
          <View key={`line-${i}`}>
            <Text style={style}>
              {line.trim() === ""
                ? "\n"
                : line
                    .replace(/\*\*/g, "")
                    .replace(/####/g, "")
                    .replace(/_/g, "")}
            </Text>
          </View>
        );
      })}{" "}
    </View>
  );
}

export default MMPdfMarkdown;
