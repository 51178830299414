import txt from "helpers/text-helper";
import { Person } from "../adress/address";
import { Client } from "../client/client";
import { Organisation } from "../organisation/organisation";
import { AssignedDevice } from "../scan/scanner";

export const PERSONAL_DETAILS_EMPTY: PersonalDetails = {
  id: null,
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  organisation_id: null,
  is_practitioner: true,
  assigned_devices: [],
};

export interface PersonalDetailsLegacyInfo {
  vlot_practitioner_id?: string;
}

export interface PersonalDetails {
  id: number | null;
  first_name: string;
  last_name: string;
  organisation_id: number | null;
  username: string;
  email: string;
  organisation?: Organisation;
  auth_id?: string;
  is_practitioner: boolean;
  legacy_info?: PersonalDetailsLegacyInfo;
  assigned_devices: AssignedDevice[];
}

export const formalName = (details: PersonalDetails | Person | Client) => {
  let result = "";

  if (
    (details as Person).initials &&
    (details as any).infix &&
    details.last_name
  ) {
    result = `${(details as Person).initials} ${(details as any).infix} ${
      details.last_name
    }`;
  } else if ((details as Person).initials && details.last_name) {
    result = `${(details as Person).initials} ${details.last_name}`;
  } else if (
    (details as any).first_name &&
    (details as any).infix &&
    details.last_name
  ) {
    result = `${(details as any).first_name} ${(details as any).infix} ${
      details.last_name
    }`;
  } else if ((details as any).infix && details.last_name) {
    result = `${(details as any).infix} ${details.last_name}`;
  } else if (details.last_name) {
    result = `${details.last_name}`;
  } else if (details.first_name) {
    result = `${details.first_name}`;
  }

  return result;
};

export const genderedPronoun = (
  details: PersonalDetails | Person | Client,
  translationBase: string = "clients.sex"
) => {
  let result = "";
  if (
    (details as Client).sex &&
    (details as Client).sex.toString().toLowerCase().substring(0, 1) === "m"
  ) {
    result = txt.lf(`${translationBase}.addressing_him`);
  } else if (
    (details as Client).sex &&
    (details as Client).sex.toString().toLowerCase().substring(0, 1) === "f"
  ) {
    result = txt.lf(`${translationBase}.addressing_her`);
  }

  return result;
};

export const genderedName = (
  details: PersonalDetails | Person | Client,
  translationBase: string = "clients.sex"
) => {
  let result = fullName(details);
  if (
    (details as Client).sex &&
    (details as Client).sex.toString().toLowerCase().substring(0, 1) === "m"
  ) {
    result = `${txt.lf(`${translationBase}.addressing_male`)} ${result}`;
  } else if (
    (details as Client).sex &&
    (details as Client).sex.toString().toLowerCase().substring(0, 1) === "f"
  ) {
    result = `${txt.lf(`${translationBase}.addressing_female`)} ${result}`;
  }

  return result;
};

export const genderedLastName = (
  details: PersonalDetails | Person | Client,
  translationBase: string = "clients.sex"
) => {
  let result = lastName(details);
  if (
    (details as Client).sex &&
    (details as Client).sex.toString().toLowerCase().substring(0, 1) === "m"
  ) {
    result = `${txt.lf(`${translationBase}.addressing_male`)} ${result}`;
  } else if (
    (details as Client).sex &&
    (details as Client).sex.toString().toLowerCase().substring(0, 1) === "f"
  ) {
    result = `${txt.lf(`${translationBase}.addressing_female`)} ${result}`;
  }

  return result;
};

export const fullName = (details: PersonalDetails | Person | Client) => {
  let result = "-";
  if (!details) {
    return result;
  }
  if (details.first_name && (details as any).infix && details.last_name) {
    result = `${details.first_name} ${(details as any).infix} ${
      details.last_name
    }`;
  } else if ((details as any).infix && details.last_name) {
    result = `${(details as any).infix} ${details.last_name}`;
  } else if (details.first_name && details.last_name) {
    result = `${details.first_name} ${details.last_name}`;
  } else if (details.first_name) {
    result = details.first_name;
  } else if (details.last_name) {
    result = details.last_name;
  }
  return result;
};

export const lastName = (details: PersonalDetails | Person | Client) => {
  let result = "-";
  if (!details) {
    return result;
  }
  if ((details as any).infix && details.last_name) {
    result = `${(details as any).infix} ${details.last_name}`;
  } else if (details.last_name) {
    result = `${details.last_name}`;
  } else if (details.first_name) {
    result = details.first_name;
  }
  return result;
};
