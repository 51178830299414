export interface LegacyProduction {
  ref: string;
  orderId: number;
  orderLineId: number;
  code: string;
  product: string;
  client: string;
  orderDate: Date;
  orderType: string;
  technician: string;
  company: string;
  location: string;
  source: string;
  workflowId: number;
}

export const renderCurrentProductionStep = (production: any, checks: any[]) => {
  let current: string = "";
  const logs: any[] = production.production_check_logs;
  for (let i = 0; i < checks.length; i++) {
    const check: any = checks[i];
    const checkedBoxIndex: number = logs.findLastIndex((log: any) => {
      const cols: string[] = [check.column];
      if (check.column === "DC: Placed in box") {
        cols.push("Placed in box"); //add old legacy checkbox name
      }
      if (check.column === "DC: Shipped") {
        cols.push("Shipped"); //add old legacy checkbox name
      }
      return cols.includes(log.check) && log.value;
    });
    const unCheckedBoxIndex: number = logs.findLastIndex((log: any) => {
      const cols: string[] = [check.column];
      if (check.column === "DC: Placed in box") {
        cols.push("Placed in box"); //add old legacy checkbox name
      }
      if (check.column === "DC: Shipped") {
        cols.push("Shipped"); //add old legacy checkbox name
      }
      return cols.includes(log.check) && !log.value;
    });
    if (checkedBoxIndex >= 0 && checkedBoxIndex > unCheckedBoxIndex) {
      if (check.currentStep) current = check.currentStep;
    }
  }
  return current;
};
