export const CLIENT_SORTABLE_FIELDS: string[] = [
  "id",
  "updated_at",
  "created_at",
];

export const APPOINTMENT_SORTABLE_FIELDS: string[] = [
  "id",
  "start",
  "practitioner_id",
  "location_id",
];
