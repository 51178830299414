import { EuiCheckbox } from "@elastic/eui";
import { useEffect, useState } from "react";

export interface MMPolicyProps {
  clientId: string;
  policiable: any;
  policy?: any;
  onPolicyUpdate: Function;
}

function MMPolicy({
  clientId,
  policiable,
  policy,
  onPolicyUpdate,
}: MMPolicyProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsChecked(!!policy);
    setIsLoading(false);
  }, [policy]);

  const onPolicyCheck = (e: any) => {
    // setIsLoading(true);
    onPolicyUpdate(clientId, policiable, e.target.checked, policy);
    setIsChecked(e.target.checked);
  };

  return (
    <EuiCheckbox
      disabled={isLoading}
      checked={isChecked}
      data-policy-id={policy ? policy.id : ""}
      id={policiable.id}
      onChange={onPolicyCheck}
    ></EuiCheckbox>
  );
}

export default MMPolicy;
