export interface File {
  id: number;
  name: string;
  mime_type: string;
  owner: number;
  url: string;
  thumbnail_url: string;
  attachment?: Attachment;
}

export enum AttachedToType {
  Order = "order",
  Referral = "referral",
  OrderLine = "order_line",
  Fitting = "fitting",
  OrderCosting = "order_costing",
}

export enum AttachmentType {
  Referral = "referral_letter_reference",
}

export interface Attachment {
  id?: number;
  attached_to_id?: number;
  attached_to_type?: AttachmentType;
  file_id?: number;
  type?: string;
}
