import DateHelper from "helpers/date-helper";
import StringHelper from "helpers/string-helper";
import React, { useEffect, useRef, useState } from "react";

export const updateField = (object: any, key: string, value: any) => {
  return { ...object, ...{ [key]: value } };
};

export const useStateWithCallback = <T>(
  initialState: T
): [
  state: T,
  setState: (
    updatedState: React.SetStateAction<T>,
    callback?: (updatedState: T) => void
  ) => void,
] => {
  const [state, setState] = useState<T>(initialState);
  const callbackRef = useRef<(updated: T) => void>();

  const handleSetState = (
    updatedState: React.SetStateAction<T>,
    callback?: (updatedState: T) => void
  ) => {
    callbackRef.current = callback;
    setState(updatedState);
  };

  useEffect(() => {
    if (typeof callbackRef.current === "function") {
      callbackRef.current(state);
      callbackRef.current = undefined;
    }
  }, [state]);

  return [state, handleSetState];
};

export const toSelectOptions = (list: any[], options: any = {}) => {
  // console.log("toSelectOptions", options);
  return list && list.map
    ? list.map((item) => ({
        ...{
          label: typeof item === "string" ? item : item.name,
          "data-id": typeof item === "string" ? item : item.id,
          value:
            typeof item === "string" ? item : item.id ? item.id : item.name,
        },
        ...options,
      }))
    : [];
};

export const onStringValue = (e: any) => e.target.value;

export const columnCurrency = (amount: any) =>
  amount === 0 || amount ? StringHelper.currency(amount) : "-";

export const columnDateOnly = (date: string) =>
  date ? DateHelper.toDate(date) : "-";
export const columnDate = (date: string) =>
  date ? DateHelper.toDateTime(date) : "-";
export const columnDateShort = (date: string) =>
  date ? DateHelper.toDateHM(date) : "-";
export const columnDateOnlyShort = (
  date: string | Date | null | undefined,
  locale: string = "nl"
) =>
  // date ? DateHelper.toDateShort(date) : "-";
  date ? DateHelper.toDateWorded(date, locale) : "-";
export const columnTimeOnlyShort = (date: string | Date | null | undefined) =>
  date ? DateHelper.toTime(date) : "-";
export const columnString = (value: string | null | undefined) =>
  value ? value : "-";
