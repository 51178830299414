import txt from "helpers/text-helper";
import { Asset } from "../asset/asset";
import { ProcessingRun, ProcessingRunSummary } from "./processing-run";
import { Scanner } from "./scanner";

export const THUMBNAILS_PREFERRED = [17, 21, 25, 29];
export const THUMBNAILS_TOTAL = 64;

export enum ScanStatus {
  Scanning = "SCANNING", // still scanning
  Scanned = "SCANNED", // scanned
  Preview = "PREVIEW", // not accepted on scanner                                       <<<<  zou OT of 3D actie op kunnen ondernemen
  Accepted = "ACCEPTED", // on scanner
  Downloading = "DOWNLOADING", // on scanner, to master
  Downloaded = "DOWNLOADED", // on scanner on master
  UploadPending = "UPLOAD-PENDING", //waiting to go to s3, others are going first      << could be internet issue...
  Uploading = "UPLOADING", //sending to 3
  UploadAborted = "UPLOAD-ABORTED", // upload aborted
  Uploaded = "UPLOADED", //sent to s3
  ProcessingPending = "PROCESSING-PENDING", //waiting to be processed
  ProcessingError = "PROCESSING-ERROR", //last run failed, take action to try again
  Processing = "PROCESSING", //processing to make a 3D model
  Failed = "FAILED", //nothing can be done anymore. failed to make a 3D model (see processing run details)
  Finished = "FINISHED", //successfully made a 3D model (see quality of processing run)
}

export const SCAN_STATUSSES_ACCEPTED: ScanStatus[] = [
  ScanStatus.Accepted,
  ScanStatus.Downloading,
  ScanStatus.Downloaded,
  ScanStatus.UploadPending,
  ScanStatus.Uploading,
  ScanStatus.Uploaded,
  ScanStatus.ProcessingPending,
  ScanStatus.ProcessingError,
  ScanStatus.Processing,
  ScanStatus.Failed,
  ScanStatus.Finished,
];

export const SCAN_STATUSSES_SELECTABLE: ScanStatus[] = [
  ScanStatus.Scanning,
  ScanStatus.Preview,
  ScanStatus.Accepted,
  ScanStatus.Downloaded,
  ScanStatus.UploadPending,
  ScanStatus.Uploading,
  ScanStatus.UploadAborted,
  ScanStatus.Uploaded,
  ScanStatus.Finished,
];

export enum Hand {
  Right = "right",
  Left = "left",
}

export enum Digit {
  Thumb = "1",
  Index = "2",
  Middle = "3",
  Ring = "4",
  Small = "5",
}

export interface ScanSummary {
  scan_id: number;
  name: string;
  captured_at: string;
  scanner_id: number;
  client_number: number;
  client_hand: Hand;
  order_number: string;
  status: ScanStatus;
  processable: boolean;
  thumbnails: Asset[];
  scanner?: Scanner;
  processing?: ProcessingRunSummary;
}

export interface Scan {
  scan_id: number;
  name: string;
  captured_at: string;
  scanner_id: number;
  client_number: number;
  client_hand: Hand;
  order_number: string;
  status: ScanStatus;
  processable: boolean;
  thumbnails: Asset[];
  scanner?: Scanner;
  processing?: ProcessingRun[];
  images_actual?: number;
  images_expected?: number;
}

export const isValidScanStatus = (statusValue: string) => {
  return (Object as any).values(ScanStatus).includes(statusValue);
};

export const isValidHand = (handValue: string) => {
  return (Object as any).values(Hand).includes(handValue);
};

export const handDescription = (
  hand: Hand | string,
  translationBase: string = "scanning.scans.scan.hand",
  acronym: boolean = false
) => {
  if (!hand) {
    return "";
  }
  let key: string = hand.toString().toLowerCase();
  if (key.startsWith("l")) {
    key = acronym ? "left_acronym" : "left";
  } else if (key.startsWith("r")) {
    key = acronym ? "right_acronym" : "right";
  }
  return txt.get(`${translationBase}.${key}`);
};

export const scanStatusDescription = (
  status: ScanStatus | null | undefined
) => {
  return status
    ? txt.get(
        `scanning.scans.scan.status.${status
          .toString()
          .toLowerCase()
          .replace("-", "")}`
      )
    : "ERROR: unknown status";
};

export const isScanAccepted = (scan: Scan | ScanSummary) =>
  scan.status && SCAN_STATUSSES_ACCEPTED.includes(scan.status);

export const scanToHealth = (status: ScanStatus) => {
  switch (status) {
    case ScanStatus.ProcessingError:
    case ScanStatus.Failed:
    case ScanStatus.UploadAborted:
      return "danger";
    case ScanStatus.Accepted:
    case ScanStatus.Downloading:
    case ScanStatus.Downloaded:
    case ScanStatus.UploadPending:
    case ScanStatus.Uploading:
    case ScanStatus.ProcessingPending:
    case ScanStatus.Processing:
      return "warning";
    case ScanStatus.Uploaded:
    case ScanStatus.Finished:
      return "success";
    case ScanStatus.Preview:
      return "default";
    default:
      return "default";
  }
};
