import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiTextArea,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Fitting, FITTING_EMPTY } from "store/data/fitting/fitting";
import { MMOrderLineDeliveryFieldProps } from "../order-line-delivery";

function MMDeliveryNotes(props: MMOrderLineDeliveryFieldProps) {
  const [fitting, setFitting] = useState<Fitting | null | undefined>();

  const [notes, setNotes] = useState<string>(props.fitting?.notes || "");

  useEffect(() => {
    setFitting(props.fitting);
    setNotes(props.fitting?.notes || "");
  }, [props.fitting]);

  const onNotesChanged = (value: string) => {
    if (props.onChange) {
      props.onChange(
        { ...(fitting || FITTING_EMPTY), notes: value },
        props.orderLineId
      );
    }
  };

  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiFormRow className="product-input" display="rowCompressed">
          <EuiTextArea
            value={notes}
            rows={3}
            compressed={true}
            placeholder={`${txt.get("orders.delivery.notes")}...`}
            onBlur={(e) => onNotesChanged(e.target.value)}
            onChange={(e) => setNotes(e.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMDeliveryNotes;
