declare const window: any;

const env = (key: string): string => {
  if (window.env && window.env[key])
    return (window.env[key] || "").toString().trim();
  if (process.env && process.env[key])
    return (process.env[key] || "").toString().trim();
  return "";
};

export default env;
