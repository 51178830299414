import { finalNumberCorrection } from "components/orders/order-entry/order-inputs";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import _ from "lodash";
import { OrderConsent } from "../consent/consent";
import { Costing, OrderCosting } from "../costing/costing";
import {
  Evaluation,
  JotformProductEvaluation,
  NpsEvaluation,
} from "../evaluation/evaluation";
import { File } from "../file/file";
import { Fitting } from "../fitting/fitting";
import { VlotInvoiceDetails } from "../invoices/vlot-invoice-details";
import { Location } from "../location/location";
import { Need } from "../need/need";
import { Organisation } from "../organisation/organisation";
import {
  PersonalDetails,
  PERSONAL_DETAILS_EMPTY,
} from "../personal-details/personal-details";
import { ProductUse } from "../product/product";
import { Referral } from "../referral/referral";

//status the order can be in
export enum OrderStatus {
  Drafted = "DRAFTED",
  Submitted = "SUBMITTED",
  Accepted = "ACCEPTED",
  ProducedPartial = "PRODUCED_PARTIAL",
  Produced = "PRODUCED",
  PackagedPartial = "PACKAGED_PARTIAL",
  Packaged = "PACKAGED", // << zit in de box
  RoutedPartial = "ROUTED_PARTIAL",
  Routed = "ROUTED", // << zit in de auto (of b2b onderweg)
  ShippedPartial = "SHIPPED_PARTIAL",
  Shipped = "SHIPPED", // << ligt op locatie / achter gelaten
  DeliveredPartial = "DELIVERED_PARTIAL",
  Delivered = "DELIVERED", // << geleverd aan klant
  ReturnedPartial = "RETURNED_PARTIAL",
  Returned = "RETURNED", // << terug gebracht naar castellum
  Finalized = "FINALIZED",
  Rejected = "REJECTED",
  Cancelled = "CANCELLED",
}

//status the order can be in
export enum OrderLineStatus {
  Drafted = "DRAFTED",
  Submitted = "SUBMITTED",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Cancelled = "CANCELLED",
  Produced = "PRODUCED",
  Packaged = "PACKAGED",
  Routed = "ROUTED",
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  Finalized = "FINALIZED",
}

//actions that put an order into a status
export enum OrderStatusAction {
  Draft = "DRAFT",
  Submit = "SUBMIT",
  Accept = "ACCEPT",
  Reject = "REJECT",
  Cancel = "CANCEL",
  // Route = "BRING",
  // Ship = "LEAVE",
  Deliver = "DELIVER",
  Finalize = "FINALIZE",
}

export interface OrderStatusLog {
  id: number;
  order_id: number;
  old_status: OrderStatus | null;
  new_status: OrderStatus;
  user: string;
  comment: string;
  logged_at: Date;
}

export const STATUS_LOG_COMMENT_DEFAULTS: string[] = [
  // "duplicated",
  "create",
  "update",
  "orderlinestatuschange",
  "status changed",
];

export const isOrderLineEndStatus = (status: OrderLineStatus): boolean => {
  return [
    OrderLineStatus.Delivered,
    OrderLineStatus.Rejected,
    OrderLineStatus.Finalized,
    OrderLineStatus.Cancelled,
  ].includes(status);
};

export const isDefaultStatusLogComment = (comment: string): boolean => {
  return (
    !!comment &&
    STATUS_LOG_COMMENT_DEFAULTS.findIndex(
      (value: string) => comment.indexOf(value) === 0
    ) >= 0
  );
};

export interface OrderLineGroup {
  groupKey: string;
  order_id: number;
  order?: Order;
  lines: OrderLine[];
}
export interface LegacyOrder {
  id: string;
  createdAt: Date;
  createdBy: string;
  clientId: string;
  organisationId: string;
  googleFolderId: string;
}

export interface ProductMeasurements {
  o1?: string;
  o2?: string;
  o3?: string;
  o4?: string;
  l1?: string;
  l2?: string;
  s1?: string;
  j1?: string;
  notes?: string;
}
export const EMPTY_PRODUCT_MEASUREMENTS = {
  o1: "",
  o2: "",
  o3: "",
  o4: "",
  l1: "",
  l2: "",
  s1: "",
  j1: "",
};

export const ORDER_EMPTY = {
  id: 0,
  ordered_by: "",
  ordered_at: new Date(),
  client_code: "",
  purchase_order: "",
  external_client_reference: "",
  external_client_name: "",
  external_client_birth_date: "",
  external_client_postal_code: "",
  external_client_email: "",
  external_client_phone_number: "",
  external_client_street_number: "",
  organisation_id: "",
  delivery_method: "",
  practitioner: "",
  personal_details: PERSONAL_DETAILS_EMPTY,
  status: OrderStatus.Drafted,
  order_lines: [],
  legacy_data: {},
  referral_letter_reference: "",
  no_referral_reasons: "",
  google_drive_info: "",
  created_at: new Date(),
  created_by: "",
  is_demo: false,
  is_archived: false,
  files: [],
  needs: [],
  version: 0,
};

export const ORDER_LINE_EMPTY = {
  id: 0,
  order_id: 0,
  code: "",
  digits: [],
  hand: undefined,
  use: ProductUse.Day,
  measurements: {},
  order_type: "",
  remake_reason: "",
  argumentation: "",
  replacement_for: "",
  communication_reasons: "",
  has_previous_aid: null,
  previous_aid: "",
  function_description: null,
  product_description: null,
  legacy_data: {},
  created_at: new Date(),
  created_by: "",
  product_selection: {},
  status: OrderLineStatus.Drafted,
  files: [],
  fittings: [],
  costing: null,
  workflow_override: "",
};

export interface OrderLine {
  id: number;
  order_id: number;
  code: string;
  digits: any;
  hand?: string; //L or R
  use?: "DAY" | "NIGHT" | ProductUse; //DAY or NIGHT
  measurements: any;
  order_type: string;
  remake_reason: string;
  replacement_for: string;
  remake_details?: { reason: string }; //todo:add remake reason model
  replacement?: { code: string }; //todo:add production model
  argumentation: string;
  communication_reasons: string;
  has_previous_aid: boolean | null;
  previous_aid: string;
  function_description: string | null;
  product_description: string | null;
  legacy_data: any;
  created_at: Date;
  created_by: string;
  product_selection: any;
  status: OrderLineStatus;
  translations?: any;
  files?: File[];
  fittings?: Fitting[];
  costing: Costing | null;
  workflow_override: string;
  vlot_invoice_detail?: VlotInvoiceDetails;
  status_date_delivery?: Date;
  order?: Order;
  updated_at?: Date;
  nps_evaluation?: NpsEvaluation;
  jotform_product_evaluation?: JotformProductEvaluation;
}

export interface Order {
  id: number;
  ordered_by: string;
  ordered_at: Date;
  client_code: string;
  purchase_order: string;
  external_client_reference: string;
  external_client_name: string;
  external_client_birth_date: string;
  external_client_postal_code: string;
  external_client_street_number: string;
  external_client_email: string;
  external_client_phone_number: string;
  organisation_id: string;
  organisation?: Organisation;
  delivery_method: string;
  location?: Location;
  location_id?: number;
  practitioner: string;
  personal_details: PersonalDetails; //from practitioner
  status: OrderStatus;
  order_lines: OrderLine[];
  legacy_data: any;
  referral_letter_reference: string;
  no_referral_reasons: string;
  referral_id?: number;
  referral?: Referral;
  google_drive_info: any;
  created_at: Date;
  created_by: string;
  is_demo: boolean;
  is_archived: boolean;
  is_complete?: boolean;
  incomplete_reasons?: string;
  files: File[];
  needs: Need[];
  evaluations?: Evaluation[];
  version: number;
  costing?: OrderCosting;
  consents?: OrderConsent[];
  order_status_logs?: OrderStatusLog[];
}

export const orderStatusFromAction = (action: OrderStatusAction) => {
  switch (action) {
    case OrderStatusAction.Draft:
      return OrderStatus.Drafted;
    case OrderStatusAction.Submit:
      return OrderStatus.Submitted;
    case OrderStatusAction.Accept:
      return OrderStatus.Accepted;
    case OrderStatusAction.Reject:
      return OrderStatus.Rejected;
    case OrderStatusAction.Cancel:
      return OrderStatus.Cancelled;
    case OrderStatusAction.Deliver:
      return OrderStatus.Delivered;
    case OrderStatusAction.Finalize:
      return OrderStatus.Finalized;
  }
};
export const hasNotes = (line: OrderLine) => {
  return line.measurements && line.measurements.notes;
};

export const isValidOrderStatus = (statusValue: string) => {
  return (Object as any).values(OrderStatus).includes(statusValue);
};

export const orderStatusDescription = (status: OrderStatus) => {
  return txt.uc(`orders.order.status.${status.toString().toLowerCase()}`);
};

export const orderLineStatusDescription = (status: OrderLineStatus) => {
  return txt.uc(
    `orders.order.order_line.status.${status.toString().toLowerCase()}`
  );
};

export const orderLineMeasurementsDescription = (measurements: any) => {
  let result = "";
  if (measurements) {
    for (const key in measurements) {
      if (key === "notes") {
        continue;
      }
      result += " " + key + ": " + measurements[key];
    }
  }
  return result;
};

export const orderLineToHealth = (status: OrderLineStatus) => {
  switch (status) {
    case OrderLineStatus.Drafted:
      return "default";
    case OrderLineStatus.Submitted:
      return "warning";
    case OrderLineStatus.Accepted:
    case OrderLineStatus.Delivered:
    case OrderLineStatus.Packaged:
    case OrderLineStatus.Produced:
    case OrderLineStatus.Finalized:
      return "success";
    case OrderLineStatus.Rejected:
      return "danger";
    case OrderLineStatus.Cancelled:
    default:
      return "hollow";
  }
};
export const orderToHealth = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Drafted:
      return "default";
    case OrderStatus.Submitted:
      return "warning";
    case OrderStatus.Accepted:
    case OrderStatus.DeliveredPartial:
    case OrderStatus.Delivered:
    case OrderStatus.ProducedPartial:
    case OrderStatus.Produced:
    case OrderStatus.Finalized:
      return "success";
    case OrderStatus.Rejected:
      return "danger";
    case OrderStatus.Cancelled:
    default:
      return "hollow";
  }
};

export const isOrderLineAuthorizationNeeded = (orderLine: OrderLine) =>
  orderLine.costing && orderLine.costing.authorization === true;

export const isOrderAuthorizationNeeded = (order: Order) => {
  let result: boolean =
    order &&
    order.order_lines &&
    order.order_lines.length > 0 &&
    order.order_lines.findIndex((orderLine: OrderLine) =>
      isOrderLineAuthorizationNeeded(orderLine)
    ) >= 0;
  console.log("isOrderAuthorizationNeeded", order.order_lines, result);
  return result;
};

export const isOrderLineDeclarationNeeded = (orderType: string) => {
  return ![
    "R&D",
    "Demo",
    "Demo, on scan",
    "Demo, standard",
    "Warranty: discoloration",
    "Warranty: other",
    "Warranty: defect",
  ].includes(orderType.trim());
};
export const orderLinesGroupedByUse = (orderLines: OrderLine[]) => {
  let result: any = {};
  for (let i = 0; i < orderLines.length; i++) {
    const orderLine: OrderLine = orderLines[i];
    if (orderLine.use) {
      if (!result[orderLine.use]) result[orderLine.use] = [];
      result[orderLine.use].push(orderLine);
    } else {
      if (!result["NONE"]) result["NONE"] = [];
      result["NONE"].push(orderLine);
    }
  }
  return result;
};

export const orderLinesGroupedByType = (orderLines: OrderLine[]) => {
  let result: any = {};
  for (let i = 0; i < orderLines.length; i++) {
    const orderLine: OrderLine = orderLines[i];
    if (orderLine.product_selection.type) {
      if (!result[orderLine.product_selection.type])
        result[orderLine.product_selection.type] = [];
      result[orderLine.product_selection.type].push(orderLine);
    } else {
      if (!result["NONE"]) result["NONE"] = [];
      result["NONE"].push(orderLine);
    }
  }
  return result;
};

//could use contract data as well....
export const orderLinesGroupedByHandFinger = (orderLines: OrderLine[]) => {
  let familyOptionsHand: string[] = ["duim", "pols"];
  let familyOptionsSpecial: string[] = ["special"];

  let result: any = {};
  for (let i = 0; i < orderLines.length; i++) {
    const orderLine: OrderLine = orderLines[i];
    if (orderLine.product_selection.type) {
      if (
        familyOptionsHand.findIndex(
          (option: string) =>
            orderLine.product_selection.type.toLowerCase().indexOf(option) >= 0
        ) >= 0
      ) {
        if (!result["HAND"]) result["HAND"] = [];
        result["HAND"].push(orderLine);
      } else if (
        familyOptionsSpecial.findIndex(
          (option: string) =>
            orderLine.product_selection.type.toLowerCase().indexOf(option) >= 0
        ) >= 0
      ) {
        if (!result["SPECIAL"]) result["SPECIAL"] = [];
        result["SPECIAL"].push(orderLine);
      } else {
        if (!result["FINGER"]) result["FINGER"] = [];
        result["FINGER"].push(orderLine);
      }
    } else {
      if (!result["NONE"]) result["NONE"] = [];
      result["NONE"].push(orderLine);
    }
  }
  return result;
};

//could use contract data as well....
export const orderLinesGroupedByConfection = (orderLines: OrderLine[]) => {
  let familyOptionsConfection: string[] = ["confectie"];

  let result: any = {};
  for (let i = 0; i < orderLines.length; i++) {
    const orderLine: OrderLine = orderLines[i];
    if (orderLine.product_selection.type) {
      if (
        familyOptionsConfection.findIndex(
          (option: string) =>
            orderLine.product_selection.type.toLowerCase().indexOf(option) >= 0
        ) >= 0
      ) {
        if (!result["CONFECTION"]) result["CONFECTION"] = [];
        result["CONFECTION"].push(orderLine);
      } else {
        if (!result["CUSTOM"]) result["CUSTOM"] = [];
        result["CUSTOM"].push(orderLine);
      }
    } else {
      if (!result["NONE"]) result["NONE"] = [];
      result["NONE"].push(orderLine);
    }
  }
  return result;
};

export const productDescription = (
  productSelection: any,
  translation?: any
) => {
  return `${
    translation && translation.name && translation.name[txt.lang()]
      ? translation.name[txt.lang()]
      : productSelection.type
  }${
    productSelection.size && productSelection.size !== "n.v.t."
      ? " " + productSelection.size
      : ""
  }${
    translation &&
    translation.color &&
    translation.color[txt.lang()] &&
    translation.color[txt.lang()] !== "n.v.t."
      ? " " + translation.color[txt.lang()]
      : productSelection.color && productSelection.color !== "n.v.t."
        ? " " + productSelection.color
        : ""
  }`;
};

export const productShortDescription = (
  productSelection: any,
  translation?: any,
  maxChars: number = 15
) => {
  const description = productDescription(productSelection, translation);
  return description.length < maxChars
    ? description
    : description.substring(0, maxChars) + "...";
};

export const productTinyDescription = (
  productSelection: any,
  translation?: any
) => {
  return `${
    translation && translation.name && translation.name[txt.lang()]
      ? translation.name[txt.lang()]
      : productSelection.type
  }`;
};

export const toGroupedOrderLines = (orders: Order[]) => {
  let result: any = {};

  for (let i = 0; i < orders.length; i++) {
    const order: Order = orders[i];
    for (let j = 0; j < order.order_lines.length; j++) {
      const orderLine: OrderLine = order.order_lines[j];
      const groupKey: string = `${orderLine.order_id}-${orderLine.hand}-${orderLine.product_selection.type}-${orderLine.order_type}-${orderLine.status}`;
      if (!result[groupKey]) {
        result[groupKey] = {
          groupKey,
          order_id: orderLine.order_id,
          order: order,
          lines: [],
        } as OrderLineGroup;
        console.log(result);
      }
      result[groupKey].lines.push(orderLine);
    }
  }
  const groups: OrderLineGroup[] = Object.values(result) as OrderLineGroup[];
  return groups;
};

export const getLastDeliveryStatusLog = (logs?: OrderStatusLog[]) => {
  return getLastStatusLog(
    [OrderStatus.Delivered, OrderStatus.DeliveredPartial, OrderStatus.Rejected],
    logs
  );
};

export const getLastStatusLog = (
  statusses: OrderStatus[],
  logs?: OrderStatusLog[]
): OrderStatusLog | null => {
  if (logs) {
    let log: OrderStatusLog | null =
      _.findLast(logs, (log: OrderStatusLog) =>
        statusses.includes(log.new_status)
      ) || null;
    return log;
  }
  return null;
};

export const getStatusDateString = (
  status: OrderStatus,
  logs?: OrderStatusLog[],
  formatFunction?: Function
) => {
  let log: OrderStatusLog | null =
    (logs
      ? _.findLast(logs, (log: OrderStatusLog) => log.new_status === status)
      : null) || null;

  if (log) {
    return formatFunction
      ? formatFunction(log.logged_at)
      : DateHelper.toDate(log.logged_at);
  }
  return "";
};

export const orderToApiOrderInfo = (order: Order): any => ({
  header: {
    organisation_id: order.organisation_id,
    ordered_at: order.ordered_at,
    ordered_by:
      order.practitioner || AuthenticationHelper.getPersonalDetailsId(),
    client_code: order.client_code,
    purchase_order: order.purchase_order,
    external_client_reference: order.external_client_reference,
    external_client_name: order.external_client_name,
    external_client_birth_date: order.external_client_birth_date,
    external_client_postal_code: order.external_client_postal_code,
    external_client_street_number: order.external_client_street_number,
    external_client_email: order.external_client_email,
    external_client_phone_number: order.external_client_phone_number,
    delivery_method: order.delivery_method,
    location_id: order.location_id,
    is_demo:
      order.status === OrderStatus.Drafted
        ? !!AuthenticationHelper.isDemo()
        : order.is_demo,
    is_complete: order.is_complete,
    incomplete_reasons: order.incomplete_reasons,
  },
  needs: order.needs,
  //only create/add referral if it exists
  referral: order.referral
    ? { ...order.referral, client_code: order.client_code }
    : null,
  costing: order.costing,
  lines: order.order_lines.map((line: OrderLine) => ({
    id: line.id,
    code: line.code,
    product_selection: line.product_selection,
    digits: line.digits ? line.digits : [],
    hand: line.hand ? line.hand.toString().charAt(0).toUpperCase() : null,
    use: line.use ? line.use.toUpperCase() : null,
    order_type: line.order_type,
    remake_reason: line.remake_reason,
    replacement_for: line.replacement_for,
    argumentation: line.argumentation,
    measurements: finalNumberCorrection(line.measurements),
    function_description: line.function_description,
    product_description: line.product_description,
    costing: line.costing ? line.costing : null,
    files: line.files ? line.files : [],
    fittings: line.fittings ? line.fittings : [],
    // product: withProduct ? line.product : {},
    communication_reasons: line.communication_reasons,
    has_previous_aid: line.has_previous_aid,
    previous_aid: line.previous_aid,
    workflow_override: line.workflow_override,
    status: line.status,
  })),
  files: order.files.map((file: any) => ({
    id: file.id,
    attachment_type: file.attachment.type,
  })),
  version: order.version || 0,
  status: order.status,
  consents: order.consents,
  id: order.id && order.id !== 0 ? order.id : null,
});

export const getIsFirstOrderOfReferral = (order?: Order | null): boolean =>
  !order ||
  !order.referral ||
  !order.referral.first_order_id ||
  order.id === order.referral.first_order_id;
