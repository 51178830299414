import { useEffect, useRef } from "react";

const DEFAULT_ZOOM = 14;

function MMMapView({ location }: { location?: google.maps.LatLngLiteral }) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    console.log("reposition map?", location, ref);
    if (location && ref && ref.current) {
      console.log("reposition map?", location, ref);
      const googleMap: google.maps.Map = new window.google.maps.Map(
        ref.current,
        {
          center: location,
          zoom: DEFAULT_ZOOM,
        }
      );

      new google.maps.Marker({
        position: location,
        map: googleMap,
        // title: "Hello World!",
      });
    }
  }, [location]);

  return location ? (
    <div
      style={{
        maxHeight: "400px",
        maxWidth: "600px",
        width: "100%",
        height: "100%",
      }}
      ref={ref}
    />
  ) : (
    <></>
  );
}

export default MMMapView;
