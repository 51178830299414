import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiInMemoryTable,
  EuiLink,
  EuiSearchBarProps,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ConnectAPIHelper from "api/connect-api-helper";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "order_date";
export const DEFAULT_SORT_ORDER = "desc";

function MMOrderMatchesList() {
  const dispatch = useDispatch();
  const api = new ConnectAPIHelper();

  const [orderMatches, setOrderMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useLocalStorage("order_matches_limit", DEFAULT_PAGE_SIZE);
  const [offset] = useLocalStorage("order_matches_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy] = useLocalStorage("order_matches_sort_by", DEFAULT_SORT_BY);
  const [sortOrder] = useLocalStorage(
    "order_matches_sort_order",
    DEFAULT_SORT_ORDER
  );

  const loadOrderMatches = async (forceReload: boolean = false) => {
    setIsLoading(true);

    let filters: any = { reloaded: forceReload };

    const result = await api.getOrderMatches(
      filters,
      limit,
      offset,
      orderMatchFieldToSortKey(sortBy),
      sortOrder
    );
    if (result.status === "OK") {
      setOrderMatches(result.result);
      console.log(result.result[0]);
      setTotal(result.meta_data.result_set.total);
    } else {
      setOrderMatches([]);
      setTotal(0);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadOrderMatches();
  }, []);
  //}, [sortOrder, sortBy, limit, offset]);

  const handleLineFix = async (line: any) => {
    setIsLoading(true);
    const result = await api.fixOrderLine(line);

    if (result.status === "OK") {
      dispatch(
        toastAdd(
          txt.get("generic.executed", txt.get("generic.fix")),
          txt.get(
            "admin.data.order_matches.fix_explanation",
            result.result.result.google_sheet_index
              ? result.result.result.google_sheet_index
              : `${txt.lo("generic.for")} ${line.order_id}-${
                  line.order_line_id
                }`
          ),
          "success"
        )
      );
    } else {
      dispatch(toastAdd(txt.get("generic.fix"), result.message, "danger"));
    }
    setIsLoading(false);
  };

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.order_matches.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.order_matches.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  // const sorting: any = {
  //   sort: {
  //     // field: "order_date",
  //     sortBy,
  //     // direction: "desc",
  //     sortOrder,
  //   },
  //   enableAllColumns: false,
  //   // readOnly: false,
  // };

  const orderMatchFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  // const onOrderMatchesChange = ({ page = {} as any, sort = {} as any }) => {
  //   console.log("onOrderMatchesChange", page, sort);
  //   if (page.size) {
  //     const newLimit = page.size;
  //     const newOffset = Math.max(0, page.index * page.size);
  //     if (limit !== newLimit) setLimit(newLimit);
  //     if (offset !== newOffset) setOffset(newOffset);
  //   }
  //   if (sort.field) {
  //     setSortBy(sort.field);
  //     setSortOrder(sort.direction ?? "asc");
  //   }
  // };

  const columns = () => {
    const onlyShowMismatch: boolean = true;

    return [
      {
        name: txt.get("orders.order.name"),
        field: "order_id",
        sortable: api.orderMatchIsSortableBy(
          orderMatchFieldToSortKey("order_id")
        ),
        style: { minWidth: "60px" },
        render: (order_id: any) => (
          <EuiLink target="_blank" href={`/my-dashboard?order=${order_id}`}>
            {order_id}
          </EuiLink>
        ),
      },
      {
        name: txt.get("orders.order.order_line.name"),
        field: "order_line_id",
        sortable: api.orderMatchIsSortableBy(
          orderMatchFieldToSortKey("order_line_id")
        ),
        style: { minWidth: "60px" },
      },
      {
        name: "Order date",
        field: "order_date",
        type: "date",
        sortable: api.orderMatchIsSortableBy(
          orderMatchFieldToSortKey("order_date")
        ),
        render: (order_date: any, line: any) => {
          return (
            <EuiFlexGroup
              justifyContent="flexStart"
              alignItems="flexStart"
              direction="column"
              gutterSize="m"
            >
              {/* Lines found: */}
              <EuiFlexItem grow={0}>
                <EuiHealth
                  textSize="xs"
                  style={{ fontFamily: "courier" }}
                  color={line.lines_found ? "success" : "danger"}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    {txt.get("admin.data.order_matches.lines_found")}:
                  </span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {line.order_line ? "✅ " : "❌ "}
                    <span
                      style={
                        line.order_line
                          ? { color: "#009C33" }
                          : {
                              color: "#9C2233",
                              textDecoration: "line-through",
                            }
                      }
                    >
                      connect
                    </span>
                    {" | "}
                    {line.sheet_line ? "✅ " : "❌ "}
                    <span
                      style={
                        line.sheet_line
                          ? { color: "#009C33" }
                          : {
                              color: "#9C2233",
                              textDecoration: "line-through",
                            }
                      }
                    >
                      sheets
                    </span>
                  </span>
                </EuiHealth>
              </EuiFlexItem>

              {/* Ordered at: */}
              <EuiFlexItem grow={0}>
                <EuiHealth
                  textSize="xs"
                  style={{ fontFamily: "courier" }}
                  color={line.date_ok ? "success" : "danger"}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    {txt.get("orders.order.ordered_at")}:
                  </span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {line.order_line && line.order_line.order
                      ? DateHelper.toDate(line.order_line.order.ordered_at)
                      : "-"}
                    {" | "}
                    {line.sheet_line && line.sheet_line.order_date
                      ? DateHelper.toDate(line.sheet_line.order_date)
                      : "n/a"}
                  </span>
                </EuiHealth>
              </EuiFlexItem>

              {/* Order status: */}
              <EuiFlexItem grow={false}>
                <EuiHealth
                  textSize="xs"
                  style={{ fontFamily: "courier" }}
                  color={line.status_health}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    {txt.get("orders.order.order_status")}:
                  </span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {line.order_line
                      ? line.order_line.status.toLowerCase()
                      : txt.get("generic.n_a")}
                    {" | "}
                    {line.sheet_line && line.sheet_line.is_accepted
                      ? "accepted"
                      : ""}
                    {line.sheet_line && line.sheet_line.is_cancelled
                      ? "cancelled"
                      : ""}
                    {!line.sheet_line ||
                    (!line.sheet_line.is_cancelled &&
                      !line.sheet_line.is_accepted)
                      ? txt.get("generic.n_a")
                      : ""}
                  </span>
                </EuiHealth>
              </EuiFlexItem>
            </EuiFlexGroup>
          );
        },
      },
      {
        name: `${txt.get(
          "admin.data.order_matches.mismatches"
        )}: (data-in-connect) | (data-in-sheet)`,
        field: "all_ok",
        render: (all_ok: any, line: any) => {
          return (
            <EuiFlexGroup
              justifyContent="flexStart"
              alignItems="flexStart"
              direction="column"
              gutterSize="m"
              wrap={true}
            >
              {/* Organisation: */}
              {!onlyShowMismatch || !line.organisation_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.organisation_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("admin.organisations.name")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.order?.organisation?.name ||
                          txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.company || txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Ordered at: */}
              {/*
                  {!onlyShowMismatch || !line.date_ok ? (
                    <>
                      <EuiFlexItem grow={0}>
                        <EuiHealth
                          textSize="xs"
                          style={{ fontFamily: "courier" }}
                          color={line.date_ok ? "success" : "danger"}
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            {txt.get("orders.order.ordered_at")}:
                          </span>
                          <span style={{ whiteSpace: "nowrap" }}>
                            {line.order_line && line.order_line.order
                              ? DateHelper.toDate(
                                  line.order_line.order.ordered_at
                                )
                              : "-"}{" "}
                            |{" "}
                            {line.sheet_line && line.sheet_line.order_date
                              ? DateHelper.toDate(line.sheet_line.order_date)
                              : "n/a"}
                          </span>
                        </EuiHealth>
                      </EuiFlexItem>
                    </>
                  ) : (
                    <></>
                  )}
                  */}

              {/* Order type: */}
              {!onlyShowMismatch || !line.order_type_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.order_type_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.order_type")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.order_type || txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.order_type || txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Practitioner: */}
              {/* !onlyShowMismatch || !line.practitioner_ok ? (
                  <>
                    <EuiFlexItem grow={0}>
                      <EuiHealth
                        color={line.practitioner_ok ? "success" : "danger"}
                        textSize="xs"
                        style={{ fontFamily: "courier" }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          {txt.get("orders.order.practitioner")}:
                        </span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {line.order_line?.order?.personal_details
                            ?.full_name || txt.get("generic.n_a")}
                          {" | "}
                          {line.sheet_line?.technician ||
                            txt.get("generic.n_a")}
                        </span>
                      </EuiHealth>
                    </EuiFlexItem>
                  </>
                ) : (
                  <></>
                ) */}

              {/* Client code: */}
              {!onlyShowMismatch || !line.client_code_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.client_code_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.client_code")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.order?.client_code ||
                          txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.client_code || txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Article code: */}
              {!onlyShowMismatch || !line.article_code_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.article_code_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("production.code")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.code || txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.article_code ||
                          txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Delivery method: */}
              {!onlyShowMismatch || !line.delivery_method_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.delivery_method_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.delivery")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.order?.delivery_method ||
                          txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.delivery_method ||
                          txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Delivery location: */}
              {!onlyShowMismatch || !line.delivery_location_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.delivery_location_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.delivery_location")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.order?.location ||
                          txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.delivery_location ||
                          txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Order notes: */}
              {!onlyShowMismatch || !line.order_notes_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.order_notes_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("production.productions.order_notes")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        <span
                          style={{
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            wordBreak: "break-all",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "220px",
                          }}
                        >
                          {line.order_line?.measurements?.notes ||
                            txt.get("generic.n_a")}
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                          }}
                        >
                          {" | "}
                        </span>
                        <span
                          style={{
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            wordBreak: "break-all",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "220px",
                          }}
                        >
                          {line.sheet_line?.order_notes ||
                            txt.get("generic.n_a")}
                        </span>
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Finger digits: */}
              {!onlyShowMismatch || !line.finger_digits_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.finger_digits_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.fingers")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.digits_string ||
                          txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.digits || txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Hand number: */}
              {!onlyShowMismatch || !line.hand_number_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.hand_number_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.hand")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.hand || txt.get("generic.n_a")}
                        {" | "}
                        {line.sheet_line?.hand || txt.get("generic.n_a")}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}

              {/* Measurements: */}
              {!onlyShowMismatch || !line.measurements_ok ? (
                <>
                  <EuiFlexItem grow={0}>
                    <EuiHealth
                      color={line.measurements_ok ? "success" : "danger"}
                      textSize="xs"
                      style={{ fontFamily: "courier" }}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {txt.get("orders.order.measurements")}:
                      </span>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {line.order_line?.measurements?.l1
                          ? `l1:${line.order_line?.measurements?.l1} `
                          : ""}
                        {line.order_line?.measurements?.l2
                          ? `l2:${line.order_line?.measurements?.l2} `
                          : ""}
                        {line.order_line?.measurements?.l3
                          ? `l3:${line.order_line?.measurements?.l3} `
                          : ""}
                        {line.order_line?.measurements?.o1
                          ? `o1:${line.order_line?.measurements?.o1} `
                          : ""}
                        {line.order_line?.measurements?.o2
                          ? `o2:${line.order_line?.measurements?.o2} `
                          : ""}
                        {line.order_line?.measurements?.o3
                          ? `o3:${line.order_line?.measurements?.o3} `
                          : ""}
                        {line.order_line?.measurements?.o4
                          ? `o4:${line.order_line?.measurements?.o4} `
                          : ""}
                        {line.order_line?.measurements?.s1
                          ? `s1:${line.order_line?.measurements?.s1} `
                          : ""}
                        {line.order_line?.measurements?.j1
                          ? `j1:${line.order_line?.measurements?.j1} `
                          : ""}
                        {" | "}
                        {line.sheet_line?.measurement_l1
                          ? `l1:${line.sheet_line?.measurement_l1} `
                          : ""}
                        {line.sheet_line?.measurement_l2
                          ? `l2:${line.sheet_line?.measurement_l2} `
                          : ""}
                        {line.sheet_line?.measurement_l3
                          ? `l3:${line.sheet_line?.measurement_l3} `
                          : ""}
                        {line.sheet_line?.measurement_o1
                          ? `o1:${line.sheet_line?.measurement_o1} `
                          : ""}
                        {line.sheet_line?.measurement_o2
                          ? `o2:${line.sheet_line?.measurement_o2} `
                          : ""}
                        {line.sheet_line?.measurement_o3
                          ? `o3:${line.sheet_line?.measurement_o3} `
                          : ""}
                        {line.sheet_line?.measurement_o4
                          ? `o4:${line.sheet_line?.measurement_o4} `
                          : ""}
                        {line.sheet_line?.measurement_s1
                          ? `s1:${line.sheet_line?.measurement_s1} `
                          : ""}
                        {line.sheet_line?.measurement_j1
                          ? `j1:${line.sheet_line?.measurement_j1} `
                          : ""}
                      </span>
                    </EuiHealth>
                  </EuiFlexItem>
                </>
              ) : (
                <></>
              )}
            </EuiFlexGroup>
          );
        },
      },
      {
        name: "",
        actions: [
          {
            render: (line: any) => {
              return line.all_ok || !line.order_line ? (
                <></>
              ) : (
                <EuiButton
                  disabled={isLoading}
                  isLoading={isLoading}
                  size="s"
                  fill={true}
                  iconSide="right"
                  onClick={(e: any) => {
                    handleLineFix(line);
                  }}
                  iconType="merge"
                >
                  {txt.get("generic.fix")}
                </EuiButton>
              );
            },
          },
        ],
      },
    ];
  };

  const lineSearch: EuiSearchBarProps = {
    // toolsLeft: renderToolsLeft(),
    // toolsRight: renderToolsRight(),
    box: {
      incremental: true,
    },
    defaultQuery: "all_ok:false",
    filters: [
      {
        type: "field_value_toggle",
        field: "all_ok",
        name: txt.get("admin.data.order_matches.mismatches_only"),

        value: false,
      },
      {
        type: "field_value_selection",
        field: "status_health",

        name: txt.get("orders.order.order_status"),
        options: [
          { value: "success", name: txt.get("generic.ok") },
          { value: "warning", name: txt.get("generic.pending") },
          { value: "danger", name: txt.get("generic.wrong") },
        ],
      },
      {
        type: "field_value_toggle",
        field: "date_ok",
        name: txt.get("admin.data.order_matches.date_wrong"),
        value: false,
      },
      {
        type: "field_value_toggle",
        field: "lines_found",
        name: txt.get("admin.data.order_matches.missing_lines"),
        value: false,
      },
    ],
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiButton
            fill={true}
            color="accent"
            onClick={() => loadOrderMatches(true)}
          >
            {txt.get("admin.data.order_matches.reload")}
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiInMemoryTable
        search={lineSearch}
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={orderMatches}
        columns={columns()}
        pagination={pagination}
        sorting={true}
      />
    </Fragment>
  );
}

export default MMOrderMatchesList;
