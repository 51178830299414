import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiCopy,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiPopover,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiWrappingPopover,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { Input } from "helpers/input-helper";
import txt from "helpers/text-helper";
import { FocusEventHandler, Fragment, useEffect, useState } from "react";
import { Costing } from "store/data/costing/costing";
import { File } from "store/data/file/file";
import { Fitting } from "store/data/fitting/fitting";
import { VlotInvoiceDetails } from "store/data/invoices/vlot-invoice-details";
import {
  Order,
  OrderLine,
  OrderLineStatus,
  ORDER_EMPTY,
  ORDER_LINE_EMPTY,
  ProductMeasurements,
} from "store/data/order/order";
import { ProductUse } from "store/data/product/product";
import { Digit, Hand } from "store/data/scan/scan";
import MMOrderAdditionalCommunication from "./inputs/order-additional-communication";
import MMOrderArgumentation from "./inputs/order-argumentation";
import MMOrderDigits from "./inputs/order-digits";
import MMOrderHand from "./inputs/order-hand";
import MMOrderIsModification from "./inputs/order-is-modification";
import MMOrderMeasurements from "./inputs/order-measurements";
import MMOrderNotes from "./inputs/order-notes";
import MMOrderProduct from "./inputs/order-product";
import MMRemakeReason from "./inputs/order-remake-reason";
import MMOrderReplacementFor from "./inputs/order-replacement-for";
import MMOrderType from "./inputs/order-type";
import MMOrderWorkflowOverride from "./inputs/order-workflow-override";
import { MMOrderEntryInputProps } from "./order-entry";
import { OrderLineInputs } from "./order-inputs";
import MMProductMeasurementInstructions from "./product-measurement-instructions";

export const EMPTY_PRODUCT_INPUT = {
  code: undefined,
  name: undefined,
  size: undefined,
  color: undefined,
  use: undefined,
  hasPreviousAid: null,
} as MMOrderLineProductInput;

export interface MMOrderLineProductInputUI {
  argumentationIsLarge?: boolean;
  notesIsLarge?: boolean;
  isExpanded?: boolean;
  isDetailsShown?: boolean;
  isMeasurementInstructionsShown?: boolean;
}
export interface MMOrderLineProductInput {
  id?: number;
  index: number;
  code?: string;
  name?: string;
  family?: string;
  size?: string;
  isSpecial?: boolean;
  color?: string;
  hand?: Hand;
  use?: ProductUse;
  digits?: Digit[];
  measurements?: ProductMeasurements;
  variation: any;
  orderType: string;
  remakeReason: string;
  communicationReasons?: string;
  replacementFor: string;
  hasPreviousAid: boolean | null;
  previousAid: string;
  functionDescription: string;
  productDescription: string;
  argumentation: string;
  isModification?: boolean;
  files?: File[];
  fittings?: Fitting[];
  costing?: Costing | null;
  vlot_invoice_details?: VlotInvoiceDetails | null;
  workflowOverride?: string;
  status: OrderLineStatus;
  ui?: MMOrderLineProductInputUI;
}

export interface ProductChangeHandler {
  (input: MMOrderLineProductInput): void;
}

export interface MMOrderLineFieldProps {
  order: Order | null;
  selection: MMOrderLineProductInput;
  showTitle?: boolean;
  titleLong?: boolean;
  products?: any[];
  remakeReasons?: any[];
  communicationReasons?: string[];
  workflows?: string[];
  clientProductions?: any[];
  orderTypes?: any[];
  onChange?: ProductChangeHandler;
  onFocus?: FocusEventHandler;
  direction?: "row" | "column";
  hideNonOptions?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  measurementsShown?: string[];
  measurementsMissing?: string[];
  orderLineId?: number;
  isEditable: boolean;
  hasValidation?: boolean;
  orderLineInputs?: OrderLineInputs;
  testId?: string;
  // isEnable?: boolean;
  // isVisible?:boolean;
}

const productFamily = (code: string, products: any[]) => {
  const result = products.find(
    (product: any) =>
      product.code === code || product.legacy_product_code === code
  );
  // console.log("productFamily for code", code, result.family);
  return result && result.family ? result.family : "";
};

export const orderLinesToProducts = (
  lines: OrderLine[],
  products: any[],
  detailsIndex?: number | null,
  measurementDetailsIndex?: number | null
): MMOrderLineProductInput[] => {
  return lines.map((line: OrderLine, i: number) => {
    const productVariation = products.find(
      (product: any) =>
        product.code === line.code || product.legacy_product_code === line.code
    );
    return {
      id: line.id,
      index: i,
      code: line.code || "",
      name: line.product_selection?.type || "",
      size: line.product_selection?.size || "",
      color: line.product_selection?.color || "",
      family: line.product_selection?.family
        ? line.product_selection?.family
        : line.code
          ? productFamily(line.code, products)
          : "",
      isSpecial: false /* special / modified? what is it exactly? */,
      hand:
        line.hand && line.hand.toUpperCase().startsWith("L")
          ? Hand.Left
          : line.hand && line.hand.toUpperCase().startsWith("R")
            ? Hand.Right
            : undefined,
      use:
        line.use && (line.use === "DAY" || line.use === ProductUse.Day)
          ? ProductUse.Day
          : line.use && (line.use === "NIGHT" || line.use === ProductUse.Night)
            ? ProductUse.Night
            : undefined,
      digits: line.digits || [],
      measurements: line.measurements || {},
      variation: productVariation,
      orderType: line.order_type || "",
      remakeReason: line.remake_reason || "",
      replacementFor: line.replacement_for || "",
      argumentation: line.argumentation || "",
      hasPreviousAid: line.has_previous_aid,
      previousAid: line.previous_aid || "",
      functionDescription: line.function_description || "",
      productDescription: line.product_description || "",
      isModification: line.product_selection?.modified || false,
      communicationReasons: line.communication_reasons || "",
      workflowOverride: line.workflow_override || "",
      files: line.files || [],
      fittings: line.fittings || [],
      costing: line.costing,
      vlot_invoice_detail: line.vlot_invoice_detail,
      status: line.status,
      ui: {
        argumentationIsLarge: false,
        notesIsLarge: true,
        isExpanded: !!line.code,
        isDetailsShown: detailsIndex === i,
        isMeasurementInstructionsShown: measurementDetailsIndex === i,
      },
    };
  });
};

export const productsToOrderLines = (
  products: MMOrderLineProductInput[]
): OrderLine[] =>
  products.map((product: MMOrderLineProductInput) => ({
    ...ORDER_LINE_EMPTY,
    code: product.code || "",
    digits: product.digits || [],
    hand: product.hand,
    use:
      product.use && product.use === ProductUse.Day
        ? "DAY"
        : product.use && product.use === ProductUse.Night
          ? "NIGHT"
          : undefined,
    measurements: product.measurements || {},
    product_selection: {
      type: product.name || "",
      size: product.size || "",
      color: product.color || "",
      family: product.family || "",
      modified: product.isModification || false,
    },
    order_type: product.orderType || "",
    remake_reason: product.remakeReason || "",
    replacement_for: product.replacementFor || "",
    argumentation: product.argumentation || "",
    communication_reasons: product.communicationReasons || "",
    has_previous_aid: product.hasPreviousAid,
    previous_aid: product.previousAid || "",
    function_description: product.functionDescription || null,
    product_description: product.productDescription || null,
    workflow_override: product.workflowOverride || "",
    files: product.files || [],
    fittings: product.fittings,
    costing: product.costing || null,
    status: product.status,
  }));

function MMOrderLineEntry(props: MMOrderEntryInputProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);

  const [order, setOrder] = useState<Order | null>(props.order || null);

  const [measurementInfoOpen, setMeasurementInfoOpen] = useState<number | null>(
    null
  );
  const [measurementsMissing, setMeasurementsMissing] = useState<string[][]>(
    []
  );
  const [orderLineProducts, setOrderLineProducts] = useState<
    MMOrderLineProductInput[]
  >(
    props.order && props.order.order_lines && props.order.order_lines.length > 0
      ? orderLinesToProducts(props.order.order_lines, props.products || [])
      : []
  );
  const [openActionsForProduct, setOpenActionsForProduct] = useState<
    number | null
  >(null);

  const [openOrderDetailsForProduct, setOpenOrderDetailsForProduct] = useState<
    number | null
  >(null);

  const [visibleIndices, setVisibleIndices] = useState<number[]>([]);

  const orderDetailsMissing = (
    orderLineProduct: MMOrderLineProductInput,
    orderLineInputs?: OrderLineInputs
  ) => {
    // console.log("orderDetailsMissing");
    if (!props.showValidation) return false;
    if (!orderLineInputs) return false;
    if (
      orderLineInputs.order_type &&
      orderLineInputs.order_type.mandatory &&
      !orderLineProduct.orderType
    )
      return true;
    if (
      orderLineInputs.remake_reason &&
      orderLineInputs.remake_reason.mandatory &&
      !orderLineProduct.remakeReason
    )
      return true;
    if (
      orderLineInputs.replacement_for &&
      orderLineInputs.replacement_for.mandatory &&
      !orderLineProduct.replacementFor
    )
      return true;
  };

  const updateMeasurementInstructionsOpen = (
    orderLineProduct: MMOrderLineProductInput,
    i: number,
    isShown: boolean = true
  ) => {
    console.log("updateMeasurementInstructionsOpen", i, isShown);
    setMeasurementInfoOpen(isShown && measurementInfoOpen !== i ? i : null);

    onProductChanged(
      {
        ...orderLineProduct,
        ui: {
          ...orderLineProduct.ui,
          isMeasurementInstructionsShown: isShown
            ? !orderLineProduct.ui?.isMeasurementInstructionsShown
            : false,
        },
      },
      i
    );
  };

  const updateDetailOpen = (
    orderLineProduct: MMOrderLineProductInput,
    i: number,
    isShown: boolean = true
  ) => {
    setOpenOrderDetailsForProduct(
      isShown && openOrderDetailsForProduct !== i ? i : null
    );

    onProductChanged(
      {
        ...orderLineProduct,
        ui: {
          ...orderLineProduct.ui,
          isDetailsShown: isShown
            ? !orderLineProduct.ui?.isDetailsShown
            : false,
        },
      },
      i
    );
  };

  useEffect(() => {
    if (
      props.inputs &&
      props.inputs.order_lines &&
      props.order &&
      props.order.order_lines
    ) {
      if (props.showValidation) {
        let missingMeasurements: string[][] = [];
        for (let i = 0; i < props.inputs.order_lines.length; i++) {
          let missingMeasurement: string[] = [];
          const orderLineInputs: OrderLineInputs = props.inputs.order_lines[i];
          if (orderLineInputs.measurements && props.order.order_lines[i]) {
            const measurementInputs: any = { ...orderLineInputs.measurements };
            for (const key in measurementInputs) {
              const input: Input = measurementInputs[key] as Input;
              if (
                input &&
                input.mandatory &&
                !props.order.order_lines[i].measurements[key]
              ) {
                missingMeasurement.push(key);
              }
            }
          }
          missingMeasurements.push(missingMeasurement);
        }
        setMeasurementsMissing(missingMeasurements);
      } else {
        setMeasurementsMissing([]);
      }
    }
    setVisibleIndices((old: number[]) => props.visibleIndices || []);
  }, [props.inputs, props.order, props.showValidation, props.visibleIndices]);

  useEffect(() => {
    console.log("reset");
    setOrder(props.order || null);
    setOrderLineProducts(
      props.order &&
        props.order.order_lines &&
        props.order.order_lines.length > 0
        ? orderLinesToProducts(
            props.order.order_lines,
            props.products || [],
            openOrderDetailsForProduct,
            measurementInfoOpen
          )
        : []
    );
  }, [props.order, openOrderDetailsForProduct]);

  const onOrderLinesChanged = (changedProducts: MMOrderLineProductInput[]) => {
    if (props.onChange) {
      props.onChange({
        ...ORDER_EMPTY,
        ...order,
        order_lines: productsToOrderLines(changedProducts),
      });
    } else {
      setOrderLineProducts(
        (products: MMOrderLineProductInput[]) => changedProducts
      );
    }
  };

  const onProductChanged = (
    changedProduct: MMOrderLineProductInput,
    i: number
  ) => {
    onOrderLinesChanged(
      orderLineProducts.map((product, j) =>
        i === j ? changedProduct : product
      )
    );
  };

  const onProductDelete = (i: number) => {
    onOrderLinesChanged(
      orderLineProducts.length === 1
        ? []
        : orderLineProducts.filter((product, j) => i !== j)
    );

    setOpenActionsForProduct(null);
  };

  const onProductDuplicate = (i: number) => {
    const duplicated: MMOrderLineProductInput = orderLineProducts[i];
    // delete duplicated.costing;
    console.log("onProductDuplicate", duplicated);
    onOrderLinesChanged(orderLineProducts.concat([duplicated]));
    setOpenActionsForProduct(null);
  };

  const currentMeasurementProductElement = (i: number) => {
    const id: string = `order-line-card-${i}-popover-anchor`;
    const result: any = document.getElementById(id);
    return result;
  };

  const needsScan = (orderLineProduct: MMOrderLineProductInput) => {
    return (
      orderLineProduct.variation &&
      orderLineProduct.variation.measurement_category &&
      orderLineProduct.variation.measurement_category.toLowerCase() ===
        "3d scan"
    );
  };
  const renderPopoverOrderLineProduct = (
    orderLineProduct: MMOrderLineProductInput,
    i: number
  ) => {
    const orderCard = currentMeasurementProductElement(i);

    return orderCard ? (
      <EuiWrappingPopover
        panelPaddingSize="xl"
        ownFocus={false}
        panelClassName="measurement-card"
        button={orderCard}
        anchorPosition="leftCenter"
        container={props.popoverContainer}
        isOpen={true}
        key={`popover-product-${i}`}
        data-index={i}
        closePopover={() => {}}
        offset={75}
        zIndex={6000}
        aria-label="Product Popover"
      >
        {renderOrderLineProduct(orderLineProduct, i)}
      </EuiWrappingPopover>
    ) : (
      <></>
    );
  };

  const renderProductionDetails = (
    orderLineProduct: MMOrderLineProductInput,
    i: number
  ) => {
    const replacementForCode: string =
      props.clientProductions && Array.isArray(props.clientProductions)
        ? (props.clientProductions || []).find(
            (production: any) =>
              production.ref === orderLineProduct.replacementFor
          )?.code
        : "";

    let showReplacementCode: boolean =
      !!props.showProductionDetails &&
      !!orderLineProduct.replacementFor &&
      !!props.clientProductions;

    const remakeReason: string = (props.remakeReasons || []).find(
      (reason: any) => reason.code === orderLineProduct.remakeReason
    )?.reason;

    return props.showProductionDetails ? (
      <Fragment>
        <EuiFlexItem>
          <EuiHorizontalRule margin="s" />
        </EuiFlexItem>
        <EuiFlexGrid gutterSize="m">
          {showReplacementCode ? (
            <EuiFlexItem grow={true}>
              <EuiFlexGroup
                gutterSize="xs"
                alignItems="center"
                justifyContent="spaceBetween"
              >
                <EuiFlexItem grow={true}>
                  <EuiText
                    size="xs"
                    style={{ textAlign: "right", whiteSpace: "nowrap" }}
                  >
                    {remakeReason || txt.get("orders.order.replaces_product")}:{" "}
                    {replacementForCode || txt.get("generic.unknown")}
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiCopy textToCopy={replacementForCode}>
                    {(copy) => (
                      <EuiButtonIcon
                        size="xs"
                        onClick={copy}
                        iconType="copy"
                        aria-label={txt.get("generic.copy")}
                      />
                    )}
                  </EuiCopy>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          ) : (
            <></>
          )}

          <EuiFlexItem>
            <MMOrderAdditionalCommunication
              isEditable={props.isEditable}
              onChange={(changedProduct: MMOrderLineProductInput) =>
                onProductChanged(changedProduct, i)
              }
              communicationReasons={props.communicationReasons || []}
              selection={orderLineProduct}
              order={order}
              showTitle={false}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <MMOrderWorkflowOverride
              isEditable={props.isEditable}
              onChange={(changedProduct: MMOrderLineProductInput) =>
                onProductChanged(changedProduct, i)
              }
              workflows={props.workflowNames || []}
              selection={orderLineProduct}
              order={order}
              showTitle={false}
            />
          </EuiFlexItem>
        </EuiFlexGrid>
      </Fragment>
    ) : (
      <></>
    );
  };
  const renderOrderLineProduct = (
    orderLineProduct: MMOrderLineProductInput,
    i: number
  ) => (
    <EuiFlexItem key={`product-${i}`} grow={false}>
      <EuiFlexGroup gutterSize="xl">
        {!!props.measurementsOnly ? (
          <></>
        ) : (
          <EuiFlexItem grow={false}>
            {i === 0 ? (
              <Fragment>
                <EuiSpacer size="l" />
                <EuiSpacer size="xs" />
              </Fragment>
            ) : (
              <Fragment>
                <EuiSpacer size="s" />
              </Fragment>
            )}

            <EuiIcon
              style={{ cursor: "pointer" }}
              type={
                orderLineProduct.ui?.isExpanded ? "arrowDown" : "arrowRight"
              }
              onClick={() => {
                console.log("on icon click");
                onProductChanged(
                  {
                    ...orderLineProduct,
                    ui: {
                      argumentationIsLarge:
                        orderLineProduct.ui?.argumentationIsLarge,
                      notesIsLarge: orderLineProduct.ui?.notesIsLarge,
                      isExpanded: !orderLineProduct.ui?.isExpanded,
                    },
                  },
                  i
                );
              }}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiFlexGroup
            direction={isMobile ? "row" : "column"}
            gutterSize="m"
            justifyContent="flexStart"
            wrap={true}
          >
            <EuiFlexItem
              grow={false}
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <EuiPopover
                anchorPosition="upRight"
                offset={25}
                panelPaddingSize="l"
                closePopover={() =>
                  updateMeasurementInstructionsOpen(orderLineProduct, i, false)
                }
                isOpen={orderLineProduct.ui?.isMeasurementInstructionsShown}
                button={
                  <EuiIcon
                    size="l"
                    color="#909090"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      updateMeasurementInstructionsOpen(orderLineProduct, i)
                    }
                    type="iInCircle"
                  />
                }
              >
                <MMProductMeasurementInstructions
                  hideImage={true}
                  product={
                    orderLineProduct && orderLineProduct.code && props.products
                      ? props.products.find(
                          (product: any) =>
                            product.code === orderLineProduct.code ||
                            product.legacy_product_code ===
                              orderLineProduct.code
                        )
                      : undefined
                  }
                />
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize="m" wrap={true} alignItems="flexStart">
                {!!props.measurementsOnly ? (
                  <></>
                ) : (
                  <EuiFlexItem>
                    <MMOrderProduct
                      isEditable={props.isEditable}
                      products={props.products}
                      selection={orderLineProduct}
                      onChange={(changedProduct: MMOrderLineProductInput) =>
                        onProductChanged(
                          {
                            ...changedProduct,
                            ui: {
                              argumentationIsLarge:
                                orderLineProduct.ui?.argumentationIsLarge,
                              notesIsLarge: orderLineProduct.ui?.notesIsLarge,
                              isExpanded: !!changedProduct.code,
                            },
                          },
                          i
                        )
                      }
                      order={order}
                      showTitle={i === 0}
                    />
                  </EuiFlexItem>
                )}
                {!!props.measurementsOnly ? (
                  <></>
                ) : (
                  <EuiFlexItem
                    grow={false}
                    style={{ flexBasis: 0, minWidth: "52px" }}
                  >
                    <MMOrderHand
                      isEditable={props.isEditable}
                      onChange={(changedProduct: MMOrderLineProductInput) =>
                        onProductChanged(changedProduct, i)
                      }
                      selection={orderLineProduct}
                      order={order}
                      showTitle={i === 0}
                    />
                  </EuiFlexItem>
                )}
                {!!props.measurementsOnly ? (
                  <> </>
                ) : (
                  <EuiFlexItem
                    grow={false}
                    style={{
                      flexBasis: 0,
                      minWidth: "130px",
                    }}
                  >
                    <MMOrderDigits
                      isEditable={props.isEditable}
                      onChange={(changedProduct: MMOrderLineProductInput) =>
                        onProductChanged(changedProduct, i)
                      }
                      selection={orderLineProduct}
                      order={order}
                      showTitle={!!props.measurementsOnly || i === 0}
                    />
                  </EuiFlexItem>
                )}
                {!orderLineProduct.variation ||
                orderLineProduct.variation.measurements.length === 0 ||
                (orderLineProduct.variation.measurements.length === 1 &&
                  orderLineProduct.variation.measurements[0] === "notes") ? (
                  <></>
                ) : (
                  <EuiFlexItem grow={false}>
                    <MMOrderMeasurements
                      isEditable={props.isEditable}
                      onChange={(changedProduct: MMOrderLineProductInput) =>
                        onProductChanged(changedProduct, i)
                      }
                      selection={orderLineProduct}
                      order={order}
                      direction="row"
                      showTitle={!!props.measurementsOnly || i === 0}
                      measurementsShown={props.measurementsShown}
                      hideNonOptions={props.hideNonOptions}
                      measurementsMissing={
                        measurementsMissing[i] ? measurementsMissing[i] : []
                      }
                    />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
            {orderLineProduct.ui?.isExpanded ? (
              <EuiFlexItem grow={false}>
                <EuiFlexGroup
                  gutterSize="s"
                  direction="column"
                  justifyContent="flexStart"
                >
                  {!!props.measurementsOnly ? (
                    <></>
                  ) : (
                    <EuiFlexItem grow={false}>
                      <EuiFlexGroup
                        direction="column"
                        justifyContent="flexStart"
                        gutterSize="m"
                      >
                        <EuiFlexItem grow={false}>
                          <MMOrderIsModification
                            isEditable={props.isEditable}
                            onChange={(
                              changedProduct: MMOrderLineProductInput
                            ) => onProductChanged(changedProduct, i)}
                            selection={orderLineProduct}
                            order={order}
                            showTitle={false}
                            products={props.products}
                          />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <MMOrderNotes
                            isEditable={props.isEditable}
                            onChange={(
                              changedProduct: MMOrderLineProductInput
                            ) => onProductChanged(changedProduct, i)}
                            selection={orderLineProduct}
                            order={order}
                            showTitle={false}
                            onFocus={() =>
                              onProductChanged(
                                {
                                  ...orderLineProduct,
                                  ui: {
                                    argumentationIsLarge: false,
                                    notesIsLarge: true,
                                    isExpanded: orderLineProduct.ui?.isExpanded,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </EuiFlexItem>

                        <EuiFlexItem grow={false}>
                          <MMOrderArgumentation
                            isEditable={props.isEditable}
                            onChange={(
                              changedProduct: MMOrderLineProductInput
                            ) => onProductChanged(changedProduct, i)}
                            selection={orderLineProduct}
                            order={order}
                            showTitle={false}
                            onFocus={() =>
                              onProductChanged(
                                {
                                  ...orderLineProduct,
                                  ui: {
                                    argumentationIsLarge: true,
                                    notesIsLarge: false,
                                    isExpanded: orderLineProduct.ui?.isExpanded,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  )}
                  <EuiFlexItem grow={false}>
                    {needsScan(orderLineProduct) ? (
                      <Fragment>
                        <EuiTitle size="xs">
                          <EuiText color="#36786a">
                            {txt.get("orders.order.make_scan")}
                          </EuiText>
                        </EuiTitle>
                        <EuiText className="scan-steps">
                          {txt.html("orders.order.make_scan_steps")}
                        </EuiText>
                        <EuiSpacer size="xl" />
                      </Fragment>
                    ) : (
                      <MMProductMeasurementInstructions
                        hideText={!needsScan(orderLineProduct)}
                        hideImage={needsScan(orderLineProduct)}
                        product={
                          orderLineProduct &&
                          orderLineProduct.code &&
                          props.products
                            ? props.products.find(
                                (product: any) =>
                                  product.code === orderLineProduct.code ||
                                  product.legacy_product_code ===
                                    orderLineProduct.code
                              )
                            : undefined
                        }
                      />
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        {!!props.measurementsOnly ? (
          <></>
        ) : (
          <EuiFlexItem grow={false}>
            <EuiPopover
              id={`product-actions-${i}`}
              button={
                <EuiIcon
                  type="boxesVertical"
                  onClick={(e: any) => {
                    setOpenActionsForProduct(i);
                  }}
                />
              }
              isOpen={openActionsForProduct === i}
              closePopover={() => setOpenActionsForProduct(null)}
              panelPaddingSize="none"
              anchorPosition="leftUp"
            >
              <EuiContextMenuPanel
                size="s"
                items={[
                  <EuiContextMenuItem
                    key="edit"
                    icon="copy"
                    onClick={(e: any) => {
                      onProductDuplicate(i);
                    }}
                  >
                    {txt.get("generic.duplicate")}
                  </EuiContextMenuItem>,
                  <EuiContextMenuItem
                    key="delete"
                    icon="cross"
                    onClick={(e: any) => {
                      onProductDelete(i);
                    }}
                  >
                    {txt.get("generic.delete")}
                  </EuiContextMenuItem>,
                  // <EuiContextMenuItem key="add" icon="plus">
                  //   {txt.get("generic.add")}
                  // </EuiContextMenuItem>,
                ]}
              />
            </EuiPopover>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l">
        {props.showProductionDetails ? (
          <EuiFlexItem>
            <EuiSpacer size="xs" />

            <EuiPopover
              id={`order-details-${i}`}
              button={
                <EuiButtonEmpty
                  style={{
                    color: orderDetailsMissing(
                      orderLineProduct,
                      props.inputs?.order_lines[i]
                    )
                      ? "#ff3333"
                      : orderLineProduct.orderType
                        ? "#303030"
                        : "#aaa",
                  }}
                  onClick={(e: any) => {
                    updateDetailOpen(orderLineProduct, i);
                  }}
                  size="xs"
                >
                  {orderLineProduct.orderType ||
                    txt.get("orders.order.order_details")}
                </EuiButtonEmpty>
              }
              isOpen={orderLineProduct.ui?.isDetailsShown}
              closePopover={() => {
                updateDetailOpen(orderLineProduct, i, false);
              }}
              anchorPosition="leftDown"
              offset={45}
            >
              <EuiFlexItem style={{ width: "300px" }}>
                <EuiFlexGrid columns={1} gutterSize="l">
                  <MMOrderType
                    isEditable={props.isEditable}
                    onChange={(changedProduct: MMOrderLineProductInput) =>
                      onProductChanged(changedProduct, i)
                    }
                    orderTypes={props.orderTypes}
                    selection={orderLineProduct}
                    order={order}
                    showTitle={true}
                  />
                  <MMRemakeReason
                    isEditable={props.isEditable}
                    onChange={(changedProduct: MMOrderLineProductInput) =>
                      onProductChanged(changedProduct, i)
                    }
                    remakeReasons={props.remakeReasons}
                    selection={orderLineProduct}
                    orderTypes={props.orderTypes}
                    order={order}
                    showTitle={true}
                  />
                  <MMOrderReplacementFor
                    isEditable={props.isEditable}
                    onChange={(changedProduct: MMOrderLineProductInput) =>
                      onProductChanged(changedProduct, i)
                    }
                    clientProductions={props.clientProductions}
                    selection={orderLineProduct}
                    orderTypes={props.orderTypes}
                    order={order}
                    showTitle={true}
                  />
                </EuiFlexGrid>
              </EuiFlexItem>
            </EuiPopover>
          </EuiFlexItem>
        ) : (
          <></>
        )}
        {needsScan(orderLineProduct) ? (
          <EuiFlexItem>
            <EuiButton
              onClick={() => {
                if (props.onScanInstructionsChange)
                  props.onScanInstructionsChange();
              }}
              size="s"
              fill={true}
              color="accent"
            >
              {txt.get("orders.order.send_order_to_scanner")}
            </EuiButton>{" "}
          </EuiFlexItem>
        ) : i >= orderLineProducts.length - 1 ? (
          <></>
        ) : (
          <EuiFlexItem>
            <EuiButton
              onClick={() => {
                if (props.onProductSelect)
                  props.onProductSelect({ data_order_line_index: i + 1 }, true);
              }}
              size="s"
              fill={true}
              color="accent"
              data-testid="product-next-button"
            >
              {txt.get("generic.next")}
            </EuiButton>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>

      {renderProductionDetails(orderLineProduct, i)}
    </EuiFlexItem>
  );

  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="m"
      style={
        props.maxHeight
          ? {
              overflowY: "auto",
              maxHeight: "calc(100vh - 460px)",
            }
          : {}
      }
    >
      {orderLineProducts.length > 0 ? (
        orderLineProducts.map(
          (orderLineProduct: MMOrderLineProductInput, i: number) =>
            !visibleIndices
              ? renderOrderLineProduct(orderLineProduct, i)
              : visibleIndices.includes(i)
                ? renderPopoverOrderLineProduct(orderLineProduct, i)
                : null
        )
      ) : (
        <Fragment>
          <EuiSpacer size="s" />
          <EuiSpacer size="s" />
          <EuiText size="s" color="subdued">
            {txt.get("orders.order.choose_products")}
          </EuiText>
        </Fragment>
      )}
      {!!props.measurementsOnly ? (
        <></>
      ) : (
        <EuiFlexItem>
          <span>
            <EuiButton
              size="s"
              fullWidth={false}
              onClick={(e: any) =>
                setOrderLineProducts((products: MMOrderLineProductInput[]) =>
                  products.concat(EMPTY_PRODUCT_INPUT)
                )
              }
              iconSide="left"
              iconType="plus"
              fill
            >
              {txt.get("orders.order.add_product")}
            </EuiButton>
          </span>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
}

export default MMOrderLineEntry;
