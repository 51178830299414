import {
  EuiButton,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import ClientsAPIHelper from "api/clients-api.helper";
import ConnectAPIHelper from "api/connect-api-helper";
import DateHelper from "helpers/date-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";

function MMData() {
  const dispatch = useDispatch();
  const api = new ConnectAPIHelper();
  const [isLoading, setIsLoading] = useState(false);
  const [validationText, setValidationText] = useState("");

  const uploadClientIds = async () => {
    const path = "clients/ids";
    const key = "ids_file";

    const fileUploadHelper = new FileUploadHelper();
    fileUploadHelper.setApi(new ClientsAPIHelper());
    try {
      const result = await fileUploadHelper.uploadFile(
        path,
        key,
        (info: string) => {
          console.log("uploadinfo", info);
          if (info === "uploading") {
            setIsLoading(true);
          }
        }
      );
      console.log("uploadClientIds result", result);
      dispatch(toastAdd(txt.get("admin.data.client_codes_uploaded")));
    } catch (message: any) {
      dispatch(
        toastAdd(
          txt.get("admin.data.client_codes_upload_failed"),
          message,
          "danger"
        )
      );
    }
    setIsLoading(false);
  };

  const uploadInvoiceDetails = async () => {
    const path = "invoices/details";
    const key = "invoice_details_file";

    const fileUploadHelper = new FileUploadHelper();
    fileUploadHelper.setApi(api);
    try {
      const result = await fileUploadHelper.uploadFile(
        path,
        key,
        (info: string) => {
          console.log("uploadinfo", info);
          if (info === "uploading") {
            setIsLoading(true);
          }
        }
      );
      console.log("uploadInvoiceDetails result", result);
      dispatch(toastAdd(txt.get("admin.data.invoice_details_uploaded")));
    } catch (message: any) {
      dispatch(
        toastAdd(
          txt.get("admin.data.invoice_details_upload_failed"),
          message,
          "danger"
        )
      );
    }
    setIsLoading(false);
  };

  const downloadXLS = async () => {
    setIsLoading(true);
    const result = await api.getXLSExport();
    // const buffer =  Buffer.from(result, "base64").toString();
    const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result}`;

    FileUploadHelper.triggerDownloadFile(
      url,
      `export_${DateHelper.toSortable()}.xlsx`
    );
    setIsLoading(false);
  };

  const downloadTranslationsXLS = async () => {
    setIsLoading(true);
    const result = await api.getTranslations("xls");
    // const buffer =  Buffer.from(result, "base64").toString();
    const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result}`;

    FileUploadHelper.triggerDownloadFile(
      url,
      `translations_${DateHelper.toSortable()}.xlsx`
    );
    setIsLoading(false);
  };

  const updateProductionNotes = async () => {
    setIsLoading(true);
    const result = await api.getProductionNotesUpdates();
    if (result) {
      dispatch(
        toastAdd(
          txt.get("admin.data.updated_production_notes"),
          null,
          "success"
        )
      );
    } else {
      dispatch(
        toastAdd(txt.get("admin.data.update_notes_failed"), null, "danger")
      );
    }
    setIsLoading(false);
  };

  const updateProductionStatusses = async () => {
    setIsLoading(true);
    const result = await api.getProductionStatusUpdates();
    if (result) {
      dispatch(
        toastAdd(txt.get("admin.data.updated_production"), null, "success")
      );
    } else {
      dispatch(toastAdd(txt.get("admin.data.update_failed"), null, "danger"));
    }
    setIsLoading(false);
  };

  const updateProductionData = async () => {
    setIsLoading(true);
    const result = await api.getProductionData();
    if (result) {
      dispatch(
        toastAdd(txt.get("admin.data.updated_production"), null, "success")
      );
    } else {
      dispatch(toastAdd(txt.get("admin.data.update_failed"), null, "danger"));
    }
    setIsLoading(false);
  };

  const reloadData = async () => {
    setIsLoading(true);

    const result = await api.getDataToReload();
    if (result) {
      dispatch(toastAdd(txt.get("admin.data.reloaded"), null, "success"));
    } else {
      dispatch(toastAdd(txt.get("admin.data.reload_failed"), null, "danger"));
    }
    setIsLoading(false);
  };

  const reloadDrawerCheckData = async () => {
    setIsLoading(true);

    const result = await api.reloadDrawerCheckData();
    if (result) {
      dispatch(toastAdd(txt.get("admin.data.reloaded"), null, "success"));
    } else {
      dispatch(toastAdd(txt.get("admin.data.reload_failed"), null, "danger"));
    }

    setIsLoading(false);
  };

  const validateProducts = async (set?: string) => {
    setIsLoading(true);
    const result = await api.getProductsToValidate(set);
    if (result) {
      let validationResultText: string = ``;
      if (result.validations.errors && result.validations.errors.length > 0) {
        validationResultText +=
          `
${txt.get("generic.x_errors", result.validations.warnings.length)}
- ` +
          result.validations.errors.join(`
- `) +
          `

`;
      } else {
        validationResultText += `
No Errors

`;
      }
      if (
        result.validations.warnings &&
        result.validations.warnings.length > 0
      ) {
        validationResultText +=
          `
${txt.get("generic.x_warnings", result.validations.warnings.length)} 
- ` +
          result.validations.warnings.join(`
- `);
      } else {
        validationResultText += `
No Warnings
  `;
      }
      setValidationText(validationResultText);

      dispatch(toastAdd(txt.get("admin.data.validated"), null, "success"));
    } else {
      dispatch(
        toastAdd(txt.get("admin.data.validation_error"), null, "danger")
      );
    }

    setIsLoading(false);
  };

  return (
    <EuiFlexItem>
      {isLoading ? (
        <EuiFlexItem>
          <EuiProgress color="accent" size="s" style={{ height: "2px" }} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiSpacer />
          <EuiText>
            <EuiButton
              iconType="download"
              size="s"
              disabled={isLoading}
              onClick={() => downloadXLS()}
            >
              {txt.get("admin.data.download")}
            </EuiButton>{" "}
            <EuiButton
              iconType="download"
              size="s"
              disabled={isLoading}
              onClick={() => downloadTranslationsXLS()}
            >
              {txt.get("admin.data.download_translations")}
            </EuiButton>
            <br />
            <br />
            <br />
            {/* <EuiDatePicker
          selected={productionFromDate}
          onChange={(moment: any) => setProductionFromDate(moment)}
        ></EuiDatePicker> */}
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => updateProductionData()}
            >
              1. {txt.get("admin.data.update_production_data")}
            </EuiButton>
            &nbsp;
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => updateProductionStatusses()}
            >
              2. {txt.get("admin.data.update_production")}
            </EuiButton>
            &nbsp;
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => updateProductionNotes()}
            >
              3. {txt.get("admin.data.update_production_notes")}
            </EuiButton>
            <br />
            <br />
            <EuiSpacer />
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => uploadClientIds()}
            >
              {txt.get("admin.data.upload_client_codes")}
            </EuiButton>
            &nbsp;
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => uploadInvoiceDetails()}
            >
              {txt.get("admin.data.upload_invoice_details")}
            </EuiButton>
            <br />
            <br />
            <EuiSpacer />
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => reloadData()}
            >
              {txt.get("admin.data.reload")}
            </EuiButton>
            &nbsp;
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => reloadDrawerCheckData()}
            >
              {txt.get("admin.data.reload_drawer_check")}
            </EuiButton>
            <br />
            <br />
            <EuiSpacer />
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => validateProducts()}
            >
              {txt.get("admin.data.validate")}
            </EuiButton>
            &nbsp;
            <EuiButton
              size="s"
              disabled={isLoading}
              onClick={() => validateProducts("enabled")}
            >
              {txt.get("admin.data.validate_enabled")}
            </EuiButton>
            &nbsp;
            <br />
            <br />
            {validationText ? (
              <Fragment>
                <EuiSpacer size="m" />
                <EuiButton size="s" onClick={() => setValidationText("")}>
                  {txt.get("admin.data.clear")}
                </EuiButton>
                <EuiSpacer size="s" />
                <EuiCodeBlock isCopyable={true}>{validationText}</EuiCodeBlock>;
              </Fragment>
            ) : (
              <></>
            )}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem></EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  );
}

export default MMData;
