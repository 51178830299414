import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { RootState /* AppThunk */ } from "store/store";

export interface Event {
  id: string;
  topic: string;
  payload: any;
}

export interface EventState {
  events: Event[];
}

const initialState: EventState = {
  events: [],
};

// const enum EventType {
//   Any = "#",

//   AnyConnect = "connect.#",
//   AnyManox = "manox.#",
//   AnyOrder = "connect.order.#",
//   AnyDevice = "manox.device.#",
//   AnyScan = "manox.scan.#",
//   AnyWatchdog = "manox.watchdog.#",

//   OrderSaved = "connect.order.saved",
//   OrderStatusChanged = "connect.order.status_changed",
//   OrderDeleted = "connect.order.deleted",
//   OrderProductionStatusChanged = "connect.order.production.status_changed",
//   OrderLineStatusChanged = "connect.order.line.status_changed",

//   DeviceRestarted = "manox.device.restarted",
//   DeviceShutdown = "manox.device.shutdown",
//   DeviceStarted = "manox.device.started",
//   ScanAccepted = "manox.scan.accepted",
//   ScanCaptured = "manox.scan.captured",
//   ScanDownloaded = "manox.scan.downloaded",
//   ScanDownloading = "manox.scan.downloading",
//   ScanProcessingAligned = "manox.scan.processing.aligned",
//   ScanProcessingDownloaded = "manox.scan.processing.downloaded",
//   ScanProcessingFailed = "manox.scan.processing.failed",
//   ScanProcessingFinished = "manox.scan.processing.finished",
//   ScanProcessingInitialized = "manox.scan.processing.initialized",
//   ScanProcessingLicenceImported = "manox.scan.processing.licence-imported",
//   ScanProcessingLogsUploaded = "manox.scan.processing.logs.uploaded",
//   ScanProcessingMarkersImported = "manox.scan.processing.markers-imported",
//   ScanProcessingModelExported = "manox.scan.processing.model.exported",
//   ScanProcessingModelGenerated = "manox.scan.processing.model.generated",
//   ScanProcessingModelOptimized = "manox.scan.processing.model.optimized",
//   ScanProcessingModelUploaded = "manox.scan.processing.model.uploaded",
//   ScanProcessingQueued = "manox.scan.processing.queued",
//   ScanProcessingRcRegionSet = "manox.scan.processing.rc-region-set",
//   ScanProcessingStarted = "manox.scan.processing.started",
//   ScanProcessingThumbnailsGenerated = "manox.scan.processing.thumbnails.generated",
//   ScanProcessingVerified = "manox.scan.processing.verified",
//   ScanProcessingWarning = "manox.scan.processing.warning",
//   ScanStarted = "manox.scan.started",
//   ScanUpdated = "manox.scan.updated",
//   ScanUploadAborted = "manox.scan.upload.aborted",
//   ScanUploadFailed = "manox.scan.upload.failed",
//   ScanUploadFinished = "manox.scan.upload.finished",
//   ScanUploadPending = "manox.scan.upload.pending",
//   ScanUploadRequested = "manox.scan.upload.requested",
//   ScanUploadResumed = "manox.scan.upload.resumed",
//   ScanUploadStarted = "manox.scan.upload.started",
//   WatchdogMeasureAborted = "manox.watchdog.measure.aborted",
//   WatchdogMeasureExecuted = "manox.watchdog.measure.executed",
//   WatchdogWatchersNodesChecked = "manox.watchdog.watchers.nodes.checked",
//   WatchdogWatchersSlavesChecked = "manox.watchdog.watchers.slaves.checked",
// }

export const isScanEvent = (event: Event) => {
  return event.topic.indexOf("manox/scan") >= 0;
};

export const isOrderEvent = (event: Event) => {
  return event.topic.indexOf("connect/order") >= 0;
};

export const isScannerEvent = (event: Event) => {
  return event.topic.indexOf("manox/device") >= 0;
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    eventAdd: {
      reducer(state, action: PayloadAction<Event>) {
        state.events.push(action.payload);
      },
      prepare(topic, payload) {
        return {
          payload: {
            id: nanoid(),
            topic,
            payload,
          },
        };
      },
    },
    eventRemove: {
      reducer(state, action: PayloadAction<string>) {
        state.events = state.events.filter(
          (event) => event.id !== action.payload
        );
      },
      prepare(id) {
        return { payload: id };
      },
    },
  },
});

export const { eventAdd, eventRemove } = eventSlice.actions;

export const eventsGet = (state: RootState) => state.event.events;
export const eventfirstGet = (state: RootState) =>
  state.event.events.length > 0 ? state.event.events[0] : undefined;
export const eventLastGet = (state: RootState) =>
  state.event.events.length > 0
    ? state.event.events[state.event.events.length - 1]
    : undefined;

export default eventSlice.reducer;
