import { EuiFlexGroup, EuiProgress } from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { ApiResponseStatus } from "api/api-helper";
import AuthAdminAPIHelper from "api/auth-admin-api-helper";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import MMPermission from "./permission";
import MMPolicy from "./policy";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

const policySorter = (a: any, b: any) => {
  if (a.type === "group" && b.type === "role") {
    return -1;
  } else if (a.type === "role" && b.type === "group") {
    return 1;
  } else if (b.type === "group" && a.type === "role") {
    return 1;
  } else if (b.type === "role" && a.type === "group") {
    return -1;
  } else if (a.name === "groups") {
    return -1;
  } else if (b.name === "groups") {
    return 1;
  } else if (a.name === "admin") {
    return 1;
  } else if (b.name === "admin") {
    return -1;
  } else {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  }
};

function MMPermissionsGrid() {
  const api = new AuthAdminAPIHelper();
  const [permissions, setPermissions] = useState<any>({});
  //the things that we want to have policies for, which we believe are groups and realm roles for now
  const [policiables, setPoliciables] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPermissions = async () => {
      setIsLoading(true);
      api.mute(true);
      const policiablesResult = await api.getPoliciables();
      const permissionsResult = await api.getPermissions();
      api.mute(false);
      policiablesResult.sort(policySorter);
      console.log("permissionsResult", permissionsResult);
      setPoliciables(policiablesResult);
      setPermissions(permissionsResult);
      setIsLoading(false);
    };

    loadPermissions();
  }, []);

  useEffect(() => {}, [policiables]);

  const deletePermissions = async (clientId: string, ids: string[]) => {
    let updatedPermissions: any = { ...permissions };
    updatedPermissions[clientId] = {
      id: permissions[clientId].id,
      permissions: permissions[clientId].permissions.filter(
        (permission: any) => !ids.includes(permission.id)
      ),
      policies: [...permissions[clientId].policies],
      scopes: [...permissions[clientId].scopes],
      resources: [...permissions[clientId].resources],
    };

    setPermissions(updatedPermissions);
  };

  const updatePermissions = async (clientId: string, ids: string[]) => {
    let updatedPermissions: any = { ...permissions };

    let updated: any[] = [];
    for (let i = 0; i < permissions[clientId].permissions.length; i++) {
      let permission: any = permissions[clientId].permissions[i];
      if (ids.includes(permission.id)) {
        permission = await api.getClientPermissionPermission(
          permissions[clientId].id,
          permission.id
        );
      }
      updated.push(permission);
    }

    updatedPermissions[clientId] = {
      id: permissions[clientId].id,
      permissions: updated,
      policies: [...permissions[clientId].policies],
      scopes: [...permissions[clientId].scopes],
      resources: [...permissions[clientId].resources],
    };

    setPermissions(updatedPermissions);
  };
  const addPermissions = async (clientId: string, ids: string[]) => {
    let updatedPermissions: any = { ...permissions };

    let updated: any[] = [...permissions[clientId].permissions];
    for (let i = 0; i < ids.length; i++) {
      const permissionId = ids[i];
      updated.push(
        await api.getClientPermissionPermission(
          permissions[clientId].id,
          permissionId
        )
      );
    }

    updatedPermissions[clientId] = {
      id: permissions[clientId].id,
      permissions: updated,
      policies: [...permissions[clientId].policies],
      scopes: [...permissions[clientId].scopes],
      resources: [...permissions[clientId].resources],
    };

    setPermissions(updatedPermissions);
  };

  const handlePolicyUpdate = async (
    clientId: string,
    policiable: any,
    isPoliciable: boolean,
    policy?: any
  ) => {
    console.log(
      "handlePolicyUpdate",
      clientId,
      policiable,
      policy,
      isPoliciable
    );
    let id: string = permissions[clientId].id;
    let result;
    if (isPoliciable && !policy) {
      if (policiable.type === "group") {
        result = await api.createPermissionGroupPolicy(
          id,
          policiable.name,
          policiable.id
        );
      } else if (policiable.type === "role") {
        result = await api.createPermissionRolePolicy(
          id,
          policiable.name,
          policiable.id
        );
      }
      if (result.status === ApiResponseStatus.OK) {
        addPolicy(clientId, result.content);
      }
    } else if (!isPoliciable && policy) {
      result = await api.deletePermissionPolicy(id, policy.id);
      if (result.status === ApiResponseStatus.OK) {
        removePolicy(clientId, policy.id);
      }
    }
  };

  const addPolicy = (clientId: string, policy: any) => {
    console.log("addPolicy", clientId, policy);
    let updatedPermissions: any = { ...permissions };
    updatedPermissions[clientId] = {
      id: permissions[clientId].id,
      permissions: [...permissions[clientId].permissions],
      policies: [...permissions[clientId].policies].concat([policy]),
      scopes: [...permissions[clientId].scopes],
      resources: [...permissions[clientId].resources],
    };

    setPermissions(updatedPermissions);
  };

  const removePolicy = (clientId: string, policyId: string) => {
    let updatedPermissions: any = { ...permissions };
    updatedPermissions[clientId] = {
      id: permissions[clientId].id,
      permissions: [...permissions[clientId].permissions],
      policies: [...permissions[clientId].policies].filter(
        (policy: any) => policy.id !== policyId
      ),
      scopes: [...permissions[clientId].scopes],
      resources: [...permissions[clientId].resources],
    };

    setPermissions(updatedPermissions);
  };
  //By hand in Keycloak we combined some scopes in the same permission (meaning multiple checkboxes are stored in the same permission)
  //to keep it consice, this function will make one permission per 1 checkbox, while using, it will delete all permissions found corresponding to the checkbox if it's turned off
  //to it will automatically clean up by using, meaning you can find 1 permission in keycloak per checkbox
  const handlePermissionUpdate = async (
    clientId: string,
    resourceId: string,
    scopeId: string,
    policyId: string,
    isPermitted: boolean
  ) => {
    console.log(
      "handlePermissionUpdate",
      clientId,
      resourceId,
      scopeId,
      policyId,
      isPermitted
    );

    const permissionsToUpdate: any[] = matchingPermissions(
      permissions[clientId].permissions,
      resourceId,
      scopeId,
      policyId
    );
    if (!isPermitted) {
      //if checkbox is turned off, remove permissions and update, or even delete if no valid resource/policy/scope is found
      for (let i = 0; i < permissionsToUpdate.length; i++) {
        const permissionToUpdate = permissionsToUpdate[i];
        //filter out resource/scope/policy combo's from any matching permissions, so the combo doesn't give access anymore
        permissionToUpdate.details.policies =
          permissionToUpdate.details.policies.filter(
            (policy: any) => policy.id !== policyId
          );
        permissionToUpdate.details.resources =
          permissionToUpdate.details.resources.filter(
            (resource: any) => resource._id !== resourceId
          );
        permissionToUpdate.details.scopes =
          permissionToUpdate.details.scopes.filter(
            (scope: any) => scope.id !== scopeId
          );

        if (
          permissionToUpdate.details.policies.length === 0 ||
          permissionToUpdate.details.resources.length === 0 ||
          permissionToUpdate.details.scopes.length === 0
        ) {
          console.log("DELETING PERMISSION", permissionToUpdate);
          //if this means the permission doesn't have a resource/scope/policy that's filled anymore, it's not doing anything anymore, so delete
          const result = await api.deleteClientPermissionPermission(
            permissions[clientId].id,
            permissionToUpdate.id
          );
          console.log(result);
          if (result.content && result.content.success) {
            deletePermissions(clientId, [permissionToUpdate.id]);
          }
        } else {
          console.log("UPDATINGPERMISSION", permissionToUpdate);
          //else, there is still a valid permission setup, so update it
          await api.updateClientPermissionPermission(
            permissions[clientId].id,
            permissionToUpdate.id,
            permissionToUpdate.details.policies,
            permissionToUpdate.details.resources,
            permissionToUpdate.details.scopes
          );
          updatePermissions(clientId, [permissionToUpdate.id]);
        }
      }
    } else {
      //checkbox is turned on, so we will add a permission
      const result = await api.createClientPermissionPermission(
        permissions[clientId].id,
        [
          permissions[clientId].policies.find(
            (policy: any) => policy.id === policyId
          ),
        ],
        [
          permissions[clientId].resources.find(
            (resource: any) => resource._id === resourceId
          ),
        ],
        [
          permissions[clientId].scopes.find(
            (scope: any) => scope.id === scopeId
          ),
        ]
      );
      if (result.content && result.content.id) {
        addPermissions(clientId, [result.content.id]);
      } else {
        console.log("could not find new id for permission");
      }
    }
    //console.log(permissionsToUpdate);
  };

  const matchingPermissions = (
    permissions: any[],
    resourceId: string,
    scopeId: string,
    policyId: string
  ) => {
    const result: any[] = permissions.filter(
      (permission: any) =>
        permission.details.resources.findIndex(
          (resource: any) => resource._id === resourceId
        ) >= 0 &&
        permission.details.scopes.findIndex(
          (scope: any) => scope.id === scopeId
        ) >= 0 &&
        permission.details.policies.findIndex(
          (policy: any) => policy.id === policyId
        ) >= 0
    );
    return result;
  };

  const renderPermissionGrid = () => {
    const clients: string[] = Object.keys(permissions);

    return (
      <table id="permissions-grid">
        <thead>
          <tr>
            <th>{txt.get("admin.permissions.resource")}</th>
            <th>{txt.get("admin.permissions.scope")}</th>
            <th>{txt.get("admin.permissions.name")}</th>
            {policiables.map((policiable: any) => (
              <th
                className="policy-name"
                key={StringHelper.slugify(policiable.id)}
              >
                <span>{policiable.name}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            return [
              <tr className="group" key={client}>
                <td colSpan={2}>
                  <Fragment>{client}</Fragment>
                </td>
                <td style={{ textAlign: "right" }}>
                  {txt.get("admin.permissions.client_policies")}:
                </td>
                {policiables.map((policiable: any) => (
                  <td
                    className="policy-select"
                    key={StringHelper.slugify(policiable.id)}
                  >
                    <MMPolicy
                      clientId={client}
                      policiable={policiable}
                      policy={permissions[client].policies.find(
                        (currentPolicy: any) =>
                          currentPolicy.name === policiable.name &&
                          currentPolicy.type === policiable.type
                      )}
                      onPolicyUpdate={handlePolicyUpdate}
                    />
                  </td>
                ))}
              </tr>,
            ].concat([
              renderClientPermissions(client, permissions[client], policiables),
            ]);
          })}
        </tbody>
      </table>
    );
  };

  const renderClientPermissions = (
    clientId: string,
    permissions: any,
    policiables: any[]
  ) => {
    return permissions.resources.map((resource: any) =>
      [
        <tr className="sub-group" key={resource._id}>
          <td colSpan={3 + policiables.length}>{resource.name}</td>
        </tr>,
      ].concat(
        resource.scopes
          ? resource.scopes
              .sort((a: any, b: any) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
              )
              .map((scope: any) => (
                <tr
                  className="highlight"
                  key={`${resource.owner.id}#${resource._id}#${scope.id}`}
                >
                  <td>{resource.name}</td>
                  <td>{scope.name}</td>
                  <td>{`${resource.name}#${scope.name}`}</td>
                  {policiables.map((policiable: any, i: number) => {
                    const currentPolicy = permissions.policies.find(
                      (currentPolicy: any) =>
                        currentPolicy.name === policiable.name &&
                        currentPolicy.type === policiable.type
                    );

                    return (
                      <td
                        key={i}
                        className={`highlight policy-${policiable.type}`}
                      >
                        {currentPolicy ? (
                          <MMPermission
                            onPermissionUpdate={handlePermissionUpdate}
                            permissions={matchingPermissions(
                              permissions.permissions,
                              resource._id,
                              scope.id,
                              currentPolicy.id
                            )}
                            clientId={clientId}
                            resourceId={resource._id}
                            scopeId={scope.id}
                            policyId={currentPolicy.id}
                          />
                        ) : (
                          <div className="no-check"></div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
          : []
      )
    );
  };
  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
          <EuiProgress size="s" color="accent" />
        </Fragment>
      ) : (
        <EuiFlexGroup alignItems="flexStart">
          {permissions ? renderPermissionGrid() : <></>}
        </EuiFlexGroup>
      )}
    </Fragment>
  );
}

export default MMPermissionsGrid;
