import { EuiLink, EuiSpacer, EuiText } from "@elastic/eui";
import MMPage from "components/layouts/page/page";

function MMQuickLinks() {
  return (
    <MMPage title="Quick Links">
      <EuiSpacer />
      <EuiText>
        <ul>
          <li>
            <EuiLink href="https://www.manometric.nl" target="_blank">
              Manometric Website
            </EuiLink>
          </li>
          <li>
            <EuiLink href="https://www.notion.so" target="_blank">
              Notion
            </EuiLink>
          </li>
          <li>
            <EuiLink href="https://drive.google.com" target="_blank">
              Google Drive
            </EuiLink>
          </li>
          <li>
            <EuiLink href="https://miro.com" target="_blank">
              Miro App
            </EuiLink>
          </li>
          <li>
            <EuiLink href="https://www.exact.com/nl/login" target="_blank">
              Exact Online
            </EuiLink>
          </li>
        </ul>
      </EuiText>
    </MMPage>
  );
}

export default MMQuickLinks;
