import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { Location, LOCATION_EMPTY } from "store/data/location/location";
import { v4 as uuid } from "uuid";
import { adminNav } from "./admin";
import MMLocation from "./location";
function MMAdminLocation() {
  const api = new ConnectAPIHelper();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteRef] = useState("delete_location_" + uuid());
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const { locationId } = useParams();

  const [location, setLocation] = useState<Location | null>(null);

  const isNew: boolean = locationId === "new";
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/locations"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const loadLocation = async () => {
      if (isNew) {
        setLocation(LOCATION_EMPTY);
      } else {
        const locationIdParam: number = Number(locationId);
        if (isNaN(locationIdParam)) {
          navigate("/404");
        } else {
          const result = await api.getAdminLocation(locationIdParam);
          if (result.status === "OK" && result.result && result.result.id) {
            console.log("setting location", result.result);
            setLocation(result.result);
          } else {
            navigate("/404");
          }
        }
      }
    };
    loadLocation();
  }, [locationId]);

  const saveLocation = async (location: Location) => {
    let result;
    if (location.id) {
      result = await api.updateAdminLocation(location.id, location);
    } else {
      result = await api.createAdminLocation(location);
    }
    if (result.status === "OK") {
      if (!location.id) {
        navigate(`/admin/locations/${result.result.location_id}`);
      }

      dispatch(
        toastAdd(
          txt.get("generic.is_saved", txt.get("admin.locations.name")),
          null,
          "success"
        )
      );
    } else {
      dispatch(
        toastAdd(
          result.message
            ? result.message
            : txt.get("admin.locations.error_saving"),
          null,
          "danger"
        )
      );
    }
  };

  const handleDelete = async () => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("admin.locations.name"))}.`,
        txt.get("admin.locations.delete_confirm"),
        deleteRef,
        null,
        null,
        `${txt.get("generic.yes")}, ${txt.lo(
          "generic.delete_x",
          txt.get("admin.locations.name")
        )}`
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === deleteRef
    ) {
      deleteLocation();
    }
  }, [alertConfirm]);

  const deleteLocation = async () => {
    if (locationId && !isNaN(Number(locationId))) {
      await api.deleteAdminLocation(Number(locationId));
      dispatch(
        toastAdd(
          txt.get("generic.is_deleted", txt.get("admin.locations.name")),
          null,
          "success"
        )
      );
    }
    navigate("/admin/locations");
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (
        !isNew &&
        (await AuthenticationHelper.hasPermission(
          "personal_details#delete_all"
        ))
      ) {
        buttons.push(
          <EuiButton
            size="s"
            onClick={handleDelete}
            color="danger"
            iconType={"cross"}
          >
            {txt.uf("generic.delete_x", txt.get("admin.locations.name"))}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.locations.name")}`}
      subTitle={`#${locationId || txt.get("generic.new")}`}
      subPages={subPages}
      backTo="/admin/locations"
      backToText={txt.uf(
        "generic.back_to",
        txt.get("admin.locations.page_title")
      )}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        {location ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <MMLocation handleSubmit={saveLocation} fields={{ location }} />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiProgress size="s" color="accent" />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminLocation;
