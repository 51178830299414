import { useNavigate } from "react-router-dom";

import { EuiButton, EuiSpacer, EuiText } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import txt from "helpers/text-helper";

function MM404() {
  const navigate = useNavigate();
  return (
    <MMPage title={txt.get("404.page_title")}>
      <EuiText>{txt.get("404.intro")}</EuiText>
      <EuiSpacer size="xl" />
      <EuiButton iconType={"arrowLeft"} onClick={() => navigate("/")}>
        {txt.uf("generic.back_to", txt.get("dashboard.page_title"))}
      </EuiButton>
    </MMPage>
  );
}

export default MM404;
