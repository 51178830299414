import {
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPanel,
  EuiText,
  EuiTextArea,
} from "@elastic/eui";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import {
  ClientInsurance,
  toInsuranceDetails,
} from "store/data/client/insurance";

export interface MMInsuranceInfoProps {
  insurance: ClientInsurance | null;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMInsuranceInfo(props: MMInsuranceInfoProps) {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown] = useState<boolean>(false);
  const [insurance, setInsurance] = useState<any>();

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.insurance) {
      setInsurance(props.insurance);
    } else {
      setInsurance(undefined);
    }
  }, [props.insurance, props.isEditable, props.isHeaderShown]);

  return insurance ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <EuiFlexGroup justifyContent="spaceBetween">
          <MMTitle text={txt.get("clients.insurance_info.name")} />
          <EuiButtonIcon aria-label="refresh" iconType={"refresh"} />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize="xs"
            style={{ maxWidth: "400px" }}
            alignItems="center"
          >
            <EuiFlexItem grow={8}>
              <EuiFormRow
                display="rowCompressed"
                label={
                  isHeaderShown
                    ? txt.get("clients.insurance_info.details")
                    : undefined
                }
                title={txt.get("clients.insurance_info.details")}
              >
                <EuiTextArea
                  rows={2}
                  placeholder={txt.get("clients.insurance_info.details")}
                  compressed={true}
                  disabled={!isEditable}
                  name="insurance_details"
                  value={insurance ? toInsuranceDetails(insurance) : ""}
                  onChange={(e) => {}}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              {insurance ? (
                <EuiText size="xs" textAlign="right" color="subdued">
                  {txt.get("clients.insurance_info.reference_date")}
                  <br />
                  {`${DateHelper.toDate(insurance.reference_date)}`}
                </EuiText>
              ) : (
                <></>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMInsuranceInfo;
