import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import UrlHelper from "helpers/url-helper";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MMOrdersList from "./order-list";
function MMOrdersSearch() {
  localStorage.setItem("last_visited_orders_view", "search");

  let searchParams = useSearchParams();

  const handleOrderNavigate = (orderId: string | number) => {
    let params = UrlHelper.queryParams();
    params.order = orderId;
    searchParams[1](params, { replace: true });
  };

  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any = [];

      if (
        await AuthenticationHelper.hasPermission([
          "orders#submit",
          "orders#submit_org",
          "orders#submit_all",
        ])
      ) {
        buttons.push(
          <EuiButton
            fill
            size="s"
            iconType="plus"
            onClick={() => handleOrderNavigate("new")}
          >
            {txt.get("generic.add_new_x", txt.lo("orders.order.name"))}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);
    };

    setInterfaceForPermissions();
  }, []);

  return (
    <MMPage
      title={txt.get("orders.search.page_title")}
      hideTitle={true}
      topActions={buttonsToShow}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMOrdersList />
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMOrdersSearch;
