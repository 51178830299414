import parse from "html-react-parser";
import LocalizedStrings from "localized-strings";

import ConnectAPIHelper from "api/connect-api-helper";

import { isString } from "lodash";
import AuthenticationHelper from "./authentication-helper";
import StringHelper from "./string-helper";

export const DEFAULT_USER_LANGUAGE = "nl";

const getTranslationStrings = async () => {
  const api = new ConnectAPIHelper();
  const strings = await api.getTranslations();
  return strings;
};

const DEFAULT_VALUE = "generic.ok"; //for quick check if there is some translated content loaded
const DEFAULT_LANGUAGE = "en";
class txt {
  static trans: any = new LocalizedStrings({ en: { empty: "empty" } });

  //Texthelper.lang(language_code) should be called when the user wants to change the interface language
  static lang(language_code?: string) {
    if (language_code) {
      this.trans.setLanguage(language_code);
    }
    return this.trans.getLanguage();
  }

  //Texthelper.init should be resolved once before loading the rest of the application....
  static async init(force: boolean = false) {
    const languageCode: string =
      JSON.parse(localStorage.getItem("user_language") as string) ||
      AuthenticationHelper.getLanguageCode() ||
      DEFAULT_LANGUAGE;

    if (!this.trans.getString(DEFAULT_VALUE)) {
      console.log("txt.init()", languageCode);
      const result = await getTranslationStrings();
      if (result) {
        this.trans.setContent(result);
      } else {
        // console.warn('TextHelper no translations found');
      }
    }
    this.lang(languageCode);
    console.log("TextHelper initialized");
  }

  static str_to_html(string: string) {
    return parse(string);
  }

  static html(key: string, ...args: any) {
    return this.str_to_html(this.get(key, ...args));
  }

  static get(key: string, ...args: any): string {
    let result = "";
    result = this.trans.getString(key);
    if (args.length) {
      result = this.trans.formatString(result, ...args);
    }
    if (!result || !isString(result)) result = `[${key}]`;
    return result;
  }

  static lo(key: string, ...args: any): string {
    return this.get(key, ...args).toLowerCase();
  }

  static up(key: string, ...args: any): string {
    return this.get(key, ...args).toUpperCase();
  }

  static uc(key: string, ...args: any): string {
    return StringHelper.ucwords(this.get(key, ...args));
  }

  static uf(key: string, ...args: any): string {
    return StringHelper.ucfirst(this.get(key, ...args));
  }
  static ufo(key: string, ...args: any): string {
    return StringHelper.upfirst(this.get(key, ...args));
  }
  static lf(key: string, ...args: any): string {
    return StringHelper.lofirst(this.get(key, ...args));
  }
}

export default txt;
