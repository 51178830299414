import {
  EuiIcon,
  EuiPageSidebar,
  EuiSideNav,
  EuiToolTip,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function MMMenu() {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isLoggedIn = AuthenticationHelper.isAuthenticated();
  const activeHref = window.location.pathname;

  const navigate = useNavigate();

  const linkColor = (
    href: string,
    exclude: string[] = [],
    disabled: boolean = false
  ) => {
    return activeHref.indexOf(href) === 0 &&
      !(exclude.findIndex((ex: string) => activeHref.indexOf(ex) === 0) >= 0)
      ? "accent"
      : disabled
        ? "subdued"
        : undefined;
  };
  const initMenuItems = async () => {
    let items: any[] = [];

    if (!isLoggedIn) {
      items.push({
        name: txt.get("login/login"),
        icon: <EuiIcon color={linkColor("/login")} type="avatar" />,
        id: "login",
        onClick: () => {
          navigate("/login");
        },
      });
      return;
    }

    // if (await AuthenticationHelper.hasPermission("sales#read_all")) {
    //   items.push({
    //     name: txt.get("sales.page_title"),
    //     icon: (
    //       <EuiIcon
    //         color={linkColor('/sales')}
    //         type="faceHappy"
    //       />
    //     ),
    //     id: "sales",
    //     onClick: () => {
    //       navigate("/sales");
    //     },
    //   });
    // }
    if (
      await AuthenticationHelper.hasPermission([
        "orders#read",
        "orders#read_org",
        "orders#read_all",
      ])
    ) {
      items.push({
        name: txt.get("my_dashboard.page_title"),
        icon: (
          <EuiToolTip
            position="right"
            content={txt.get("my_dashboard.page_title")}
          >
            <EuiIcon color={linkColor("/my-dashboard")} type="home" />
          </EuiToolTip>
        ),
        id: "orders",
        onClick: () => {
          navigate("/my-dashboard");
        },
      });
      items.push({
        name: txt.get("orders.page_title"),
        icon: (
          <EuiToolTip position="right" content={txt.get("orders.page_title")}>
            <EuiIcon color={linkColor("/orders")} type="documentEdit" />
          </EuiToolTip>
        ),

        id: "search",
        onClick: () => {
          navigate("/orders");
        },
      });
    }

    if (await AuthenticationHelper.hasPermission(["client_details#read_all"])) {
      items.push({
        name: txt.get("clients.page_title"),
        icon: (
          <EuiToolTip position="right" content={txt.get("clients.page_title")}>
            <EuiIcon color={linkColor("/clients")} type="faceHappy" />
          </EuiToolTip>
        ),

        id: "clients",
        onClick: () => {
          navigate("/clients");
        },
      });
    }

    if (
      await AuthenticationHelper.hasPermission([
        "appointments#create",
        "appointments#delete",
        "productions#read_all",
      ])
    ) {
      items.push({
        name: txt.get("production.page_title"),
        icon: (
          <EuiToolTip
            position="right"
            content={txt.get("production.page_title")}
          >
            <EuiIcon color={linkColor("/production")} type="wrench" />
          </EuiToolTip>
        ),

        id: "production",
        onClick: () => {
          navigate("/production");
        },
      });
    }

    if (await AuthenticationHelper.hasPermission("finance#read_all")) {
      items.push({
        name: txt.get("finance.page_title"),
        icon: (
          <EuiToolTip position="right" content={txt.get("finance.page_title")}>
            <EuiIcon color={linkColor("/finance")} type="stats" />
          </EuiToolTip>
        ),

        id: "finance",
        onClick: () => {
          navigate("/finance");
        },
      });
    }

    if (await AuthenticationHelper.hasPermission("products#read_all")) {
      items.push({
        name: txt.get("products.page_title"),
        icon: (
          <EuiToolTip position="right" content={txt.get("products.page_title")}>
            <EuiIcon color={linkColor("/products")} type="layers" />
          </EuiToolTip>
        ),
        id: "products",
        onClick: () => {
          navigate("/products");
        },
      });
    }

    if (
      (await AuthenticationHelper.hasPermission([
        "scans#read_all",
        "scanners#read_all",
      ])) ||
      ((await AuthenticationHelper.hasPermission([
        "scans#read_org",
        "scanners#read_org",
      ])) &&
        AuthenticationHelper.hasRole("scanner"))
    ) {
      items.push({
        name: txt.get("scanning.page_title"),
        icon: (
          <EuiToolTip position="right" content={txt.get("scanning.page_title")}>
            <EuiIcon color={linkColor("/scanning")} type="compute" />
          </EuiToolTip>
        ),

        id: "scanning",
        onClick: () => {
          navigate("/scanning/scans");
        },
      });
    }

    if (
      await AuthenticationHelper.hasPermission([
        "finance#read_all",
        "sales#read_all",
        "finance#read_all",
      ])
    ) {
      items.push({
        name: txt.get("quick_links.page_title"),
        icon: (
          <EuiToolTip
            position="right"
            content={txt.get("quick_links.page_title")}
          >
            <EuiIcon color={linkColor("/quick-links")} type="link" />
          </EuiToolTip>
        ),
        id: "quick-links",
        onClick: () => {
          navigate("/quick-links");
        },
      });
    }

    setMenuItems(() => [{ name: "", id: "dashboard", items }]);
  };

  useEffect(() => {
    initMenuItems();
  }, [isLoggedIn]);

  return (
    <EuiPageSidebar
      style={
        isMobileScreen
          ? { display: "none" }
          : { minInlineSize: "44px !important", backgroundColor: "#d1dfdf" }
      }
      paddingSize="m"
    >
      {isLoggedIn ? <EuiSideNav items={menuItems} /> : <></>}
    </EuiPageSidebar>
  );
}

export default MMMenu;
