import MMPage from "components/layouts/page/page";

import { EuiButton, EuiSpacer } from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMAppointmentsList from "components/appointments/appointments-list";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";

function MMProductionAgendaImport(props: any) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const [buttons, setButtons] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleRemoveAppointments = async () => {
    setIsLoading(true);
    let result: any = api.removeAppointments();

    //todo: use toasts
    if (typeof result === "string") {
      console.error("something went wrong", result);
    } else {
      console.log("appointments removed from sheet");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any = [];
      buttons.push(
        <EuiButton
          isLoading={isLoading}
          color="danger"
          onClick={(e: any) => {
            handleRemoveAppointments();
          }}
        >
          {txt.get("appointments.sheet_sync.remove")}
        </EuiButton>
      );
      setButtons(buttons);
    };
    setInterfaceForPermissions();
  }, []);
  return (
    <MMPage
      title={txt.get("production.agenda.import.page_title")}
      sideActions={buttons}
    >
      <EuiSpacer />
      <MMAppointmentsList />
    </MMPage>
  );
}

export default MMProductionAgendaImport;
