import { Text, View } from "@react-pdf/renderer";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Appointment } from "store/data/appointment/appointment";
import { fullName } from "store/data/personal-details/personal-details";
import { styles } from "../styles";

export interface MMPdfClientContactMomentsProps {
  appointments: Appointment[];
}

function MMPdfClientAppointments(props: MMPdfClientContactMomentsProps) {
  const [appointments, setAppointments] = useState<Appointment[]>(
    props.appointments
  );

  useEffect(() => {
    setAppointments(props.appointments);
  }, [props]);
  return (
    <View>
      <View style={styles.spacer} />
      <View style={styles.columns}>
        <View style={[styles.section_12, styles.tableHeader]}>
          <Text style={styles.title}>
            {txt.get("pdfs.client_dossier.appointments")}
          </Text>
        </View>
      </View>

      <View style={styles.table}>
        {appointments.length > 0 ? (
          appointments.map((appointment: Appointment, i: number) => (
            <View
              key={`appointment-${i}`}
              style={{ ...styles.tableRow, ...styles.columns }}
            >
              <View style={styles.section_3}>
                <Text>{DateHelper.toDateHM(appointment.start)}</Text>
              </View>
              <View style={styles.section_2}>
                <Text>
                  {appointment.practitioner
                    ? fullName(appointment.practitioner)
                    : appointment.vlot_data?.behandelaarnaam
                      ? appointment.vlot_data.behandelaarnaam
                      : "-"}
                </Text>
              </View>
              <View style={styles.section_2}>
                <Text>{appointment.appointment}</Text>
              </View>
              <View style={styles.section_6}>
                <Text>
                  {appointment.vlot_data?.locatie?.omschrijving || "-"}
                </Text>
              </View>
            </View>
          ))
        ) : (
          <View>
            <View style={[styles.section_12, styles.soft]}>
              <Text>
                {txt.get(
                  "pdfs.table.no_x",
                  txt.lo("pdfs.client_dossier.appointments")
                )}
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

export default MMPdfClientAppointments;
