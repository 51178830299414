import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMGeocoder, { LatLng } from "components/layouts/map/geocoder";
import MMMap from "components/layouts/map/map";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { toSelectOptions, updateField } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import {
  ACTIVE_COUNTRY_CODES,
  Address,
  address,
  addressablePerson,
  Addressee,
  addressee,
  ADDRESSEE_EMPTY,
  ADDRESS_EMPTY,
  formattedAddress,
  Person,
  PERSON_EMPTY,
} from "store/data/adress/address";
import {
  contact,
  ContactMethod,
  ContactMethodType,
} from "store/data/contactmethod/contactmethod";
import {
  Location,
  LOCATION_EMPTY,
  viewName,
} from "store/data/location/location";

const LOCATION_IS_ACTIVE_DEFAULT: boolean = true;

const INVOICING_OPTIONS: string[] = ["SEPARATE", "COMBINED", "UNKNOWN"];
const PLANNING_BY_OPTIONS: string[] = [
  "UNKNOWN",
  "BOTH",
  "LOCATION",
  "MANOMETRIC",
  "LOCATION_EXCLUSIVELY",
  "MANOMETRIC_LOCATION_EXCLUSIVE",
];
export interface MMLocationProps {
  fields: {
    location: Location | null;
  };
  handleSubmit?: Function;
}

function MMLocation(props: MMLocationProps) {
  const api = new ConnectAPIHelper();
  const [location, setLocation] = useState(props.fields.location);
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisations, setSelectedOrganisations] = useState<any[]>([]);
  const [editAllowed, setEditAllowed] = useState(false);

  const [countries] = useState(
    ACTIVE_COUNTRY_CODES.map((code) => ({ name: code, id: code }))
  );
  const [invoicingOptions] = useState(
    INVOICING_OPTIONS.map((invoicingOption) => ({
      name: txt.get(
        `admin.locations.invoicing.${invoicingOption.toLowerCase()}`
      ),
      id: invoicingOption,
    }))
  );
  const [planningByOptions] = useState(
    PLANNING_BY_OPTIONS.map((planningByOption) => ({
      name: txt.get(
        `admin.locations.planning_by.${planningByOption.toLowerCase()}`
      ),
      id: planningByOption,
    }))
  );
  const [selectedInvoicingOptions, setSelectedInvoicingOptions] = useState<
    any[]
  >([]);
  const [selectedPlanningByOptions, setSelectedPlanningOptions] = useState<
    any[]
  >([]);
  const [selectedMainCountries, setSelectedMainCountries] = useState<any[]>([]);
  const [selectedShippingCountries, setSelectedShippingCountries] = useState<
    any[]
  >([]);
  const [selectedInvoiceCountries, setSelectedInvoiceCountries] = useState<
    any[]
  >([]);

  const [mainAddressFormatted, setMainAddressFormatted] = useState("");
  const [mainAddressLatLng, setMainAddressLatLng] = useState<LatLng | null>(
    null
  );

  useEffect(() => {
    const mainLocationAddress: Address | null = address(location, "main");

    if (mainLocationAddress) {
      setMainAddressFormatted(formattedAddress(location, "main") || "");
      if (mainLocationAddress.lat && mainLocationAddress.lng) {
        setMainAddressLatLng({
          lat: mainLocationAddress.lat,
          lng: mainLocationAddress.lng,
        } as LatLng);
      }
    }

    const loadOrganisations = async () => {
      const organisations = await api.getOrganisations();
      setOrganisations(organisations);
    };

    loadOrganisations();
    const setPermissions = async () => {
      setEditAllowed(
        await AuthenticationHelper.hasPermission("locations#edit_all")
      );
    };
    setPermissions();
  }, []);

  useEffect(() => {
    if (organisations.length > 0 && location) {
      setSelectedOrganisations(
        toSelectOptions(
          organisations.filter(
            (organisation: any) => organisation.id === location.organisation_id
          )
        )
      );
    }

    const mainLocationAddress: Address | null = address(location, "main");

    if (mainLocationAddress) {
      setSelectedMainCountries(
        toSelectOptions(
          countries.filter(
            (country: any) => mainLocationAddress.country === country.id
          )
        )
      );
    }

    const shippingLocationAddress: Address | null = address(
      location,
      "shipping"
    );

    if (shippingLocationAddress) {
      setSelectedShippingCountries(
        toSelectOptions(
          countries.filter(
            (country: any) => shippingLocationAddress.country === country.id
          )
        )
      );
    }
    const invoiceLocationAddress: Address | null = address(location, "invoice");

    if (invoiceLocationAddress) {
      setSelectedInvoiceCountries(
        toSelectOptions(
          countries.filter(
            (country: any) => invoiceLocationAddress.country === country.id
          )
        )
      );
    }
    if (location) {
      setSelectedInvoicingOptions(
        toSelectOptions(
          invoicingOptions.filter(
            (invoicingOption: any) => location.invoicing === invoicingOption.id
          )
        )
      );

      setSelectedPlanningOptions(
        toSelectOptions(
          planningByOptions.filter(
            (planningByOption: any) =>
              location.planning_by === planningByOption.id
          )
        )
      );
    }
  }, [organisations, location]);

  const onName = (name: string) => {
    setLocation(updateField(location, "name", name));
  };

  const onAddressee = (salutation: string, name: string) => {
    let addressees: Addressee[] = location?.addressees || [];

    let result: Addressee | null = addressee(location, name) || {
      ...ADDRESSEE_EMPTY,
      addressable_type: "location",
      addressable_id: location?.id || null,
      name,
    };
    result.salutation = salutation;
    result.name = name;

    let found: boolean = false;

    for (let i = 0; i < addressees.length; i++) {
      const addressee: Addressee = addressees[i];
      if (addressee.name === name) {
        addressees[i] = result;
        found = true;
      }
    }
    if (!found) {
      addressees.push(result);
    }
    setLocation(updateField(location, "addressees", addressees));
  };

  const onMasterSheetName = (name: string) => {
    setLocation(
      updateField(
        location,
        "legacy_naming",
        updateField(location?.legacy_naming, "master_sheet", name)
      )
    );
  };

  const onVlotName = (name: string) => {
    setLocation(
      updateField(
        location,
        "legacy_naming",
        updateField(location?.legacy_naming, "vlot", name)
      )
    );
  };

  const onAddressDone = (name: string) => {
    const currentFormattedAddress: string | null = formattedAddress(
      location,
      name
    );
    if (name === "main") {
      setMainAddressFormatted(currentFormattedAddress || "");
    }
  };

  const onAddress = (address: Address, name: string) => {
    let addressees: Addressee[] = location?.addressees || [];

    let result: Addressee | null = addressee(location, name) || {
      ...ADDRESSEE_EMPTY,
      addressable_type: "location",
      addressable_id: location?.id || null,
    };
    result.address = address;

    let found: boolean = false;
    for (let i = 0; i < addressees.length; i++) {
      const addressee: Addressee = addressees[i];
      if (addressee.name === name) {
        addressees[i] = result;
        found = true;
      }
    }
    if (!found) {
      addressees.push(result);
    }

    setLocation(updateCity(updateField(location, "addressees", addressees)));
  };

  const onContactMethod = (contactMethod: ContactMethod) => {
    let updatedContactMethods: ContactMethod[] =
      location?.contact_methods || [];
    let found: boolean = false;
    for (let i = 0; i < updatedContactMethods.length; i++) {
      const current: ContactMethod = updatedContactMethods[i];
      if (
        current.name === contactMethod.name &&
        current.type === contactMethod.type
      ) {
        updatedContactMethods[i] = { ...contactMethod };
        found = true;
      }
    }
    if (!found) {
      updatedContactMethods.push(contactMethod);
    }

    setLocation(
      updateField(location, "contact_methods", updatedContactMethods)
    );
  };

  const onPerson = (person: Person, name: string) => {
    let addressees: Addressee[] = location?.addressees || [];

    let result: Addressee | null = addressee(location, name) || {
      ...ADDRESSEE_EMPTY,
      addressable_type: "location",
      addressable_id: location?.id || null,
    };
    result.person = person;

    let found: boolean = false;
    for (let i = 0; i < addressees.length; i++) {
      const addressee: Addressee = addressees[i];
      if (addressee.name === name) {
        addressees[i] = result;
        found = true;
      }
    }
    if (!found) {
      addressees.push(result);
    }
    setLocation(updateField(location, "addressees", addressees));
  };

  const onActiveChange = (e: any) => {
    setLocation(updateField(location, "is_active", e.target.checked));
  };

  const onStringValue = (e: any) => e.target.value;

  const onOrganisationChange = (selectedOptions: any[]) => {
    setLocation(
      updateField(
        location,
        "organisation_id",
        selectedOptions.length > 0 ? selectedOptions[0]["data-id"] : null
      )
    );
  };

  const onInvoicingChange = (selectedOptions: any[]) => {
    setLocation(
      updateField(
        location,
        "invoicing",
        selectedOptions.length > 0 ? selectedOptions[0]["data-id"] : null
      )
    );
  };

  const onPlanningByChange = (selectedOptions: any[]) => {
    setLocation(
      updateField(
        location,
        "planning_by",
        0 < selectedOptions.length ? selectedOptions[0]["data-id"] : null
      )
    );
  };

  const onCountryChange = (selectedOptions: any[], name: string) => {
    onAddress(
      updateField(
        address(location, name),
        "country",
        selectedOptions.length > 0 ? selectedOptions[0]["data-id"] : null
      ),
      name
    );
  };

  const overwriteAddressees = (
    target: Addressee = ADDRESSEE_EMPTY,
    source: Addressee = ADDRESSEE_EMPTY,
    name: string = ""
  ) => {
    const sourceAddress: Address = source?.address || ADDRESS_EMPTY;
    const sourcePerson: Person = source?.person || PERSON_EMPTY;

    const targetAddress: Address = target?.address || ADDRESS_EMPTY;
    const targetPerson: Person = target?.person || PERSON_EMPTY;

    const overwritten: Addressee = {
      id: target.id,
      name: target.name || name,

      addressable_type: target.addressable_type || "location",
      addressable_id: target.addressable_id,
      salutation: source.salutation,

      person: {
        id: targetPerson.id,

        salutation: sourcePerson.salutation,
        first_name: sourcePerson.first_name,
        last_name: sourcePerson.last_name,
        initials: sourcePerson.initials,
        infix: sourcePerson.infix,
      },

      address: {
        id: targetAddress.id,

        street_number_suffix: sourceAddress.street_number_suffix,
        street_number: sourceAddress.street_number,
        street_name: sourceAddress.street_name,
        postal_area: sourceAddress.postal_area,
        country: sourceAddress.country,
        city: sourceAddress.city,
        lat: sourceAddress.lat,
        lng: sourceAddress.lng,
      },
    };

    let addressees: Addressee[] = [];
    const newLocation: Location = location ? location : LOCATION_EMPTY;

    addressees.push(overwritten);
    newLocation.addressees.forEach((addressee) => {
      if (addressee.name && addressee.name !== overwritten.name) {
        addressees.push(addressee);
      }
    });

    return addressees;
  };

  const onCopyMainAddresseeTo = (name: string = "") => {
    if (!name) {
      return;
    }

    const main: Addressee = addressee(location, "main") || ADDRESSEE_EMPTY;
    const target: Addressee = addressee(location, name) || ADDRESSEE_EMPTY;
    const addressees = overwriteAddressees(target, main, name);

    setLocation(updateField(location, "addressees", addressees));
  };

  const onClearAddressee = (name: string = "") => {
    if (!name) {
      return;
    }

    const source: Addressee = ADDRESSEE_EMPTY;
    const target: Addressee = addressee(location, name) || ADDRESSEE_EMPTY;
    const addressees = overwriteAddressees(target, source, name);

    setLocation(updateField(location, "addressees", addressees));
  };

  const updateCity = (location: Location) => {
    let updatedLocation: Location = { ...location };
    let mainAddress: Address | null = address(updatedLocation, "main");
    if (mainAddress?.city && location) {
      updatedLocation.city = mainAddress.city;
    }
    return updatedLocation;
  };
  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      props.handleSubmit(location ? updateCity(location) : null);
    }
  };

  const renderAddressInput = (
    name: string,
    selectedCountries: any[],
    withLatLngFromAddress?: string | null,
    withLatLng?: LatLng | null
  ) => (
    <Fragment>
      <EuiFlexGroup justifyContent="flexStart">
        <EuiFlexItem grow={1} style={{ maxWidth: "400px" }}>
          <EuiFlexGroup alignItems="flexEnd">
            <EuiFlexItem grow={true}>
              <EuiTitle size="xs">
                <EuiText>
                  {txt.get(`admin.locations.${name}_address_details`)}
                </EuiText>
              </EuiTitle>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              {name !== "main" &&
                (!addressee(location, name)?.salutation ? (
                  <EuiButtonEmpty
                    iconType="copy"
                    color="text"
                    size="xs"
                    onClick={() => {
                      onCopyMainAddresseeTo(name);
                    }}
                  >
                    {txt.get(`admin.locations.use_main_address`)}
                  </EuiButtonEmpty>
                ) : addressee(location, name)?.salutation ? (
                  <EuiButtonEmpty
                    iconType="cross"
                    color="text"
                    size="xs"
                    onClick={() => {
                      onClearAddressee(name);
                    }}
                  >
                    {txt.get(`generic.clear`)}
                  </EuiButtonEmpty>
                ) : (
                  <></>
                ))}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="xs" />
          <EuiFlexItem grow={3}>
            <EuiFormRow label={txt.get("address.salutation")}>
              <EuiFieldText
                disabled={!editAllowed}
                name="details"
                value={addressee(location, name)?.salutation || ""}
                onChange={(e) => onAddressee(onStringValue(e), name)}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={3}>
              <EuiFormRow label={txt.get("person.salutation")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="person_salutation"
                  value={addressablePerson(location, name)?.salutation || ""}
                  onChange={(e) =>
                    onPerson(
                      updateField(
                        addressablePerson(location, name),
                        "salutation",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={2}>
              <EuiFormRow label={txt.get("person.initials")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="person_initials"
                  value={addressablePerson(location, name)?.initials || ""}
                  onChange={(e) =>
                    onPerson(
                      updateField(
                        addressablePerson(location, name),
                        "initials",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>{" "}
            <EuiFlexItem grow={5}>
              <EuiFormRow label={txt.get("person.first_name")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="person_first_name"
                  value={addressablePerson(location, name)?.first_name || ""}
                  onChange={(e) =>
                    onPerson(
                      updateField(
                        addressablePerson(location, name),
                        "first_name",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={3}>
              <EuiFormRow label={txt.get("person.infix")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="person_infix"
                  value={addressablePerson(location, name)?.infix || ""}
                  onChange={(e) =>
                    onPerson(
                      updateField(
                        addressablePerson(location, name),
                        "infix",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={7}>
              <EuiFormRow label={txt.get("person.last_name")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="person_last_name"
                  value={addressablePerson(location, name)?.last_name || ""}
                  onChange={(e) =>
                    onPerson(
                      updateField(
                        addressablePerson(location, name),
                        "last_name",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={3}>
              <EuiFormRow label={txt.get("address.street_name")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_street_name"
                  value={address(location, name)?.street_name || ""}
                  onBlur={() => {
                    onAddressDone(name);
                  }}
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(location, name),
                        "street_name",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label={txt.get("address.street_number_short")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_street_number"
                  value={address(location, name)?.street_number || ""}
                  onBlur={() => {
                    onAddressDone(name);
                  }}
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(location, name),
                        "street_number",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label={txt.get("address.street_number_suffix_short")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_street_number_suffix"
                  value={address(location, name)?.street_number_suffix || ""}
                  onBlur={() => {
                    onAddressDone(name);
                  }}
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(location, name),
                        "street_number_suffix",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={2}>
              <EuiFormRow label={txt.get("address.postal_area")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_postal_area"
                  value={address(location, name)?.postal_area || ""}
                  onBlur={() => {
                    onAddressDone(name);
                  }}
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(location, name),
                        "postal_area",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={6}>
              <EuiFormRow label={txt.get("address.city")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="address_city"
                  value={address(location, name)?.city || ""}
                  onBlur={() => {
                    onAddressDone(name);
                  }}
                  onChange={(e) =>
                    onAddress(
                      updateField(
                        address(location, name),
                        "city",
                        onStringValue(e)
                      ),
                      name
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiFormRow label={"Country"}>
                <EuiComboBox
                  inputRef={(input) => {
                    if (input) input.name = "country_code";
                  }}
                  style={{ width: "100px" }}
                  isDisabled={!editAllowed}
                  aria-label="Country code"
                  placeholder={"..."}
                  onBlur={() => {
                    onAddressDone(name);
                  }}
                  singleSelection={{ asPlainText: true }}
                  options={toSelectOptions(countries)}
                  selectedOptions={selectedCountries}
                  onChange={(e: any) => onCountryChange(e, name)}
                  aria-autocomplete="none"
                  isClearable={false}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        {withLatLngFromAddress !== null ? (
          <EuiFlexItem grow={1} style={{ maxWidth: "450px" }}>
            <EuiSpacer size="s" />
            <EuiSpacer size="xxl" />
            <MMGeocoder
              onLatLng={(latLng: LatLng | null) => {
                onAddress(
                  updateField(
                    updateField(
                      address(location, name),
                      "lat",
                      latLng?.lat || null
                    ),
                    "lng",
                    latLng?.lng || null
                  ),
                  name
                );
                setMainAddressLatLng(latLng);
              }}
              address={withLatLngFromAddress || ""}
            />
            {withLatLng ? (
              <Fragment>
                <MMMap
                  lat={withLatLng.lat || undefined}
                  lng={withLatLng.lng || undefined}
                />
              </Fragment>
            ) : (
              <></>
            )}
          </EuiFlexItem>
        ) : (
          <></>
        )}
      </EuiFlexGroup>
      <EuiSpacer />
    </Fragment>
  );

  return (
    <EuiForm aria-autocomplete="none">
      <EuiFlexGroup justifyContent="flexStart">
        <EuiFlexItem grow={1} style={{ maxWidth: "400px" }}>
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem grow={4}>
              <EuiFormRow label={txt.get("admin.locations.name")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="location-name"
                  value={location?.name}
                  onChange={(e) => onName(onStringValue(e))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={3}>
              <EuiFormRow label={txt.get("admin.locations.details")}>
                <EuiFieldText
                  disabled={!editAllowed}
                  name="details"
                  value={location?.details || ""}
                  onChange={(e) =>
                    setLocation(
                      updateField(location, "details", onStringValue(e))
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={1} style={{ maxWidth: "450px", paddingTop: "20px" }}>
          <EuiText size="s" color="gray">
            <EuiText style={{ fontWeight: "bold" }}>
              {location ? viewName(location) : ""}
            </EuiText>
            <EuiText size="xs" color="#aaaaaa">
              {txt.lo("admin.locations.view_name")},{" "}
              {txt.lo("admin.locations.view_name_explanation")}
            </EuiText>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFormRow
        label={txt.get("admin.organisations.name")}
        helpText={txt.get("admin.locations.organisation_help")}
      >
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select organisation"
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("admin.organisations.name")
          )}
          singleSelection={{ asPlainText: true }}
          options={toSelectOptions(organisations)}
          selectedOptions={selectedOrganisations}
          onChange={onOrganisationChange}
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("admin.locations.invoicing.name")}>
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select invoicing option"
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("admin.locations.invoicing.name")
          )}
          singleSelection={{ asPlainText: true }}
          options={toSelectOptions(invoicingOptions)}
          selectedOptions={selectedInvoicingOptions}
          onChange={onInvoicingChange}
        />
      </EuiFormRow>
      <EuiSpacer size="m" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem>
          <EuiFormRow label={txt.get("admin.locations.vat_number")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="vat_number"
              value={location?.vat_number || ""}
              onChange={(e) =>
                setLocation(
                  updateField(location, "vat_number", onStringValue(e))
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={txt.get("admin.locations.coc_number")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="coc_number"
              value={location?.coc_number || ""}
              onChange={(e) =>
                setLocation(
                  updateField(location, "coc_number", onStringValue(e))
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>{" "}
      <EuiSpacer size="m" />
      <EuiFormRow label={txt.get("admin.locations.planning_by.name")}>
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select planning by option"
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("admin.locations.planning_by.name")
          )}
          singleSelection={{ asPlainText: true }}
          options={toSelectOptions(planningByOptions)}
          selectedOptions={selectedPlanningByOptions}
          onChange={onPlanningByChange}
        />
      </EuiFormRow>
      <EuiFormRow
        label={
          <span id="location-is-active">
            {txt.get("admin.locations.is_active")}
          </span>
        }
      >
        <EuiSwitch
          disabled={!editAllowed}
          onChange={onActiveChange}
          label={
            location?.is_active ? txt.get("generic.yes") : txt.get("generic.no")
          }
          checked={
            location && location.is_active !== undefined
              ? location.is_active
              : LOCATION_IS_ACTIVE_DEFAULT
          }
          aria-describedby="location-is-active"
        />
      </EuiFormRow>
      <EuiSpacer />
      {renderAddressInput(
        "main",
        selectedMainCountries,
        mainAddressFormatted,
        mainAddressLatLng
      )}
      {renderAddressInput("shipping", selectedShippingCountries, null)}
      {renderAddressInput("invoice", selectedInvoiceCountries, null)}
      <EuiTitle size="xs">
        <EuiText>{txt.get("contact.page_title")}</EuiText>
      </EuiTitle>
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("admin.locations.shipment_email")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="shipment_contact_email"
              value={
                contact(location, "shipment_contact", ContactMethodType.Email)
                  ?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(
                      location,
                      "shipment_contact",
                      ContactMethodType.Email
                    ),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="shipment_contact_email_contact"
              value={
                contact(location, "shipment_contact", ContactMethodType.Email)
                  ?.salutation || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(
                      location,
                      "shipment_contact",
                      ContactMethodType.Email
                    ),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("admin.locations.shipment_mobile_phone")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="shipment_contact_mobile_phone"
              value={
                contact(
                  location,
                  "shipment_contact",
                  ContactMethodType.MobilePhone
                )?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(
                      location,
                      "shipment_contact",
                      ContactMethodType.MobilePhone
                    ),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="shipment_contact_mobile_phone_contact"
              value={
                contact(
                  location,
                  "shipment_contact",
                  ContactMethodType.MobilePhone
                )?.salutation || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(
                      location,
                      "shipment_contact",
                      ContactMethodType.MobilePhone
                    ),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("admin.locations.invoice_email")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="invoice_email"
              value={
                contact(location, "invoice_email", ContactMethodType.Email)
                  ?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(location, "invoice_email", ContactMethodType.Email),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="invoice_email_contact"
              value={
                contact(location, "invoice_email", ContactMethodType.Email)
                  ?.salutation || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(location, "invoice_email", ContactMethodType.Email),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("admin.locations.invoice_email_cc")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="invoice_email_cc"
              value={
                contact(location, "invoice_email_cc", ContactMethodType.Email)
                  ?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(
                      location,
                      "invoice_email_cc",
                      ContactMethodType.Email
                    ),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="invoice_email_cc_contact"
              value={
                contact(location, "invoice_email_cc", ContactMethodType.Email)
                  ?.salutation || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(
                      location,
                      "invoice_email_cc",
                      ContactMethodType.Email
                    ),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xs" />
      <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
        <EuiFlexItem grow={3}>
          <EuiFormRow label={txt.get("admin.locations.debit_email")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="debit_email"
              value={
                contact(location, "debit_email", ContactMethodType.Email)
                  ?.value || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(location, "debit_email", ContactMethodType.Email),
                    "value",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow label={txt.get("contact.contact")}>
            <EuiFieldText
              disabled={!editAllowed}
              name="debit_email_contact"
              value={
                contact(location, "debit_email", ContactMethodType.Email)
                  ?.salutation || ""
              }
              onChange={(e) =>
                onContactMethod(
                  updateField(
                    contact(location, "debit_email", ContactMethodType.Email),
                    "salutation",
                    onStringValue(e)
                  )
                )
              }
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFormRow
        label={txt.get("admin.locations.master_sheet_name")}
        helpText={txt.get("admin.locations.master_sheet_help")}
      >
        <EuiFieldText
          disabled={!editAllowed}
          name="master_sheet_name"
          value={location?.legacy_naming.master_sheet || ""}
          onChange={(e) => onMasterSheetName(onStringValue(e))}
        />
      </EuiFormRow>
      <EuiFormRow
        label={txt.get("admin.locations.vlot_name")}
        helpText={txt.get("admin.locations.vlot_help")}
      >
        <EuiFieldText
          disabled={!editAllowed}
          name="vlot_name"
          value={location?.legacy_naming.vlot || ""}
          onChange={(e) => onVlotName(onStringValue(e))}
        />
      </EuiFormRow>
      {editAllowed ? (
        <Fragment>
          {" "}
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMLocation;
