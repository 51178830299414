import {
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
} from "@elastic/eui";
import { MMLink } from "components/layouts/navigation/link";
import MMCell from "components/layouts/table/cell";
import MMMemoryTable from "components/layouts/table/memory-table";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";
import {
  isOrderLineEndStatus,
  Order,
  OrderLine,
  OrderLineGroup,
  orderLineStatusDescription,
  toGroupedOrderLines,
} from "store/data/order/order";
import { handDescription } from "store/data/scan/scan";

export interface MMOrderInfoProps {
  client: Client | null;
  orders: Order[];
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMOrderInfo(props: MMOrderInfoProps) {
  const [client, setClient] = useState<Client>();
  const [groupedOrderLines, setGroupedOrderLines] = useState<OrderLineGroup[]>(
    []
  );

  useEffect(() => {
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    if (props.orders) {
      const result = toGroupedOrderLines(props.orders);
      setGroupedOrderLines(result);
    } else {
      setGroupedOrderLines([]);
    }
  }, [props.orders, props.client, props.isEditable, props.isHeaderShown]);

  const getColumns = (): Array<EuiBasicTableColumn<any>> => [
    {
      name: "",
      field: "lines",
      // sortable: true,
      render: (lines: OrderLine[], group: OrderLineGroup) => (
        <MMCell
          size="xs"
          text={`${lines.length > 0 ? lines[0].product_selection.type : "-"} ${
            lines.length > 1 ? `(${lines.length}x)` : ""
          }`}
        />
      ),
    },
    {
      name: "",
      field: "lines",
      // sortable: true,
      render: (lines: OrderLine[], group: OrderLineGroup) => (
        <MMCell
          size="xs"
          align="center"
          text={
            lines.length > 0
              ? handDescription(
                  lines[0].hand as string,
                  "scanning.scans.scan.hand",
                  true
                )
              : "-"
          }
          wrap={false}
        />
      ),
    },
    {
      name: "",
      field: "lines",
      // sortable: true,
      render: (lines: OrderLine[], group: OrderLineGroup) => (
        <MMCell
          size="xs"
          align="center"
          text={
            lines.length > 0 ? orderLineStatusDescription(lines[0].status) : "-"
          }
          subText={
            !isOrderLineEndStatus(lines[0].status) &&
            lines[0].order_type !== "1st fitting"
              ? lines[0].order_type
              : undefined
          }
          wrap={false}
        />
      ),
    },
    {
      name: "",
      field: "order_id",
      // className: "order-id",
      sortable: true,
      render: (order_id: number, group: OrderLineGroup) => (
        <MMCell
          size="xs"
          align="center"
          text={
            <MMLink href={`/clients/${client?.id}/?order=${order_id}`}>
              {order_id}
            </MMLink>
          }
          subText={
            group.order?.ordered_at
              ? DateHelper.toDate(group.order?.ordered_at)
              : undefined
          }
          wrap={false}
        />
      ),
    },
  ];

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="xs" justifyContent="spaceBetween">
          <MMTitle text={txt.get("clients.care_info.orders.name")} />
          <EuiButtonIcon aria-label="add" iconType={"plus"} />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <MMMemoryTable
            items={groupedOrderLines}
            columns={getColumns()}
            groupKey="order_id"
          />
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMOrderInfo;
