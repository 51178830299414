import env from "helpers/env-helper";
import Helper from "./local-storage-helper";

class LocalStorageMigrator {
  static clearValues(keys: string[]): void {
    for (const key of keys) {
      Helper.clear(key);
    }
  }

  static migrate(): void {
    const current: string = env("REACT_APP_BUILD_VERSION") || "v.local";
    const last: string | null = Helper.get("REACT_APP_LAST_VERSION");

    if (current === last) {
      return;
    }

    Helper.set("REACT_APP_LAST_VERSION", current);

    this.clearValues([
      "client_number",
      "scan_limit",
      "scan_offset",
      "scan_search",
      "scan_since",
      "scan_sort_by",
      "scan_sort_order",
      "scan_status",
      "scan_until",
    ]);
  }
}

export default LocalStorageMigrator;
