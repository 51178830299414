import moment from "moment-timezone";
import DateHelper from "./date-helper";

class MomentHelper {
  static toMoment(date: Date) {
    // console.log("MomentHelper.toMoment", date);
    return moment(DateHelper.format(date, "yyyy-MM-dd HH:mm:ss"));
  }

  static toDate(moment: any) {
    // console.log("MomentHelper.toDate", moment);
    return DateHelper.parseDate(
      moment && moment.format ? moment.format() : null
    );
  }
}

export default MomentHelper;
