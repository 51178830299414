import { EuiButton, EuiFlexItem, EuiSpacer, EuiText } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function MMProduction() {
  const navigate = useNavigate();

  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["appointments#create"])) {
        buttons.push(
          <EuiText key="appointments">
            <p>{txt.get("production.productions.intro")}</p>
            <EuiButton onClick={() => navigate("/production/agenda/import")}>
              {txt.get("production.agenda.import.intro")}
            </EuiButton>
          </EuiText>
        );
      }
      buttons.push(
        <EuiText key="space">
          <EuiSpacer />
        </EuiText>
      );
      if (await AuthenticationHelper.hasPermission(["productions#read_all"])) {
        buttons.push(
          <EuiText key="productions">
            <EuiButton onClick={() => navigate("/production/productions")}>
              {txt.get("production.productions.page_title")}
            </EuiButton>
          </EuiText>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  return (
    <MMPage title={txt.get("production.page_title")}>
      <EuiFlexItem>
        <EuiSpacer />
        {buttonsToShow}
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMProduction;
