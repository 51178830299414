import { Component } from "react";
import { Link } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import MMVersionInfo from "../version-info/version-info";

import { EuiPageSection } from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";

const storeMap = () => ({
  user: AuthenticationHelper.getUserInfo(),
  isLoggedIn: AuthenticationHelper.isAuthenticated(),
});

const storeConnectedComponent = connect(storeMap);
type StoreProps = ConnectedProps<typeof storeConnectedComponent>;

interface MMFooterProps extends StoreProps {}

class MMFooter extends Component<MMFooterProps> {
  render(): JSX.Element {
    return (
      <EuiPageSection
        paddingSize="s"
        color="plain"
        style={{ backgroundColor: "#d1dfdf" }}
      >
        <div
          className="Footer"
          style={{ textAlign: "center", position: "relative" }}
        >
          {/* <Link className="Footer--link" to="/">Home</Link>&nbsp;|&nbsp;
                    { this.props.isLoggedIn
                    ?
                    <Link className="Footer--link" to="/logout">Logout</Link>
                    :
                    <Link className="Footer--link" to="/login">Login</Link>
                    }
                    <br/><br/> */}
          <Link className="Footer--link" to="/">
            &copy; Manometric B.V.
          </Link>
          {/* &nbsp;|&nbsp;
                    <Link className="Footer--link" to="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;
                    <Link className="Footer--link" to="/toc">Terms & Conditions</Link> */}
          <div
            style={{
              position: "absolute",
              right: "0px",
              bottom: "0px",
              color: "#a0a0a0",
              fontSize: 10,
            }}
          >
            <MMVersionInfo />
          </div>
        </div>
      </EuiPageSection>
    );
  }
}

export default storeConnectedComponent(MMFooter);
