import { EuiFlexItem, EuiSpacer, EuiText } from "@elastic/eui";
import MMPage from "components/layouts/page/page";

function MMOKRs() {
  return (
    <MMPage title="OKRs">
      <EuiFlexItem>
        <EuiSpacer />
        <EuiText>
          <ul>
            <li>
              North Star Metric
              <ul>
                <li>Nr of patients helped</li>
                <li>NPS score</li>
              </ul>
            </li>
            <li>company OKRs</li>
            <li>department ‘lead’ OKRs</li>
            <li>personal OKRs</li>
          </ul>
        </EuiText>
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMOKRs;
