import {
  EuiBasicTable,
  EuiButton,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiText,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import {
  isDefaultStatusLogComment,
  Order,
  orderStatusDescription,
  OrderStatusLog,
} from "store/data/order/order";
import { v4 as uuid } from "uuid";

import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import { updateField } from "hoc/helper-hooks";
import { toastAdd } from "store/components/toast/toast";

export interface MMOrderStatusLogsProps {
  order: Order;
}

function MMOrderStatusLogs(props: MMOrderStatusLogsProps) {
  const api = new ConnectAPIHelper();
  const [deleteRef] = useState("delete_order_" + uuid());

  const dispatch = useDispatch();

  const [logs, setLogs] = useState<OrderStatusLog[]>([]);
  const [editLogSelected, setEditLogSelected] = useState<number | null>(null);
  const [logToUpdate, setLogToUpdate] = useState<OrderStatusLog | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [order] = useState(props.order);

  const loadLogs = async () => {
    setIsLoading(true);
    const canSeeLogs: boolean = await AuthenticationHelper.hasPermission([
      "order_status_logs#read",
      "order_status_logs#read_org",
      "order_status_logs#read_all",
    ]);
    if (canSeeLogs) {
      const result: OrderStatusLog[] = await api.getOrderStatusLogs(order.id);
      setLogs(result || []);
    } else {
      setLogs([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadLogs();
  }, []);

  useEffect(() => {
    if (editLogSelected) {
      setLogToUpdate(
        logs.find((log: OrderStatusLog) => log.id === editLogSelected) || null
      );
    } else {
      setLogToUpdate(null);
    }
  }, [editLogSelected]);

  const handleSaveLogToUpdate = async () => {
    setIsLoading(true);
    if (logToUpdate) {
      const result: ApiResponse = await api.updateOrderStatusLog(
        logToUpdate?.order_id,
        logToUpdate?.id,
        logToUpdate.logged_at,
        logToUpdate.comment
      );

      if (result && result.status === ApiResponseStatus.OK) {
        console.log("Log updated");
        setEditLogSelected(null);
        await loadLogs();
      }
    } else {
      console.log("no log to update, doing nothing");
    }
    setIsLoading(false);
  };
  const handleLogDelete = async (orderId: number, orderStatusLogId: number) => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("orders.order.log"))}.`,
        txt.get("orders.order.log_entry_delete_confirm"),
        deleteRef,
        { order_id: orderId, order_status_log_id: orderStatusLogId }
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === deleteRef
    ) {
      deleteLog(
        alertConfirm.actionData.order_id,
        alertConfirm.actionData.order_status_log_id
      );
    }
  }, [alertConfirm]);

  const deleteLog = async (orderId: number, orderStatusLogId: number) => {
    setIsLoading(true);
    const result: ApiResponse = await api.deleteOrderStatusLog(
      orderId,
      orderStatusLogId
    );
    if (result && result.status === ApiResponseStatus.OK) {
      await loadLogs();
    } else {
      dispatch(toastAdd(txt.get("generic.error"), result.message, "danger"));
    }
    setIsLoading(false);
  };

  const columns = () => [
    {
      name: `${txt.get("orders.order.log")} v.${props.order.version || 0}`,
      truncateText: false,
      width: "250px",
      render: (log: any) => (
        <EuiPopover
          id="edit-log"
          button={
            <EuiFlexGroup direction="column" gutterSize="xs">
              {!isDefaultStatusLogComment(log.comment) ? (
                <EuiFlexItem>
                  <EuiText size="xs">
                    <i>{log.comment}</i>
                  </EuiText>
                </EuiFlexItem>
              ) : (
                <></>
              )}
              <EuiFlexItem
                onClick={() => {
                  setEditLogSelected(
                    editLogSelected === log.id ? null : log.id
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <EuiText size="xs">
                  {DateHelper.ago(log.logged_at, txt.lang(), true)}&nbsp;
                  {orderStatusDescription(log.new_status).toLowerCase()}&nbsp;
                  {txt.lo("generic.by_x", log.user)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          }
          isOpen={editLogSelected === log.id}
          closePopover={() => {
            setEditLogSelected(null);
          }}
          panelPaddingSize="none"
          anchorPosition="downRight"
          style={{ height: "100%" }}
        >
          <EuiPanel>
            <EuiFormRow display="rowCompressed" label={"date"}>
              <EuiDatePicker
                dateFormat={"DD-MM-YYYY"}
                compressed={true}
                selected={
                  logToUpdate?.logged_at
                    ? MomentHelper.toMoment(
                        DateHelper.parseDate(logToUpdate?.logged_at)
                      )
                    : undefined
                }
                onChange={(moment: any) => {
                  setLogToUpdate(
                    updateField(
                      logToUpdate,
                      "logged_at",
                      MomentHelper.toDate(moment)
                    )
                  );
                }}
              ></EuiDatePicker>
            </EuiFormRow>
            <EuiFormRow display="rowCompressed" label={"comment"}>
              <EuiFieldText
                compressed={true}
                onChange={(e: any) => {
                  setLogToUpdate(
                    updateField(logToUpdate, "comment", e.target.value)
                  );
                }}
                value={logToUpdate?.comment}
                style={{
                  opacity: isDefaultStatusLogComment(logToUpdate?.comment || "")
                    ? 0.5
                    : 1.0,
                }}
              />
            </EuiFormRow>
            <EuiButton
              size="s"
              color="accent"
              onClick={(e: any) => {
                handleSaveLogToUpdate();
              }}
            >
              {txt.get("generic.save")}
            </EuiButton>
          </EuiPanel>
        </EuiPopover>
      ),
    },
    {
      name: "",
      width: "30px",
      render: (log: any) => (
        <EuiIcon
          style={{ cursor: "pointer" }}
          onClick={
            !isLoading
              ? () => {
                  handleLogDelete(log.order_id, log.id);
                }
              : undefined
          }
          size="s"
          type={"cross"}
          aria-label="delete log"
        />
      ),
    },
  ];

  return logs && logs.length > 0 ? (
    <EuiFlexItem style={{ maxWidth: "250px" }}>
      <EuiBasicTable
        itemId="id"
        items={logs}
        columns={columns()}
        noItemsMessage={txt.uf(
          "generic.found_no_x",
          txt.lo("orders.order.log")
        )}
      />
    </EuiFlexItem>
  ) : (
    <></>
  );
}

export default MMOrderStatusLogs;
