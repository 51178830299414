import { Text, View } from "@react-pdf/renderer";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { toAPGHForOrderLine } from "store/data/contract/contract";
import {
  costingTotal,
  OrderCostingType,
  toCostType,
  VAT_LOW,
} from "store/data/costing/costing";
import { Order, OrderLine } from "store/data/order/order";
import { handDescription } from "store/data/scan/scan";
import { styles } from "../styles";

export interface MMPdfOrderLinesProps {
  order: Order;
  orderLines: OrderLine[]; //a subselection of order lines to print
}

function MMPdfOrderLines(props: MMPdfOrderLinesProps) {
  return (
    <View style={styles.table}>
      <View style={{ ...styles.tableHeader, ...styles.columns }}>
        <View style={styles.section_2}>
          <Text>{txt.get("pdfs.quotation.code")}</Text>
          <Text style={styles.soft}>{txt.get("pdfs.quotation.product")}</Text>
        </View>
        <View style={styles.section_6}>
          <Text>{txt.get("pdfs.quotation.description")}</Text>
          <Text style={styles.soft}>
            {txt.get("pdfs.quotation.cost_codes")}
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.amount")}
          </Text>
          <Text style={[{ textAlign: "right" }, styles.soft]}>
            {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")}
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.sub_total")}
          </Text>
          <Text style={[{ textAlign: "right" }, styles.soft]}>
            {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")}
          </Text>
        </View>
      </View>
      {props.orderLines.map((orderLine: OrderLine, i: number) => (
        <View key={`order-line-${i}`}>
          <View
            style={[
              orderLine.costing?.amount_effort
                ? styles.tableRowBetween
                : styles.tableRow,
              styles.columns,
              styles.small,
            ]}
          >
            <View
              style={[
                styles.section_2,
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <View>
                <Text>
                  {props.order.costing?.type === OrderCostingType.InvoicePrivate
                    ? orderLine.code
                    : orderLine.costing?.contract?.insurer_product_code}
                </Text>
              </View>
              <View>
                <Text style={[styles.small, styles.soft]}>
                  {props.order.costing?.type === OrderCostingType.InvoicePrivate
                    ? ""
                    : orderLine.costing?.contract
                        ?.product_declaration_description}
                </Text>
              </View>
              {orderLine.costing?.amount_effort ? (
                <View>
                  <Text>
                    {props.order.costing?.type ===
                    OrderCostingType.InvoicePrivate
                      ? ""
                      : orderLine.costing?.contract?.hourly_code}
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
            <View
              style={[
                styles.section_6,
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <View>
                <Text>
                  {props.order.costing?.type === OrderCostingType.InvoicePrivate
                    ? orderLine.product_selection.type.toUpperCase()
                    : orderLine.costing?.description
                      ? orderLine.costing?.description?.toUpperCase()
                      : orderLine.costing?.contract?.invoice_line_description?.toUpperCase()}
                  {" - "}
                  {handDescription(
                    orderLine.hand?.toString() || ""
                  ).toUpperCase()}
                  {orderLine.digits && orderLine.digits.length > 0
                    ? ` (${orderLine.digits.join(",")})`
                    : ""}
                </Text>
              </View>
              <View>
                {props.order.costing?.type ===
                OrderCostingType.InvoicePrivate ? (
                  <Text style={[styles.small, styles.soft]}></Text>
                ) : (
                  <Text style={[styles.small, styles.soft]}>
                    {txt.up("pdfs.quotation.gph")}:{" "}
                    {orderLine.costing?.contract?.product_declaration_code}
                    {orderLine.costing?.contract
                      ?.product_declaration_additional_code1
                      ? `, ${txt.up(
                          "pdfs.quotation.a_gph_1"
                        )}: ${toAPGHForOrderLine(orderLine)}`
                      : ""}
                    {orderLine.costing?.contract
                      ?.product_declaration_additional_code2
                      ? `, ${txt.up("pdfs.quotation.a_gph_2")}: ${
                          orderLine.costing?.contract
                            ?.product_declaration_additional_code2
                        }`
                      : ""}
                    , {txt.up("pdfs.quotation.cost_type")}:
                    {toCostType(orderLine)}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={[
                styles.section_2,
                { justifyContent: "center" },
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <Text style={{ textAlign: "right" }}>
                {orderLine.costing?.amount
                  ? StringHelper.currency(orderLine.costing?.amount)
                  : "-"}
              </Text>
            </View>
            <View
              style={[
                styles.section_2,
                { justifyContent: "center" },
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <Text style={{ textAlign: "right" }}>
                {orderLine.costing?.amount
                  ? StringHelper.currency(
                      orderLine.costing?.amount +
                        (orderLine.costing?.amount_effort || 0)
                    )
                  : "-"}
              </Text>
            </View>
          </View>
          {orderLine.costing?.amount_effort ? (
            <View>
              <View style={[styles.tableRow, styles.columns, styles.small]}>
                <View style={styles.section_2}>
                  {orderLine.costing?.amount_effort ? (
                    <View>
                      <Text>
                        {i === 0
                          ? orderLine.costing?.contract
                              ?.measuring_fee_first_code
                          : orderLine.costing?.contract
                              ?.measuring_fee_consecutive_code}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.section_6}>
                  <View>
                    <Text>
                      {txt.up("pdfs.quotation.measure")}
                      {/* {order.costing?.type === OrderCostingType.InvoicePrivate
                        ? txt.up("pdfs.quotation.measure")
                        : orderLine.costing?.description_effort?.toUpperCase()} */}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.section_2, justifyContent: "center" }}>
                  <Text style={{ textAlign: "right" }}>
                    {orderLine.costing?.amount_effort
                      ? StringHelper.currency(orderLine.costing?.amount_effort)
                      : "-"}
                  </Text>
                </View>
                <View style={{ ...styles.section_2, justifyContent: "center" }}>
                  <Text style={{ textAlign: "right" }}></Text>
                </View>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      ))}
      <View style={[styles.tableFooter, styles.columns]}>
        <View style={{ ...styles.section_10, alignItems: "flex-end" }}>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.total")}{" "}
            {txt.lo("pdfs.quotation.vat_exclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")} :
          </Text>
          <Text style={{ textAlign: "right" }}>
            {txt.lo("pdfs.quotation.vat")} ({StringHelper.percentage(VAT_LOW)}):
          </Text>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.total")}{" "}
            {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")}:
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={{ textAlign: "right" }}>
            {StringHelper.currency(costingTotal(props.orderLines, VAT_LOW))}
          </Text>
          <Text style={{ textAlign: "right" }}>
            {StringHelper.currency(
              costingTotal(props.orderLines, VAT_LOW, true)
            )}
          </Text>
          <Text style={{ textAlign: "right" }}>
            {StringHelper.currency(costingTotal(props.orderLines))}
          </Text>
        </View>
      </View>
    </View>
  );
}
export default MMPdfOrderLines;
