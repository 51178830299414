import { A4_H_5, A4_P_S, A4_W_10, style } from "./params";

export const styles = style({
  font: {
    fontFamily: "futura-pt",
    fontSize: "10pt",
  },
  title: {
    fontWeight: 600,
    fontSize: "14pt",
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "10pt",
  },
  small: {
    fontSize: "9pt",
  },
  soft: {
    color: "#777777",
  },
  logo: {
    width: "120pt",
    height: "18pt",
    objectFit: "contain",
  },
  logoFooter: {
    width: "80pt",
    height: "12pt",
    objectFit: "contain",
  },
  addressPaneHolder: {
    height: A4_H_5,
    width: A4_W_10,
    padding: A4_P_S,
    justifyContent: "center",
    // borderWidth: 1,
  },
  addressPane: {
    // flexGrow: 1,
  },
  p: {
    padding: `${A4_P_S} 0pt`,
  },
});
