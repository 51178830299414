import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ConnectAPIHelper from "api/connect-api-helper";
import txt from "helpers/text-helper";
import { columnDateOnly, columnString } from "hoc/helper-hooks";
import { useNavigate } from "react-router-dom";
import { Workflow } from "store/data/production/workflow";
import { WorkflowStep } from "store/data/production/workflow-step";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMWorkflowsList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "workflow_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("workflow_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "workflow_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "location_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [error, setError] = useState("");
  // const [isActiveValue, setIsActiveValue] = useState("_");

  const [workflows, setWorkflows] = useState<Workflow[]>([]);

  useEffect(() => {
    const loadWorkflows = async () => {
      setIsLoading(true);

      let filters: any = {};

      const result = await api.getWorkflows(
        filters,
        limit,
        offset,
        workflowFieldToSortKey(sortBy),
        sortOrder
      );
      if (result.status === "OK") {
        setWorkflows(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setWorkflows([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadWorkflows();
  }, [sortOrder, sortBy, limit, offset]);

  useEffect(() => {
    const loadWorkflows = async () => {
      setIsLoading(true);
      const result = await api.getWorkflows();
      if (result.status === "OK") {
        setWorkflows(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setWorkflows([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadWorkflows();
  }, []);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.workflows.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.workflows.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const workflowFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onWorkflowsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("admin.workflows.id"),
      field: "id",
      sortable: api.workflowIsSortableBy(workflowFieldToSortKey("id")),
      style: { minWidth: "60px" },
    },
    {
      name: txt.get("admin.workflows.name"),
      field: "name",
      sortable: api.workflowIsSortableBy(workflowFieldToSortKey("name")),
    },
    {
      name: txt.get("generic.acronym"),
      field: "acronym",
      sortable: api.workflowIsSortableBy(workflowFieldToSortKey("acronym")),
      render: columnString,
    },
    {
      name: txt.get("admin.workflows.steps"),
      field: "workflow_steps",
      sortable: false,
      render: (workflow_steps: WorkflowStep[]) =>
        workflow_steps ? workflow_steps.length : 0,
    },
    {
      name: txt.get("admin.workflows.days"),
      field: "workflow_steps",
      sortable: false,
      render: (workflow_steps: WorkflowStep[]) =>
        workflow_steps
          ? workflow_steps.reduce(
              (total: number, step: WorkflowStep) =>
                Math.max(total, step.delivery_day || 0),
              0
            )
          : 0,
    },
    {
      name: txt.get("generic.is_active"),
      sortable: api.workflowIsSortableBy(workflowFieldToSortKey("is_active")),
      type: "boolean",
      field: "is_active",
      render: (is_active: boolean) => (
        <EuiHealth color={is_active ? "success" : "danger"}>
          {is_active ? txt.get("generic.yes") : txt.get("generic.no")}
        </EuiHealth>
      ),
    },
    {
      name: txt.get("generic.created_at"),
      sortable: api.workflowIsSortableBy(workflowFieldToSortKey("created_at")),
      type: "date",
      field: "created_at",
      style: { minWidth: "90px" },
      render: columnDateOnly,
    },
    {
      name: txt.get("generic.updated_at"),
      sortable: api.workflowIsSortableBy(workflowFieldToSortKey("updated_at")),
      type: "date",
      field: "updated_at",
      style: { minWidth: "90px" },
      render: columnDateOnly,
    },
  ];

  const getRowProps = (workflow: any) => {
    const { id } = workflow;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          navigate(`/admin/workflows/${id}`);
        }
      },
    };
  };

  // const isActiveOptions = [
  //   {
  //     value: "_",
  //     dropDownDisplay: (
  //       <EuiHealth color="success" style={{ lineHeight: "inherit" }}>
  //         {txt.get("generic.active")}
  //       </EuiHealth>
  //     ),
  //     inputDisplay: (
  //       <EuiText color="subdued" size="xs">
  //         {txt.uf("generic.select_x", txt.get("generic.active_state"))}
  //       </EuiText>
  //     ),
  //   },
  //   {
  //     value: "yes",

  //     inputDisplay: (
  //       <EuiHealth
  //         textSize="xs"
  //         color="success"
  //         style={{ lineHeight: "inherit" }}
  //       >
  //         {txt.get("generic.active")}
  //       </EuiHealth>
  //     ),
  //   },
  //   {
  //     value: "no",
  //     inputDisplay: (
  //       <EuiHealth
  //         textSize="xs"
  //         color="danger"
  //         style={{ lineHeight: "inherit" }}
  //       >
  //         {txt.get("generic.inactive")}
  //       </EuiHealth>
  //     ),
  //   },
  // ];
  // const onIsActiveChange = (value: string) => {
  //   setIsActiveValue(value);
  // };
  return (
    <Fragment>
      {/* <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            compressed={true}
            style={{ width: "225px" }}
            options={isActiveOptions}
            valueOfSelected={isActiveValue}
            onChange={(value) => onIsActiveChange(value)}
          ></EuiSuperSelect>
        </EuiFlexItem>
      </EuiFlexGroup> */}
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={workflows}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf(
                "generic.found_no_x",
                txt.get("admin.workflows.page_title")
              )
        }
        onChange={onWorkflowsChange}
      />
    </Fragment>
  );
}

export default MMWorkflowsList;
