import { Document, Font, PDFViewer, Text, View } from "@react-pdf/renderer";
import DateHelper from "helpers/date-helper";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Appointment } from "store/data/appointment/appointment";
import { Client } from "store/data/client/client";
import { ClientInsurance } from "store/data/client/insurance";
import {
  consentOfType,
  OrderConsent,
  OrderConsentType,
} from "store/data/consent/consent";
import {
  JotformProductEvaluationQA,
  qaFilteredForDossier,
  qaToAnswerString,
} from "store/data/evaluation/evaluation";
import { File } from "store/data/file/file";
import {
  Fitting,
  fittingScoreString,
  lastFitting,
} from "store/data/fitting/fitting";
import {
  IndicationGrouped,
  indicationsGrouped,
  IndicationSide,
} from "store/data/indication/indication";
import { getNeeds, Need } from "store/data/need/need";
import {
  getLastDeliveryStatusLog,
  Order,
  OrderLine,
  orderStatusDescription,
  OrderStatusLog,
} from "store/data/order/order";
import {
  formalName,
  fullName,
} from "store/data/personal-details/personal-details";
import { Referral } from "store/data/referral/referral";
import { handDescription } from "store/data/scan/scan";
import fontFuturaPTBold from "./assets/fonts/futura-pt-bold.ttf";
import fontFuturaPT from "./assets/fonts/futura-pt.ttf";
import MMPdfClientAddress from "./sections/pdf-client-address";
import MMPdfClientAppointments from "./sections/pdf-client-appointments";
import MMPdfClientContactMoments from "./sections/pdf-client-contact-moments";
import MMPdfMarkdown from "./sections/pdf-markdown";
import MMPdfOrderLines from "./sections/pdf-order-lines";
import MMPdfOrderProducts from "./sections/pdf-order-products";
import MMPdfPage from "./sections/pdf-page";
import { styles } from "./styles";
export const pdfnltobr = (value: string) => {
  return value.replaceAll("<br>", "\n").replaceAll("<br/>", "\n");
};
export interface MMPdfClientDossierProps {
  type: "view" | "download";
  client: Client;
  insurance: ClientInsurance | null;
  contactMoments: any[];
  appointments: Appointment[];
  referralsWithOrders: Referral[];
}

function MMPdfClientDossier(props: MMPdfClientDossierProps) {
  const [client, setClient] = useState<Client>(props.client);
  const [insurance, setInsurance] = useState<ClientInsurance | null>(
    props.insurance
  );
  const [contactMoments, setContactMoments] = useState<any[]>(
    props.contactMoments
  );
  const [appointments, setAppointments] = useState<Appointment[]>(
    props.appointments
  );
  const [referralsWithOrders, setReferralsWithOrders] = useState<Referral[]>(
    props.referralsWithOrders
  );

  useEffect(() => {
    setClient(props.client);
    setInsurance(props.insurance);
    setContactMoments(props.contactMoments);
    setAppointments(props.appointments);
    setReferralsWithOrders(props.referralsWithOrders);
  }, [props]);

  Font.register({
    family: "futura-pt",

    fonts: [{ src: fontFuturaPT }, { src: fontFuturaPTBold, fontWeight: 600 }],
  });

  console.log({
    client,
    insurance,
    contactMoments,
    appointments,
    referralsWithOrders,
  });

  const toTitle = (client: Client) =>
    `${txt.get("pdfs.client_dossier.name")} ${client.client_code || "."} ${DateHelper.toSortable()}`;

  const renderIntro = () => (
    <View style={styles.addressPaneHolder}>
      <MMPdfClientAddress
        client={client}
        salutation={txt.get("clients.name")}
      />
    </View>
  );

  const renderClientIds = () => (
    <View>
      <Text style={styles.soft}>
        {txt.get("pdfs.client_dossier.connect_id_signifier")}
        {client.id || "0"}.{txt.get("pdfs.client_dossier.vlot_id_signifier")}
        {client.vlot_id || "0"}.
        {txt.get("pdfs.client_dossier.leestnummer_signifier")}
        {client.vlot_client_code || "0"}
      </Text>
    </View>
  );

  const renderClientDetails = () => (
    <View>
      <View style={{ ...styles.columns }}></View>
      <View style={{ ...styles.columns }}>
        <View style={styles.section_6}>
          <View style={styles.columns}>
            <View
              style={{
                ...styles.section_2,
                paddingLeft: "0pt",
                paddingTop: "0pt",
              }}
            >
              <Text>{txt.get("pdfs.client_dossier.client_code")}:</Text>
              <Text>{txt.get("pdfs.client_dossier.birth_date")}:</Text>
              <Text>
                {txt.get("pdfs.client_dossier.citizen_service_number")}:
              </Text>
              <Text style={styles.soft}>
                {txt.get("pdfs.client_dossier.client_signifier")}:
              </Text>
            </View>
            <View
              style={{
                ...styles.section_4,
                paddingLeft: "0pt",
                paddingTop: "0pt",
              }}
            >
              <Text>{client.client_code}</Text>
              <Text>
                {client.birth_date
                  ? DateHelper.toDateFullYear(client.birth_date)
                  : "-"}
              </Text>
              <Text>{client.bsn || "-"}</Text>
              {renderClientIds()}
            </View>
          </View>
        </View>
        <View style={styles.section_6}>
          <View style={styles.columns}>
            <View
              style={{
                ...styles.section_2,
                paddingLeft: "0pt",
                paddingTop: "0pt",
              }}
            >
              <Text>{txt.get("pdfs.client_dossier.insurer")}:</Text>
              <Text>{txt.get("pdfs.client_dossier.insurance")}:</Text>
              <Text>
                {txt.get("pdfs.client_dossier.insurance_registration_number")}:
              </Text>
            </View>
            <View
              style={{
                ...styles.section_4,
                paddingLeft: "0pt",
                paddingTop: "0pt",
              }}
            >
              <Text>{insurance?.insurer_name || "-"}</Text>
              <Text>{insurance?.insurance_name || "-"}</Text>
              <Text>{insurance?.registration_number || "-"}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ ...styles.columns }}>
        <View style={styles.section_12}>
          <View style={styles.columns}>
            <View
              style={{
                ...styles.section_2,
                paddingLeft: "0pt",
                paddingTop: "0pt",
              }}
            >
              <Text>{txt.get("pdfs.client_dossier.email")}:</Text>
              <Text>{txt.get("pdfs.client_dossier.phone")}:</Text>
            </View>
            <View
              style={{
                ...styles.section_10,
                paddingLeft: "0pt",
                paddingTop: "0pt",
              }}
            >
              <Text>{client.email || "-"}</Text>

              <Text>{client.phone_number || "-"}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const renderReferral = (referral: Referral, order?: Order) => (
    <View wrap={false}>
      <View style={styles.columns}>
        <View style={[styles.section_6, styles.tableHeader]}>
          <Text style={styles.title}>
            {txt.get("pdfs.client_dossier.intake")}{" "}
          </Text>
        </View>
        <View style={[styles.section_6, styles.tableHeader, styles.right]}>
          <Text style={[styles.subtitle, { paddingTop: "4pt" }]}>
            {order ? fullName(order.personal_details) : "-"}{" "}
            {referral.intake_at ? DateHelper.toDate(referral.intake_at) : "-"}
          </Text>
        </View>
      </View>

      <View style={styles.columns}>
        <View style={[styles.section_4]}>
          <Text style={styles.subtitle}>
            {txt.get("pdfs.client_dossier.referral_files")}
          </Text>

          <Text>
            {referral.referred_at
              ? DateHelper.toDate(referral.referred_at)
              : "-"}
          </Text>

          {referral?.files && referral.files.length > 0 ? (
            referral.files.map((file: File, i: number) => (
              <Text key={`file-${i}`}>{file.name}</Text>
            ))
          ) : (
            <Text>-</Text>
          )}
          {referral?.files.length > 0 ? (
            <Text style={styles.soft}>
              {txt.get("pdfs.client_dossier.see_attached")}
            </Text>
          ) : (
            <></>
          )}
        </View>
        <View style={[styles.section_4]}>
          <Text style={styles.subtitle}>
            {txt.get("pdfs.quotation.referrer")}
          </Text>
          <Text>
            {referral.referrer?.person
              ? formalName(referral.referrer.person)
              : ""}
          </Text>
          {referral?.referrer?.agb_code ? (
            <Text style={styles.soft}>
              {txt.get("pdfs.quotation.agb_code")}{" "}
              {referral?.referrer?.agb_code}
            </Text>
          ) : (
            <></>
          )}
          <Text>
            {referral?.referrer?.organisation
              ? referral.referrer.organisation.name
              : referral?.referrer?.alternative_organisation
                ? referral?.referrer?.alternative_organisation
                : ""}
          </Text>
          {referral?.referrer?.organisation_agb_code ? (
            <Text style={styles.soft}>
              {txt.get("pdfs.quotation.agb_code")}{" "}
              {referral?.referrer?.organisation_agb_code}
            </Text>
          ) : (
            <></>
          )}
        </View>
        <View style={[styles.section_4]}>
          <Text style={[styles.subtitle]}>
            {txt.get("pdfs.quotation.indication")}
          </Text>
          {referral?.indications && referral?.indications.length > 0 ? (
            indicationsGrouped(referral.indications).map(
              (indication: IndicationGrouped, i: number) => (
                <Text key={`indication-${i}`}>
                  {indication.indication}{" "}
                  {indication.sides
                    .map((side: IndicationSide) =>
                      handDescription(side, "pdfs.indication.hand")
                    )
                    .join(" & ")
                    .toLowerCase()}
                </Text>
              )
            )
          ) : (
            <Text>-</Text>
          )}
        </View>
      </View>
    </View>
  );

  const toNeedText = (need: Need, split: boolean = false) => {
    let result: string = "";
    result +=
      need.answer === "true"
        ? txt.get(`needs.questions.${need.question}`)
        : split
          ? need.answer
              .split(",")
              // .map((val: string) => StringHelper.ucfirst(val))
              .join(", ")
          : StringHelper.ucfirst(need.answer);
    //should be changes to markdown
    if (result.startsWith("<em>")) {
      result = result.replace("<em>", "").replace("</em>", "");
    }
    return StringHelper.ucfirst(result);
  };

  const renderNeeds = (needs: Need[]) => (
    <View wrap={false} style={styles.columns}>
      <View style={styles.section_3}>
        <Text style={styles.subtitle}>
          {txt.get("needs.questions.care_needs.title")}
        </Text>
        {getNeeds(needs, "care_needs").map((need: Need, i: number) => (
          <Text key={`need-${i}`}>{toNeedText(need)}</Text>
        ))}
      </View>
      <View style={styles.section_3}>
        <Text style={styles.subtitle}>
          {txt.get("needs.questions.activities.title")}
        </Text>
        {getNeeds(needs, "activities.activities").map(
          (need: Need, i: number) => (
            <Text key={`need-${i}`}>{toNeedText(need, true)}</Text>
          )
        )}
        {getNeeds(needs, "activities.hobbies").map((need: Need, i: number) => (
          <Text key={`need-${i}`}>{toNeedText(need, true)}</Text>
        ))}
      </View>
      <View style={styles.section_3}>
        <Text style={styles.subtitle}>
          {txt.get("needs.questions.usage.usage_title")}
        </Text>
        {getNeeds(needs, "usage").map((need: Need, i: number) => (
          <Text key={`need-${i}`}>{toNeedText(need)}</Text>
        ))}
      </View>
      <View style={styles.section_3}>
        <Text style={styles.subtitle}>
          {txt.get("needs.questions.characteristics.title")}
        </Text>
        {getNeeds(needs, "characteristics").map((need: Need, i: number) => (
          <Text key={`need-${i}`}>{toNeedText(need)}</Text>
        ))}
      </View>
    </View>
  );

  const renderDelivery = (order: Order) => {
    const lastDeliveryStatus: OrderStatusLog | null = getLastDeliveryStatusLog(
      order.order_status_logs
    );
    return (
      <View wrap={false}>
        <View style={styles.spacer} />
        <View style={styles.columns}>
          <View style={[styles.section_6, styles.tableHeader]}>
            <Text style={styles.title}>
              {txt.get("pdfs.client_dossier.delivery")}
            </Text>
          </View>
          <View style={[styles.section_6, styles.tableHeader, styles.right]}>
            <Text style={[styles.subtitle, { paddingTop: "4pt" }]}>
              {lastDeliveryStatus
                ? orderStatusDescription(lastDeliveryStatus.new_status)
                : ""}{" "}
              {lastDeliveryStatus
                ? DateHelper.toDate(lastDeliveryStatus.logged_at)
                : ""}
            </Text>
          </View>
        </View>
        {lastDeliveryStatus ? (
          <View style={styles.table}>
            <View style={[styles.tableHeader, styles.columns]}>
              <View style={styles.section_4}>
                <Text style={styles.soft}>
                  {txt.get("pdfs.quotation.product")}
                </Text>
              </View>
              <View style={styles.section_2}>
                <Text style={styles.soft}>{txt.get("pdfs.fitting.fit")}</Text>
              </View>
              <View style={styles.section_2}>
                <Text style={styles.soft}>
                  {txt.get("pdfs.fitting.function")}
                </Text>
              </View>
              <View style={styles.section_2}>
                <Text style={styles.soft}>
                  {txt.get("pdfs.fitting.comfort")}
                </Text>
              </View>
              <View style={styles.section_2}>
                <Text style={styles.soft}>{txt.get("pdfs.fitting.usage")}</Text>
              </View>
            </View>
            {order.order_lines.map((orderLine: OrderLine, i: number) => {
              const fitting: Fitting | undefined =
                lastFitting(orderLine.fittings) || undefined;

              return (
                <View key={`order-line-fitting-${i}`}>
                  <View style={[styles.columns, styles.small]}>
                    <View style={styles.section_4}>
                      <Text>{orderLine.code}</Text>
                    </View>
                    <View style={styles.section_2}>
                      <Text>
                        {fittingScoreString("fitting.fit", fitting) || "-"}
                      </Text>
                    </View>
                    <View style={styles.section_2}>
                      <Text>
                        {fittingScoreString("fitting.function", fitting) || "-"}
                      </Text>
                    </View>
                    <View style={styles.section_2}>
                      <Text>
                        {fittingScoreString("fitting.comfort", fitting) || "-"}
                      </Text>
                    </View>
                    <View style={styles.section_2}>
                      <Text>
                        {fittingScoreString("fitting.usage", fitting) || "-"}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.tableRow, styles.columns, styles.small]}>
                    <View style={styles.section_4}>
                      {fitting?.personal_details ? (
                        <Text style={styles.soft}>
                          {fullName(fitting.personal_details)}
                        </Text>
                      ) : (
                        <></>
                      )}
                      <Text>
                        {fitting && fitting.is_fitting
                          ? txt.get("pdfs.fitting.product_fit")
                          : txt.get("pdfs.fitting.product_no_fit")}
                      </Text>
                      <Text>
                        {fitting && !fitting.is_fitting && fitting.reason_unfit
                          ? fitting.reason_unfit
                          : ""}
                      </Text>
                    </View>
                    <View style={styles.section_8}>
                      <Text>{fitting ? fitting.notes : "-"}</Text>
                      <Text></Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ) : (
          <View style={styles.columns}>
            <View style={styles.section_12}>
              <Text>-</Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  const renderConsent = (consent: OrderConsent) => (
    <View style={[styles.soft, styles.small]}>
      <MMPdfMarkdown>{consent.consents_to}</MMPdfMarkdown>
      <View style={styles.columns_np}>
        <View style={styles.section_2}>
          <Text>{txt.get("pdfs.client_dossier.client_code")}</Text>
          <Text>{txt.get("pdfs.client_dossier.date")}</Text>
          <Text>{txt.get("pdfs.client_dossier.consent")}</Text>
        </View>
        <View style={styles.section_2}>
          <Text>{consent.client_code}</Text>
          <Text>{DateHelper.toDate(consent.consented_at)}</Text>
          <Text>
            {consent.consents
              ? txt.get("pdfs.client_dossier.yes")
              : txt.get("pdfs.client_dossier.no")}
          </Text>
        </View>
      </View>
    </View>
  );

  const renderConsents = (order: Order) => {
    let submitConsent: OrderConsent | null = consentOfType(
      OrderConsentType.Submit,
      order.consents
    );
    let deliverConsent: OrderConsent | null = consentOfType(
      OrderConsentType.Deliver,
      order.consents
    );

    return (
      <View>
        <View style={styles.spacer} />
        <View wrap={false}>
          <View style={styles.columns}>
            <View style={[styles.section_6, styles.tableHeader]}>
              <Text style={styles.subtitle}>
                {txt.get("pdfs.client_dossier.consent_order")}
              </Text>
            </View>
            <View style={[styles.tableHeader, styles.section_6]}>
              <Text style={styles.subtitle}>
                {txt.get("pdfs.client_dossier.consent_delivery")}
              </Text>
            </View>
          </View>
          <View style={styles.columns}>
            <View style={[styles.section_6, styles.tableHeader]}>
              {submitConsent ? (
                renderConsent(submitConsent)
              ) : (
                <View>
                  <Text>-</Text>
                </View>
              )}
            </View>
            <View style={[styles.tableHeader, styles.section_6]}>
              {deliverConsent ? (
                renderConsent(deliverConsent)
              ) : (
                <View>
                  <Text>-</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderEvaluation = (order: Order) => {
    return (
      <View>
        <View wrap={false}>
          <View style={styles.spacer} />
          <View style={styles.columns}>
            <View style={[styles.section_6, styles.tableHeader]}>
              <Text style={styles.title}>
                {txt.get("pdfs.client_dossier.evaluation")}
              </Text>
            </View>
            <View style={[styles.section_6, styles.tableHeader, styles.right]}>
              <Text style={[styles.subtitle, { paddingTop: "4pt" }]}></Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableHeader, styles.columns]}>
              <View style={styles.section_4}>
                <Text style={styles.soft}>
                  {txt.get("pdfs.quotation.product")}
                </Text>
              </View>
              <View style={styles.section_4}>
                <Text style={styles.soft}>
                  {txt.get("pdfs.evaluation.submitted_at")}
                </Text>
              </View>
              <View style={styles.section_4}>
                <Text style={styles.soft}>
                  {txt.get("pdfs.evaluation.nps")}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          {order.order_lines.map((orderLine: OrderLine, i: number) => {
            return (
              <View key={`order-line-evaluation-${i}`}>
                <View style={[styles.columns, styles.small]}>
                  <View style={styles.section_4}>
                    <Text>{orderLine.code}</Text>
                  </View>
                  <View style={styles.section_4}>
                    <View>
                      <Text>
                        {orderLine.nps_evaluation
                          ? DateHelper.toDate(
                              orderLine.nps_evaluation.submitted_at
                            )
                          : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.section_4}>
                    <View>
                      <Text>
                        {orderLine.nps_evaluation
                          ? `${orderLine.nps_evaluation.rating}/10`
                          : ""}
                      </Text>
                    </View>
                  </View>
                </View>
                {orderLine.jotform_product_evaluation ? (
                  qaFilteredForDossier(
                    orderLine.jotform_product_evaluation.questions_and_answers
                  ).map((qa: JotformProductEvaluationQA, i: number) => (
                    <View
                      wrap={false}
                      style={(styles.tableRow, styles.section_12)}
                      key={`order-line-evaluation-question-${i}`}
                    >
                      <Text style={[styles.soft, styles.small]}>
                        {qa.question}
                      </Text>
                      <Text style={styles.small}>{qaToAnswerString(qa)}</Text>
                    </View>
                  ))
                ) : (
                  <View>
                    <Text>-</Text>
                  </View>
                )}
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const renderOrder = (order: Order) => {
    return (
      <View>
        <View style={styles.spacer} />
        <View wrap={false}>
          <View style={styles.columns}>
            <View style={[styles.section_10, styles.tableHeader]}>
              <Text style={styles.title}>
                {txt.get("pdfs.client_dossier.order")} #{order.id}
              </Text>
            </View>
            <View style={[styles.section_2, styles.tableHeader, styles.right]}>
              <Text style={[styles.subtitle, { paddingTop: "4pt" }]}>
                {DateHelper.toDate(order.ordered_at)}
              </Text>
            </View>
          </View>
          {renderNeeds(order.needs || [])}
        </View>
        <View style={styles.spacer} />
        <MMPdfOrderLines order={order} orderLines={order.order_lines} />
        <MMPdfOrderProducts order={order} orderLines={order.order_lines} />
        {renderDelivery(order)}
        {renderConsents(order)}
        {renderEvaluation(order)}
      </View>
    );
  };
  const renderOrders = (orders: Order[]) => (
    <View>
      {orders.map((order: Order, i: number) => (
        <View key={`order-${i}`}>{renderOrder(order)}</View>
      ))}
    </View>
  );

  const renderReferralsWithOrders = (referralsWithOrders: Referral[]) =>
    referralsWithOrders.map((referral: Referral, i: number) => (
      <View break key={`referral-${i}`}>
        {renderReferral(
          referral,
          (referral.orders || []).find(
            (order: Order) => order.id === referral.first_order_id
          )
        )}
        {renderOrders(referral.orders || [])}
      </View>
    ));

  const renderDocument = () => (
    <Document title={toTitle(client)}>
      <MMPdfPage title={txt.get("pdfs.client_dossier.name").toUpperCase()}>
        {renderIntro()}
        {renderClientDetails()}
        <View break>
          <MMPdfClientContactMoments contactMoments={contactMoments} />
          <MMPdfClientAppointments appointments={appointments} />
        </View>
        {renderReferralsWithOrders(referralsWithOrders)}
      </MMPdfPage>
    </Document>
  );

  return props.type === "view" ? (
    <PDFViewer height={"100%"}>{renderDocument()}</PDFViewer>
  ) : (
    renderDocument()
  );
}

export default MMPdfClientDossier;
