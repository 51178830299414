import { File } from "../file/file";
import { PersonalDetails } from "../personal-details/personal-details";

export const FITTING_EMPTY: Fitting = {
  id: null,
  order_line_id: 0,
  fitting_scores: [],
  instructed: true,
};

export interface Fitting {
  id: number | null;
  order_line_id: number;
  personal_details?: PersonalDetails;
  is_fitting?: boolean;
  reason_unfit?: string;
  notes?: string;
  instructed: boolean;
  files?: File[];
  fitting_scores: FittingScore[];
}

export interface FittingScore {
  id?: number;
  fitting_id?: number;
  attribute: string;
  score: number;
}

export const lastFitting = (fittings?: Fitting[]) => {
  if (!fittings || fittings.length === 0) return null;

  let lastFitting: Fitting = fittings[fittings.length - 1];
  return lastFitting;
};

export const fittingScoreString = (attribute: string, fitting?: Fitting) => {
  if (!fitting) return "";
  let score: FittingScore | null =
    (fitting.fitting_scores
      ? fitting.fitting_scores.find(
          (score: FittingScore) => score.attribute === attribute
        )
      : null) || null;
  if (!score) return "";
  return score.score > 0 ? `${Math.floor(score.score / 2)}/5` : "-";
};
