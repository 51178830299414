import { Fitting } from "store/data/fitting/fitting";
import { Order, OrderLine } from "store/data/order/order";

export const isAllFittingHandled = (order: Order | null) => {
  if (!order || !order.order_lines) {
    return false;
  }
  for (let i = 0; i < order.order_lines.length; i++) {
    if (!validateFitting(toFitting(order, order.order_lines[i].id))) {
      return false;
    }
  }
  return true;
};

export const validateFittingScores = (fitting: Fitting | null | undefined) => {
  if (!fitting) return false;
  return fitting.fitting_scores.length >= 4;
};

export const validateFittingInstructed = (
  fitting: Fitting | null | undefined
) => {
  if (!fitting) return false;
  return fitting.is_fitting === true ? !!fitting.instructed : true;
};
export const validateFittingNotes = (fitting: Fitting | null | undefined) => {
  if (!fitting) return false;
  return fitting.is_fitting === false ? !!fitting.notes : true;
};
export const validateFittingReasonUnfit = (
  fitting: Fitting | null | undefined
) => {
  if (!fitting) return false;
  return fitting.is_fitting === false ? !!fitting.reason_unfit : true;
};

export const errorCountFitting = (fitting: Fitting | null | undefined) => {
  let result: number = 0;
  if (!validateFittingScores(fitting)) result++;
  if (!validateFittingInstructed(fitting)) result++;
  if (!validateFittingNotes(fitting)) result++;
  if (!validateFittingReasonUnfit(fitting)) result++;
  return result;
};

export const validateFitting = (fitting: Fitting | null | undefined) =>
  validateFittingScores(fitting) &&
  validateFittingInstructed(fitting) &&
  validateFittingNotes(fitting) &&
  validateFittingReasonUnfit(fitting);

export const toFitting = (order?: Order | null, orderLineId?: number) => {
  if (order && order.order_lines && orderLineId) {
    const orderLine: OrderLine | undefined = order.order_lines.find(
      (orderLine: OrderLine) => orderLine.id === orderLineId
    );
    if (orderLine && orderLine.fittings && orderLine.fittings.length > 0) {
      return orderLine.fittings[0];
    }
  }
  return null;
};
