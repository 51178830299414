import env from "helpers/env-helper";

export const versionInfo = () => {
  const git_commit = env("REACT_APP_GIT_COMMIT");
  const api_connect = env("REACT_APP_API_CONNECT");
  const commit = git_commit ? git_commit.substring(0, 6) : "_d"; /* d for dev */
  const api = api_connect
    ? api_connect.replace("https://", "").replace("http://", "").substring(0, 1)
    : "_l"; /* l for local */
  return `v.${commit}.${api}`;
};
