import {
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageSection,
  EuiPageSidebar,
  EuiSideNav,
  EuiSpacer,
  htmlIdGenerator,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import MMMenu from "components/layouts/menu/menu";
import MetaInfoHelper from "helpers/meta-info-helper";
import txt from "helpers/text-helper";
import { Fragment, ReactNode, useEffect } from "react";
import { To, useNavigate } from "react-router-dom";

export interface PageLink {
  title: string;
  href: string;
  active?: boolean;
  icon?: string;
  items?: PageLink[];
}

interface MMPageProps {
  title?: string;
  subTitle?: ReactNode | string;
  hideTitle?: boolean;
  className?: string;
  children?: any;
  backTo?: string;
  backToText?: string;
  share?: boolean;
  sideActions?: any[];
  topActions?: any[];
  subPages?: PageLink[];
}

function MMPage(props: MMPageProps) {
  const navigate = useNavigate();
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);

  useEffect(() => {
    MetaInfoHelper.setTitle(props.title);
  });

  const getTitle = () => {
    if (props.hideTitle) {
      return;
    } else if (props.share) {
      return (
        <Fragment>
          {props.title ? `${props.title}.` : ""}&nbsp;
          <EuiCopy content="Copy link" textToCopy={window.location.href}>
            {(copy) => (
              <EuiButtonIcon
                aria-label="Copy page link"
                size="xs"
                iconType="share"
                onClick={copy}
              >
                Copy Link
              </EuiButtonIcon>
            )}
          </EuiCopy>
        </Fragment>
      );
    } else {
      return props.title ? `${props.title}.` : "";
    }
  };

  const toSideNavItems: any = (subPages: PageLink[]) => {
    return subPages.map((page) => ({
      name: page.title,
      id: htmlIdGenerator(page.title)(),
      onClick: () => {
        navigate(page.href);
      },
      isSelected: page.active,
      icon: page.icon ? <EuiIcon type={page.icon} /> : null,
      items: page.items ? toSideNavItems(page.items) : null,
    }));
  };
  return (
    <EuiPage style={{ backgroundColor: "#d1dfdf" }}>
      {props.subPages && props.subPages.length > 0 ? (
        <EuiPageSidebar paddingSize="m">
          <EuiSideNav
            isOpenOnMobile={false}
            items={toSideNavItems(props.subPages)}
          />
        </EuiPageSidebar>
      ) : (
        <MMMenu />
      )}
      <EuiPageBody
        paddingSize="xl"
        style={
          isMobileScreen
            ? {}
            : {
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                border: "solid 1px #d1dfdf",
                backgroundColor: "#f9f9f9",
              }
        }
      >
        {props.topActions ? (
          <EuiFlexGroup gutterSize="none" style={{ flexGrow: 0 }}>
            <EuiFlexItem>
              <EuiFlexGroup style={{ flexGrow: 0 }}>
                {(props.topActions || []).map((action: any, i: number) => (
                  <EuiFlexItem key={`action-${i}`} grow={false}>
                    {action}
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup
                style={{ flexGrow: 0 }}
                justifyContent="flexEnd"
                alignItems="center"
              >
                {(props.sideActions || []).map((action: any, i: number) => (
                  <EuiFlexItem key={`action-${i}`} grow={false}>
                    {action}
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <></>
        )}
        {(!props.title || props.hideTitle) &&
        !props.subTitle &&
        // (!props.sideActions || props.sideActions.length === 0) &&
        !props.backTo ? (
          <></>
        ) : (
          <EuiPageHeader
            paddingSize="xs"
            pageTitle={getTitle()}
            description={props.subTitle ?? ""}
            rightSideItems={!props.topActions ? props.sideActions : undefined}
            breadcrumbs={
              props.backTo
                ? [
                    {
                      text: (
                        <>
                          <EuiIcon size="s" type="arrowLeft" />{" "}
                          {props.backToText
                            ? props.backToText
                            : txt.get("generic.back")}
                        </>
                      ),
                      color: "primary",
                      "aria-current": false,
                      href: props.backTo,
                      onClick: (e) => {
                        e.preventDefault();
                        navigate(props.backTo as To);
                      },
                    },
                  ]
                : [
                    {
                      text: (
                        <>
                          <EuiSpacer />
                        </>
                      ),
                    },
                  ]
            }
          ></EuiPageHeader>
        )}

        <EuiPageSection paddingSize="s">
          <div>{props.children}</div>
        </EuiPageSection>
      </EuiPageBody>
    </EuiPage>
  );
}

export default MMPage;
