// export enum OrganisationOrdering {
//     Internal = "INTERNAL",
//     External = "EXTERNAL",
//     B2B = "B2B",
//   }

import { Addressee } from "../adress/address";
import { ContactMethod } from "../contactmethod/contactmethod";

//not all are relevant atm.
export const UZOVI_CODE_EMPTY: UzoviCode = {
  id: 0,
  insurer_id: null,
  name: "",
  code: "",
};

export const INSURER_EMPTY: Insurer = {
  id: null,
  name: "",
  acronym: "",
  uzovi_codes: [],
  email: "",
  website: "",
  quotation_contact: "",
  quotation_type: "",
  quotation_answer: "",
  payment_term_days: null,
  declaration_term_days: null,
  contract_notes: "",
  is_active: true,
};

export interface UzoviCode {
  id: number;
  insurer_id: number | null;
  name: string;
  code: string;
  insurer?: InsurerSummary;
  addressees?: Addressee[];
  contact_methods?: ContactMethod[];
}
export interface InsurerSummary {
  id: number | null;
  name: string;
  acronym: string;
}
export interface Insurer {
  id: number | null;
  name: string;
  acronym: string;
  uzovi_codes: UzoviCode[];
  email: string;
  website: string;
  quotation_contact: string;
  quotation_type: string;
  quotation_answer: string;
  payment_term_days: number | null;
  declaration_term_days: number | null;
  contract_notes: string;
  is_active: boolean;
  created_at?: Date;
  updated_at?: Date;
}
